.autocomplete {
    display: flex;
    position: relative;
    width: 100%;

    &__focus {
        position: absolute;
        opacity: 0;
        width: 0;
        z-index: -1;
    }

    &.autocomplete-input {
        input {
            @include font-medium;
            @include font(16px, 19px);
            border-radius: 0;
            -webkit-appearance: none;
            -webkit-border-radius: 0;
            padding-left: 15px;
            padding-right: 15px;

            ::placeholder {
                @include font-medium;
                @include font(16px, 19px);
            }
        }

        &__container {
            margin-right: 15px;
        }

        .autocomplete-list {
            border: none;
            margin-top: 40px;
            padding-bottom: 15px;
            padding-top: 15px;
            margin-left: 15px;
            margin-right: 15px;
            width: calc(100% - 30px);

            @include respond-to("small") {
                margin-left: initial;
                margin-right: initial;
                width: 100%;
            }

            &__item {
                a {
                    padding-left: 10px;
                    padding-right: 10px;
                }
            }
        }
    }
}
.autocomplete-list {
    background-color: $color-white;
    border: 0 solid #aaa;
    box-shadow: 0 4px 7px #ccc;
    display: none;
    max-height: 300px;
    height: auto;
    left: 0;
    margin-top: 45px;
    max-width: 100%;
    overflow-y: scroll;
    position: absolute;
    z-index: 999;

    &--show {
        display: block;
    }

    &__item {
        @include font-medium;
        overflow: hidden;
        padding: 5px;
        text-overflow: ellipsis;
        white-space: nowrap;

        &.result-group {
            @include font-heading();
        }
        &:focus-visible {
            @include focus-outline();
        }

        &:not(.result-group) {
            &.active-item {
                background-color: $color-purple;

                a {
                    color: $color-white;
                }
            }

            @include hover-active {
                background-color: $color-purple;

                a {
                    color: $color-white;
                }
            }
        }

        &-view-all {
            @include font-semibold;
        }

        &-view-more {
            @include font-semibold;
        }

        a,
        span {
            @include font(15px);
            display: block;
            height: 100%;
            width: 100%;
        }

        span {
            color: $color-purple;
            text-transform: uppercase;
            height: 100%;
            width: 100%;
        }
    }
}
