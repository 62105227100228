﻿.sections {

    @include for-print() {
        margin: 0 auto;
        max-width: $print-width;
    }

    &__section-heading {
        @include responsive-component-margin();
        @include font-heading();
        @include font(36px, 37px);
        color: $color-black;
        margin-bottom: 24px;
        text-transform: uppercase;

        @include for-print() {
            @include font(18px, 19px);
            @include responsive-component-margin(0);
        }
    }
}
