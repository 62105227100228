.practice-landing {
    margin: 0 0 10px 0;

    .tabs {
        @include for-print() {
            ul li:first-child {
                display: none !important;
            }
        }

        &-title {
            &--block {
                a {
                    @include for-print() {
                        @include font(24px, 34px);
                        font-family: FuturaW01-ExtraBoldCond, Arial !important;
                        text-transform: uppercase;
                    }
                }

                &.is-active {
                    @include for-print() {
                        background-color: transparent;
                    }
                }

                &:not(.is-active) {
                    @include for-print() {
                        display: none;
                    }
                }
            }
        }

        &--block {
            @include for-print() {
                margin: 0px;
                @include respond-to("medium") {
                    margin: auto;
                }
            }
        }
    }
}
@include respond-to('xs') {
    .practice-landing {
        margin: 40px 0;
    }
}