/* Classes used in the Rich Text Editor's Apply Custom CSS dropdown 
   These classes need to be duplicated in \Project\MorganLewis\website\default.css to have any effect on the RTE */

div > a.rte-button,
p > a.rte-button {
    width: 145px;
    padding: 12px 20px;
    display: block;
    background-color: #000;
    border: none;
    color: #fff;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
}

div > a.rte-button:hover,
div > a.rte-button:focus,
p > a.rte-button:hover,
p > a.rte-button:focus {
    background-color: $color-purple;
    color: #fff;
}
