﻿.checkbox-list {
    width: 100%;

    &__container {
        display: block;

        @include respond-to('xxs') {
            display: flex;
            flex-wrap: wrap;
        }
    }

    &__header-label {
        @include font-semibold();
        @include font(16px, 20px);
        margin-top: 16px;
    }

    &__item {
        margin-top: 16px;

        &:focus-visible {
            @include focus-outline();
        }

        @include respond-to('xxs') {
            width: 50%;
        }
    }

    &__input {
        opacity: 0;
        filter: alpha(opacity=0);
        width: 24px;
        height: 24px;
        margin: 0;
        padding: 0;

        &-container {
            float: left;
            margin-right: 7px;
            height: 24px;
            width: 24px;
            clear: left;
            float: left;
            background-image: url(../images/checkbox_24x24.png);
            background-repeat: no-repeat;
            background-position: left 0;
            cursor: default;
            text-align: left;
            position: relative;
            transition: none;
            z-index: 2;

            &--checked {
                background-position: left -48px;
            }
        }
    }

    &__label {
        @include font-semibold();
        @include font(16px, 20px);
        color: #000;
        display: flex;
        padding-top: 2px;
    }
}
