﻿.related-sections {

    @include for-print() {
        margin: 0;
    }

    &--fullwidth {
        container-type: inline-size;

        .related-sections__list {
            @include respond-to-container('xs') {
                columns: 3;

                .related-sections__list-item {
                    break-inside: avoid;
                }
            }

            @include respond-to-container('small') {
                columns: 4;

                .related-sections__list-item {
                    break-inside: avoid;
                }
            }
        }

        .related-sections__title {
            @include font-medium();
            @include font(24px, 28px);
            color: $color-purple;
            margin-bottom: 5px;
            padding-bottom: 11px;
            text-transform: none;

            @include respond-to-container('xs') {
                @include font(30px, 34px);
            }

            @include for-print() {
                @include font(18px, 22px);
            }
        }
    }

    &__list {
        @include chevron-bullet();
        list-style: none;

        @include for-print() {
            list-style-type: disc;
            list-style-position: outside;
            padding-left: 17px;

            .icon-chevron {
                display: none;
            }

            a {
                font-size: 14px;
                font-weight: 400;
            }
        }
    }

    &__list-item {
        align-items: center;
        position: relative;

        & + & {
            margin-top: 7px;

            @include for-print() {
                margin-top: 0;
            }
        }

        &::marker {
            color: $color-purple-contrast;
        }

        @include for-print() {
            display: list-item;
        }
    }

    &__title {
        @include font-semibold();
        @include font(18px, 22px);
        color: $color-purple;
        padding-bottom: 11px;

        @include for-print() {
            @include font(14px, 15px);
        }
    }

    &__trigger {
        @include showmore-trigger--small();

        @include for-print() {
            display: none;
        }
    }

    &__trigger-label {
        @include showmore-trigger-label--small();
    }

    .showmore__shown + .showmore__hidden {
        margin-top: 7px;
    }
}
