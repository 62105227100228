.contact-us-modal-content {
    overflow-y: auto;
    max-height: 80vh;

    @include for-print() {
        display: none;
    }

    &__button {
        @include font-medium();
        @include font(18px, 18px);
        background-color: $color-black;
        border: 1px solid $color-black;
        border-radius: 20px;
        color: $color-white;
        cursor: pointer;
        margin-top: 16px;
        max-height: 40px;
        overflow: hidden;
        padding: 10px;
        text-align: center;
        transition: all $transition-timing $transition-function;
        width: min(40vw, 306px);

        @include respond-to('xs') {
            margin-top: 0;
        }

        &:hover,
        &:focus,
        &:focus-visible {
            background-color: $color-purple;
            border-color: $color-purple;
        }

        &:focus-visible {
            @include focus-outline();
        }
    }

    &__col {
        display: flex;
        flex-direction: column;
    }

    &__footer {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        @include respond-to('xs') {
            flex-direction: row;
            justify-content: flex-end;
        }
    }

    &__form-wrapper {
        &, & * {
            height: 0;
            opacity: 0;
            transition: all 0.1s ease-out;
        }

        &--show {
            padding: 24px 16px;

            @include respond-to('xs') {
                padding: 40px;
            }

            &, & * {
                height: auto;
                opacity: 1;
            }
        }
    }

    &__header {
        @include font-heading();
        @include font(28px, 32px);
        margin-bottom: 8px;
        text-transform: uppercase;
    }

    &__input {
        @include font-content();
        @include font(16px, 18px);
        border: 2px solid $color-black;
        margin: 5px 0;
        padding: 10px;
        // prevent iOS from rounding corners
        -webkit-appearance: none;
        -webkit-border-radius: 0;

        &--invalid,
        &:invalid {
            border-color: $color-red;
        }

        @include respond-to('xs') {
            width: calc(50% - 28px);

            &:first-of-type {
                margin-right: 10px;
            }
        }

        &:focus-visible {
            @include focus-outline();
        }
    }

    &__row {
        display: flex;
        flex-direction: column;

        @include respond-to('xs') {
            flex-direction: row;
        }
    }

    &__success-content {
        &, & * {
            height: 0;
            opacity: 0;
            position: relative;
            transition: all 0.1s ease-in;
        }

        &:not(&--show) * {
            display: none;
        }

        &--show {
            padding: 24px 16px;

            @include respond-to('xs') {
                padding: 40px;
            }

            &, & * {
                height: auto;
                opacity: 1;
            }
        }

        .contact-us-modal-content__header {
            text-align: center;

            @include respond-to('xs') {
                text-align: left;
            }
        }
    }

    &__success-image {
        background-image: url("../icons/icon_form_success.svg");
        background-repeat: no-repeat;
        display: none;
        height: 60px;
        margin: 8px auto;
        width: 60px;

        &--show {
            display: block;
        }

        @include respond-to('xs') {
            margin: 0 16px 0 0;
        }
    }

    &__text {
        &-primary {
            margin-bottom: 15px;

            &, & * {
                @include font-medium();
                @include font(16px, 20px);
            }
        }

        &-info {
            @include font-medium();
            @include font(16px, 20px);
            color: $color-dark-grey;
        }

        &-purple {
            &, & * {
                color: $color-purple;
            }
        }

        &-secondary {
            margin-bottom: 6px;

            &, & * {
                @include font-medium();
                @include font(16px, 20px);
                color: $color-dark-grey;
            }
        }

        &-error {
            @include richtext();
            color: $color-red;
            height: 0;
            margin: auto 15px 0 0;
            opacity: 0;
            transition: all 0.1s ease-in;
            width: auto;

            @include respond-to('xs') {
                width: 50%;
            }

            &--show {
                height: auto;
                opacity: 1;
            }
        }
    }

    &__textarea {
        @include font-content();
        @include font(16px, 20px);
        border: 2px solid $color-black;
        margin: 5px 0;
    }
}
