.practice-list {
  .letter-anchor {
    position: absolute;
    margin-top: -80px;
  }

  &__container {
    align-items: center;
    display: flex;
    flex-direction: row;
    margin: auto;
    width: 100%;

    @include respond-to("contentLayout") {
      width: $structure-normal;
    }

    @include for-print() {
          width: 100%;
      }
  }

  &__alpha-column {
    display: flex;
    justify-content: center;
    width: 20%;

    @include respond-to("xs") {
      width: 15%;
    }

    .letter {
      @include font-heading();
      @include font(30px);
      text-align: center;
    }
  }

  &__link-column {
    width: 100%;
    margin-right: 10px;

    @include respond-to("contentLayout") {
      margin-right: initial;
    }

    ul {
      column-count: 1;
      margin-right: 20px;
      @include for-print(){
            column-count: 2;
      }

      @include respond-to("xs") {
        column-count: 2;
        margin-right: initial;
      }

      li {
        margin: 0 30px 12px 0;

        @include respond-to("xs") {
            margin: 0 60px 12px 0;
        }
        @include for-print(){
            margin-right: 20px;
        }
      }
    }

    .practice-link {
      @include font-semibold();
      @include font(16px, 20px);  
      @include for-print() {
          @include font(16px, 20px);
      }
    }
  }

  &__letters {
    padding: 20px 0;
    position: relative;
    margin: auto;

    &:nth-child(odd) {
      background-color: $color-white-smoke;
    }

    @include for-print() {
          @include font(16px, 20px);
          @include font-heading();
          width: 100%;
      }
  }
}