﻿// Is this used anymore? referenced in C:\_projects\sitecore\morgan-lewis-sitecore\src\Feature\Shared\website\Views\Shared\Header.cshtml
.header {
  line-height: initial;

  @include respond-to-max("xs") {
      &#top-header{
        border-bottom: 2px solid $color-black;
      }
  }
  &--container.container {
    max-width: 940px;

    nav {
      ol,
      ul {
        margin-bottom: 0;
      }

      a:hover {
        text-decoration: none;
      }
    }
    .no-padding.clr {
      line-height: initial;
    }
    .logo-wrapper {
      a {
        line-height: initial;
      }
      img {
        border: initial;
        vertical-align: initial;
      }
    }
  }
}
