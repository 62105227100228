﻿.media-modal {
    &__carousel {
        align-items: center;
        display: flex;

        &__nav-next,
        &__nav-prev {
            display: none;
            height: 100%;
            min-width: 25px;
            position: absolute;

            &:focus-visible {
                @include focus-outline(-5px);
            }

            @include respond-to('xs') {
                display: table;
            }

            .icon {
                color: $color-white;
                cursor: pointer;
                display: table-cell;
                font-size: 3rem;
                height: 25px;
                vertical-align: middle;
            }
        }

        &__nav-next {
            right: -40px;
        }

        &__nav-prev {
            left: -40px;

            .icon {
                transform: rotate(180deg);
            }
        }
    }

    &__description {
        @include font(1.4rem, 1.6rem);
        @include font-content();
        color: $color-dark-grey;
        margin: 3px 0;
    }

    &__image {
        max-height: 80vh;
        max-width: 100%;
        object-fit: contain;
        width: 100%;
    }

    &__title {
        @include font(1.6rem, 1.8rem);
        @include font-semibold();
        color: $color-black;
        margin: 7px 0 4px;
        text-transform: none;
    }

    .audio {
        max-height: 40.25vw;

        @include respond-to('contentLayout') {
            max-height: 370px;
        }

        &-player:focus-visible {
            @include focus-outline();
        }
    }

    .video {
        max-height: 80vh;

        &-player:focus-visible {
            @include focus-outline(-5px);
        }
    }
}
