﻿@mixin icon() {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: "icons" !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@mixin icon-chevron() {
    content: "\e91a";
}

@mixin icon-plus() {
    content: "\ea0a";
}

@mixin icon-minus() {
    content: "\ea0b";
}