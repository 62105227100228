﻿$carousel-pagination-item__icon-size: 12px; //increases by 4 w/ border stroke
$carousel-pagination-item__icon-border-size: 2px;

.carousel {
	@include for-print() {
		display: none;
	}
	// start core carousel
	&__heading {
		border: none;

		h2 {
			color: #000;
			font-size: 36px;
			margin-top: 0;
			text-align: left !important;
			text-transform: uppercase;			
		}
	}

	&__heading + &__abstract,
	&__heading + &__slides,
	&__abstract + &__slides {
		margin-top: 20px;
	}

	&__slides {
		border-bottom: 2px solid $color-black;
		border-top: 7px solid $color-black;
		box-sizing: border-box;
		overflow: hidden;
		padding: 20px 0;
		width: auto;
	}

	&__pagination {
		display: flex;
		justify-content: center;
		margin-left: auto;
		margin-right: auto;
		margin-top: 20px;
		width: 100%;

		&-item {
			@include font(20px, $font-small: 18px);
			background-color: white;
			border: black $carousel-pagination-item__icon-border-size solid;
			border-radius: $carousel-pagination-item__icon-size;
			color: rgba(255, 255, 255, 0.75);
			cursor: pointer;
			height: $carousel-pagination-item__icon-size;
			margin-left: 6px;
			margin-right: 6px;
			transition: 0.4s;
			width: $carousel-pagination-item__icon-size;

			&--active {
				color: rgba(255, 255, 255, 1);
				background-color: black;
			}

			@include hover-active {
				color: rgba(255, 255, 255, 1);
			}
		}
	}
	// end core carousel
	//content link
	&-content-link {
		height: 100%;
		gap: 16px;
		min-height: 300px;
		overflow: hidden;
		position: relative;
		width: 100%;
		flex-direction: column;

		@include respond-to('xxs') {
			flex-direction: row;
		}

		.structure-two-column--halves & {
			flex-direction: column;

			@include respond-to('xxs') {
				flex-direction: row;
			}

			@include respond-to('small') {
				flex-direction: column;
			}

			@include respond-to('contentLayout') {
				flex-direction: row;
			}
		}


		&__abstract {
			@include font(18px, 20px);
			letter-spacing: -0.2px;
			margin-bottom: 65px;
			display: none;

			@include respond-to('xxs') {
				display: block;
			}

			.structure-two-column & {
				@include font(18px, 20px);
			}

			.structure-two-column--halves & {
				@include font(16px, 20px);
				margin-bottom: 0;

				@include respond-to('xs') {
					margin-bottom: 65px;
				}

				@include respond-to('small') {
					margin-bottom: 0;
				}

				@include respond-to('contentLayout') {
					margin-bottom: 65px;
				}
			}
		}

		&__media {
			display: inline-block;
			flex-basis: 300px;
			height: 140px;
			order: 1;
			overflow: hidden;
			width: 300px;
			padding: 0;
			margin-bottom: 10px;

			&-image {
				background-position: center;
				background-repeat: no-repeat;
				background-size: cover;
				height: 100%;
				transition: transform 0.3s ease-in;
				width: 100%;
			}

			.structure-one-column & {
				flex-shrink: inherit;
				width: 100%;

				@include respond-to('xxs') {
					height: 170px;
					flex-basis: 300px;
					width: 300px;
				}

				@include respond-to('small') {
					flex-basis: 470px;
					height: 257px;
					width: 470px;
				}
			}

			.structure-two-column & {
				width: 100%;
				flex-shrink: inherit;

				@include respond-to('xxs') {
					flex-basis: 300px;
					height: 170px;
					width: 300px;
				}
			}

			.structure-two-column--halves & {
				flex-shrink: inherit;
				padding: 0;
				margin-bottom: 10px;
				width: 100%;

				@include respond-to('xxs') {
					flex-basis: 300px;
					height: 170px;
					width: 300px;
				}

				@include respond-to('small') {
					margin-bottom: 0;
					width: 100%;
				}

				@include respond-to('contentLayout') {
					flex-basis: 240px;
					height: 170px;
					width: 240px;
				}
			}
		}

		&__title {
			@include font(28px, 29px);

			@include font-heading();

			.structure-one-column & {
				@include font(20px, 21px);
				
				@include respond-to('xxs') {
					@include font(28px, 29px);	
				}
			}

			.structure-two-column & {
				@include font(24px, 25px);

				@include respond-to('xxs') {
					@include font(20px, 21px);
				}
			}

			.structure-two-column--halves & {
				@include font(20px, 21px);
			}
		}

		&__content-type {
			top: 0px;
			transform: translateY(-50%);
		}

		&__content {
			display: flex;
			flex-flow: column;
			width: 100%;
		}

		&__header {
			left: auto;
			margin: 0;
			margin-bottom: 10px;
			padding: 0;
			position: relative;

			h2 {
				@include font-heading();
				display: block;
				color: $color-purple;
				font-size: 16px;
				margin: 0;
				padding: 0;
				position: relative;
				text-transform: uppercase;

				img, span {
					display: inline-block;
					position: relative;
				}

				img {
					vertical-align: baseline;
				}
			}
		}

		&--left,
		&--right {
			& + .carousel-content-link_content {
				width: 100%;

				@include respond-to('xs') {
					width: 50%;
				}

				@include respond-to('medium') {
					width: 45%;
				}
			}
		}

		&--right {
			order: 1;
			padding-right: 0;

			& + .carousel-content-link__content {
				margin-right: auto;
				margin-left: 0;
			}
		}

		&__link {
			left: auto;
			margin: 0;
			margin-bottom: 10px;
			padding: 0;
			position: relative;

			a {
				@include font-heading();
				color: $color-black;
				display: inline-block;
				font-size: 2rem;
				line-height: 2rem;
				margin: 2px 0;
				text-transform: uppercase;

				@include respond-to('small') {
					@include font(28px, 29px);

					.structure-two-column--halves & {
						@include font(20px, 21px);
					}

					.structure-two-column & {
						@include font(24px, 25px);
					}
				}

				&.referenced-item {
					font-size: 28px;
					line-height: 100%;
				}

				&:active, :focus, :hover {
					color: $color-purple;
				}
			}
		}
	}
	// audio video slide
	&-audio-video-slide {
		&,
		.structure-two-column & {
			display: flex;
			flex-direction: column;
			gap: 16px;

			@include respond-to('xxs') {
				flex-direction: row;
				gap: 20px;
			}

			@include respond-to('xs') {
				gap: 25px;
			}
		}

		.structure-two-column--halves & {
			display: flex;
			flex-direction: column;
			gap: 16px;

			@include respond-to('largeipad') {
				flex-direction: row;
			}
		}

		&--right {
			order: 2;
		}

		&__media {
			&,
			.structure-two-column & {
				order: 2;

				@include respond-to('xxs') {
					display: flex;
					order: 0;
				}
			}

			.structure-two-column--halves & {
				order: 2;

				@include respond-to('largeipad') {
					display: flex;
					order: 0;
				}
			}
		}

		&__content {
			display: flex;
			flex-direction: column;
			gap: 16px;
			order: 1;
			width: 100%;

			@include respond-to('xxs') {
				order: 1;
				width: 50%;
			}

			.structure-two-column--halves & {
				display: flex;
				flex-direction: column;
				gap: 16px;
				order: 1;
				width: 100%;

				@include respond-to('largeipad') {
					order: 1;
					width: 50%;
				}
			}
		}

		&__eyebrow {
			display: flex;
			flex-direction: row;
			gap: 10px;
		}

		&__icon {
			color: $color-black;
			font-size: 30px;
		}

		&__label {
			@include font(16px, 28px);
			@include font-heading();
			color: $color-purple;
			text-transform: uppercase;
		}

		&__heading {
			@include font(20px, 21px);
			@include font-heading();
			color: $color-black;
			text-transform: uppercase;

			@include respond-to('xs') {
				@include font(24px, 25px);
			}

			@include respond-to('small') {
				@include font(28px, 29px);

				.structure-two-column--halves & {
					@include font(20px, 21px);
				}

				.structure-two-column & {
					@include font(24px, 25px);
				}
			}
		}

		&__abstract {
			& {
				display: none;

				@include respond-to('xxs') {
					display: block;
					@include font(16px, 20px);
				}
			}

			.structure-two-column & {
				display: none;

				@include respond-to('xxs') {
					display: block;
					@include font(16px, 20px);
				}
			}

			.structure-two-column--halves & {
				display: none;

				@include respond-to('largeipad') {
					display: block;
					@include font(16px, 20px);
				}
			}
		}
	}
	// quote slide
	&-quote-slide {
		display: flex;
		justify-content: center;
		margin: auto;
		line-height: 2em;

		&__content {
			display: flex;
			flex-direction: column;
			margin: 10px 15px;
		}

		&__quote {
			&-text {
				display: contents;

				&-size {
					color: #000000;
					opacity: 1;

					&-small {
						@include font(28px, 32px);
					}

					&-medium {
						@include font(32px, 36px);
					}

					&-large {
						@include font(36px, 40px);
					}

					.structure-two-column--halves & {
						@include respond-to('small') {
							&-small {
								@include font(22px, 26px);
							}

							&-medium {
								@include font(24px, 28px);
							}

							&-large {
								@include font(28px, 32px);
							}
						}
					}

					.structure-two-column & {
						@include respond-to('small') {
							&-small {
								@include font(24px, 28px);
							}

							&-medium {
								@include font(28px, 32px);
							}

							&-large {
								@include font(32px, 36px);
							}
						}
					}

					&,
					.structure-two-column & {
						&-small {
							@include font(18px, 22px);
						}

						&-medium {
							@include font(20px, 24px);
						}

						&-large {
							@include font(24px, 28px);
						}

						@include respond-to('xs') {
							&-small {
								@include font(24px, 28px);
							}

							&-medium {
								@include font(28px, 32px);
							}

							&-large {
								@include font(32px, 36px);
							}
						}
					}
				}
			}

			&-mark {

				&::before {
					@include font-heading();
					@include font(90px, 95px);
					color: $color-purple-light;
					content: '“';
					font-weight: 900;
					letter-spacing: 0px;
					opacity: 1;

					& {
						@include respond-to('xs') {
							@include font(140px, 95px);
						}
					}

					.structure-two-column & {
						@include respond-to('xs') {
							@include font(120px, 95px);
						}
					}
				}

				&--reverse {
					transform: scale(-1);

					&::after {
						@include font-heading();
						@include font(90px, 95px);
						color: $color-purple-light;
						content: '“';
						font-weight: 900;
						letter-spacing: 0px;
						opacity: 1;

						& {
							@include respond-to('xs') {
								@include font(140px, 95px);
							}
						}

						.structure-two-column & {
							@include respond-to('xs') {
								@include font(120px, 95px);
							}
						}
					}
				}
			}
		}

		&__source {
			&-text {
				@include font(14px, 28px);
				@include font-heading();
				color: $color-purple;
				letter-spacing: 0px;
				margin-top: 1em;
				opacity: 1;
				text-align: end;
				text-transform: uppercase;

				@include respond-to('xs') {
					@include font(18px, 28px);
				}
			}
		}
	}


	&-icon {
		color: black;
		font-size: 30px;
		top: 0px;
	}

	&-slide {
		display: inline-flex;
		width: 100%;
	}
}

.experience-editor {
	.carousel {
		&-slide--background {
			div {
				background-position: 0;
			}
		}

		.swiper-container {
			height: auto;
		}

		.swiper-wrapper {
			flex-direction: column;
			height: auto;
		}

		.swiper-slide {
			flex-shrink: 1;
			height: auto;
		}
	}
}
