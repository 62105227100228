﻿.fade-in {
    transition: opacity 1s ease-in-out;

    &--show {
        opacity: 1;
    }
    // default behavior is to only enable fade in on viewport widths larger than 665px
    @include respond-to('xs') {
        &--hide {
            opacity: 0;
        }
    }
    // optional modifier to enable the fade in on all viewport widths
    &--mobile.fade-in--hide {
        opacity: 0;
    }

    @include for-print() {
        opacity: 1;
    }
}
