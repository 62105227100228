@use 'sass:math';

@mixin linkStateStyles(){
    &:hover,
    &:focus,
    &--active {
        color: $color-white;
        opacity: 1;

        @include for-print() {
            color: $color-black;
        }

        &:before {
            opacity: 1;
            @include for-print() {
                opacity: 0;
            }
        }

    }
    &:hover {
        &:before {
            background: $color-white;
        }
    }

    &:focus,
    &--active {            
        &:before {
            background: $color-purple-semilight;
        }
    } 
}

.section-navigation {
    @include respond-to('xs') {
        height: 52px;
    }

    @include for-print() {
        display: none;
    }

    &__static {
        background-color: $color-black;
        left: 0;
        right: 0;

        @include respond-to('xs') {
            padding: 16px 0;
            position: absolute;
        }
    }

    &__content {
        max-width: $structure-normal;

        @include respond-to('xs') {
            @include responsive-component-margin();
        }

        &--fixed {
            @include responsive-component-margin-unstructured(); // needed to force margins at 940 when the component is placed in a narrow structure. fixed view content margin is always max at 940.
            padding: 16px 0;
        }
    }

    &__list {
        display: flex;
        flex-direction: column;

        @include respond-to('xs') {
            flex-direction: row;
            justify-content: space-evenly;
        }
    }

    &__list-item-wrapper {
        & + & {
            border-top: 1px solid $color-very-dark-grey;

            @include respond-to('xs') {
                border: none;
            }
        }
    }

    &__list-item {
        padding: 12px 0;

        @include respond-to-max('xs') {
            @include responsive-component-margin();
        }

        @include respond-to('xs') {
            padding: 0;
        }
    }

    &__link {
        $section-navigation__link-line-height: 20px;
        @include font(18px, $section-navigation__link-line-height);
        @include font-medium();
        color: $color-white;
        opacity: 0.75;
        position: relative;
        transition: color .3s ease-in-out;
        display: inline-block;

        @include respond-to('xs') {
            margin-left: 16px;
        }

        @include for-print() {
            @include font(14px, 16px);
        }

        &:before {
            background: $color-purple-semilight;
            border-radius: 50%;
            content: "";
            display: none;
            height: 8px;
            left: -12px;
            opacity: 0;
            position: absolute;
            top: #{math.div($section-navigation__link-line-height, 2)};
            transform: translate(-50%, -50%);
            transition: all 0.3s ease-in-out;
            width: 8px;

            @include respond-to('xs') {
                display: block;
            }
        }

        @include linkStateStyles();
    }

    &__fixed { //fixed view rules
        background-color: $color-black;
        color: $color-white;
        display: none;
        left: 0;
        position: fixed;
        right: 0;
        top: 0;
        transform: translateY(-100%);
        transition: all 300ms ease-in-out;
        width: 100%;
        z-index: $z-index-sidenav;

        @include for-print() {
            display: none;
        }

        &--active {
            display: block;
            top: $navigation__fixed--mobile;
            transform: translateY(0);

            @include respond-to('small') {
                top: $navigation__fixed;
            }
        }

        .section-navigation__link {
            color: $color-white;
            opacity: 0.75;

            &:before {
                opacity: 0;
                display: block;
            }

            @include linkStateStyles();
        }
    }

    &__mobile { //mobile/accordion view rules
        background-color: $color-black;
        color: $color-white;
        left: 0;
        position: fixed;
        right: 0;
        top: 0;
        transform: translateY(-100%);
        transition: all 300ms ease-in-out;
        width: 100%;
        z-index: $z-index-sidenav;

        @include for-print() {
            display: none !important;
        }

        &--active {
            display: block;
            top: #{$navigation__fixed--mobile - 2}; //subtract for the 2px border
            transform: translateY(0);

            @include respond-to('small') {
                top: $navigation__fixed;
            }
        }
    }

    &__accordion {
        padding: 20px 0;
    }

    &__accordion-title {
        align-items: center;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        text-transform: uppercase;

        &:hover {
            .section-navigation__heading,
            .section-navigation__accordion-toggle {
                color: $color-extremely-light-grey;
            }
        }

        .section-navigation__accordion-heading {
            @include font(18px, 18px);
            @include font-heading();
            color: $color-white;
            transition: color 0.3s ease-in-out;
            padding-right: 10px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    &__accordion-toggle {
        color: $color-white;
        cursor: pointer;
        display: inline-block;
        font-size: 30px;
        transition: color 0.3s ease-in-out;
        vertical-align: middle;

        &--active {
            height: 30px;
            transform: rotate(90deg);
        }
    }

    &__accordion-panel {
        height: 0px;
        overflow: hidden;
        transition: height 0.3s ease-in-out;
        max-height: 65vh;
        overflow-y: scroll;
        overflow-x: hidden;

        .section-navigation__list {
            flex-direction: column;
            margin-top: 12px;
        }

        .section-navigation__list-item {
            margin-top: 12px;
            padding: 14px 0;
        }

        .section-navigation__link {
            color: $color-white;
            opacity: 0.75;

            &:before {
                opacity: 0;
                display: block;
            }

            @include linkStateStyles();
        }
    }
}
