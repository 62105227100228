﻿.container {
    &.container.sub-footer {
        margin: 0px auto;
        width: 940px;
        position: relative;
      }
    &--bottom-margin {
        margin-bottom: 2.5em;
        // TODO: Remove when careers updated
        /*  Start - one-column-container */
        &.one-column-container {
            margin-left: auto;
            margin-right: auto;
            max-width: 780px;
            width: 100%;
            position: relative;

            .column-container-content {
                padding-top: 40px;
                padding-bottom: 40px;

                &-bordered {
                    padding-top: 40px;
                    padding-bottom: 40px;
                    border-top: 2px solid #000;
                }

                &-item-box {
                    overflow: hidden;
                    height: 246px;
                }
            }
        }
        /* End - one-column-container */
        // TODO: Remove when careers updated
        /* Start - two-column-container */
        &.two-column-container {
            margin-left: auto;
            margin-right: auto;
            max-width: 780px;
            position: relative;
            width: 100%;
        }
        /* End - two-column-container */

    }

    .recent-resources {
        background: white;
        flex-direction: column;
        padding-left: 0%;
        padding-right: 0%;
        width: 100%;

        &__container {
            background: none !important;
            flex-direction: column;
            width: 100%;

            &--list {
                background: #F0F0F0;
                max-width: 100%;
            }
        }

        &__header {
            padding-top: 1em;
        }

        &__list {
            margin-bottom: 4em;
            margin-left: 0;
            margin-right: 0;
            max-width: 100%;

            &_item {
                padding-left: 0;
                padding-right: 0;

                div {
                    margin-left: .625em;
                    margin-right: .625em;
                    padding-left: 1em;
                    padding-right: 1em;
                }
            }
        }

        &__view-all {
            margin-bottom: 0;
            padding-left: 1.65em;
        }
    }
}
