﻿.event-materials-request-modal-content {
	overflow-y: auto;
	max-height: 80vh;

	@include for-print() {
		display: none;
	}

	&__button {
		background-color: $color-black;
		border-radius: 20px;
		font-weight: 500;
		max-height: 40px;
		overflow: hidden;
		margin-top: 16px;
		text-align: center;
		transition: all $transition-timing $transition-function;
		width: min(40vw, 306px);

		@include respond-to('xs') {
			margin-top: 0;
		}

		&:hover {
			cursor: pointer;
			background-color: $color-purple;
		}

		&--success {
			margin: 0 auto;
		}
	}

	&__button-label {
		@include font(18px, 21px);
		color: $color-white;
		display: inline-block;
		padding: 10px;
		transition: color $transition-timing $transition-function;
		width: calc(100% - 20px);
	}

	&__col {
		display: flex;
		flex-direction: column;
	}

	&__footer {
		align-items: center;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;

		@include respond-to('xs') {
			flex-direction: row;
			justify-content: flex-end;
		}
	}

	&__form-wrapper {
		&, & * {
			height: 0;
			opacity: 0;
			transition: all 0.1s ease-out;
		}

		&--show {
			padding: 24px 16px;

			@include respond-to('xs') {
				padding: 40px;
			}

			&, & * {
				height: auto;
				opacity: 1;
			}
		}
	}

	&__header {
		@include font-heading();
		@include font(28px, 32px);
		margin-bottom: 8px;
		text-transform: uppercase;

		&--success {
			text-align: center;

			@include respond-to('xs') {
				text-align: left;
			}
		}
	}

	&__row {
		display: flex;
		flex-direction: column;

		@include respond-to('xs') {
			flex-direction: row;
		}
	}

	&__success-content {
		&, & * {
			height: 0;
			opacity: 0;
			position: relative;
			transition: all 0.1s ease-in;
		}

		&:not(&--show) * {
			display: none;
		}

		&--show {
			display: flex;
			justify-content: center;
			padding: 24px 16px;

			@include respond-to('xs') {
				padding: 40px;
			}

			&, & * {
				height: auto;
				opacity: 1;
			}
		}
	}

	&__success-image {
		background-image: url("../icons/icon_form_success.svg");
		background-repeat: no-repeat;
		display: none;
		height: 60px;
		margin: 8px auto;
		width: 60px;

		&--show {
			display: block;
		}

		@include respond-to('xs') {
			margin: 0 16px 0 0;
		}
	}

	&__text {
		&-primary {
			margin-bottom: 16px;

			&, & * {
				@include font-medium();
				@include font(16px, 20px);
			}
		}

		&-info {
			@include font-medium();
			@include font(16px, 20px);
			color: $color-dark-grey;
		}

		&-purple {
			&, & * {
				color: $color-purple;
			}
		}

		&-secondary {
			margin-bottom: 16px;

			&, & * {
				@include font-medium();
				@include font(16px, 20px);
				color: $color-dark-grey;
			}
		}

		&-error {
			@include richtext();
			color: $color-red;
			height: 0;
			margin: auto 15px 0 0;
			opacity: 0;
			transition: all 0.1s ease-in;
			width: auto;

			@include respond-to('xs') {
				width: 50%;
			}

			&--show {
				height: auto;
				opacity: 1;
			}
		}
	}
}
