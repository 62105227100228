﻿.breadcrumbs-bar {
    background-color: $color-black;
    min-height: 35px;
    padding-top: 13px;

    @include respond-to('mobile-default') {
        min-height: 44px;
        padding-bottom: 13px;
    }

    &__inner {
        @include structure-normal();
        clear: both;
        overflow: auto;
        position: relative;

        &:after {
            clear: both
        }

        .breadcrumbs {
            padding: 0;
        }
    }

    @include for-print() {
        display: none;
    }
}
