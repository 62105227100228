.location-landing-office {

    &__heading {
        @include font(18px);
        @include font-heading();
        text-transform: uppercase;
    }

    &__link {
    }

    &__address {
        @include font(16px, 20px);
        @include font-content();
    }

    &__phone {
        @include font(16px, 20px);
        @include font-semibold();
    }

    &__partners {
        margin-top: 10px;
        margin-bottom: 4px;
    }

    &__partners-label {
        @include font(16px, 20px);
        @include font-content();
    }

    &__partner {
    }

    &__partner-link {
        @include font(16px, 20px);
        @include font-semibold();
        min-height: 24px;
        text-decoration: underline;
    }

    &__partner-email {
        background: url(../images/icon-email.gif) no-repeat 0 6px;
        cursor: pointer;
        display: inline-block;
        height: 18px;
        margin-left: 5px;
        min-height: 24px;
        text-decoration: none;
        width: 16px;
    }
}
