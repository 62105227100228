.publications-audio-article {
    display: flex;
    flex-direction: column;
    height: 86px;
    margin-bottom: 24px;
    justify-content: space-between;

    @include for-print() {
        display: none;
    }

    &__label {
        @include font-heading();
        @include font(20px, 21px);
        color: $color-black;
        margin-bottom: 16px;
        position: relative;
    }

    &__container {
        display: flex;
        position: relative;
    }

    &__audio {
        flex-grow: 1;
        opacity: 1;
        width: 100%;
    }
}
