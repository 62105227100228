﻿$header-height: 110px;
$mobile-nav-height: 52px;
$breakpoint: 'small';
$logo-max-width: 100%;
$logo-max-width--small: 168px;

.slim-header {
    height: auto;

    @include respond-to($breakpoint) {
        height: initial;
    }

    &__header {
        background-color: $color-white;
        position: relative;
        width: 100%;
        z-index: 7000;

        @include respond-to($breakpoint) {
            width: initial;
        }
    }

    &__spacer {
        display: none;
        height: $header-height;

        @include respond-to($breakpoint) {
            display: initial;
        }
    }

    &__spacer-mobile {
        display: flex;
        height: $mobile-nav-height;
        transition: height 0.4s;

        @include respond-to($breakpoint) {
            display: none;
            height: initial;
        }
    }

    &--fixed {
        position: fixed;
        width: 100%;

        .slim-header__container {
            background-color: $color-white;
            height: $mobile-nav-height;

            &::before {
                display: none;
            }

            @include respond-to($breakpoint) {
                height: 62px;
            }
        }

        .slim-header__logo {
            max-width: $logo-max-width--small;
        }
    }

    &__logo {
        max-width: $logo-max-width--small;

        @include respond-to($breakpoint) {
            max-width: $logo-max-width;
        }

        img {
            width: 100%;
        }
    }

    &__link {
        align-content: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    &__container {
        border-bottom: 2px solid $color-black;
        display: flex;
        flex-direction: column;
        height: $mobile-nav-height;
        min-height: $mobile-nav-height;

        @include respond-to($breakpoint) {
            height: $header-height;
            min-height: initial;
        }

        @include for-print() {
            border-bottom: 0;
            height: $header-height;
        }

        &-main {
            align-items: center;
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: center;
            max-width: #{map-get($breakpoints, 'contentLayout')};
            position: relative;

            @include responsive-component-margin-unstructured($global-page-margin);

            @include for-print() {
                height: unset;
                margin-top: 5px;
                max-width: 100%;
            }
        }
    }
}

.experience-editor {
    .slim-header__spacer {
        display: none;
    }
}
