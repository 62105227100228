.practice-search {
  align-items: center;
  display: flex;
  background-color: $color-black;
  height: 75px;
  justify-content: center;
  position: absolute;
  top: -115px;
  width: 100%;

  @include for-print() {
      display: none;
  }

  .autocomplete__container {
    padding-left: 15px;
  }
  &__button-container {
    margin-left: 16px;
  }
  .autocomplete-input {
    div.autocomplete {
      @include respond-to-max("small") {
        flex: 1;
        max-width: 100%;
        padding-bottom: 4px;
        padding-left: 16px;
        padding-right: 16px;
        width: 100%;
      }
    }
    input[type="search"]::-webkit-search-decoration,
    input[type="search"]::-webkit-search-cancel-button,
    input[type="search"]::-webkit-search-results-button,
    input[type="search"]::-webkit-search-results-decoration {
      -webkit-appearance: none;
    }
    input {
      border: none;
      height: 40px;
      width: 100%;
    }
  }

  .autocomplete--keep-open {
    height: 40px;
    padding-bottom: 0;
    padding-top: 0;

    &:focus-visible {
      @include focus-outline();
    }
  }
  .ml-button {
    border: 2px solid $color-white;
    height: 40px;
    margin-right: 19px;
    padding: 0;
    text-transform: uppercase;
    width: 185px;
  }
  &__list {
    border-bottom: 2px $color-black solid;
    margin-top: 40px;

    .practice-list__link-column {
      margin-bottom: 32px;
      margin-left: 40px;
      margin-right: 40px;
      @include respond-to-max("small") {
        .practice-search__list-item:first-of-type {
          margin-top: 0;
        }
      }
    }

    &-item {
      margin-bottom: 10px;
      margin-top: 10px;

      a {
        @include font-semibold();
        @include font(16px, 20px);
      }
    }
    &-table {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 32px;
      margin-left: 40px;
      margin-right: 40px;

      @include respond-to-max("small") {
        flex-direction: column;
      }
    }
  }

  &__no-results {
    @include font(16px, 19px);
    @include font-medium();
    background-color: $color-white-smoke;
    margin-bottom: 40px;
    margin-top: 40px;
    padding-bottom: 12px;
    padding-left: 15px;
    padding-top: 12px;
    width: 100%;
  }

  &__search-query {
    @include font-semibold();
    @include font(16px, 19px);
    padding-bottom: 22px;
    .icon-close-symbol {
      @include font(10px);
      font-weight: 900;
      margin-left: 2px;    
    }
  }
  @include respond-to-max("small") {
    flex-direction: column;
    height: 125px;
    padding-left: 0;
    position: relative;
    top: 0;
    .ml-button {
      max-width: 100%;
      margin-right: 0;
      width: 100%;
    }
    &__button-container {
      margin-left: 0;
      padding-bottom: 16px;
      width: 100%;
      div {
        padding-left: 16px;
        padding-right: 16px;
      }
    }
    .autocomplete__container {
      padding-left: 0;
    }
    .autocomplete-input,
    .ml-button {
      height: 40px;
      margin-top: 16px;
      padding: 0px;
      width: 100%;
    }
    .autocomplete__focus {
      display: none;
    }
    &__list {
      margin-left: 15px;
      margin-right: 15px;
    }
    .autocompleteList {
      margin-left: 16px;
      width: calc(100% - 32px);
    }
  }
}
