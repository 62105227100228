.col{
    &-r.last{
        & > .block{
            & > .recent-resources {
                width: 100vw;
                position: relative;
                left: calc(-50vw + 50%);
                .recent-resources__container {
                    // width: 100%;
                    width: 100vw;
                }
            }
        }
    }
}