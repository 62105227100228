.contactlist {
    &--multi {
        display: block;

        @include respond-to('xs') {
            columns: 3;
        }

        @include respond-to('small') {
            columns: 4;
        }
    }

    &__description {
        @include for-print() {
            @include font(14px, 16px);
        }
    }

    &__title {
        color: $color-black;
        font-size: 18px;
        margin: 30px 0 16px 0;
        text-transform: uppercase;

        &--purple {
            @include font-medium();
            @include font(24px, 28px);
            color: $color-purple;
            text-transform: none;

            @include for-print() {
                @include font(18px, 22px);
            }

            @include respond-to('xxs') {
                @include font(30px, 34px);
            }
        }
    }

    &__contact-section {
        break-inside: avoid-column;
        margin-bottom: 16px;
        margin-right: 16px;

        &__header {
            @include for-print() {
                @include font(14px, 16px);
            }

            &:last-child {
                text-decoration: underline;
            }
        }

        &__name {
            @include for-print() {
                @include font(14px, 16px);
            }

            a {
                line-height: 24px;
                text-decoration: underline;

                &:hover {
                    text-decoration: none;
                }
            }

            &-tag {
                margin-left: 3px;
            }
        }
    }

    .print-wrapper &__contact-section__name {
        font-size: 14px;
    }

    .print-wrapper &.oniblock-group:last-of-type {
        padding-bottom: 0;
    }

    .print-wrapper & {
        .contactlist__title {
            margin-top: 2px;
        }

        &__contact-section:last-of-type {
            margin-bottom: 0;
        }
    }
}
