.experience-editor {
    .ml-component {
        position: relative;
    }

    &__image {
        bottom: 0;
        left: 0;
        overflow: hidden;
        position: absolute;
        z-index: 2;

        img {
            border: solid 2px green;
            height: 50px;
            width: 50px;
        }
    }

    &__label {
        color: green;
        font-family: Arial;
        font-weight: 500;
    }
    
    .scEmptyPlaceholder {
        width: 100%;
    }
}
