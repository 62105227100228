.remove-filter {
    display: flex;
    margin-bottom: 10px;
    margin-top: 20px;

    @media only screen and (max-width: 500px) {
        margin-bottom: 10px;
        margin-top: 0;        
    }
    
    .remove-filter__link {
        @include font-semibold();
        background-color: $color-white-smoke;
        border-radius: 18px;   
        color: $color-black;
        font-size: 16px;
        line-height: 18px;
        padding: 8px 52px 8px 16px;
        position: relative;
        text-align: left;
        transition: background-color ease-in-out 0.3s;

        .remove-filter__close {        
            background-image: url(../images/close-symbol.svg);
            background-repeat: no-repeat;                
            display: inline-block;           
            height: .75em;                    
            margin-right: 16px;
            position: absolute;
            right: 0;
            top: 13px;
            width: .75em;

            @media only screen and (max-width: 500px) {
                top: 12px;        
            }     
        }
        &:hover {
            background-color: $color-very-light-grey;
        }           
    }
}