﻿.audio,
.video {
    .mejs__controls {
        height: 35px;
        margin: 0;
        opacity: 1 !important;
        padding: 0 10px;

        @include respond-to('xs') {
            opacity: initial;
        }

        .mejs__button {
            height: 32px;
            width: 32px;

            *:not(.mejs__volume-slider, .mejs__volume-handle, .mejs__volume-current, .mejs__volume-total) {
                height: 20px;
                width: 20px;
            }

            .mejs__volume-slider {
                height: 115px;
                width: 25px;
            }

            .mejs__volume-total {
                height: 100px;
                top: 8px;
                width: 2px;
            }

            .mejs__volume-handle {
                height: 6px;
                width: 16px;
            }
        }

        .mejs__horizontal-volume-slider .mejs__horizontal-volume-total {
            height: 12px;
        }

        .mejs__time {
            box-sizing: content-box;
            color: $color-white;
            font-size: 11px;
            font-weight: 700;
            height: 24px;
            overflow: hidden;
            padding: 16px 6px 0;
            text-align: center;
            width: auto;

            &-buffering,
            &-hovered,
            &-current,
            &-loaded {
                height: 99%;
            }

            &-float {
                background: #eee;
                border: 1px solid #333;
                bottom: 100%;
                color: #111;
                display: none;
                height: 17px;
                margin-bottom: 9px;
                position: absolute;
                text-align: center;
                transform: translateX(-50%);
                width: 36px;

                &-current {
                    display: block;
                    font-size: 10px;
                    left: 0;
                    margin: 2px;
                    padding-top: 3px;
                    text-align: center;
                    width: 30px;
                }
            }


            &-handle {
                &-content {
                    height: 10px;
                    width: 10px;
                }
            }

            &-rail {
                height: 40px;
                margin: 0 10px;
                padding-top: 10px;
                position: relative;
            }

            &-total {
                border-radius: 2px;
                cursor: pointer;
                display: block;
                height: 10px;
                position: absolute;
            }
        }
    }
}
