﻿.person-detail-mob {
    .person-heading {
        padding-bottom: 16px;

        > div {
            float: none;
            margin-right: 0;
            padding: 0;
            width: 100%;
        }

        &__pronouns {
            @include font(18px);
            color: $color-dark-grey;
            margin-top: 2px;
        }
    }
}
