﻿.subscribe-link {
    padding: 13px 0 12px;
    position: absolute;
    right: 0;
    text-align: right;
    width: 15%;

    a {
        @include font-heading();
        color: $color-white;
        font-size: 16px;
        margin-left: 20px;

        &:hover,
        &:focus {
            color: $color-purple;
        }
    }

    @media only screen and (min-width: 501px) and (max-width: 640px) {
        padding: 13px 15px 12px 0;
    }

    @media only screen and (min-width: 480px) and (max-width: 500px) {
        padding: 16px 23.9px 17px 0;
    }

    @media only screen and (min-width: 400px) and (max-width: 479px) {
        padding: 16px 35px 17px 0;
    }

    @media only screen and (max-width: 399px) {
        padding: 16px 55px 17px 0;
    }
}
