﻿.accolade-carousel-slide {
    @include for-print() {
        height: auto;
        width: auto
    }

    &:nth-child(odd) {
        .accolade-carousel-slide__content::after {
            background: $color-purple;
            border: solid $color-purple 2px;
        }
    }

    &:nth-child(even) {
        .accolade-carousel-slide__content::after {
            background: $color-dark-grey;
            border: solid $color-dark-grey 2px;
        }
    }

    &__icon {
        width: 30px;
        height: auto;
        position: absolute;
        right: 0;
        left: 0;
        margin: auto;
        font-size: 1.6em;
        bottom: 4px;

        @include for-print() {
            opacity: 0;
        }

        .bg {
            opacity: .2;
            transition: opacity .3s ease-in-out;
        }

        .fg {
            fill: white;
        }
    }

    &__content {
        align-items: center;
        border-radius: 50%;
        display: flex;
        flex-direction: column;
        height: 216px;
        justify-content: center;
        max-height: 264px;
        max-width: 264px;
        overflow: hidden;
        padding: 24px;
        text-align: center;
        width: 216px;

        &::after {
            border-radius: 50%;
            content: '';
            height: 264px;
            left: 50%;
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
            transition: transform 0.3s ease-in-out;
            width: 264px;
            z-index: $z-index-neg3;

            @include for-print() {
                display: none;
            }
        }

        @include for-print() {
            @include font-content();
            border: none;
            flex-direction: row;
            height: auto;
            justify-content: left;
            overflow: visible;
            padding-bottom: 8px;
            padding-left: 0px;
            padding-top: 0px;
        }

        &--hover:hover,
        &--hover:focus {
            border-color: $color-light-grey;

            &::after {
                transform: translate(-50%, -50%) scale(1.03);
            }
        }

        @media (hover: hover) {
            &:hover .accolade-carousel-slide__icon .bg {
                transition: opacity .3s ease-in-out;
                opacity: 1;
            }
        }
    }

    &__heading {
        @include font(24px, 28px);
        color: $color-white;
        font-weight: 500;

        @include for-print() {
            @include font(14px, 18px);
            @include font-content();
            color: $color-black;
            font-weight: normal;
            padding-top: 4px;
            text-align: left;
            white-space: nowrap;

            &:after {
                content: ', ';
                margin-right: 3px;
            }
        }
    }

    &__primary {
        @include font(18px, 20px);
        color: $color-white;
        font-weight: 500;
        margin-top: 4px;

        @include for-print() {
            @include font(14px, 18px);
            @include font-content();
            color: $color-black;
            font-weight: normal;
            text-align: left;
            white-space: nowrap;

            &:after {
                content: ', ';
                margin-right: 3px;
            }
        }
    }

    &__secondary,
    &__tertiary {
        @include font(18px, 28px);
        color: $color-white;
        margin-top: 4px;

        @include for-print() {
            @include font(14px, 18px);
            @include font-content();
            color: $color-black;
            font-weight: normal;
            text-align: left;
            white-space: nowrap;
        }
    }

    &__secondary {
        @include for-print() {
            &:after {
                content: ' ';
                margin-right: 3px;
            }
        }
    }

    &.swiper-slide {
        display: flex;
        justify-content: center;
        transition: opacity 0.3s ease-in-out;

        @include for-print() {
            justify-content: left;
        }

        .swiper-initialized & {
            opacity: 0;

            &-active {
                opacity: 1;
            }

            @include respond-to-container('xs') {
                &-next {
                    opacity: 1;
                }
            }

            @include respond-to-container('medium') {
                &-next + .swiper-slide {
                    opacity: 1;
                }
            }

            @supports not (container-type: inline-size) {
                @include respond-to('xs') {
                    &-next {
                        opacity: 1;
                    }
                }

                @include respond-to('medium') {
                    &-next + .swiper-slide {
                        opacity: 1;
                    }
                }
            }
        }

        .experience-editor & {
            max-height: 264px;
            opacity: 1 !important;
        }
    }
}
