$short-viewport-height: 700px;

@mixin pagination-styles {
    @include font(20px, $font-small: 18px);
    color: rgba(255, 255, 255, 0.75);
    cursor: pointer;
    transition: 0.4s;
    max-width: 190px;

    .pagination-progress-bar {
        background-color: $color-white;
        height: 5px;
        margin-bottom: 12px;
        width: 0%;
    }

    &--active {
        color: rgba(255, 255, 255, 1);

        .pagination-progress-bar {
            width: 100%;
        }

        &.pagination-item--mobile {
            .pagination-circle {
                background-color: $color-white;
            }
        }
    }

    @include hover-active {
        color: rgba(255, 255, 255, 1);
    }
}

.hero-carousel {
    background: $color-black;
    display: block;
    height: 100%;
    max-width: 1920px;
    position: relative;
    width: 100%;

    @include for-print() {
        background: unset;
        max-width: 100%;
    }

    &-bottom {
        left: 50%;
        position: absolute;
        z-index: 10;

        @include for-print() {
            display: none;
        }

        &-arrow {
            align-items: baseline;
            background: $color-black;
            border-bottom-left-radius: 110px;
            border-bottom-right-radius: 110px;
            display: none;
            height: 32px;
            left: -50%;
            justify-content: center;
            margin-left: auto;
            margin-right: auto;
            margin-top: -8px;
            position: relative;
            width: 64px;

            @include respond-to("xs") {
                display: flex;
            }

            .icon-tab-down-arrow {
                @include animation('bounce 2s infinite');
                color: rgba(255, 255, 255, 0.75);
                padding-top: 30px;
            }
        }
    }

    &-container {
        align-items: center;
        background-color: $color-black;
        display: flex;
        justify-content: center;

        @include for-print() {
            background-color: unset;
        }
    }

    &-font {
        &--large {
            .hero-carousel-slide-title {
                @include font($font-default: 64px, $font-default-line-height: 65px, $font-small: 56px, $font-small-line-height: 57px, $font-xs: 40px, $font-xs-line-height: 41px, $font-iphonev: 24px, $font-iphonev-line-height: 25px);

                @media (max-height: #{$short-viewport-height + 1}) {
                    @include font($font-default: 54px, $font-default-line-height: 55px, $font-small: 46px, $font-small-line-height: 47px, $font-xs: 30px, $font-xs-line-height: 31px, $font-iphonev: 24px, $font-iphonev-line-height: 25px);
                }
            }

            .hero-carousel-slide-abstract {
                @include font($font-default: 28px, $font-default-line-height: 32px, $font-small: 20px, $font-small-line-height: 24px, $font-iphonev:16px, $font-iphonev-line-height: 18px);
            }
        }

        &--medium {
            .hero-carousel-slide-title {
                @include font($font-default: 56px, $font-default-line-height: 57px, $font-small: 48px, $font-small-line-height: 49px, $font-xs: 32px, $font-xs-line-height: 33px, $font-iphonev: 24px, $font-iphonev-line-height: 25px);

                @media (max-height: #{$short-viewport-height + 1}) {
                    @include font($font-default: 46px, $font-default-line-height: 47px, $font-small: 38px, $font-small-line-height: 39px, $font-xs: 32px, $font-xs-line-height: 33px, $font-iphonev: 24px, $font-iphonev-line-height: 25px);
                }
            }

            .hero-carousel-slide-abstract {
                @include font($font-default: 24px, $font-default-line-height: 28px, $font-small: 20px, $font-small-line-height: 24px, $font-xs: 16px, $font-xs-line-height: 20px, $font-iphonev: 16px, $font-iphonev-line-height: 18px);
            }
        }

        &--small {
            .hero-carousel-slide-title {
                @include font($font-default: 48px, $font-default-line-height: 49px, $font-small: 36px, $font-small-line-height: 37px, $font-xs: 24px, $font-xs-line-height: 28px, $font-iphonev:24px, $font-iphonev-line-height: 25px);

                @media (max-height: #{$short-viewport-height + 1}) {
                    @include font($font-default: 38px, $font-default-line-height: 39px, $font-small: 26px, $font-small-line-height: 27px, $font-xs: 24px, $font-xs-line-height: 25px);
                }
            }

            .hero-carousel-slide-abstract {
                @include font($font-default: 22px, $font-default-line-height: 26px, $font-small: 20px, $font-small-line-height: 24px, $font-xs: 16px, $font-xs-line-height: 20px, $font-iphonev:16px, $font-iphonev-line-height: 18px);
            }
        }
    }

    @include for-print() {
        &-font {
            &--large,
            &--medium,
            &--small {
                .hero-carousel-slide-title {
                    @include font(18px, 19px);

                    @media (max-height: #{$short-viewport-height + 1}) {
                        @include font(18px, 19px);
                    }
                }

                .hero-carousel-slide-abstract {
                    @include font(16px, 20px);
                }
            }
        }
    }

    &-slide {
        @include font(18px);
        align-items: center;
        background: $color-black;
        display: flex;
        flex-direction: column;
        justify-content: center;
        overflow: hidden;
        position: relative;
        text-align: center;

        &--background {
            height: 100%;
            left: 0;
            min-height: 100%;
            min-width: 100%;
            position: absolute;
            top: 0;

            @include for-print() {
                flex-shrink: 0;
                height: 100px;
                left: unset;
                margin-right: 16px;
                min-height: auto;
                min-width: unset;
                position: relative;
                top: unset;
                width: 100px;
            }

            div {
                background: $color-black;
                background-size: cover;
                height: 100%;
                min-height: 100%;
                min-width: 100%;
                transition-delay: 500ms;
                width: 100%;
                z-index: 1;
            }

            &::before {
                background: rgba(0, 0, 0, 0);
                background-image: linear-gradient( rgba(0, 0, 0, 1) 2%, rgba(0, 0, 0, 0.35) 50%, rgba(0, 0, 0, 1) 94% );

                @include respond-to("xs") {
                    background-image: linear-gradient(#000 10%,rgba(0,0,0,.35) 50%,#000 90%);
                }

                display: inline-block;
                content: "";
                height: 100%;
                left: 0;
                pointer-events: none;
                position: absolute;
                width: 100%;
                z-index: 2;

                @include for-print() {
                    all: unset;
                }
            }
        }

        @include for-print() {
            align-items: flex-start;
            background: unset;
            flex-direction: row;
            justify-content: space-between;
            margin-bottom: 24px;
            overflow: unset;
            text-align: unset;
            width: calc(50% - 12px) !important; // important to override swiper
            // override swiper in print
            opacity: 1 !important;
            transform: unset !important;
            transition-duration: unset !important;

            &:first-of-type {
                margin-right: 0;
                width: 100% !important; // important to override swiper

                .hero-carousel-slide--background {
                    height: 200px;
                    width: 200px;
                }

                .hero-carousel-slide-abstract {
                    display: block;
                }

                .hero-carousel-slide-title {
                    @include font(32px, 33px);

                    @media (max-height: #{$short-viewport-height + 1}) {
                        @include font(32px, 33px);
                    }
                }
            }

            &:nth-child(2n) {
                margin-right: 24px;
            }
        }
    }

    &-slide-title,
    &-slide-abstract,
    &-slide-cta {
        color: $color-white;
        z-index: 26;
    }

    @include for-print() {
        &-slide-title,
        &-slide-abstract,
        &-slide-cta {
            color: $color-black;
            z-index: 1;
        }
    }

    &-slide-abstract {
        @include font-medium();
        margin-left: 20px;
        margin-right: 20px;
        margin-top: 25px;
        max-width: 750px;

        @include respond-to("xs") {
            margin-top: 40px;
        }

        @media (max-height: #{$short-viewport-height + 1}) {
            margin-top: 20px;
        }

        @include for-print() {
            color: $color-dark-grey;
            display: none;
            margin: 16px 0 0 0;
            max-width: 100%;


            @media (max-height: #{$short-viewport-height + 1}) {
                margin: 16px 0 0 0;
            }
        }
    }

    &-slide-cta {
        margin-top: 25px;
        max-height: 45px;

        @include respond-to("iphonev") {
            margin-top: 40px
        }

        @media (max-height: #{$short-viewport-height + 1}) {
            margin-top: 20px;
        }

        @include for-print() {
            display: none;
        }

        a {
            @include font-semibold();

            @include hover-active {
                background: $color-white;
                border: $color-white;
                color: $color-black;
            }
        }
    }

    &-slide-title {
        @include font-heading();
        margin-left: 20px;
        margin-right: 20px;
        max-width: 750px;

        @include for-print() {
            @include font(32px, 33px);
            margin: 0;
            max-width: 100%;
        }
    }

    &-autoplay, &--mobile--autoplay {
        .pagination-item--active {
            .pagination-progress-bar {
                @include animation('progress linear');
                animation-iteration-count: unset;
            }
        }
    }

    .swiper-container {
        background-color: $color-black;
        min-height: auto;
        height: 82vh;

        @include respond-to("largeipad") {
            min-height: #{$short-viewport-height - 100};
            height: 93vh;
        }

        @include for-print() {
            background-color: unset;
            min-height: auto;
            height: auto;
        }
    }

    .swiper-slide-active {
        .hero-carousel-slide--background {
            transform: scale(1.15, 1.15);
            transition: all 0.3s ease-in-out;

            @include for-print() {
                transform: unset;
                transition: unset;
            }
        }
    }

    &--overlay {
        @include for-print() {
            display: none;
        }
    }
}

.swiper-wrapper {
    @include for-print() {
        flex-wrap: wrap;
        transform: unset;
        transition: unset;
    }
}

.swiper-pagination {
    bottom: 10px;
    display: none;
    justify-content: space-around;
    left: 0;
    margin: auto;
    margin-bottom: 10px;
    max-width: 940px;
    right: 0;
    width: 100%;
    z-index: 26;

    @include respond-to("xs") {
        display: flex;
    }

    @include for-print() {
        display: none;
    }

    &--mobile {
        display: flex;

        @include for-print() {
            display: none;
        }
    }

    &--mobile {
        bottom: 34px;
        display: flex;
        justify-content: center;
        overflow-x: scroll;
        position: absolute;
        text-align: center;
        width: 100%;
        z-index: 30;

        @include respond-to('xs') {
            display: none;
        }

        @include for-print() {
            display: none;
        }

        .pagination-item--mobile {
            line-height: 18px;
            margin-left: 18px;
            margin-right: 18px;

            &:first-of-type {
                margin-left: 0;
            }

            &:last-of-type {
                margin-right: 0;
            }

            .pagination-circle {
                background-color: rgba(255, 255, 255, 0.3);
                border-radius: 50%;
                display: inline-block;
                height: 20px;
                width: 20px;
            }

            span {
                @include font(16px, 18px);
                text-align: center;
            }
        }

        &::-webkit-scrollbar {
            display: none;
        }
    }
}

.pagination-item {
    @include pagination-styles;
}

.pagination-item:focus-visible {
    @include pagination-styles;
    @include focus-outline();
}

.experience-editor {
    .hero-carousel {
        .swiper-container {
            height: auto;
        }
    }

    .swiper-wrapper {
        flex-direction: column;
        height: auto;
    }

    .swiper-slide {
        flex-shrink: 1;
        height: 700px;
    }

    .hero-carousel-slide--background {
        div {
            background-position: 0;
        }
    }
}
