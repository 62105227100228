﻿.audio-player {
    width: 100%;

    .modal__content & {
        width: $modal-width;

        @include respond-to('xs') {
            width: $modal-width--xs;
        }

        @include respond-to('largeipad') {
            width: $modal-width--largeipad;
        }
    }
}
