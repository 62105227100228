@use 'sass:math';

$practice-segments__margin: 16px;
$practice-segments__accordion-toggle-size: 15px;

.practice-segments {
    &__intro {
    }

    &__heading {
        @include font(30px);
        @include font-medium();
        color: $color-purple;

        @include for-print() {
            @include font(18px, 22px);
        }

    }

    &__abstract {
        @include font(18px, 24px);
        @include font-content();
        @include richtext();
        margin-top: $practice-segments__margin;

        @include for-print() {
            @include font(14px, 16px);
        }
    }

    &__list {
        display: flex;
        flex-direction: column;
        gap: #{math.div($practice-segments__margin, 2)};
        margin-top: $practice-segments__margin;

        @include for-print() {
            gap: #{$practice-segments__margin * 1.5};
            margin-bottom: 32px;
        }
    }

    &__list-item {
        background-color: $color-white-smoke;

        @include for-print() {
            background-color: transparent;
        }
    }

    &__accordion {
    }

    &__accordion-title {
        @include accordion-title();
        background: none;

        &:hover,
        &:focus {
            background-color: $color-purple;

            @include for-print() {
                background-color: transparent;
            }

            .practice-segments__accordion-toggle,
            .practice-segments__accordion-heading {
                color: $color-white;

                @include for-print() {
                    color: $color-black;
                }
            }
        }

        &:focus-visible {
            @include focus-outline();
        }
    }

    &__accordion-toggle {
        @include accordion-toggle();
    }

    &__accordion-heading {
        @include accordion-heading();
    }

    &__accordion-panel {
        @include accordion-panel();
    }

    &__accordion-padding {
        padding: $practice-segments__margin $practice-segments__margin $practice-segments__margin #{$practice-segments__margin * 3};

        @include for-print() {
            padding: 8px 0 0 0;
        }
    }

    &__accordion-content {
        @include font(16px, 20px);
        @include font-content();
        @include richtext();

        @include for-print() {
            @include font(14px, 16px);
            color: $color-black;
        }


        p {
            @include for-print(){
                @include font(14px, 16px);
                color: $color-black;
            }
        }
    }

    &__link-container {
        @include font(11px);
        color: $color-black;
        margin-top: $practice-segments__margin;
        transition: color $transition-timing $transition-function;

        @include for-print() {
            display: none;
        }

        &:hover,
        &:focus {
            color: $color-purple;
        }
    }

    &__link {
        @include font(16px);
        @include font-semibold();
        color: inherit;
        margin-left: 8px;
        text-decoration: none;
        transition: color $transition-timing $transition-function;

        a:visited {
            color: inherit;
            text-decoration: none;
        }
    }
}
