﻿@mixin flyin-hidden() {
    opacity: 0;
    //transform: translateX(calc(100% + var(--translation-offset)));
}

@mixin flyin-shown() {
    opacity: 1;
    transform: none;
}

$fly-in__extend-amount: 120px;

.fly-in {
    --translation-offset: 0px;
    overflow: hidden;

    @include respond-to('small') {
        overflow: initial;
    }

    .structure-two-column--skewed-left & {
        @include respond-to('large') {
            --translation-offset: #{$fly-in__extend-amount};
        }
    }

    &__panel {
        @include flyin-hidden();
        transition: all var(--fly-in-duration) ease-in-out;

        @include for-print() {
            @include flyin-shown();
        }

        &--extend {
            width: 100%;

            @include respond-to('large') {
                width: calc(100% + #{$fly-in__extend-amount});
            }
        }

        &--left {
            transform: translateX(calc(100% + var(--translation-offset)));

            @include for-print() {
                @include flyin-shown();
            }
        }

        &--right {
            transform: translateX(calc(0px - calc(100% + var(--translation-offset))));

            @include for-print() {
                @include flyin-shown();
            }
        }
    }

    &--show {

        & .fly-in__panel {
            @include flyin-shown();
        }

        & .fly-in__panel--extend {
            transform: translateX(var(--translation-offset));
        }

        .structure-two-column--skewed-left & .fly-in__panel--extend {
            transform: translateX(calc(0px - var(--translation-offset)));
        }
    }

    .experience-editor & {
        @include flyin-shown();
    }
}
