@use 'sass:math';

$team-list-person__margin: 16px;

.team-list-person {
    display: flex;
    flex-direction: row;
    gap: $team-list-person__margin;
    max-width: 100%;

    @include for-print() {
        page-break-inside: avoid;
    }

    @supports not (gap: 1px) {
        margin-top: 32px;
    }

    @include respond-to-container('medium') {
        flex-direction: var(--team-list-person--layout);
    }

    &:not(&--aside) {
        &.team-list-person--column {
            @include respond-to-container('medium') {
                justify-self: center;
                max-width: 146px;

                .team-list-person__details-container {
                    margin-top: 0;
                }

                .team-list-person__details {
                    text-align: center;
                }
            }
        }
    }

    &__image-container {
        display: block;
        flex-shrink: 0;
        width: 120px;

        @include respond-to-container('small') {
            width: 146px;
        }

        @include for-print() {
            max-width: 120px;
        }
    }

    &__image {
        max-height: auto;
        max-width: min(100%, 120px);

        @include respond-to-container('small') {
            max-width: min(100%, 146px);
        }

        @include for-print() {
            max-width: 120px;
        }
    }

    &--aside &__image-container {
        @include respond-to-container('small') {
            width: 34%;
        }
    }

    &--aside &__image {
        @include respond-to-container('small') {
            height: auto;
            max-width: 100%;
            width: 100%;
        }
    }

    &__details-container {
        display: flex;
        flex-direction: column;
        margin-top: $team-list-person__margin;

        &--no-margin {
            margin-top: 0;
        }

        @supports not (gap: 1px) {
            padding-left: $team-list-person__margin;
        }
    }

    @include for-print() {
        &__details h4 {
            display: inline;

            a {
                text-transform: uppercase;
            }
        }
    }

    &__name {
        @include font(18px, 19px);
        @include font-heading();
        color: $color-black;
        text-transform: uppercase;

        @include for-print() {
            @include font(16px, 17px);
        }

        .team-list__listing-container--text-only-in-print & a {
            @include for-print() {
                @include font-semibold();
                text-transform: capitalize;
            }
        }
    }

    &--aside &__name {
        @include font(18px, 19px);
    }

    &__link {
        display: block;
    }

    &__title {
        @include font-medium();
        @include font(16px);
        color: $color-black;
        margin-top: #{math.div($team-list-person__margin, 4)};

        @include for-print() {
            @include font(14px, 15px);
        }
    }

    &__block {
        @include font(14px, 16px);
        @include font-content();
        color: $color-black;
        margin-top: #{math.div($team-list-person__margin, 2) + 2};
    }

    &__practices {
        margin-top: #{math.div($team-list-person__margin, 4)};
        @include font(14px, 18px);
    }

    &__practice-link {
        @include font(14px, 18px);
        @include font-content();
        color: $color-black;
        display: inline-block;

        &:not(:last-of-type) {
            &:after {
                content: ', '
            }
        }

        @include for-print() {
            @include font(14px, 15px);
        }
    }

    &__offices {
        margin-top: #{math.div($team-list-person__margin, 4)};
    }

    &__office-link {
        @include font(14px, 24px);
        @include font-content();
        color: $color-black;
        min-height: 24px;
        white-space: nowrap;

        &:not(:last-of-type) {
            &:after {
                content: ' / '
            }
        }
    }

    @include for-print() {

        &__details-container,
        &__details {
            display: inline;
        }
    }
}