.feature-card {
    display: inline-block;
    overflow: hidden;

    @include respond-to('xxs') {
        max-height: 300px;
    }

    @include respond-to('small') {
    }

    &:hover {
        .feature-card__padding {
            background-color: $color-footer-grey;
            height: 268px;
            padding: 16px;
            overflow: hidden;

            @include respond-to('xxs') {
                max-height: 268px;
            }
        }

        .feature-card__image {
            transform: scale(1.2);
        }

        .feature-card__sizing:only-child {
            height: 268px;
            max-height: 268px;
        }

        .feature-card__content {
            max-height: 100%;
            overflow: hidden;
        }

        .feature-card__heading {
            color: $color-purple-label;
        }

        .feature-card__abstract {
            @include richtext();
            color: $color-black;
            overflow: hidden;
        }
    }

    &__image {
        background-size: cover !important;
        height: 75px;
        max-height: 25%;
        opacity: 1;
        transition: transform $transition-timing $transition-function;

        &-container {
            overflow: hidden;
        }
    }

    &__padding {
        background-color: $color-white-smoke;
        height: 268px;
        padding: 16px;
        transition: background-color 0.3s ease-in-out;

        @include respond-to('xxs') {
            max-height: 268px;
        }
    }

    .feature-card__image-container + .feature-card__padding {
        height: 193px;
        
        @include respond-to('xxs') {
            max-height: 193px;
        }
    }

    &__content {
        max-height: 100%;
        overflow: hidden;
    }

    &__label {
        @include font-medium();
        color: $color-purple-label;
        font-size: 14px;
        line-height: 15px;
        margin-bottom: 4px;
    }

    &__heading {
        @include font-semibold();
        color: $color-black;
        font-size: 18px;
        line-height: 22px;
        transition: color 0.3s ease-in-out;
    }

    &__abstract {
        @include font-content();
        @include richtext();
        color: $color-dark-grey;
        font-size: 16px;
        line-height: 20px;
        margin-top: 8px;
        transition: color 0.3s ease-in-out;

        @include respond-to('xxs') {
            max-height: 142px;
            overflow: hidden;
        }
    }
}
