﻿$header-height: 158px;
$mobile-nav-height: #{$navigation__fixed--mobile - 2}; //subtract for the 2px border
$mobile-logo-width: 169px;
$desktop-logo-fixed-width: 180px;
$icon-size: 38px;
$icon-size--small: 25px;
$print-header-height: 90px;
$desktop-logo-aspect-ratio: auto 281/45;
$mobile-logo-aspect-ratio: auto 169/27;
$desktop-logo-fixed-aspect-ratio: auto 180/29;

.navigation {
    position: fixed;
    width: 100%;
    z-index: $z-index-nav;

    @include for-print() {
        position: static;
    }

    @include respond-to('small') {
        position: relative;
        width: auto;
    }

    &__search {
        @include font(24px);

        @include respond-to('small') {
            @include font(38px);
        }
    }

    .subnavigation-module-header-menu {
        background-color: $color-black;
        display: none;

        @include respond-to('small') {
            display: block;
        }
    }

    &-spacer-mobile--darkMode {
        height: 0px;
        transition: height 0.4s;

        &--fixed {
            display: flex;
            height: 100px;

            @include respond-to('small') {
                display: none;
            }
        }
    }

    &-spacer--lightMode {
        display: none;
        height: 0;

        @include respond-to('small') {
            display: block;
        }

        @include for-print() {
            display: none;
        }
    }

    &-spacer--show {
        height: 160px;
    }

    &-spacer-mobile--lightMode {
        display: flex;
        height: $mobile-nav-height;
        transition: height 0.4s;

        @include respond-to('small') {
            display: none;
        }

        @include for-print() {
            display: none;
        }

        &--fixed {
            height: 152px;
        }
    }

    &.darkMode:not(.fixed-nav) {
        width: 100%;

        nav {
            border-bottom: none;
            position: absolute;

            @include respond-to('small') {
                border-bottom: 2px rgba($color: $color-very-dark-grey, $alpha: 0.4) solid;
            }

            @include for-print() {
                border-bottom: none;
                position: relative;
            }
        }

        .navigation__container {
            background: transparent;
            position: relative;
        }

        .navigation__container-main {
            background-color: transparent;
        }

        .navigation__languages-label,
        .navigation__languages-btn,
        .navigation__search-btn {
            color: hsla(0, 0%, 100%, 0.75);
            transition: color 0.4s;
        }

        .navigation__languages {
            @include hover-active {
                .navigation__languages-label,
                .navigation__languages-btn {
                    color: $color-white;
                }
            }
        }

        .navigation__search-btn {
            @include hover-active {
                color: $color-white;
            }
        }
    }

    &--top-container {
        background-color: $color-header;
        height: 100px;
        margin-top: -100px;
        transition: margin 0.4s;

        @include for-print() {
            display: none;
        }

        &.search-open {
            margin-top: 0px;
        }
    }

    &__icon-container {
        display: none;
        flex-direction: row-reverse;

        @include respond-to('small') {
            display: flex;
        }

        &--mobile {
            display: flex;

            @include respond-to('small') {
                display: none;
            }
        }
    }

    &:not(.darkMode),
    &.fixed-nav {
        .navigation__container {
            border-bottom: 2px solid $color-black;

            @include for-print() {
                border-bottom: none;
            }
        }

        @include for-print() {
            position: static;
        }

        .navigation__search-btn,
        .navigation__languages-btn {
            color: rgba(0, 0, 0, 1);
            transition: color 0.4s;

            @include respond-to("small") {
                @include hover-active {
                    color: $color-purple;
                }
            }
        }

        .navigation__languages-btn {
            @include respond-to("small") {
                @include hover-active {
                    color: $color-purple;
                }
            }
        }

        .navigation__languages-label,
        .navigation__languages-btn {
            color: $color-black;
            transition: color 0.4s;

            @include respond-to("small") {
                color: $color-very-dark-grey;
            }
        }
    }

    &.fixed-nav {
        background-color: $color-white;
        border-bottom: 2px $color-black solid;
        height: $mobile-nav-height;
        max-height: $navigation__fixed;
        position: fixed;
        top: 0;
        width: 100%;

        @include respond-to('small') {
            height: $navigation__fixed;
            top: initial;
        }

        @include for-print() {
            border-bottom: none;
            position: static;
        }

        nav {
            min-height: $mobile-nav-height;
        }

        .navigation__languages,
        .navigation__search {
            align-items: center;
            height: 100%;
            top: 0;
        }

        .navigation__container-main {
            flex-direction: row;
            justify-content: space-between;
            margin-top: 2px;

            @include respond-to('small') {
                justify-content: center;
                margin-top: 0;
            }

            @include for-print() {
                justify-content: center;
            }
        }

        .navigation__search,
        .navigation__languages {
            top: 0;

            &_label {
                @include font(14px, 24px);
                color: $color-black;
                margin-top: 3px;
            }

            @include hover-active {
                .navigation__languages-label,
                .navigation__languages-btn {
                    color: $color-purple;
                }
            }
        }

        .navigation__logo {
            aspect-ratio: $mobile-logo-aspect-ratio;
            margin-top: 5px;
            padding-top: 0;
            width: $mobile-logo-width;

            @include respond-to('small') {
                aspect-ratio: $desktop-logo-fixed-aspect-ratio;
                margin-top: 0;
                width: $desktop-logo-fixed-width;
            }

            @include for-print() {
                width: 280px;
            }
        }

        .navigation__container {
            background-color: $color-white;
            background-image: none;
            height: $mobile-nav-height;
            justify-content: center;

            @include respond-to('small') {
                height: $navigation__fixed;
            }

            &::before {
                display: none;
            }

            .navigation__links {
                display: none;
            }
        }
    }

    &__logo {
        margin-top: 5px;
        padding-top: 0;
        text-align: center;
        width: $mobile-logo-width;
        z-index: $z-index-1;

        @include respond-to('small') {
            margin-top: 55px;
            padding-top: initial;
            width: initial;
        }

        @include for-print() {
            height: $print-header-height;
            margin-top: 0;

            &--default {
                display: none;
            }

            &--reversed {
                display: block !important;
            }
        }

        a {
            align-items: center;
            display: flex;
            justify-content: center;

            @include respond-to('small') {
                align-items: initial;
                justify-content: initial;
            }

            @include for-print() {
                align-items: center;
                justify-content: center;
            }
        }

        img {
            aspect-ratio: $mobile-logo-aspect-ratio;
            width: 100%;

            @include for-print() {
                width: 268px;
            }
        }
    }

    nav {
        display: block;
        min-height: $mobile-nav-height;
        position: fixed;
        width: 100%;

        @include respond-to('small') {
            min-height: $header-height;
            position: relative;
        }

        @include for-print() {
            position: static;
            min-height: $print-header-height;
        }
    }

    &__container {
        align-items: center;
        background-color: $color-white;
        border-bottom: 2px solid rgba(255, 255, 255, 0.15);
        display: flex;
        flex-direction: column;
        height: $mobile-nav-height;
        justify-content: flex-start;
        margin-left: auto;
        margin-right: auto;
        min-height: $mobile-nav-height;
        width: 100%;

        @include respond-to('small') {
            border-bottom: none;
            height: $header-height;
            min-height: initial;
        }

        @include for-print() {
            border-bottom: none;
            height: $print-header-height;
        }

        &-margins {
            @include responsive-component-margin-unstructured($global-page-margin);
        }

        &-main {
            align-items: center;
            display: flex;
            flex-direction: row;
            height: 100%;
            justify-content: space-between;
            margin: 0 auto;
            margin-top: 5px;
            max-width: 940px;
            min-height: 42px;
            position: relative;
            width: 100%;

            @include respond-to('small') {
                margin-top: 0;
                min-height: initial;
                justify-content: initial;
                flex-direction: column;
            }

            @include for-print() {
                height: $print-header-height;
                justify-content: center;
            }
        }
    }

    &__global-search,
    &__language-toggle {
        align-items: center;
        background-color: $color-header;
        display: flex;
        height: 100px;
        justify-content: center;
        margin-top: -100px;
        position: absolute;
        transition: margin 0.4s;
        width: 100%;

        &.search-open,
        &.language-open {
            margin-top: 0px;
            transition: margin 0.4s;
        }
    }

    &__links {
        align-items: center;
        display: none;
        flex-direction: row;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        margin-top: 15px;
        padding-bottom: 16px;
        width: 100%;

        @include respond-to('small') {
            display: flex;
        }

        @include for-print() {
            display: none;
        }
    }

    &-item {
        @include font(18px, 24px);
        padding-left: 8px;
        padding-right: 8px;
        margin-left: 8px;
        margin-right: 8px;
        text-align: center;

        &--active {
            a.ml-link {
                @include font-semibold;
            }
        }
    }

    &__search {
        display: flex;
        flex-direction: row-reverse;
        left: auto;
        position: relative;
        top: 0;
        width: auto;

        @include respond-to('small') {
            flex-direction: row;
            left: 0;
            position: absolute;
            top: 15px;
            width: 100%;
        }

        @include for-print() {
            display: none;
        }

        &-btn {
            cursor: pointer;
            height: $icon-size--small;
            margin-left: 13px;
            width: $icon-size--small;

            @include respond-to('small') {
                height: $icon-size;
                margin-left: 0;
                width: $icon-size;
            }

            @include hover-active {
                cursor: pointer;
                color: $color-purple;
            }

            &:not(:first-of-type) {
                margin-left: 13px;
            }
        }
    }

    &__languages {
        align-items: center;
        display: flex;
        justify-content: center;
        position: relative;
        right: auto;
        top: 0;

        @include hover-active {
            cursor: pointer;

            .navigation__languages-btn,
            .navigation__languages-label {
                color: $color-purple;
            }
        }

        @include respond-to('small') {
            position: absolute;
            right: 0;
            top: 24px;
        }

        @include for-print() {
            display: none;
        }

        &-label {
            @include font(14px, $icon-size--small);
            @include font-semibold();
        }

        &-btn {
            @include font($icon-size--small);
            align-items: center;
            display: flex;
            justify-content: center;
            margin-left: 8px;
            margin-right: 0;
            text-align: right;
        }
    }
}

.header--padding {
    height: $header-height;
    transition: height 0.4s;

    &.nav-padding {
        height: 290px;
    }
}

.experience-editor {
    .navigation {
        &-spacer {
            &--lightMode {
                display: none;
            }
        }
    }
}
