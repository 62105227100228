﻿.slide-in {
    --translateX-in-from: 0;
    --translateY-in-from: 0;
    --translateX-out-to: 0;
    --translateY-out-to: 0;
    --duration: 1s;
    --timing-function: ease-in-out;
    opacity: 0;

    @include for-print() {
        opacity: 1;
    }

    &--triggered {
        opacity: 1;
    }

    &--in {
        animation: slidein var(--duration) var(--timing-function);

        @include for-print() {
            animation: none;
        }
    }

    &--out {
        animation: slideout var(--duration) var(--timing-function);
        opacity: 0;

        @include for-print() {
            animation: none;
            opacity: 1;
        }
    }

    &--in-up {
        --translateY-in-from: 100%;
    }

    &--in-right {
        --translateX-in-from: 100%;
    }

    &--in-down {
        --translateY-in-from: -100%;
    }

    &--in-left {
        --translateX-in-from: -100%;
    }

    &--out-up {
        --translateY-out-to: -100%;
    }

    &--out-right {
        --translateX-out-to: 100%;
    }

    &--out-down {
        --translateY-out-to: 100%;
    }

    &--out-left {
        --translateX-out-to: -100%;
    }

    @keyframes slidein {
        from {
            transform: translate(var(--translateX-in-from), var(--translateY-in-from));
            opacity: 0;
        }

        to {
            transform: translate(0%, 0%);
            opacity: 1;
        }
    }

    @keyframes slideout {
        from {
            transform: translate(0%, 0%);
            opacity: 1;
        }

        to {
            transform: translate(var(--translateX-out-to), var(--translateY-out-to));
            opacity: 0;
        }
    }
}
