﻿// START PLAYLIST(S)
.ml-playlists {
    box-sizing: border-box;
    width: 100%;
    position: relative;
    margin-right: auto;
    margin-left: auto;
    @media only screen and (max-width : 640px) {
        width: 100%;
        padding: 0 15px;
    }

    * {
        box-sizing: border-box;

        &::after,
        &::before {
            box-sizing: border-box;
        }
    }

    .ml-see-more {
        @include font-semibold();
        color: #000;
        text-decoration: none;
        display: inline-block;
        padding-left: 10px;
        &:hover {
            color: #000;
        }
    }

    input[type="checkbox"] {
        display: none;
    }

    .ml-playlist-footer-container {
        width: 100%;
        position: relative;
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        text-align: center;
        margin-bottom: 15px;

        .ml-playlist-load-more {
            @include font-heading();
            width: 100%;
            color: $color-black;
            padding: 15px;
            margin: 0 auto;
            cursor: pointer;
            max-width: 350px;
            background: #fff;
            text-align: center;
            display: inline-block;
            border: 2px solid #000;
            text-transform: uppercase;
            font-size: 16px;

            &:hover {
                color: #fff;
                background-color: rgb(100, 51, 128);
            }
        }

        .ml-playlist-preloader {
            display: inline-block;
            color: rgb(100, 51, 128);

            &.hidden {
                display: none;
            }
        }
    }
}
// END PLAYLIST(S)

// START PLAYLIST
.ml-playlist {
    width: 100%;
    //margin-top: 16px;
    //margin-bottom: 24px;
    background: #fff;
    position: relative;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;

    @media only screen and (max-width : 640px) {
        html {
            font-size: 70%;
        }

        .ml-playlists {
            width: 100%;
        }
    }

    .icon {
        font-family: 'Glyphicons Halflings';
        font-style: normal;
        font-weight: normal;
        display: inline;
        position: relative;
        color: #000;
        font-size: 14px;

        &-caret-right {
            font-size: 1.6rem;
            line-height: 1.6rem;
            color: #CBCBCB;
            position: relative !important;
            margin: 0 4px;
            vertical-align: middle;
            width: 8px;
            height: 12px;
            background: url("/contents/images/arrow-light-grey1.png") 0 0 no-repeat;

            &::before {
                content: "";
            }
        }

        &-caret-down .rotate-90{
            transform: rotate(90deg);
        }

        &-caret-down .rotate-180{
            transform: rotate(180deg);
        }
    }

    &.ml-playlist-row {
        .ml-playlist-title {
            display: flex;
            flex-direction: row;

            .ml-accordion-trigger {
                display: none;

                @media only screen and (max-width : 640px) {
                    display: inline-block;
                    position: relative;
                    left: unset;
                    pointer-events: all;
                    vertical-align: middle;
                    margin: 0;
                    padding: 0 5px 0 0;
                }
            }

            .ml-accordion-description-wrapper {
                padding: 0;
                display: inline-block;
                vertical-align: middle;
            }
        }

        .ml-playlist-videos-container {
            @media only screen and (max-width : 640px) {
                display: none;

                &.open {
                    display: block;
                }
            }
        }
    }

    .ml-playlist-title {
        margin: 12px 0;

        a {
            @include font-semibold();
            font-size: 14px;
            line-height: 1.8rem;
            color: #000;
            display: inline-block;
            margin-left: 8px;
            text-decoration: none;

            @media only screen and (max-width : 640px) {
                display: none;
            }
        }

        .ml-accordion-description-wrapper {
            h3 {
                @include font-heading();
                color: $color-black;
                display: inline-block;
                font-size: 16px;
                margin: 0;
                margin-left: 4px;
                text-transform: uppercase;

                @media only screen and (max-width : 640px) {
                    font-size: 26px;
                    margin: 0;
                }
            }
        }

        .ml-view-more a {
            cursor: pointer;
        }

        .ml-view-more:hover {
            a {
                color: $color-purple;
            }

            .icon-caret-right {
                background: url("/contents/images/arrow-light-grey1.png") 0 0 no-repeat;
            }
        }
    }

    .ml-playlist-videos-container {
        display: flex;
        width: 100%;
        position: relative;

        .ml-playlist-nav .icon {
            @media only screen and (min-width: 640px) and (max-width : 768px) {
                line-height: 9rem;
                font-size: 2.1rem;
                color: #000;
            }
        }

        .ml-playlist-videos {

            .ml-playlist-item {
                width: 100%;
                min-height: 100px;
                margin-bottom: 20px;
                box-sizing: border-box;
                -moz-box-sizing: border-box;
                -webkit-box-sizing: border-box;

                .ml-media-title {
                    @include font-semibold();
                    margin: 0;
                    padding: 0;
                    left: 200px;
                    font-size: 16px;
                    margin-top: -2px;
                    position: relative;
                    width: calc(100% - 200px);
                    text-transform: none;

                    @media only screen and (max-width : 640px) {
                        left: 0;
                        width: 100%;
                        margin-top: 0;
                        position: relative;
                    }
                }

                .ml-media-description {
                    @include font-content();
                    margin: 0;
                    padding: 0;
                    left: 200px;
                    font-size: 12px;
                    position: relative;
                    width: calc(100% - 200px);
                    line-height: 16px;

                    @media only screen and (max-width : 640px) {
                        left: 0;
                        width: 100%;
                        margin-top: 0;
                        position: relative;
                    }
                }


                .ml-view-more-mobile {
                    display: none;
                }
            }
        }
    }
}
    // END PLAYLIST
    // HORIZONTAL PLAYLIST
.ml-playlist.horizontal {

    .ml-playlist-videos-container {

        @media only screen and (min-width : 640px) {
            display: flex;
        }

        .ml-playlist-videos {
            white-space: nowrap;
            overflow-x: hidden;
            font-size: 0;

            @media only screen and (max-width : 640px) {
                width: 100%;
            }

            .ml-view-more-mobile {
                position: relative;
                display: block;
                text-align: center;
                top: -15px;

                @media only screen and (max-width : 640px) {
                    position: relative;
                    display: block;
                    text-align: left;
                    top: -15px;
                }

                & > a {
                    @include font-semibold();
                    font-size: 1.6rem;
                    line-height: 1.8rem;
                    color: #000;
                    display: inline-block;
                    margin-left: 8px;
                    cursor: pointer;

                    & > .icon {
                        font-family: 'Glyphicons Halflings';
                        font-style: normal;
                        font-weight: normal;
                        position: relative;
                        top: 0;
                        color: #000;
                        font-size: 14px;
                    }
                }
            }

            .ml-view-more-mobile:hover {
                a {
                    color: $color-purple;
                }

                .icon-caret-right {
                    background: url("/contents/images/arrow-light-grey1.png") 0 0 no-repeat;
                }
            }

            .ml-media-block {
                display: inline-block;
                width: 24.36%;
                padding: 0 4px 0 4px;
                white-space: normal;
                vertical-align: top;

                a.ml-open-media {
                    cursor: pointer;

                    @media only screen and (max-width : 640px) {
                        pointer-events: all;
                    }

                    .ml-video-thumbnail {
                        width: 100%;
                        position: relative;

                        & > img {
                            width: 100%;
                            height: 100%;
                            vertical-align: middle;
                        }

                        .ml-video-thumb-overlay {
                            color: rgba(255, 255, 255, 0.7);
                            transition: all ease-in-out 0.2s;
                            position: absolute;
                            text-align: center;
                            cursor: pointer;
                            height: 100%;
                            width: 100%;
                            left: 0;
                            top: 0;

                            &:hover {
                                color: rgba(255, 255, 255, 0.9);
                            }

                            .ml-video-thumb-overlay-wrapper {
                                position: relative;
                                width: 100%;
                                height: 100%;

                                @media only screen and (max-width : 640px) {
                                    &.gated .playIcon {
                                        top: 50%;
                                        width: auto;
                                        max-width: 100%;
                                        margin-top: -45px;
                                        position: relative;
                                    }
                                }
                            }

                            .playIcon {
                                position: absolute;
                                top: calc(50% - 28px);
                                left: calc(50% - 20px);
                                width: 50px;
                                pointer-events: all;
                            }
                        }
                    }

                    .ml-video-mobile {
                        width: 100%;
                        height: 100%;
                        display: block;
                        max-width: 100%;
                        min-height: 10px;
                        max-height: 100%;
                        position: relative;

                        iframe {
                            @media only screen and (max-width : 640px) {
                                display: block;
                            }
                        }

                        .mejs-container {
                            max-width: 100%;
                            height: 100%;
                        }

                        .mejs__container {
                            max-width: 100%;
                            height: 100%;
                        }
                    }
                }

                .ml-video-title {
                    @include font-semibold();
                    font-size: 12px;
                    color: #000;
                    line-height: 16px;
                    margin: 4px 0;
                    text-transform: none;
                }
            }

            @media only screen and (max-width : 640px) {
                .ml-media-block,
                .ml-media-block:first-child,
                .ml-media-block:last-child {
                    display: block;
                    width: 100%;
                    margin: 10px 0 30px 0;
                    white-space: normal;
                }
            }
        }

        .icon-caret-right {
            font-size: 1.6rem;
            line-height: 1.6rem;
            color: #CBCBCB;
            //position: relative !important;
            margin: 0 4px;
            vertical-align: middle;
        }

        .ml-playlist-nav {
            .icon {
                @media only screen and (min-width : 769px) {
                    top: 28px !important;
                }

                top: 7px;
                height: 25px;
                font-size: 3rem;
                color: #000;
                cursor: pointer;

                &.icon-prev {
                    left: -7%;

                    &::before {
                        content: "";
                        width: 22px;
                        height: 25px;
                        margin-left: 18px;
                        position: relative;
                        display: inline-block;
                        background: url("/~/media/MorganLewisCareers/Carousel/carousel-control.ashx") 0 -25px no-repeat;
                    }
                }

                &.icon-next {
                    right: -7%;

                    &::before {
                        content: "";
                        width: 22px;
                        height: 25px;
                        position: relative;
                        margin-right: 18px;
                        display: inline-block;
                        /* top: calc(50% - 28px); */
                        background: url("/~/media/MorganLewisCareers/Carousel/carousel-control.ashx") 0 0 no-repeat;
                    }
                }

                &.icon-viewall {
                    right: -8%;

                    &::before {
                        background: url("/~/media/MorganLewisCareers/Carousel/carousel-control.ashx") 0 -50px no-repeat;
                        width: 22px;
                    }
                }
            }
        }
    }
}    
// END HORIZONTAL PLAYLIST
 
// VERTICAL PLAYLIST
.ml-playlist.vertical {
    .ml-playlist-videos {
        width: 100%;
    }
    .ml-playlist-item {
        margin-bottom: 30px;

        .stacked-media-module-main-holder {
            width: 100%;
            height: 100%;
            min-height: 100px;
            overflow: hidden;
            position: relative;

            @media only screen and (max-width : 640px) {
                height: auto;
            }

            .ml-media-block {
                display: flex !important;
                padding: 0;
                margin-left: 0 !important;
                white-space: normal;
                width: 100% !important;

                @media only screen and (max-width : 640px) {
                    top: 0;
                    left: 0;
                    width: unset !important;
                    margin: unset !important;
                    height: auto;
                    overflow: hidden;
                    position: relative;
                    flex-direction: column;
                }

                .stacked-media.stacked-media-module-main-video-holder,
                .stacked-media.stacked-media-module-main-audio-holder {
                    top: 0;
                    left: 0;
                    overflow: hidden;
                    position: relative;
                    margin-bottom: auto;
                    width: 50%;

                    @media only screen and (max-width : 640px) {
                        width: 100%;
                        height: auto;
                    }

                    .stacked-media-module-overlay {
                        .stacked-media-module-overlay-wrapper {
                            .playIcon {
                                top: calc(50% - 32px);
                                left: calc(50% - 32px);
                                width: 64px;
                                margin: auto;
                                pointer-events: all;
                            }
                        }
                    }
                }
              
                .ml-content-wrapper {
                    display: inline-block;
                    position: relative;
                    max-width: 50%;
                    padding-left: 20px;

                    @media only screen and (max-width : 640px) {
                        text-transform: none;
                        left: 0;
                        max-width: 100%;
                        padding-left: 0;
                        position: relative;
                    }

                    .ml-media-title {
                        @include font-semibold();
                        left: 0;
                        width: 100%;                        
                        padding: 0;
                        font-size: 16px;
                        margin: 0;
                        position: relative;

                        @media only screen and (max-width : 640px) {
                            margin: 4px 0;
                        }
                    }

                    .ml-media-description {
                        @include font-content();
                        left: 0;
                        width: 100%;
                        margin: 0;
                        padding: 0;
                        font-size: 12px;
                        position: relative;
                        line-height: 16px;
                    }
                }
            }
        }
    }
}
// END VERTICAL PLAYLIST