.standalone-call-to-action-tile {
    container-type: inline-size;

    @include for-print() {
        display: none;
    }

    &:hover {
        .standalone-call-to-action-tile__button-container {
            background-color: $color-purple;
        }
    }

    &--spacer {
        border-top: 2px solid $color-black;
        margin-top: 40px;
        padding-top: 20px;

        @include respond-to-container('xxs') {
            border: none;
            margin-top: 32px;
            padding: 0;
        }
    }

    &__link {
        align-items: center;
        background-color: $color-white;
        display: flex;
        height: inherit;
        justify-content: center;
        max-height: inherit;

        @include respond-to-container('xxs') {
            background-color: $color-white-smoke;
        }
    }

    &__padding {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: #{$medium-spacer * 2} 0;
        width: 100%;

        @include respond-to-container('xxs') {
            flex-direction: row;
            padding: #{$medium-spacer * 4};
            width: calc(100% - #{$medium-spacer * 4});
        }
    }

    &__top {
        overflow: hidden;

        @include respond-to-container('xxs') {
            width: calc(50% - #{$medium-spacer * 2});
        }
    }

    &__heading {
        @include font-medium();
        color: $color-purple;
        font-size: 24px;
        line-height: 28px;
        padding-bottom: 16px;
        text-transform: none;

        @include for-print() {
            @include font(18px, 20px);
        }
    }

    &__abstract {
        @include font-content();
        color: $color-dark-grey;
        font-size: 16px;
        line-height: 20px;
        padding-bottom: 32px;

        @include respond-to-container('xxs') {
            padding-bottom: 0px;
        }

        &:has(p) {
            padding-bottom: 16px;

            @include respond-to-container('xxs') {
                padding-bottom: 0px;

                p {
                    margin-bottom: 0px;
                }
            }
        }

        @include for-print() {
            @include font(14px, 16px);
        }
    }

    &__bottom {
        @include respond-to-container('xxs') {
            display: flex;
            flex-direction: column;
            width: calc(50% - #{$medium-spacer * 2});
        }
    }

    &__button-container {
        align-items: center;
        background-color: $color-black;
        border-radius: 20px;
        display: flex;
        justify-content: center;
        min-height: 40px;
        margin: auto 0;
        transition: background-color ease-in-out 0.3s;
    }

    &__button {
        @include font-semibold();
        color: $color-white;
        font-size: 18px;
        line-height: 18px;
        // gimmicky way to limit the css rule to IE10+
        @media all and (-ms-high-contrast:none) {
            margin-top: 10px;
        }
    }
}
