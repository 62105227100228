.animated-infographic-container {
    color: var(--textcolor);
    background-color: var(--backgroundcolor);
    overflow: hidden;
    position: relative;
    width: 100%;

    &__overlay {
        background-color: rgba(0,0,0,.45);
        height: 100%;
        position: absolute;
        width: 100%;
        z-index: $z-index-0;

        @include for-print() {
            background: none;
            position: relative;
        }
    }

    @include for-print() {
        color: $color-black;
        background-color: $color-white;
        background-image: none !important;
    }

    p {
        color: var(--textcolor);

        @include for-print() {
            color: $color-dark-grey;
        }
    }

    &__image {
        background-position-x: center;
        background-position-y: top;
        background-repeat: no-repeat;
        background-size: cover;
        height: 100%;
        position: absolute;
        width: 100%;

        @include for-print() {
            display: none;
        }
    }

    &__content {
        padding-top: 44px;
        padding-bottom: 44px;
        max-width: $structure-normal;

        @include for-print() {
            padding-top: 0;
            padding-bottom: 0;
        }
    }

    &__heading {
        @include font-heading();
        @include font(36px, 37px);
        position: relative;
        z-index: $z-index-1;
    }

    &__abstract,
    &__abstract p {
        @include font-content();
        @include font(20px, 24px);
        margin-top: 16px;
        position: relative;
        z-index: $z-index-1;
    }

    &__container {
        display: flex;
        flex-wrap: wrap;
        margin: 25px auto;
        width: 100%;

        &--one {
            .animated-infographic-container__container-item {
                width: 100%;

                @include for-print() {
                    width: 50%;
                }
            }
        }

        &--two {
            .animated-infographic-container__container-item {
                width: 100%;

                @include respond-to('xs') {
                    width: 50%;
                }

                @include for-print() {
                    width: 50%;
                }
            }
        }

        &--three {
            .animated-infographic-container__container-item {
                width: 100%;

                @include respond-to('xs') {
                    width: 50%;
                }

                @include respond-to('largeipad') {
                    width: 33%;
                }

                @include for-print() {
                    width: 50%;
                }
            }
        }
    }
}
