@use "sass:selector";

@mixin for-print() {
    @media print {
        @content;
    }

    @at-root #{selector-nest("body.is-print-mode", &)} {
        @content;
    }
}