﻿.rightbox {
    .recent-resources {
        background: white;
        padding-left: 0%;
        padding-right: 0%;
        width: 100%;
        &__container {
            flex-direction: column;
            background: none !important;
            &--list{
                background: #F0F0F0;
            }
        }
        &__header {
            padding-top: 1em;
    
        }
        .recent-resources__list {
            margin-bottom: 4em;
            margin-left: 0;
            margin-right: 0;
            &_item{
                padding-left: 0;
                padding-right: 0;
                div{
                    margin-left: .625em;
                    margin-right: .625em;
                    padding-left: 1em;
                    padding-right: 1em;
                }
            }

        }
        .recent-resources__view-all {
            margin-bottom: 0;
        }
    }
}
