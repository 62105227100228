﻿@use 'sass:color';

.header-language-selector {
    @include responsive-component-margin-unstructured($global-page-margin);
    display: flex;
    justify-content: flex-end;
    max-width: $single-column-width;
    position: relative;
    width: 100%;

    &__languages {
        display: flex;
    }

    &__language {
        margin-left: 15px;
        margin-right: 0;
        position: relative;

        @include respond-to('small') {
            margin-right: 15px;
        }
    }

    &__link {
        @include font-semibold();
        color: color.adjust($color-white, $alpha: -0.25);
        transition: color $transition-timing $transition-function;

        &::after {
            background: $color-white;
            bottom: -4px;
            content: "";
            height: 2px;
            left: 0;
            margin: 0 auto;
            position: absolute;
            right: 0;
            width: 0;
            transition: width $transition-timing $transition-function;
        }

        @include hover-active {
            color: $color-white;

            &::after {
                width: 100%;
            }
        }

        &--active {
            color: $color-white;

            &::after {
                width: 100%;
            }
        }
    }
}