div > a.rte-whitepill,
p > a.rte-whitepill {
    @include font-semibold();
    @include font(16px, 44px);
    align-items: center;
    background-color: $color-white;
    border: 2px solid $color-light-grey;
    border-radius: 25px;
    color: $color-black;
    display: block;
    height: 44px;
    padding: 0 16px;
    text-align: center;
    text-decoration: none;
    transition: color $transition-timing $transition-function, background-color $transition-timing $transition-function, border $transition-timing $transition-function;

    @include respond-to('xxs') {
        max-width: 280px;
    }
}

div > a.rte-whitepill:hover,
div > a.rte-whitepill:focus,
p > a.rte-whitepill:hover,
p > a.rte-whitepill:focus {
    background-color: $color-black;
    border: 2px solid $color-black;
    color: $color-white;
    cursor: pointer;
    transition: color $transition-timing $transition-function, background-color $transition-timing $transition-function, border $transition-timing $transition-function;
}