.dropdown-vue {
    @include dropdown-styles();
    display: block;
    position: relative;

    .dropdown-vue--mobile__select {
        @include dropdown-styles();
    }
    .vs__actions {
        z-index: 4;
    }
    .vs__dropdown-toggle {
        border-radius: 0px;
    }

    .vs__dropdown-menu,
    .vs__dropdown-toggle {
        padding: 5px 6px 5px 6px;
    }

    .vs__dropdown-menu,
    .vs__dropdown-toggle,
    .vs__search::placeholder {
        @include font-medium();
    }

    .vs__dropdown-menu {
        border: 2px solid #000;
        margin-left: 0 !important;
        margin-right: 0 !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
        z-index: 997;

        .vs__deselect {
            margin-left: 0;
        }
    }

    .vs__dropdown-option {
        @include font-medium();
        font-size: 16px;
        line-height: 1.25em;
        padding: 0.4em 10px;
        position: relative;
        white-space: normal;
        width: 100%;

        &:hover,
        &:focus,
        &:active {
            background-color: $color-purple;
        }
    }

    &:not(.vs--searching):not(.vs--tabs) {
        .vs__dropdown-option {
            &:first-of-type {
                padding-bottom: 10px;
                padding-top: 10px;

                .vs__dropdown-option__text {
                    &::after {
                        border-bottom: 1px solid #ccc;
                        content: "";
                        display: block;
                        padding-top: 20px;
                        position: absolute;
                        top: 25px;
                        width: calc(100% - 20px);
                    }
                }
            }

            &:nth-of-type(2) {
                margin-top: 25px;
            }
        }
    }

    .vs__dropdown-option--highlight {
        background-color: $color-purple;
    }

    &--mobile,
    .vs__dropdown-toggle {
        .dropdown__arrow {
            background-image: url("../images/selectArrow.png");
            background-repeat: no-repeat;
            background-position: 100%;
            cursor: pointer;
            height: 1.25em;
            width: 2em;
        }

        .close__indicator {
            background-image: url("../images/close-symbol.svg");
            background-repeat: no-repeat;
            background-position: 100%;
            height: 1em;
            cursor: pointer;
            width: 1em;

            @media screen and (max-width: 600px) {
                display: none;
            }
        }
    }

    &--mobile {
        display: block;
        cursor: pointer;
        height: 42px;
        position: relative;

        .vs__clear {
            height: 100%;
            position: absolute;
            right: 30px;
            margin-top: 2px;
        }

        &__select {
            opacity: 0;
            position: absolute;
            width: 100%;

            option {
                &:hover,
                &:focus,
                &:active {
                    background-color: $color-purple;
                }
            }
        }

        &--close-arow.close__indicator {
            display: block;
            padding-right: 0.25em;
        }

        &--label {
            align-items: center;
            border: 2px solid #000000;
            color: #666;
            display: flex;
            font-size: 1em;
            line-height: 30px;
            height: 42px;
            justify-content: space-between;
            position: absolute;
            width: 100%;

            span {
                overflow: hidden;
                padding-left: 0.5em;
                white-space: nowrap;
                width: 90%;
            }
        }
    }
}
