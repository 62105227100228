﻿.limited-text-area {
    display: flex;
    flex-direction: column;
    margin: 16px 0 8px 0;

    &__label {
        @include font-semibold();
        @include font(16px, 20px);
    }

    &__textarea {
        @include font-content();
        @include font(16px, 20px);
        border: 2px solid $color-black;
        cursor: auto;
        margin: 5px 0 8px;
        padding: 5px 8px;
        resize: vertical;

        &--invalid {
            border-color: $color-red;
        }

        &:focus-visible {
            @include focus-outline();
        }
    }

    &__max-label {
        @include font-medium();
        @include font(14px, 16px);
        color: $color-dark-grey;
        margin-left: auto;
    }

    &__text-error {
        color: $color-red;
    }
}
