.accordion {
    background-color: $color-white;
    padding-bottom: 39px;

    &--plus-minus {
        padding-bottom: 8px;

        .accordion__title {
            @include accordion-title();
            background-color: $color-white-smoke;

            &:hover,
            &:focus {
                background-color: $color-purple;

                @include for-print() {
                    background-color: transparent;
                }

                .accordion__toggle, h2 {
                    color: $color-white;

                    @include for-print() {
                        color: $color-black;
                    }
                }
            }

            &:focus-visible {
                @include focus-outline();
            }

            .accordion__toggle {
                @include accordion-toggle();
            }

            h2 {
                @include accordion-heading();
                margin-left: 0;
                text-transform: none;
            }

            @include for-print() {
                background: none;
            }
        }

        .accordion__panel {
            @include accordion-panel();
            background-color: $color-white-smoke;
            padding: 0 16px 16px 48px;

            @include for-print() {
                background: none;
            }

            .rte-link {
                @include for-print() {
                    display: none;
                }
            }
        }
    }

    &__title {
        background-color: $color-white;
        border: 0;
        display: flex;
        flex-direction: row;
        padding: 0;

        &:focus-visible {
            @include focus-outline();
        }

        &:hover {
            h2, .accordion__toggle, .icon-accordion.active {
                color: $color-purple;
            }
        }

        h2 {
            color: $color-black;
            cursor: pointer;
            display: inline;
            font-size: 28px;
            font-style: normal;
            font-weight: 400;
            line-height: 31px;
            margin-left: 10px;
            text-align: left;
            text-transform: uppercase;
            vertical-align: middle;

            @include respond-to-max('small') {
                font-size: 24px;
            }

            @include for-print() {
                @include font(18px, 18px);
                margin: 0;
            }
        }

        .accordion__toggle {
            color: $color-black;
            cursor: pointer;
            display: inline-block;
            font-size: 30px;
            vertical-align: middle;

            @include for-print() {
                display: none !important;
            }

            &.icon-accordion.active {
                height: 30px;
                transform: rotate(90deg);
                color: $color-black;

                ~ h2 {
                    color: $color-black;
                }
            }
        }
    }

    &__panel {
        height: 0;
        overflow: hidden;
        padding-left: 40px;
        transition: height 0.3s ease-out;

        @include for-print() {
            @include font(14px, 16px);
            color: $color-black;
            height: auto;
            padding: 0px;
        }

        .rte-link {
            @include for-print() {
                display: none;
            }
        }
    }

    .showmore__shown {
        margin-top: 16px;
    }
}

.experience-editor{
    .accordion__panel{
        height: auto;
    }
}
