@mixin chevron-bullet() {
    .chevron-bullet {
        display: flex;
        flex-direction: row;

        @include for-print() {
            display: list-item;
            list-style-type: disc;

            &::marker {
                color: $color-purple-bullet;
                font-size: calc(105%);
            }
        }

        &--address-block {
            padding-bottom: 16px;

            .chevron-bullet__icon {
                font-size: 12px;
                margin-top: 4px;
            }
        }
    }

    .chevron-bullet--rte-link {
        display: block;

        @include for-print() {
            display: none;
        }

        .chevron-bullet__link {
            @include font(18px, 28px);
        }
    }

    .chevron-bullet__content {
        flex-direction: column;

        p {
            padding-left: 10px;

            @include for-print() {
                padding-left: 0;
            }
        }
    }

    .chevron-bullet--alt-link {
        &:active .chevron-bullet__icon,
        &:focus .chevron-bullet__icon,
        &:hover .chevron-bullet__icon {
            color: $color-purple;
            transition: all $transition-timing $transition-function !important;
        }

        &:active .chevron-bullet__link,
        &:focus .chevron-bullet__link,
        &:hover .chevron-bullet__link {
            color: $color-purple;
            transition: all $transition-timing $transition-function !important;
        }
    }

    .chevron-bullet__icon {
        @include font(14px);
        align-self: flex-start;
        color: $color-ml-light-grey;
        margin-top: 3px;
        transition: all $transition-timing $transition-function;

        &:hover {
            color: $color-purple;
        }

        @include for-print() {
            display: inline-block;
        }
    }

    .chevron-bullet__icon--grey {
        &:hover {
            color: $color-ml-light-grey !important;
        }

        @include for-print() {
            display: none;
        }
    }

    .chevron-bullet__link {
        @include font-semibold();
        background: none;
        color: $color-black;
        display: inline-block;
        font-size: 16px;
        max-width: calc(100% - 22px);
        padding-left: 10px;
        text-decoration: none;
        transition: all $transition-timing $transition-function;

        &:hover {
            color: $color-purple;

            @include for-print() {
                color: $color-black;
            }
        }

        @include for-print() {
            padding-left: 0;
        }
    }

    .chevron-bullet__link--underlined {
        @include font-medium();
        color: $color-dark-grey;
        display: inline-block;
        font-size: 16px;
        text-decoration: underline;

        &:hover {
            color: $color-purple;
            text-decoration: none;
        }

        @include for-print() {
            @include font(14px, 16px);
            display: inline;
            padding-left: 0;
        }
    }

    .chevron-bullet__icon:has(+ .chevron-bullet__link:hover),
    .chevron-bullet__icon:hover + .chevron-bullet__link {
        color: $color-purple;
    }
}
