.feature-card-collection {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 20px;

    &__cards {
        display: grid;
        flex-wrap: wrap;
        gap: 20px;
        justify-content: center;
        width: 100%;

        @include respond-to('xxs') {
            grid-template-columns: repeat(2, 1fr);
            justify-content: left;
        }

        @include respond-to('medium') {
            grid-template-columns: repeat(3, 1fr);
        }

        @include for-print() {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    &__trigger {
        @include showmore-trigger--small();

        @include for-print() {
            display: none;
        }
        
        margin-top: 0;
    }

    &__trigger-label {
        @include showmore-trigger-label--small();
    }
}
