﻿.input-field {
    @include font-content();
    @include font(16px, 18px);
    border: 2px solid $color-black;
    margin: 5px 0;
    padding: 10px;
    // prevent iOS from rounding corners
    -webkit-appearance: none;
    -webkit-border-radius: 0;

    &--invalid,
    &:invalid {
        border-color: $color-red;
    }

    @include respond-to('xs') {
        width: 50%;

        &:first-of-type {
            margin-right: 10px;
        }
    }
}
