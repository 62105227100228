@mixin keyframes($animation-name) {
    @-webkit-keyframes #{$animation-name} {
        @content;
    }

    @-moz-keyframes #{$animation-name} {
        @content;
    }

    @-ms-keyframes #{$animation-name} {
        @content;
    }

    @-o-keyframes #{$animation-name} {
        @content;
    }

    @keyframes #{$animation-name} {
        @content;
    }
}

@mixin animation($str) {
    -webkit-animation: #{$str};
    -moz-animation: #{$str};
    -ms-animation: #{$str};
    -o-animation: #{$str};
    animation: #{$str};
}

@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        transform: translate3d(0%, -50%, 0);
    }

    40% {
        transform: translate3d(0%, -35px, 0);
    }

    60% {
        transform: translate3d(0%, -25px, 0);
    }
}

@keyframes progress {
    from {
        width: 0%;
    }

    to {
        width: 100%;
    }
}

@mixin slide-up($duration, $childClass) {
    #{$childClass} {
        bottom: 0;
        left: 0;
        position: absolute;
        top: auto;
        transform: translateY(100%);
        transition: transform $duration;
    }

    &:hover {
        #{$childClass} {
            transform: translateY(0px);

            @include for-print() {
                transform: unset;
            }
        }
    }
}
