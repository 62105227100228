﻿.alternating-list-item {
    display: block;
    padding: 14px 16px 16px 16px;

    &:active &__abstract p,
    &:focus &__abstract p,
    &:hover &__abstract p {
        color: $color-black;
    }

    &:active &__image,
    &:focus &__image,
    &:hover &__image {
        transform: scale(1.2);
    }

    &:active h3,
    &:focus h3,
    &:hover h3 {
        color: $color-purple;
    }

    h3 {
        @include font(18px, 22px);
        @include font-semibold();
        color: $color-black;
        margin-top: 16px;

        @include for-print() {
            @include font(14px, 15px);
        }
    }

    &__image {
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        min-height: 242px;
        transform: scale(1.0);
        transition: transform $transition-timing $transition-function;
        width: 100%;

        &-container {
            float: right;
            margin: 0 0 20px 20px;
            overflow: hidden;
            width: 100%;

            @include respond-to("xxs") {
                width: 46%;
            }

            @include respond-to("small") {
                width: 35%;
            }
        }
    }

    &__abstract {
        margin-top: 16px;

        @include for-print() {
            @include font(14px, 16px);

            p {
                @include font(14px, 16px);
            }
        }
    }

    &__type-label {
        margin-bottom: 4px;

        & + h3 {
            margin-top: 0;
        }

        p {
            @include font-medium();
            @include font(14px, 15px);
            color: $color-purple;
            margin: 0;
            text-transform: uppercase;
        }
    }

    &__link {
        padding-top: 10px;

        @include for-print() {
            display: none !important;
        }
    }
}
