﻿.vertical-playlist {

    &__button {
        @include font-heading();
        background: $color-white;
        border: 2px solid $color-black;
        color: $color-black;
        cursor: pointer;
        display: block;
        font-size: 16px;
        margin: auto;
        max-width: 350px;
        padding: 15px;
        text-align: center;
        text-transform: uppercase;
        width: 100%;

        &-container {
            margin: 10px 0 15px 0;
            position: relative;
            text-align: center;
            width: 100%;

            @include for-print() {
                display: none;
            }
        }

        &:focus,
        &:hover {
            background: $color-purple;
            color: $color-white;
        }
    }

    &__description {
        margin: 12px 0;

        @include for-print() {
            @include font(14px, 16px);        
            p {
                    @include font(14px, 16px);
            }
        }
    }

    &__panel {
        overflow-y: hidden;
        transition: height 0.3s ease-in-out;
    }

    &__title {
        font-size: 18px;
        margin-bottom: 12px;
        text-transform: uppercase;

        &.accordion-enabled {
            cursor: pointer;
        }

        h3 {
            display: inline-block;

            @include for-print() {
                @include font(18px, 19px);
            }
        }
    }
}
