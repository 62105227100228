@mixin dropdown-styles {
    border: 2px solid #000;
    color: #666;
    font-size: 1em;
    line-height: 30px;
    height: 42px;

    option {
        padding: 0 10px;
    }

    .vs__actions {
        background: white;
        padding-left: .75em;
        position: absolute;
        right: 0;
        top: .4em;
        z-index: 100;
    }

    .vs__clear {
        padding-right: .25em;
    }

    .vs__dropdown-toggle {
        border: none;
    }

    .vs__search, .vs__selected, option {
        @include font-medium();
    }

    .vs__search, .vs__search:focus {
        margin: 2px 0 0;
    }

    .vs__selected {
        margin: 2px 2px 0;
        max-width: 100%;
        overflow: hidden;
        white-space: nowrap;

        &-options {
            max-width: 100%;
        }
    }

    .vs__dropdown-menu {
        left: -2px;
        padding-left: 0;
        width: calc(100% + 3.95px);
    }

    .vs__deselect {
        margin-left: 0;
    }
}
