.c-results {
  &__block {
    line-height: 1.15em;
    padding-bottom: 28px;
    .c-results__title a {
      color: $color-black;
    }
  }
  &__contacts {
    @include font-medium();
    color: $color-dark-grey;
    // font-family: $font-content-medium;
    font-size: 14px;
    a {
      color: $color-black;
      text-decoration: underline;
      &:hover {
        color: $color-purple;
        text-decoration: none;
      }
    }
  }
  &__contacts-separator {
    margin-left: -2.5px;
  }

  &__date {
    @include font-medium();
    font-size: 14px;
    color: $color-black;
  }
  &__details {
    @include font-content();
    color: $color-dark-grey;
    font-size: 14px;
    margin-top: 6px;
    * {
      @include font-content();
      color: $color-dark-grey;
      font-size: 14px;
      padding-top: 6px;
    }
    a {
      color: rgb(0, 0, 0);
      text-decoration: underline;
      &:hover {
        color: rgb(100, 51, 128);
        text-decoration: none;
      }
    }
    ul {
      margin-bottom: 15px;
      li {
        background: url(../images/arrow-light-grey1.png) no-repeat 0 5px;
        color: $color-dark-grey;
        display: block;
        font-size: 14px;
        list-style-type: none;
        margin: 10px 0 0 15px;
        padding-left: 18px;
        padding-top: 0px;
        text-decoration: none;
      }
    }
  }
  &__location {
    @include font-content();
    font-size: 14px;
  }
  &__loading-icon {
    display: block;
    text-align: center;
  }
  &__no-result {
    background: $color-white-smoke;
    padding: 10px 10px;
    margin: 0 auto;
    width: 83%;
    p {
      @include font-medium();
      font-size: 16px;      
      margin: 0;
    }
  }
  &__switch-label {
    @include font-semibold();
    color: $color-black;
    order: 2;
    position: relative;
    right: 2px;
    top: 6px;
  }
  &__title {
    @include font-heading();
    color: $color-black;
    font-size: 18px;
    line-height: 1;
    padding-bottom: 2px;
    text-transform: uppercase;
    a:hover {
      color: $color-purple;
    }
  }
  &__total {
    @include font-medium();
    color: $color-purple;
    font-size: 20px;
    line-height: 24px;
    margin-right: 12px;
  }
  &__type {
    @include font-medium();
    color: $color-black;
    font-size: 14px;
  }
}
