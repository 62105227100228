.skip-to-content-links {    
    padding: 2px;
    position: absolute;
    text-align: center;
    top: 0;
    transform: translateY(-100%);
    transition: transform 0.3s;
    width: calc(100% - 4px);
    z-index: 7001;
  
    ul {
      background-color: $color-white;    
      display: inline-block;
      list-style-type: none;
      margin: 0;        
      overflow: hidden;        
  
      li {
          float:  left;
          padding:  5px 20px;
      }
    }

    &:focus-within {
        transform: translateY(0);
      }
}
.career-body .skip-to-content-links:focus-within {
    top: 100px;
}