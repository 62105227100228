.hover-button {
    margin: 0 auto;
    width: min(100%, 300px);
    z-index:$z-index-1;

    @include for-print() {
        display: none;
    }

    &:focus-visible {
        @include focus-outline();
    }
           
    &__link
     {
        @include font(16px, 20px);
        @include font-semibold();
        align-items: center;
        display: flex;
        background-color: $color-white;
        border: 2px solid $color-light-grey;
        border-radius: 24px;
        color: $color-black;
        cursor: pointer;
        justify-content: center;        
        min-height: $button-min-height;
        overflow: hidden;
        text-align: center;
        text-transform: capitalize;
        transition: border 0.3s ease-in-out, background-color 0.3s ease-in-out;        

        &:hover,
        &:focus {
            background-color: $color-black;
            border: 2px solid $color-black;
            color: $color-white;
        }

        &-top50 {
            margin-top: 50px;
        }

        &-top40 {
            margin-top: 40px;
        }
    }
}
