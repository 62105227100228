.accolade {
    border-top: 1px solid $color-very-light-grey;
    display: block;
    padding: 24px 0;

    @include for-print() {
        padding: 16px 0;
    }

    &--hoverable {
        &:hover {
            .accolade__text {
                color: $color-purple;
            }
        }
    }

    &__text {
        @include font(22px, 25px);
        @include font-medium();
        color: $color-black;
        transition: all 0.3s ease-in-out;

        @include for-print() {
            @include font(20px, 24px);
        }
    }

    &__accreditation-line-one {
        @include font(16px, 21px);
        @include font-heading();
        color: $color-purple;
        margin-top: 12px;
        text-transform: uppercase;

        @include for-print() {
            @include font(16px, 28px);
            margin-top: 8px;
        }
    }

    &__accreditation-line-two {
        @include font(16px, 22px);
        @include font-medium();
        color: $color-black;

        @include for-print() {
            @include font(14px, 16px);
        }
    }
}
