.structure {
    &-one-column {
        @include structure-normal();

        @include for-print() {
            margin: 0 auto;
            max-width: $print-width;
            width: $print-width;
        }

        &--wide {
            @include structure-wide();

            @include for-print() {
                margin: 0 auto;
                max-width: $print-width;
                width: $print-width;
            }
        }

        &--narrow {
            @include structure-narrow();
        }
    }

    &-two-column {
        display: flex;
        flex-direction: column;

        @include structure-normal();

        &--narrow {
            @include structure-narrow();
        }

        &--wide {
            @include structure-wide();
        }

        @include for-print() {
            flex-direction: column;
            margin: 0 auto;
            max-width: $print-width;
            width: $print-width;

            article,
            aside {
                margin: 0;
                width: 100%;
            }
        }

        article,
        aside,
        .column--halfwidth {
            width: 100%;
        }

        @include respond-to('medium') {

            flex-direction: row;

            &--article-skewed-left,
            &--article-skewed-right {
                article {
                    width: 72%;
                }

                aside {
                    width: 22%;
                }
            }

            &--article-skewed-left {
                aside {
                    margin-left: 6%;
                }
            }

            &--article-skewed-right {
                article {
                    margin-left: 6%;
                }
            }
        }

        @include respond-to('small') {
            
            &--skewed-right,
            &--skewed-left {
                flex-direction: row;

                article {
                    width: 63%;
                }

                aside {
                    width: 31%;
                }
            }

            &--skewed-left {
                aside {
                    margin-right: 6%;
                }
            }

            &--skewed-right {
                article {
                    margin-right: 6%;
                }
            }
            
            .column--halfwidth {
                width: 48%;

                &:first-of-type {
                    margin-right: 4%;
                }
            }
        }
    }

    &-two-column--halves {
        justify-content: space-between;

        @include respond-to('small') {
            flex-direction: row;
        }

        @include for-print() {
            flex-direction: row;
        }

        .recent-resources {
            width: 100%;
        }

        .column--halfwidth {
            flex: 0 1 100%;

            @include for-print() {
                margin: 0;
                padding: 0;
            }

            @include respond-to("small") {
                flex: 0 1 calc(48% - 5px);
            }
        }
    }
}

.experience-editor {
    .structure {
        &-one-column,
        &-two-column {
            border: 1px solid green;
            padding: 40px 0;
            margin: 40px auto;
        }
    }
}
