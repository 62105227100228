.alphabet-links {
  display: flex;
  justify-content: space-between;

  &__letter-link {
    @include font-heading();
    @include font(20px, 30px);
    display: inline-block;
    padding-left: 20px;
    padding-right: 20px;

    &--disabled {
      color: $color-ml-light-grey;
    }

    @include respond-to("xs") {
      padding-left: initial;
      padding-right: initial;
    }
  }
}
