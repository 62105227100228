.oniblock {
  padding-bottom: 28px;
  position: relative;

  &-group {
      padding-bottom: 0;

      &:last-of-type {
        padding-bottom: 28px;
      }
  }

  span.small-head-next {
    @include font-content();
    color: $color-dark-grey;
    display: block;
    font-size: 14px;
  }
}
