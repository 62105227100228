.rich-text {
    @include responsive-component-margin();

    @include for-print() {
        @include responsive-component-margin(0);
    }

    &__content {
        @include chevron-bullet();
        @include richtext(18px, 26px);

        @include for-print() {
            @include font(14px, 16px);
            margin-bottom: 10px;

            div, span, li, p {
                @include font(14px, 16px);
            }
        }
    }

    &__intro {
        @include font-medium();
        @include font(20px, 28px);
        letter-spacing: 0.1px;

        @include for-print() {
            @include font(14px, 16px);
            font-weight: 400;
        }

        &--purple {
            color: $color-purple;

            @include for-print() {
                color: $color-dark-grey;
            }

            li, span, div, p {
                color: $color-purple;

                @include for-print() {
                    @include font(14px, 16px);
                    color: $color-dark-grey;
                    font-weight: 400;
                }
            }
        }

        p {
            @include font-medium();
            @include font(20px, 28px);
            letter-spacing: 0.1px;

            @include for-print() {
                @include font(14px, 16px);
            }

            @include respond-to('xxs') {
                @include font(22px, 30px);
            }
        }

        ul li::before {
            font-size: 15px;
        }
    }

    &__trigger {
        @include showmore-trigger--small();

        @include for-print() {
            display: none;
        }
    }

    &__trigger-label {
        @include showmore-trigger-label--small();
    }
}
