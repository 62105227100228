@mixin li-marker() {
    li {
        color: $color-dark-grey;
        position: relative;

        &::marker {
            color: $color-purple-contrast;
            font-size: calc(105%);
        }

        @include for-print() {
            margin-bottom: 0;
            padding-bottom: 3px;
        }
    }
}

@mixin richtext-ul-chevron() {
    @include li-marker();
    list-style-position: unset;
    list-style-type: none;
    margin-bottom: 0;
    padding-left: 0;

    @include for-print() {
        list-style-position: outside;
        list-style-type: disc;
        margin-top: 4px;
        padding-left: 17px;
    }

    ul {
        list-style-type: none;

        ul {
            list-style-type: none;
        }
    }

    li {
        padding-bottom: 7px;
        a:hover {
            color: $color-purple;
            text-decoration: none;
        }
    }

    li:hover {
        span.li-arrow {
            background: url(/contents/images/arrow-light-grey1.png) no-repeat scroll 0 4px;
        }
    }
}

@mixin richtext-ul($padding-left: 18px) {
    ul {
        list-style-position: outside;
        list-style-type: disc;
        margin-bottom: 16px;
        padding-left: $padding-left;

        @include for-print() {
            margin-bottom: 0;
        }

        ul {
            list-style-type: circle;
            padding-top: 7px;
            
            @include for-print() {
                margin-bottom: 0;
                padding-top: 0;
            }

            ul {
                list-style-type: square;

                @include for-print() {
                    margin-bottom: 0;
                    padding-bottom: 0;
                }
            }
        }

        @include li-marker();
    }
}

@mixin richtext($font-size: 16px, $line-height: 20px, $font: 'content', $padding-left: 40px) {
    @include flex-table;

    @if $font == 'light' {
        @include font-light();
    }
    @else if $font == 'medium' {
        @include font-medium();
    }
    @else {
        @include font-content();
    }

    font-size: $font-size;
    line-height: $line-height;

    ul li,
    ol li {
        margin-bottom: 7px;
    }

    ol {
        list-style-position: outside;
        padding-left: 40px;

        li {
            padding-left: 7px;
        }
    }

    @include richtext-ul($padding-left);

    p {
        @include font-content();
        font-size: $font-size;
        line-height: $line-height;
    }

    a,
    a:visited {
        color: $color-black;
        text-decoration: underline;
        transition: color 0.3s ease-in-out;

        &:focus,
        &:hover {
            color: $color-purple;
            text-decoration: none;
        }
    }
}
