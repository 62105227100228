/* General CSS */
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    vertical-align: baseline;
    background: transparent;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

body {
    @include font-content();
    font-size: 16px;
    color: #414042;
}

input[type="text"],
textarea,
input[type="submit"],
input[type="button"],
input[type="reset"] {
    -webkit-appearance: none;
    -webkit-border-radius: 0px;
}

p {
    @include font-content();
    color: #414042;
    font-size: 16px;
    margin-bottom: 16px;
}

ul {
    list-style-type: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: #010101;
    font-family: arial;
    font-weight: normal;
}

h5 {
    @include font-heading();
    color: $color-black;
    font-size: 18px;
    line-height: 1;
    text-transform: uppercase;
}

h6 {
    @include font-heading();
    color: $color-purple;
    font-size: 16px;
    line-height: 18px;
    text-transform: uppercase;
}

a {
    color: #000000;
    text-decoration: none;
}

a:hover {
    color: $color-purple;
}

a:focus {
    color: $color-purple;
}

input:focus,
textarea:focus {
    outline: none;
}

*::-moz-selection {
    background: none repeat scroll 0 0 $color-purple;
    color: #fff;
}

*::selection {
    background: none repeat scroll 0 0 $color-purple;
    color: #fff;
}

::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 10px;
}

::-webkit-scrollbar-thumb {
    border-radius: 0;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

a:focus-visible, h1:focus {
    @include focus-outline();
}

/* clears the ‘X’ from Internet Explorer */
input[type=search]::-ms-clear { display: none; width : 0; height: 0; }
input[type=search]::-ms-reveal { display: none; width : 0; height: 0; }
/* clears the ‘X’ from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; }