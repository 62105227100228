$ml-carousel-pagination-item__icon-size: 12px;
$ml-carousel-pagination-item__icon-border-size: 2px;

.ml-carousel {
  background: $color-white;
  border-bottom: 2px solid $color-black;
  border-top: 7px solid $color-black;
  box-sizing: border-box;
  margin-bottom: 60px;
  overflow: hidden;
  position: relative;
  padding-top: 20px;
  padding-bottom: 20px;

  div {
    box-sizing: border-box;
  }

  &-page-indicator {
    margin-top: 15px;
    
    ul {
      text-align: center;;
    }
  }
  ul > li {
    background: none;  
    display: inline-block;
    margin: 0 1.5px;
    list-style: none;
    padding-left: 0;

    &:focus-visible {
        @include focus-outline();
    }
    a {    
      cursor: pointer;
    }
  }
  &--link {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
  }
  .ml-carousel-panning {
    left: 0;
    height: 100%;
    width: 4146px;
    position: relative;
    transition: all ease-in-out 0.4s;
    -moz-transition: all ease-in-out 0.4s;
    -o-transition: all ease-in-out 0.4s;
    -webkit-transition: all ease-in-out 0.4s;

    .ml-carousel-item {
        float: left;
        width: 500px;
        height: 100%;
        min-height: 188px;
        position: relative;
        box-sizing: border-box;

        .ml-publication {
            height: 100%;
            overflow: hidden;
            position: relative;
            width: 100%;

            &__pull-quote {
              display: flex;
              max-height: 100%;
              overflow: hidden;
              position: relative;

              @media all and (-ms-high-contrast: none) {
                display: block;
              }
              &--content {
                @include font-content();
                display: flex;
                flex-direction: column;
                font-size: 1.5em;
                height: 100%;
                justify-content: center;
                margin-left: 73px;
                padding-bottom: 8px;
                margin-right: 63px;
                padding-left: 5px;
                padding-top: 8px;

                @media all and (-ms-high-contrast: none) {
                  display: block;
                }
                .inner-text {
                  display: flex;
                  flex-basis: 100%;
                  flex-direction: column;
                  height: 100%;
                }
                &.large {
                  font-size: 28px;
                  line-height: 32px;
                }
                &.medium {
                  font-size: 24px;
                  line-height: 28px;
                }
                &.small {
                  font-size: 22px;
                  line-height: 26px;
                }
                @media only screen and (max-width: 768px) {
                  &.large {
                    font-size: 26px;
                    line-height: 30px;
                  }
                  &.medium {
                    font-size: 22px;
                    line-height: 26px;
                  }
                  &.small {
                    font-size: 18px;
                    line-height: 22px;
                  }
                }
                @media only screen and (max-width: 640px) {
                  font-size: 1em;
                  margin-left: 50px;
                  margin-right: 50px;

                  &.large {
                    font-size: 24px;
                    line-height: 28px;
                  }
                  &.medium {
                    font-size: 20px;
                    line-height: 24px;
                  }
                  &.small {
                    font-size: 18px;
                    line-height: 22px;
                  }
                }
                &--footer {
                  @include font-heading();
                  color: $color-purple;
                  font-size: 16px;
                  letter-spacing: 0px;
                  padding-top: 10px;
                  padding-right: 30px;
                  text-align: right;
                  text-transform: uppercase;

                  @media only screen and (max-width: 640px) {
                    font-size: 14px;
                    padding-right: 8px;
                  }
                }
              }
              &--quote {
                @include font-heading-alt();
                color: $color-purple-light;
                font-size: 110px;
                height: 77px;
                letter-spacing: 0px;
                position: absolute;
                transform: skew(-10deg, 0deg);

                &.medium {
                  height: 75px;
                }
                &.small {
                  height: 73px;
                }
                @media only screen and (max-width: 640px) {
                  font-size: 90px;
                  height: 63px;
                  letter-spacing: 0px;

                  &.medium {
                    height: 61px;
                  }
                  &.small {
                    height: 59px;
                  }
                }
                &--reverse {
                  transform: scale(-1, -1) skew(-10deg, 0deg);
                }
                &--end {
                  bottom: 0px;
                  right: 0;
                }
              }
            }
            .ml-publication-header{
                left: auto;
                margin: 0;
                margin-bottom: 10px;
                padding: 0;
                position: relative;

                h2 {
                    @include font-heading();
                    margin: 0;
                    padding: 0;
                    height: 34px;
                    display: block;
                    font-size: 16px;
                    color: $color-purple;
                    position: relative;
                    text-transform: uppercase;

                    img, span {
                        position: relative;
                        display: inline-block;
                    }
                    img {
                        vertical-align: baseline;
                    }
                    span {
                        top: -9px;
                    }
                }
            }
            .ml-publication-link {
                margin: 0;
                padding: 0;
                left: auto;
                position: relative;
                margin-bottom: 10px;
                
                a {
                    @include font-heading();
                    font-size: 2rem;
                    line-height: 2rem;
                    text-transform: uppercase;
                    color: $color-black;
                    display: inline-block;
                    margin: 2px 0;
                    
                    &.referenced-item {
                        font-size: 28px;
                        line-height: 100%;
                    }
                    &:active, 
                    &:focus, 
                    &:hover {
                        color: $color-purple;
                    }
                }
            }  
            .ml-publication-media{
                .video-iframe {
                    display: none;
                }
                .ml-publication-media-instance.video-iframe {
                    display: block !important;
                }
            } 
            &[data-has-media="true"]{
                .ml-publication-header, .ml-publication-link {
                    left: 300px;
                    width: calc(100% - 300px);
                }
                .ml-publication-media {
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    display: block;
                    overflow: hidden;
                    max-width: 280px;
                    min-height: 100px;
                    max-height: 170px;
                    position: absolute;

                    &.media-center {
                        top: 0;
                        left: calc(50% - 140px);
                        width: 100%;
                        height: 100%;
                        display: block;
                        overflow: hidden;
                        max-width: 280px;
                        position: absolute;
                    }
                    .ml-publication-media-instance{
                        width: 100%;
                        height: 100%;
                        position: relative;
                          
                        .audio-with-thumbnail,
                        .video-with-thumbnail,
                        .photo-with-thumbnail {
                            width: 100%;
                            height: 100%;
                            display: block;
                            position: relative;

                            .ml-overlay
                            .ml-overlay-wrapper
                            .playIcon {
                                top: 50%;
                                width: auto;
                                margin-top: -45px;
                                position: relative;
                            }
                            .img-thumbnail {
                                width: 100%;
                                height: 100%;
                                display: block;
                                max-width: 100%;
                                max-height: 100%;
                                position: relative;
                            }
                        }
                        .audio-with-thumbnail,
                        .video-with-thumbnail,
                        .photo-with-thumbnail,
                        .leftphoto-with-thumbnail,
                        .rightphoto-with-thumbnail{
                            .ml-overlay {
                                top: 0;
                                left: 0;
                                width: 100%;
                                height: 100%;
                                display: block;
                                position: absolute;
                                cursor: pointer;

                                .ml-overlay-wrapper {
                                    width: 100%;
                                    height: 100%;
                                    position: relative;
                                    text-align: center;
                                }
                            }
                            .img-thumbnail{
                                min-height: 180px;
                            }
                        }
                        .audio-iframe,
                        .video-iframe {
                            width: 100%;
                            height: 100%;
                            min-height: 100px;
                            display: block;
                            position: relative;
                        }
                    }
                    &.media-center[data-media-type="image"] {
                        left: 0;
                        max-width: 100%;
                        min-height: 100px;
                        position: absolute;
                    }
                    &[data-media-type="leftimage"],
                    &[data-media-type="leftImagePDF"] {
                      left: 0;
                      min-height: 100px;
                      max-width: none;
                      position: absolute;
                      overflow: hidden;
                      width: calc(50% - 10px);
                      padding-right: 10px;
                    }
                    &[data-media-type="rightimage"],
                    &[data-media-type="rightImagePDF"] {
                      left: 50%;
                      width: calc(50% - 10px);
                      padding-left: 10px;
                      min-height: 100px;
                      position: absolute;
                      max-width: none;
                    }
                }
                .ml-publication-header[data-media-type="leftimage"],
                .ml-publication-header[data-media-type="leftImagePDF"] {
                    left: 50%;
                    width: 50%;
                }
                .ml-publication-header[data-media-type="rightimage"],
                .ml-publication-header[data-media-type="rightImagePDF"] {
                    left: auto;
                    width: 50%;
                    padding-right: 10px;
                }
                .ml-publication-link[data-media-type="leftimage"],
                .ml-publication-link[data-media-type="leftImagePDF"] {
                    left: calc(50% - 10px);
                    padding-left: 10px;
                    width: 50%;
                }
                .ml-publication-link[data-media-type="rightimage"],
                .ml-publication-link[data-media-type="rightImagePDF"] {
                    left: auto;
                    width: calc(50% - 10px);
                    padding-right: 10px;
                }
            }
            &[data-has-media="false"]{
                .ml-publication-media {
                    display: none;
                  }
            }
        }
        .stacked-media-module-main-holder {
            width: 100%;
            height: 100%;
            overflow: hidden;
            position: relative;

            .ml-media-type {
                @include font-heading();
                margin: 0;
                padding: 0;
                left: 320px;
                height: 34px;
                display: block;
                font-size: 16px;
                color: $color-purple;
                position: relative;
                width: calc(100% - 200px);
                text-transform: uppercase;

                img {
                    vertical-align: baseline;
                }
                span {
                    top: -9px;
                }
                img, span {
                    position: relative;
                    display: inline-block;
                }
            }  
            .ml-media-title {
                @include font-heading();
                margin: 0;
                padding: 0;
                left: 320px;
                margin-top: 5px;
                font-size: 28px;
                position: relative;
                width: calc(100% - 320px);
            }
            .stacked-media.stacked-media-module-main-video-holder{
                .video-with-thumbnail,
                .audio-with-thumbnail,
                .photo-with-thumbnail {
                    width: 100%;
                    height: 100%;
                    display: block;
                    position: relative;

                    .img-thumbnail {
                        width: 100%;
                        height: 100%;
                        display: block;
                        max-width: 100%;
                        max-height: 100%;
                        position: relative;
                    }
                    .stacked-media-module-overlay {
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        display: block;
                        position: absolute;
                        
                        .stacked-media-module-overlay-wrapper {
                            width: 100%;
                            height: 100%;
                            position: relative;
                            text-align: center;

                            .playIcon {
                                top: 50%;
                                max-width: 100%;
                                margin-top: -45px;
                                position: relative;
                            }
                        }
                    }
                }
            }
            .stacked-media.stacked-media-module-main-video-holder,
            .stacked-media.stacked-media-module-main-audio-holder {
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                overflow: hidden;
                max-width: 300px;
                max-height: 170px;
                position: absolute;
            }
        }
        .stacked-media-module-main-holder .ml-media-description,
        .ml-publication .ml-media-description {
            @include font-content();
            margin: 0;
            padding: 0;
            left: 200px;
            font-size: 12px;
            position: relative;
            width: calc(100% - 200px);
            display: none;
        }
    }
  }

  .pagination-item {
        background-color: $color-white;
        border: $color-black $ml-carousel-pagination-item__icon-border-size solid;
        border-radius: $ml-carousel-pagination-item__icon-size;
        color: rgba(255, 255, 255, 0.75);
        cursor: pointer;
        display:  inline-block;
        height: $ml-carousel-pagination-item__icon-size;
        margin-left: 4px;
        margin-right: 4px;
        transition: 0.4s;
        width: $ml-carousel-pagination-item__icon-size;

        &--active {
            color: rgba(255, 255, 255, 1);
            background-color: $color-black;
        }

        @include hover-active {
            color: rgba(255, 255, 255, 1);
        }

        &:focus-visible {
            @include focus-outline();
        }
      }  
}

@media only screen and (max-width: 640px) {
  .ml-carousel{
      height: auto;
      padding-bottom: 15px;

      .ml-carousel-panning {
          height: auto;

          .ml-carousel-item {
              max-height: 250px;

              .ml-publication[data-has-media="true"] {
                  .ml-publication-media {
                      .ml-publication-media-instance {
                          height: 180px; /*force MediaElement to restore back to its original height in portrait mode*/
  
                          .audio-iframe,
                          .video-iframe {
                              display: block;
                          }
                      }
                  }

                  .ml-publication-header,
                  .ml-publication-header[data-media-type="rightimage"],
                  .ml-publication-header[data-media-type="leftimage"],
                  .ml-publication-header[data-media-type="rightImagePDF"],
                  .ml-publication-header[data-media-type="leftImagePDF"] {
                      margin: 0;
                      left: auto;
                      width: 100%;
                  }
                  .ml-publication-link,
                  .ml-publication-link[data-media-type="rightimage"],
                  .ml-publication-link[data-media-type="leftimage"],
                  .ml-publication-link[data-media-type="rightImagePDF"],
                  .ml-publication-link[data-media-type="leftImagePDF"] {
                      left: 0;
                      width: 100%;
                      font-size: 24px;
                      margin-top: 5px;
                      position: relative;
                      padding-left: 0px;
                      padding-right: 0px;
                  }
                  .ml-publication-media,
                  .ml-publication-media[data-media-type="rightimage"],
                  .ml-publication-media[data-media-type="leftimage"],
                  .ml-publication-media[data-media-type="rightImagePDF"],
                  .ml-publication-media[data-media-type="leftImagePDF"] {
                      max-width: 100%;
                      margin-top: 5px;
                      height: auto;
                      max-height: 100%;
                      position: relative;
                      width: 100%;
                      left: auto;
                      padding-left: 0px;
                      padding-right: 0px;
                  }
              }
              .stacked-media-module-main-holder{
                  .ml-media-type {
                      left: auto;
                      width: 100%;
                      margin: 0;
                  }
                  .ml-media-title {
                      left: 0;
                      width: 100%;
                      font-size: 24px;
                      margin-top: 5px;
                      position: relative;
                  }
                  .stacked-media.stacked-media-module-main-video-holder,
                  .stacked-media.stacked-media-module-main-audio-holder {
                      max-width: 100%;
                      max-height: 100%;
                      margin-top: 5px;
                      position: relative;
                  }
              }
          }
      }
  }
}

@media only screen and (orientation: landscape) {
.ml-carousel {
  height: auto;
  padding-bottom: 25px;
}
}
@media all and (orientation: landscape) {
.ml-carousel .ml-carousel-panning .ml-carousel-item {
  max-height: 235px;
}
}
@media all and (orientation: portrait) {
.ml-carousel {
  height: auto;
}
}