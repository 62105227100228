@use 'sass:math';

$person-listing-gap: 2%;

.person-listing {
    page-break-inside: avoid;

    @include for-print() {
        & + .person-listing {
            margin-top: 8px;
        }
    }

    &__intro {
        margin-bottom: 24px;
    }

    &__heading {
        @include font-heading();
        @include font(20px, 18px);

        @include respond-to('small') {
            @include font(24px, 25px);
        }

        margin-bottom: 8px;

        @include for-print() {
            @include font(14px, 15px);
        }
    }

    &__description {
        @include richtext();
        margin-bottom: 0px;

        @include for-print() {
            @include font(14px, 16px);

            p {
                @include font(14px, 16px);
            }
        }
    }

    &__listing {
        display: flex;
        gap: 2%;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
    }

    &__person {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        margin-bottom: 16px;
        page-break-inside: avoid;
        width: calc(#{100% / 2} - #{$person-listing-gap - math.div($person-listing-gap, 2)});

        @include for-print() {
            max-width: 100px;
            width: 100px;
        }

        @include respond-to('small') {
            .structure-one-column &,
            .structure-one-column--wide & {
                width: calc(#{100% / 6} - #{$person-listing-gap - math.div($person-listing-gap, 6)});
            }
        }

        @include respond-to('xxs') {
            width: calc(#{100% / 4} - #{$person-listing-gap - math.div($person-listing-gap, 4)});
        }

        @supports not (gap: 2%) {
            &:not(:last-child) {
                margin-right: 2%;
            }
        }

        &-image {
            height: auto;
            width: 100%;
        }

        &-name {
            @include font-semibold();
            padding-top: 3px;
            text-align: center;

            @include for-print() {
                @include font(14px, 16px);
            }
        }

        &-title {
            @include font(16px, 22px);
            @include font-medium();
            color: $color-black;
            display: block;
            padding-top: 3px;
            text-align: center;

            @include for-print() {
                @include font(14px, 16px);
            }
        }

        &-offices {
            @include font(14px, 16px);
            color: $color-black;
            display: block;
            padding-top: 3px;
            text-align: center;

            @include for-print() {
                @include font(14px, 16px);
            }
        }

        &-office-link {
            white-space: nowrap;

            &:not(:last-child) {
                &:after {
                    content: ' / '
                }
            }
        }

        &-practices {
            @include font(14px, 16px);
            color: $color-black;
            display: block;
            padding-top: 3px;
            text-align: center;

            @include for-print() {
                @include font(14px, 16px);
            }
        }
    }
}
