.sc-form {
    background-color: $color-white-smoke;
    margin: 40px auto auto auto;
    max-width: 300px;
    padding: 20px;

    &--two-column {
        background-color: $color-white;
        max-width: 100%;
        padding: 0;
    }

    &__abstract {
        font-weight: 600;
        margin-top: 10px;
    }

    &__abstract,
    &__footnote {
        a {
            color: #000;
            cursor: pointer;
            font-weight: 400;
            text-decoration: underline;

            &:hover {
                color: $color-purple;
                text-decoration: none;
            }
        }
    }

    &__abstract,
    &__footnote,
    &__title {
        text-align: center;

        &--two-column {
            text-align: justify;

            h1 {
                @include font-heading();
                color: $color-black;
                font-size: 48px;
                text-transform: uppercase;
            }

            h2 {
                @include font-medium();
                color: $color-purple;
                font-size: 20px;
                margin: 20px auto;
            }
        }
    }

    &__container {
        @include responsive-component-margin();
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        max-width: 100%;
    }

    &__section {
        display: flex;
        flex-direction: column;
    }

    &__row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;

        @media (max-width: 455px) {
            flex-direction: column;
            flex-wrap: wrap;

            .sc-form__input-wrapper,
            .sc-form__input-wrapper--last {
                margin-right: 0px;
                max-width: 100%;
                width: 100%;
            }

            &--button {
                width: 100%;

                .ml-sitecore-forms-submit {
                    width: 100%;

                    .sc-form.btn-default {
                        width: 100%;
                        max-width: 100%;
                    }
                }
            }
        }

        &--button {
            display: flex;
            justify-content: right;
        }

        &--message p {
            color: $color-dark-grey;
            font-weight: 600;
            margin: 0;
        }
    }

    &__input-wrapper {
        display: flex;
        flex-direction: column;
        margin-right: 20px;
        max-width: 50%;
        width: 50%;

        &--last {
            margin-right: 0;
        }

        &--fullwidth {
            margin-right: 0;
            max-width: 100%;
            width: 100%;
        }

        label {
            color: $color-dark-grey;
            font-weight: 600;
            margin-top: 10px;
        }
    }

    &__input {
        @include font-medium();
        border: 2px solid #000;
        border-radius: 0;
        box-sizing: border-box;
        font-size: 16px;
        height: 41.5px;
        line-height: 1.35em;
        margin: 5px 0;
        padding: 10px;

        &--two-column {
            display: flex;
            background: white;
            height: auto;
            max-width: 100%;
        }
    }

    &__dropdown {
        @include font-medium();
        display: flex;
        background: white;
        border: 2px solid #000;
        border-radius: 0;
        box-sizing: border-box;
        font-size: 16px;
        flex-grow: 1;
        height: 45.6px;
        max-height: 45.6px;
        margin: 5px 0;
        max-width: 100%;
        padding: 10px;
        padding-left: 6px;
        width: 100%;

        &:focus-visible {
            @include focus-outline();
        }

        option:active,
        option:focus,
        option:hover,
        option:focus-visible {
            color: $color-white;
            background-color: $color-purple;
            cursor: default;
        }
    }

    .btn-default {
        @include font-heading();
        background-color: #000;
        border: 0;
        box-sizing: border-box;
        color: $color-white;
        cursor: pointer;
        font-size: 16px;
        line-height: 1.35em;
        margin: 5px 0 20px 0;
        padding: 8px 24px;
        text-transform: uppercase;

        &--two-column {
            @include font-content();
            justify-content: right;
            text-transform: none;
            border-radius: 50px;
        }

        &:focus-visible {
            @include focus-outline();
        }

        &:hover {
            background-color: $color-purple;
        }
    }

    &__placeholder {
        @include font-medium();
        color: $color-light-grey;

        &:focus-visible {
            @include focus-outline();
        }
    }

    .field-validation-error {
        color: red;
    }

    .input-validation-error {
        border: red solid;
    }
}

.limited-tex-area__textarea {
    font-weight: 500;
}
