.fade {
  &-enter-active,
  &-leave-active {
    transition: opacity 0.5s;
  }
  &-enter,
  &-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
}
