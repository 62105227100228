div > a.rte-blackpill,
p > a.rte-blackpill {
    @include font-semibold();
    @include font(16px, 44px);
    align-items: center;
    background-color: $color-black;
    border: 1px solid $color-black;
    border-radius: 25px;
    color: $color-white !important;
    display: block;
    height: 44px;
    padding: 0 16px;
    text-align: center;
    text-decoration: none;
    transition: background-color $transition-timing $transition-function, border-color $transition-timing $transition-function;

    @include respond-to('xxs') {
        max-width: 280px;
    }
}

div > a.rte-blackpill:hover,
div > a.rte-blackpill:focus,
p > a.rte-blackpill:hover,
p > a.rte-blackpill:focus {
    background-color: $color-purple;
    border-color: $color-purple;
    cursor: pointer;
}

