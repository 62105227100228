@mixin for-safari() {
    //sonoma, ventura, sierra safari
    @supports (-webkit-hyphens:none){
        @content;
    }
    //monterey, big sur, catalina, mojave, high sierra safari
    @media not all and (min-resolution:.001dpcm)
    { @supports (-webkit-appearance:none) and (stroke-color:transparent) and (-webkit-hyphens:none) 
        {
            @content;
        }
    }
}