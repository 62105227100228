$hero-image-height: 436px;

.hero-image {
    height: #{$hero-image-height * .7};
    position: relative;
    width: 100%;

    @include for-print() {
        height: auto;
    }

    @include respond-to('xs') {
        height: $hero-image-height;
    }

    &--has-image {
        .hero-image__content {
            color: $color-white;

            @include for-print() {
                color: $color-black;
            }
        }

        .hero-image__heading,
        .hero-image__abstract,
        .hero-image__content p {
            color: $color-white;

            @include for-print() {
                color: $color-black;
            }
        }
    }

    &__container {
        height: 100%;
        overflow: hidden;
        position: absolute;
        width: 100%;
    }

    &__image {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 100%;
        width: 100%;

        @include for-print() {
            display: none;
        }
    }

    &__overlay {
        background-color: rgba(0,0,0,0.45);
        height: 100%;
        position: absolute;
        width: 100%;

        @include for-print() {
            background: none;
            position: relative;
        }
    }

    &__breadcrumbs {
        @include structure-normal();
        @include responsive-component-margin();

        @include for-print() {
            display: none;
        }
    }

    &__content {
        @include structure-normal();
        @include responsive-component-margin();
        position: relative;

        @include for-print() {
            @include responsive-component-margin(0);
            padding: 0;
        }
    }

    &__heading {
        @include font-heading();
        color: $color-black;
        font-size: #{$font-size-base * 2};
        line-height: #{$font-size-base * 2};
        text-transform: uppercase;

        @include respond-to('xs') {
            font-size: #{$font-size-base * 4};
            line-height: #{$font-size-base * 4};
        }

        @include for-print() {
            @include font(36px, 37px);
        }
    }

    &__abstract {
        margin-top: 8px;
        width: 100%;

        @include respond-to('xs') {
            width: 63%;
        }

        @include for-print() {
            width: 100%;
        }
    }

    &__abstract,
    &__content p {
        @include font-medium();
        color: $color-black;
        font-size: #{$font-size-base * 1.125};
        line-height: #{$line-height-base * 2.2};

        @include respond-to('xs') {
            font-size: #{$font-size-base * 1.5};
            line-height: #{$line-height-base * 3};
        }

        @include for-print() {
            @include font(20px, 24px);
        }
    }
}