﻿@mixin font-light() {
    //Fonts: HCoWhitney, arial
    font-family: $font-whitney, $font-sans-serif;
    font-weight: 300;
    font-style: normal;

    @include for-print() {
        font-family: $font-whitney, $font-sans-serif;
    }
}

@mixin font-light-italic() {
    //Fonts: HCoWhitney, arial
    font-family: $font-whitney, $font-sans-serif;
    font-weight: 300;
    font-style: italic;

    @include for-print() {
        font-family: $font-whitney, $font-sans-serif;
    }
}

@mixin font-content() {
    //Fonts: HCoWhitney, arial
    font-family: $font-whitney, $font-sans-serif;
    font-weight: 400;
    font-style: normal;

    @include for-print() {
        font-family: $font-whitney, $font-sans-serif;
    }
}

@mixin font-content-italic() {
    //Fonts: HCoWhitney, arial
    font-family: $font-whitney, $font-sans-serif;
    font-weight: 400;
    font-style: italic;

    @include for-print() {
        font-family: $font-whitney, $font-sans-serif;
    }
}

@mixin font-medium() {
    //Fonts: HCoWhitney, arial
    font-family: $font-whitney, $font-sans-serif;
    font-weight: 500;
    font-style: normal;

    @include for-print() {
        font-family: $font-whitney, $font-sans-serif;
    }
}

@mixin font-medium-italic() {
    //Fonts: HCoWhitney arial
    font-family: $font-whitney, $font-sans-serif;
    font-weight: 500;
    font-style: italic;

    @include for-print() {
        font-family: $font-whitney, $font-sans-serif;
    }
}

@mixin font-semibold() {
    //Fonts: HCoWhitney, arial
    font-family: $font-whitney, $font-sans-serif;
    font-weight: 600;
    font-style: normal;

    @include for-print() {
        font-family: $font-whitney, $font-sans-serif;
    }
}

@mixin font-semibold-italic() {
    //Fonts: HCoWhitney, arial
    font-family: $font-whitney, $font-sans-serif;
    font-weight: 600;
    font-style: italic;

    @include for-print() {
        font-family: $font-whitney, $font-sans-serif;
    }
}

@mixin font-bold() {
    //Fonts: HCoWhitney, arial
    font-family: $font-whitney, $font-sans-serif;
    font-weight: 700;
    font-style: normal;

    @include for-print() {
        font-family: $font-whitney, $font-sans-serif;
    }
}

@mixin font-bold-italic() {
    //Fonts: HCoWhitney, arial
    font-family: $font-whitney, $font-sans-serif;
    font-weight: 700;
    font-style: italic;

    @include for-print() {
        font-family: $font-whitney, $font-sans-serif;
    }
}

@mixin font-heading() {
    //Fonts: FuturaW01-ExtraBoldCond, Arial
    font-family: $font-header, $font-sans-serif;
    font-weight: normal;
    font-style: normal;

    @include for-print() {
        font-family: $font-header, $font-sans-serif;
    }
}

@mixin font-heading-alt() {
    @include font-heading();
}