.team-list-collection {
    container-type: inline-size;

    &__container {
        &--with-background {
            background-color: $color-white-smoke;
            padding: 16px;

            @include respond-to-container('small') {
                padding: 24px;
            }

            @include for-print() {
                background-color: transparent;
                padding: 0;
            }
        }
    }

    &__heading {
        @include font(24px, 28px);
        @include font-medium();
        color: $color-purple;
        text-transform: none;

        @include respond-to('xxs') {
            @include font(30px, 34px);
        }

        @include for-print() {
            @include font(18px, 22px);
        }
    }

    &__heading--with-background {
        @include font(18px, 19px);
        @include font-heading();
        color: $color-purple;
        text-transform: uppercase;

        @include for-print() {
            @include font(16px, 17px);
        }
    }

    &__abstract {
        padding-top: 16px;

        @include for-print() {
            @include font(14px, 16px);
            color: $color-black;
        }
    }

    &--aside &__heading {
        @include font(16px, 18px);
    }

    &--aside &__abstract {
        padding-top: 11px;
    }

    &--aside &__listing {
        padding-top: 11px;
    }

    @include for-print() {
        padding: 0;
    }
}
