﻿$hero-image-height: 536px;

.animated-hero {
    height: 436px;
    position: relative;
    width: 100%;

    @include for-print() {
        height: auto;
    }

    @include respond-to('xs') {
        height: $hero-image-height;
    }

    &__breadcrumbs {
        @include structure-normal();
        bottom: 0;
        left: 0;
        margin: auto;
        position: absolute;
        right: 0;
        top: 0;

        @include for-print() {
            display: none;
        }
    }

    &__content {
        @include structure-normal();
        @include responsive-component-margin();
        color: $color-white;
        margin: auto;
        text-align: left;
        width: 800px;

        & > * {
            color: $color-white;
        }

        &--center {
            text-align: center;
        }

        @include for-print() {
            @include responsive-component-margin(0);
            text-align: left;
        }
    }

    &__eyebrow {
        @include font(16px, 21px);
        margin-bottom: 8px;

        @include for-print() {
            @include font(16px, 17px);
            color: $color-purple;
            margin-bottom: 8px;
        }
    }

    &__container {
        height: 100%;
        overflow: hidden;
        position: absolute;
        width: 100%;

        @include for-print() {
            display: none;
        }
    }

    &__background {
        background-size: cover;
        height: auto;
        min-height: 100%;
        min-width: 100%;
        overflow: hidden;
        position: absolute;
        width: 100%;

        &--image {
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            height: 100%;
        }

        &--video {
            object-fit: cover;
        }

        @include for-print() {
            display: none;
        }
    }

    &__heading {
        @include font(32px, 33px);
        @include font-heading();
        margin-bottom: 24px;
        text-transform: uppercase;

        @include respond-to('xs') {
            @include font(64px, 65px);
        }

        @include for-print() {
            @include font(32px, 33px);
            color: $color-black;
            margin-bottom: 8px;
        }
    }

    &__subheading {
        @include font(18px, 24px);
        @include font-medium();
        margin-bottom: 40px;

        @include respond-to('xs') {
            @include font(24px, 28px);
        }

        @include for-print() {
            @include font(18px, 24px);
            color: $color-black;
            margin-bottom: 30px;
        }
    }

    &__overlay {
        background-color: rgba(0,0,0,0.45);
        display: flex;
        flex-direction: column;
        height: 100%;
        position: absolute;
        width: 100%;

        @include for-print() {
            background: none;
            position: relative;
        }
    }

    &__date {
        @include font(18px, 22px);
        @include font-semibold();
        text-transform: capitalize;

        @include for-print() {
            @include font(14px, 16px);
            color: $color-dark-grey;
            margin-bottom: 16px;
            text-transform: none;
        }
    }
}
