.side-section-listing {
  padding: 0px 0px 26px 0px;
  h2 {
    @include font-heading();
    color: $color-purple;
    font-size: 16px;
    padding-bottom: 11px;
    text-transform: uppercase;
  }
  h3 {
    @include font-heading();
    color: $color-purple;
    font-size: 16px;
    padding-bottom: 11px;
    text-transform: uppercase;
  }
  ul {
    @include chevron-bullet();

    li {
      padding-bottom: 7px;
      position: relative;

      a {
        .audio {
          background: url(../images/icon-audio.png) no-repeat 0px 4px;
          &:hover,
          &:focus {
            background: url(../images/icon-audio-hover.png) no-repeat 0px 4px;
            color: $color-purple;
          }
        }
        .pdf {
          background: url(../images/icon-pdf.png) no-repeat 0px 2px;
          &:hover,
          &:focus {
            background: url(../images/icon-pdf.png) no-repeat 0px 2px;
            color: $color-purple;
          }
        }
        .video {
          background: url(../images/icon-video.png) no-repeat 0px 5px;
          &:hover,
          &:focus {
            background: url(../images/icon-video-hover.png) no-repeat 0px 5px;
            color: $color-purple;
          }
        }
        .ja {
          @include font-semibold();
        }
      }
    }
  }
  .show-more-link {
    padding: 28px 0 5px 0;
  }
}

.print-wrapper {
    .side-section-listing {
        ul {
            margin-top: 4px;
            list-style-type: disc;
            list-style-position: outside;
            padding-left: 17px;

            li {
                color: $color-purple-contrast;
                
                .icon-chevron {
                    display: none;
                }
            }
        }
    }
}
