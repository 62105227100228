.rte-text {
    &--small {
        @include font(20px, 21px);
        color: $color-black;
        text-transform: uppercase;
        margin-top: 24px;
        margin-bottom: 12px;

        @include for-print() {
            @include font(14px, 16px);
            margin-top: 6px;
            margin-bottom: 8px;
        }
    }

    &--medium {
        @include font(24px, 25px);
        color: $color-black;
        text-transform: uppercase;
        margin-top: 24px;
        margin-bottom: 16px;

        @include for-print() {
            @include font(16px, 17px);
            margin-top: 6px;
            margin-bottom: 12px;
        }
    }

    &--purple-section {
        @include font(30px, 36px);
        @include font-medium();
        color: $color-purple;
        margin-bottom: 16px;

        @include for-print() {
            @include font(18px, 22px);
        }
    }

    &--large {
        @include font(36px, 37px);
        color: $color-black;
        text-transform: uppercase;
        margin-bottom: 24px;

        @include for-print() {
            @include font(18px, 19px);
            margin-bottom: 16px;
        }
    }
}
