.body {
  &-scroll--disabled {
    @include respond-to-max("small") {
      overflow: hidden;
    }
  }
}
body {
  min-height: 100vh;
  min-height: -webkit-fill-available;
}
html {
  height: -webkit-fill-available;
}