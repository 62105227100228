@font-face {
    font-family: "icons";
    src: url("../fonts/icons.eot?sccet0#newicons");
    src: url("../fonts/icons.eot?sccet0#newicons") format("embedded-opentype"),
        url("../fonts/icons.ttf?sccet0#newicons") format("truetype"),
        url("../fonts/icons.woff?sccet0#newicons") format("woff"),
        url("../fonts/icons.svg?sccet0#icons#newicons") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
    @include icon();
}

.icon-tab-down-arrow:before {
    content: "\e900";
}

.icon-menu-close-mobile:before {
    content: "\e901";
}

.icon-search-white:before {
    content: "\e902";
}

.icon-search-white-mobile:before {
    content: "\e903";
}

.icon-hamburger-white:before {
    content: "\e904";
}

.icon-social-vimeo:before {
    content: "\e905";
}

.icon-hamburger-white-mobile:before {
    content: "\e906";
}

.icon-audio:before {
    content: "\e907";
}

.icon-press-release:before {
    content: "\e908";
}

.icon-resource:before {
    content: "\e909";
}

.icon-blog:before {
    content: "\e90a";
}

.icon-diversity:before {
    content: "\e90b";
}

.icon-event:before {
    content: "\e90c";
}

.icon-flash:before {
    content: "\e90d";
}

.icon-location:before {
    content: "\e90e";
}

.icon-news:before {
    content: "\e90f";
}

.icon-person:before {
    content: "\e910";
}

.icon-publication:before {
    content: "\e911";
}

.icon-social-twitter:before {
    content: "\e912";
}

.icon-social-linkedin:before {
    content: "\e913";
}

.icon-video:before {
    content: "\e914";
}

.icon-social-facebook:before {
    content: "\e915";
}

.icon-social-instagram:before {
    content: "\e916";
}

.icon-menu-close:before {
    content: "\e917";
}

.icon-globe:before {
    content: "\e918";
}

.icon-feather-x:before {
    content: "\e919";
}

.icon-chevron:before {
    @include icon-chevron();
}

.icon-close-symbol:before {
    content: "\e91b";
}

.icon-plus:before {
    @include icon-plus();
}

.icon-minus:before {
    @include icon-minus();
}

.icon-cross:before {
    content: "\ea0f";
}

.icon-arrow-right:before {
    content: "\f061";
}

.icon-accordion:before {
    content: "\e91c";
}

.icon-audio-black:before {
    content: "\e91d";
}

.icon-speech:before {
    content: "\e91e";
}

.icon-social-wechat:before {
    content: "\e91f";
}
