﻿@mixin ja-heading-font-whitney() {
    //Fonts: HCoWhitney, Arial, Yu Gothic Pr6 B
    font-family: $font-whitney, $font-japanese-semibold;
    font-weight: 600;

    @include for-print() {
        font-family: $font-whitney, $font-japanese-print;
    }
}

@mixin ja-content-font() {
    //Fonts: HCoWhitney, Arial, Yu Gothic Pr6 B
    font-family: $font-content, $font-japanese-semibold;

    @include for-print() {
        font-family: $font-content, $font-japanese-print;
    }
}

@mixin ja-content-font-bold() {
    //Fonts: HCoWhitney, Arial, Yu Gothic Pr6 B
    font-family: $font-content, $font-japanese-semibold;
    font-weight: 600;

    @include for-print() {
        font-family: $font-content, $font-japanese-print;
    }
}

@mixin zh-cn-content-font() {
    font-family: $font-content, $font-chinese-content;
    font-weight: normal;
    font-style: normal;

    @include for-print() {
        font-family: $font-content, $font-chinese-print;
    }
}

@mixin zh-tw-content-font() {
    font-family: $font-content, $font-chinese-traditional-content;
    font-weight: normal;
    font-style: normal;

    @include for-print() {
        font-family: $font-content, $font-chinese-print;
    }
}

@mixin font( $font-default: 0, $font-default-line-height: 0, $font-medium: 0, $font-large: 0, $font-small: 0, $font-xs: 0, $font-xlarge: 0, $font-contentlayout: 0, $font-xxs: 0, $font-iphonex: 0, $font-iphonev: 0, $font-medium-line-height: 0, $font-large-line-height: 0, $font-small-line-height: 0, $font-xs-line-height: 0, $font-xlarge-line-height: 0, $font-contentlayout-line-height: 0, $font-xxs-line-height: 0, $font-iphonex-line-height: 0, $font-iphonev-line-height: 0 ) {
    font-size: $font-default;

    @if ($font-default-line-height != 0) {
        line-height: $font-default-line-height;
    }

    @if ($font-xlarge != 0) {
        @include respond-to-max("xlarge") {
            font-size: $font-xlarge;

            @if ($font-xlarge-line-height != 0) {
                line-height: $font-xlarge-line-height;
            }
        }
    }

    @if ($font-large != 0) {
        @include respond-to-max("large") {
            font-size: $font-large;

            @if ($font-large-line-height != 0) {
                line-height: $font-large-line-height;
            }
        }
    }

    @if ($font-contentlayout != 0) {
        @include respond-to-max("contentlayout") {
            font-size: $font-contentlayout;

            @if ($font-large-line-height != 0) {
                line-height: $font-large-line-height;
            }
        }
    }

    @if ($font-medium != 0) {
        @include respond-to-max("medium") {
            font-size: $font-medium;

            @if ($font-medium-line-height != 0) {
                line-height: $font-medium-line-height;
            }
        }
    }

    @if ($font-small != 0) {
        @include respond-to-max("small") {
            font-size: $font-small;

            @if ($font-small-line-height != 0) {
                line-height: $font-small-line-height;
            }
        }
    }

    @if ($font-xs != 0) {
        @include respond-to-max("xs") {
            font-size: $font-xs;

            @if ($font-xs-line-height != 0) {
                line-height: $font-xs-line-height;
            }
        }
    }

    @if ($font-xxs != 0) {
        @include respond-to-max("xxs") {
            font-size: $font-xxs;

            @if ($font-xxs-line-height != 0) {
                line-height: $font-xxs-line-height;
            }
        }
    }

    @if ($font-iphonex != 0) {
        @include respond-to-max("iphonex") {
            font-size: $font-iphonex;

            @if ($font-iphonex-line-height != 0) {
                line-height: $font-iphonex-line-height;
            }
        }
    }

    @if ($font-iphonev != 0) {
        @include respond-to-max("iphonev") {
            font-size: $font-iphonev;

            @if ($font-iphonev-line-height != 0) {
                line-height: $font-iphonev-line-height;
            }
        }
    }
}
