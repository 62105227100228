/* auto suggest */
.ui-widget-content {
  background: #ffffff !important;
  border: 0 solid #aaa !important;
  border-radius: 0 !important;
  box-shadow: 0 4px 7px #cccccc;
  color: #222;
}

.ui-autocomplete-category {
  line-height: 19px;
}

.ui-menu .ui-menu-item {
  @include font-medium();
  font-size: 15px;
  line-height: 18px;
  padding: 5px !important;
  box-sizing: border-box;
  color: #000000;
}

.ui-menu .ui-menu-item.ui-autocomplete-category {
  @include font-heading();
}

.ui-menu .ui-menu-item.ui-autocomplete-view-all {
  @include font-semibold();
}

.ui-menu .ui-menu-item a {
  @include font-medium();
  text-transform: none;
  font-size: 15px;
  display: block;
  padding: 0px !important;
  color: #000000;
}

.ui-menu .ui-menu-item a:hover,
.ui-menu .ui-menu-item a:focus {
  border-radius: 0 !important;
  background: $color-purple !important;
  color: #ffffff !important;
  border: none;
}

ul li.ui-autocomplete-category,
ul li.autocompleteHeaderWithoutLink {
  @include font-heading();
  color: $color-purple !important;
  text-transform: uppercase;
  padding: 5px !important;
}

ul.autocomplete-width li.ui-menu-item {
  text-size-adjust: none;
  -webkit-text-size-adjust: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.ui-state-hover,
.ui-widget-content .ui-state-hover,
.ui-widget-header .ui-state-hover,
.ui-state-focus,
.ui-state-focus a,
.ui-widget-content .ui-state-focus,
.ui-widget-header .ui-state-focus {
  border: none !important;
  background: $color-purple !important;
  border-radius: 0 !important;
  color: #ffffff !important;
}

.ui-menu .ui-menu-item a:focus {
  color: #ffffff !important;
}

.ui-autocomplete-category {
  cursor: default !important;
}

.ui-widget-content .ui-autocomplete-category.ui-state-hover,
.ui-widget-content .ui-autocomplete-category.ui-state-focus,
.ui-widget-header .ui-autocomplete-category.ui-state-hover,
.ui-widget-header .ui-autocomplete-category.ui-state-focus {
  background: none !important;
  color: $color-purple !important;
}
