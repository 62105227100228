@use 'sass:math';

.ml-component {
    &__experience-editor-notification {
        display: none;
    }

    &__responsive-margins {
        @include responsive-component-margin($global-page-margin);

        @include for-print() {
            @include responsive-component-margin(0);
        }

        &--half {
            @include responsive-component-margin(math.div($global-page-margin, 2));

            @include for-print() {
                @include responsive-component-margin(0);
            }
        }

        &--mobile-only {
            @include responsive-component-margin($global-page-margin, 0);

            @include for-print() {
                @include responsive-component-margin(0);
            }
        }

        & .ml-component__responsive-margins {
            // set margins to 0 when we have nested components
            @include responsive-component-margin(0);
        }
    }
}

.experience-editor {
    .ml-component {
        border: 1px solid green;
        overflow: hidden;
        padding: 20px 0;

        &__experience-editor-notification {
            display: block;
            margin-left: 10px;
        }
    }
}
