﻿select option:last-child {
    border-bottom: 2px solid $color-purple;
}

.jumbotron {
    background: transparent;
    margin-bottom: 0;
    padding: 0;
}

.jumbotron h1,
.jumbotron h2 {
    @include font-heading();
    color: $color-white;
    line-height: 1;
    text-transform: uppercase;
    /*text-shadow: -1px 1px rgba(0, 0, 0, 0.4);*/
}

.jumbotron h1 {
    font-size: 64px;
    margin-top: 60px;
}

.breadcrumb {
    background: transparent;
    padding: 8px 0;
}

.breadcrumb > li a,
.breadcrumb > li {
    @include font-semibold();
    color: #fff;
    font-size: 14px;
    text-decoration: none;
    font-weight: normal;
    /*text-shadow: -1px 1px rgba(0, 0, 0, 0.4);*/
}

.breadcrumb > li a:hover {
    text-decoration: underline;
}

.breadcrumb > li + li:before {
    content: "\003e";
    color: #fff;
}

.btn {
    @include font-heading();
}

.page-header {
    margin-top: 0;
    border: none;
}

.page-header h1 {
    @include font-heading();
    text-align: left !important;
    text-transform: uppercase;
    margin-top: 0;
    color: $color-black;
}

.img-thumbnail {
    padding: 0;
    border: none;
    border-radius: 0;
    background: transparent;
}

.dropdown-menu {
    max-height: 150px;
    overflow-y: auto;
}

