﻿@mixin c2r1-tile-image-left {
    .tile__intro {
        padding: 0;

        @include respond-to('xxs') {
            padding-left: 16px;
        }
    }

    .tile__media {
        order: unset;

        @include respond-to('xxs') {
            order: -1;
        }
    }
}

@mixin c2-r1-tile-link {
    .tile__link {
        .tile__padding {
            flex-direction: column;

            @include respond-to('xxs') {
                flex-direction: row;
            }
        }

        .tile__intro {
            height: calc(#{$tile-height-increment - $large-spacer - 20});
            padding-right: 8px;
            width: 100%;

            @include respond-to('xxs') {
                height: initial;
                width: 50%;
            }
        }

        .tile__media {
            height: calc(#{$tile-height-increment - $large-spacer + 20});
            width: 100%;

            @include respond-to('xxs') {
                height: #{$tile-height-increment - ($large-spacer * 2)};
                width: 50%;
            }
        }
    }
}

.tile {
    @include print-background-images();

    @include for-print() {
        position: relative;
    }

    &:hover,
    &:focus {
        .tile {
            &__image {
                transform: scale(1.2);

                @include for-print() {
                    transform: none;
                }
            }
        }
    }

    &__date {
        @include font-content();
        @include font(14px, 15px);
        color: $color-medium-grey;
    }

    &__padding {
        padding: $large-spacer 0;
    }

    &__label {
        @include font-medium();
        @include font(14px);
        color: $color-white;
        margin-bottom: 8px;
        opacity: 0.75;
        text-transform: uppercase;
    }

    &__link {
        height: 100%;
        width: 100%;

        &:focus-visible {
            @include focus-outline();
            display: inline-block;
        }
    }

    &__heading {
        padding-bottom: 18px;
        text-transform: uppercase;
    }

    &__media {
        width: 100%;
        opacity: 1;
        transition: opacity $transition-timing-function $transition-duration;
        position: relative;

        @include for-print() {
            transition: none;
        }
    }

    &__image-container {
        display: block;
        height: 100%;
        overflow: hidden;
        width: 100%;
    }

    &__image {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 100%;
        transition: transform 0.3s ease-in;
        width: 100%;

        @include for-print() {
            transition: none;
        }
    }

    &__image-overlay-container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        @include for-print() {
            display: none;
        }
    }

    &__image-overlay {
        color: $color-white;
        transition: background-color 0.3s ease-in;
        padding: $large-spacer;
        height: calc(100% - #{$large-spacer * 2});

        &--no-padding {
            padding: 0;
            height: 100%;
        }

        &--dark {
            background-color: rgba(0,0,0,0.45);
        }

        &--gradient {
            background-image: linear-gradient(to bottom, rgba($color-black, 1), rgba($color-black, 0.75), rgba($color-black, 0));

            @include respond-to('xs', and, hover) {
                background-image: linear-gradient(to bottom, rgba($color-black, 1), rgba($color-black, 0));
            }
        }

        @include for-print() {
            transition: none;
        }
    }
}

.tile--content,
.tile--media {
    &:hover,
    &:focus {
        .tile {
            &__title {
                color: $color-purple;
            }

            &__subtitle,
            &__date {
                color: $color-black;
            }

            &__image-overlay {
                background-color: rgba(0,0,0,0.25);

                &--dark {
                    background-color: rgba(0,0,0,0.55);
                }
            }
        }
    }

    .tile {
        &__padding {
            display: flex;
            flex-direction: column;
        }

        &__heading {
            @include font-heading();
            color: $color-purple;
            display: flex;
            font-size: 16px;
            line-height: 30px;

            @include for-print() {
                @include font(16px, 28px);
            }
        }

        &__icon {
            margin-right: $medium-spacer;
            margin-left: 1px;
            font-size: 30px;
            color: $color-black;
        }

        &__title {
            color: $color-black;
            transition: color $transition-timing-function $transition-duration;

            @include for-print() {
                @include font(20px, 21px);
                transition: none;
            }
        }

        &__title,
        &__image-overlay {
            @include font-heading();
            font-size: 21px;
            line-height: 22px;
            text-transform: uppercase;

            @include respond-to('small') {
                font-size: 24px;
                line-height: 25px;
            }

            .tile-grid__item--r3.tile-grid__item--c3 & {
                font-size: 21px;
                line-height: 22px;

                @include respond-to('xs') {
                    font-size: 42px;
                    line-height: 43px;
                }
            }

            .tile-grid__item--r3.tile-grid__item--c2 & {
                font-size: 21px;
                line-height: 22px;

                @include respond-to('xs') {
                    font-size: 36px;
                    line-height: 37px;
                }
            }

            .tile-grid__item--r2.tile-grid__item--c2 & {
                font-size: 21px;
                line-height: 22px;

                @include respond-to('xs') {
                    font-size: 28px;
                    line-height: 29px;
                }
            }

            .tile-grid__item--r2.tile-grid__item--c3 & {
                font-size: 21px;
                line-height: 22px;

                @include respond-to('xs') {
                    font-size: 30px;
                    line-height: 31px;
                }
            }
        }

        &__subtitle {
            @include font-content();
            font-size: 15px;
            line-height: 18px;
            color: $color-dark-grey;
            transition: color $transition-timing-function $transition-duration;

            @include respond-to('small') {
                font-size: 16px;
                line-height: 20px;
            }

            @include for-print() {
                @include font(14px, 16px);
                transition: none;
            }

            .tile-grid__item--r2.tile-grid__item--c3 & {
                font-size: 15px;
                line-height: 18px;

                @include respond-to('xs') {
                    font-size: 16px;
                    line-height: 20px;
                }
            }
        }

        &__date {
            @include font-content();
            color: $color-medium-grey;
            font-size: 14px;
            line-height: 14px;

            @include respond-to('xs') {
                font-size: 14px;
                line-height: 15px;
            }
        }
    }

    .tile__title + .tile__subtitle {
        padding-top: 8px;
    }
}

.tile--border {
    border-bottom: 2px solid $color-medium-grey;
}

.tile--content {
    opacity: 1;
    transition: opacity $transition-timing-function $transition-duration;

    @include for-print() {
        transition: none;
    }

    &.tile-grid__item {
        &--r1 {
            &.tile-grid__item--c2 {
                &.tile--image-left {
                    @include c2r1-tile-image-left();
                }

                @include c2-r1-tile-link();
            }
        }

        &--r2 {
            .tile__intro {
                height: calc(#{$tile-height-increment - $large-spacer - 20});
            }

            .tile__media {
                height: calc(#{$tile-height-increment - $large-spacer + 20});
                max-width: 100%;
                max-height: 100%;
            }

            &.tile-grid__item--c1 {
                @include for-print() {
                    @include c2r1-tile-image-left();
                    @include c2-r1-tile-link();
                }
            }
        }

        &--r3 {
            &.tile-grid__item--c2,
            &.tile-grid__item--c3 {
                .tile__intro {
                    height: calc(#{$tile-height-increment - $large-spacer - 20});
                }

                .tile__media {
                    height: calc(#{$tile-height-increment - $large-spacer + 20});
                }

                @include respond-to('xs') {
                    .tile__intro {
                        height: calc(#{$tile-height-increment - $large-spacer});
                    }

                    .tile__media {
                        height: calc(#{($tile-height-increment * 2) - $large-spacer});
                    }
                }
            }
        }
    }
}

.tile--media {
    .stacked-media-module-main-holder {
        .stacked-media-module-main-video-holder,
        .stacked-media-module-main-audio-holder {
            width: 100%;
            height: 100%;
        }
    }

    .tile__intro {
        height: $media-tile-intro-height;
    }

    .tile__media {
        position: relative;

        .mejs__controls {
            bottom: 5px;
        }

        .audio-iframe {
            position: absolute;
            width: 100%;
            bottom: 0px;
        }
    }

    &.tile-grid__item {
        &--r1 {
            .tile__media,
            .video-iframe, .video-iframe > .mejs-container, .video-iframe > .mejs__container {
                height: #{$tile-height-increment - $media-tile-intro-height - ($large-spacer * 2)};
            }
        }

        &--r2 {
            .tile__media,
            .video-iframe, .video-iframe > .mejs-container, .video-iframe > .mejs__container {
                height: #{($tile-height-increment * 2) - $media-tile-intro-height - ($large-spacer * 2)};
            }
        }

        &--r3 {
            .tile__media,
            .video-iframe, .video-iframe > .mejs-container, .video-iframe > .mejs__container {
                height: #{($tile-height-increment * 3) - $media-tile-intro-height - ($large-spacer * 2)};
            }
        }

        @include respond-to-max('xxs') {
            &.tile-grid__item--c2,
            &.tile-grid__item--c3 {
                .tile__media,
                .video-iframe, .video-iframe > .mejs-container, .video-iframe > .mejs__container {
                    height: #{($tile-height-increment * 2) - $media-tile-intro-height - ($large-spacer * 2)};
                }
            }
        }
    }
}

.tile--simple-content {
    background-color: rgba(var(--backgroundcolor), var(--backgroundcolortransitionlevel));

    &:hover {
        background-color: rgba(var(--backgroundcolor), 1);
        cursor: pointer;
        transition: background-color .3s ease-in-out;

        .tile__body-cta .icon {
            color: var(--titletextcolor);
        }

        .tile__subtitle {
            color: var(--subtitletextcolor);
        }
    }

    @include for-print() {
        transition: none;
    }

    &.tile-grid__item {
        &--r1 {
            // small
            &.tile-grid__item--c1 {

                .tile__title {
                    @include font(20px, 24px);

                    @include for-print() {
                        @include font(18px, 20px);
                    }
                }

                .tile__link {
                    @include font(14px, 20px);
                }
            }
            // medium 2 col
            &.tile-grid__item--c2 {
                .tile__title {
                    @include font(24px, 28px);

                    @include for-print() {
                        @include font(18px, 20px);
                    }
                }

                .tile__link {
                    @include font(14px, 20px);
                }
            }
            // wide medium
            &.tile-grid__item--c3 {

                .tile__link {
                    @include font(14px, 20px);
                }

                .tile__title {
                    @include font(24px, 28px);

                    @include respond-to('xxs') {
                        @include font(28px, 30px);
                    }

                    @include for-print() {
                        @include font(18px, 20px);
                    }
                }

                .tile__subtitle {
                    @include font(18px, 22px);

                    @include respond-to('xxs') {
                        @include font(16px, 20px);
                    }

                    @include for-print() {
                        @include font(14px, 16px);
                    }
                }

                .tile__link {
                    @include font(14px, 20px);
                }
            }
        }

        &--r2 {
            // tall medium
            &.tile-grid__item--c1 {
                .tile__title {
                    @include font(24px, 28px);

                    @include for-print() {
                        @include font(18px, 20px);
                    }
                }

                .tile__subtitle {
                    @include font(18px, 22px);

                    @include for-print() {
                        @include font(14px, 16px);
                    }
                }

                .tile__link {
                    @include font(14px, 20px);
                }
            }
            // medium
            &.tile-grid__item--c2 {
                .tile__title {
                    @include font(24px, 28px);

                    @include for-print() {
                        @include font(18px, 20px);
                    }
                }

                .tile__link {
                    @include font(14px, 20px);
                }
            }
            // wide large
            &.tile-grid__item--c3 {
                .tile__title {
                    @include font(24px, 28px);

                    @include respond-to('xs') {
                        @include font(30px, 34px);
                    }

                    @include for-print() {
                        @include font(24px, 28px);
                    }
                }

                .tile__subtitle {
                    @include font(18px, 22px);

                    @include respond-to('xs') {
                        @include font(22px, 28px);
                    }

                    @include for-print() {
                        @include font(18px, 22px);
                    }
                }
            }
        }

        &--r3 {
            // large
            &.tile-grid__item--c2 {
                .tile__title {
                    @include font(28px, 32px);

                    @include for-print() {
                        @include font(18px, 20px);
                    }
                }

                .tile__subtitle {
                    @include font(20px, 24px);

                    @include for-print() {
                        @include font(14px, 16px);
                    }
                }

                .tile__link {
                    @include font(14px, 20px);
                }
            }
            // wide large
            &.tile-grid__item--c3 {
                .tile__title {
                    @include font(24px, 28px);

                    @include respond-to('xxs') {
                        @include font(30px, 34px);
                    }

                    @include for-print() {
                        @include font(18px, 20px);
                    }
                }

                .tile__subtitle {
                    @include font(18px, 22px);

                    @include respond-to('xxs') {
                        @include font(22px, 28px);
                    }

                    @include for-print() {
                        @include font(14px, 16px);
                    }
                }

                .tile__link {
                    @include font(14px, 20px);
                }
            }
        }
    }

    .tile {
        &__padding {
            display: flex;
            flex-direction: column;
            height: calc(100% - #{$large-spacer * 2});
            padding: $large-spacer;
            width: calc(100% - #{$large-spacer * 2});
        }

        &__intro {
            display: flex;
            flex-direction: column;
            height: 100%;
        }

        &__heading {
            @include font-medium();
            @include font(14px, 15px);
            color: var(--eyebrowtextcolor);
            display: flex;
            padding-bottom: 8px;

            @include for-print() {
                @include font(14px, 16px);
            }
        }

        &__title {
            @include font-medium();
            color: var(--titletextcolor);
            transition: color $transition-timing-function $transition-duration;

            @include for-print() {
                @include font(18px, 20px);
                transition: none;
            }
        }

        &__subtitle {
            @include font-content();
            @include font(15px, 18px);
            color: var(--subtitletextcolor);
            transition: color $transition-timing-function $transition-duration;

            @include respond-to('small') {
                @include font(16px, 20px);
            }

            @include for-print() {
                @include font(14px, 16px);
                transition: none;
            }
        }

        &__date {
            @include font-content();
            @include font(14px, 14px);
            color: $color-medium-grey;

            @include respond-to('xs') {
                @include font(14px, 15px);
            }
        }

        &__body-cta {
            align-items: center;
            display: flex;
            margin-top: auto;

            .icon {
                color: var(--titletextcolor);
                font-size: 12px;
            }

            @include for-print() {
                display: none;
            }
        }

        &__body-cta-label {
            @include font-semibold();
            @include font(18px, 18px);
            color: var(--titletextcolor);
            margin-left: 8px;
        }
    }

    .tile__title + .tile__subtitle {
        padding-top: 8px;
    }
}

.tile--call-to-action {
    background-color: $color-white-smoke;

    &:hover {
        .tile__button-container {
            background-color: $color-purple;
        }
    }

    &.tile-grid__item--r1 {
        &.tile-grid__item--c2 {
            .tile {
                &__padding {
                    flex-direction: column;

                    @include respond-to('xxs') {
                        flex-direction: row;
                    }
                }

                &__top,
                &__bottom {
                    width: 100%;

                    @include respond-to('xxs') {
                        width: 50%;
                    }
                }

                &__bottom {
                    display: flex;
                    justify-content: center;
                    margin-left: 0;

                    @include respond-to('xxs') {
                        margin-left: #{$large-spacer};
                    }
                }

                &__button-container {
                    width: 100%;
                }
            }
        }

        &.tile-grid__item--c3 {
            .tile {
                &__padding {
                    flex-direction: column;

                    @include respond-to('xxs') {
                        flex-direction: row;
                    }
                }

                &__top {
                    width: 100%;

                    @include respond-to('xxs') {
                        width: 60%;
                    }
                }

                &__bottom {
                    display: flex;
                    justify-content: center;
                    margin-left: 0;
                    width: 100%;

                    @include respond-to('xxs') {
                        margin-left: #{$large-spacer};
                        width: 40%;
                    }
                }

                &__button-container {
                    width: 100%;
                }
            }
        }
    }

    &.tile-grid__item--r2 {
        // wide large
        &.tile-grid__item--c3 {
            .tile__header {
                @include font(24px, 28px);
            }

            .tile__abstract {
                @include font(16px, 20px);
            }
        }
    }

    .tile {
        &__link {
            align-items: center;
            display: flex;
            height: inherit;
            justify-content: center;
        }

        &__padding {
            display: flex;
            flex-direction: column;
            padding: #{$medium-spacer * 2};
            width: calc(100% - #{$medium-spacer * 4});

            .tile-grid__item--r2 & {
                flex-direction: row;
            }
        }

        &__top {
        }

        &__heading {
            @include font-medium();
            color: $color-purple;
            font-size: 24px;
            line-height: 28px;
            padding-bottom: 16px;
            text-transform: none;

            @include for-print() {
                @include font(18px, 20px);
            }
        }

        &__abstract {
            @include font-content();
            color: $color-dark-grey;
            font-size: 16px;
            line-height: 20px;
            padding-bottom: 16px;

            @include for-print() {
                @include font(14px, 16px);
            }
        }

        &__bottom {
        }

        &__button-container {
            align-items: center;
            background-color: $color-black;
            border-radius: 24px;
            display: flex;
            justify-content: center;
            min-height: $button-min-height;
            margin: auto 0;
            transition: background-color ease-in-out 0.3s;
        }

        &__button {
            @include font-semibold();
            color: $color-white;
            font-size: 18px;
            line-height: 18px;
            // gimmicky way to limit the css rule to IE10+
            @media all and (-ms-high-contrast:none) {
                margin-top: 10px;
            }
        }
    }
}

.tile--contact-us {
    background-color: $color-white-smoke;

    @include for-print() {
        display: none;
    }

    &:focus-visible {
        @include focus-outline();
    }

    &.tile-grid__item--r1 {
        &.tile-grid__item--c2 {

            .tile {
                &__padding {
                    flex-direction: column;
                    height: calc(100% - #{$large-spacer * 4});
                    padding: #{$large-spacer * 2};

                    @include respond-to-container('xxs') {
                        flex-direction: row;
                    }

                    @supports not(container-type: inline-size) {
                        @include respond-to('xxs') {
                            flex-direction: row;
                        }
                    }
                }
            }
        }

        &.tile-grid__item--c3 {
            @include respond-to-container('small') {
                height: calc(#{$tile-height-increment} - #{$large-spacer});
            }

            @supports not(container-type: inline-size) {
                @include respond-to('largeipad') {
                    height: calc(#{$tile-height-increment} - #{$large-spacer});
                }
            }

            .tile {
                &__padding {
                    flex-direction: column;
                    height: calc(100% - #{$large-spacer * 2});
                    padding: #{$large-spacer};

                    @include respond-to-container('xs') {
                        flex-direction: row;
                        height: calc(100% - #{$large-spacer * 4});
                        padding: #{$large-spacer * 2};
                    }

                    @supports not(container-type: inline-size) {
                        @include respond-to('largeipad') {
                            flex-direction: row;
                            height: calc(100% - #{$large-spacer * 4});
                            padding: #{$large-spacer * 2};
                        }
                    }

                    @include respond-to-container('small') {
                        flex-direction: row;
                        height: calc(100% - #{48px * 2});
                        padding: 48px;
                    }

                    @supports not(container-type: inline-size) {
                        @include respond-to('largeipad') {
                            flex-direction: row;
                            height: calc(100% - #{48px * 2});
                            padding: 48px;
                        }
                    }
                }

                @include respond-to-container('xs') {
                    &__top {
                        width: 60%;
                        padding-right: 60px;
                    }

                    &__bottom {
                        width: 40%;
                    }
                }

                @supports not(container-type: inline-size) {
                    @include respond-to('largeipad') {
                        &__top {
                            width: 60%;
                            padding-right: 60px;
                        }

                        &__bottom {
                            width: 40%;
                        }
                    }
                }

                .tile-grid--two-column & {
                    flex-direction: column;
                    height: calc(#{$tile-height-increment} - #{$large-spacer * 2});
                    padding: #{$large-spacer};

                    @include respond-to-container('small') {
                        background-color: yellow;
                        flex-direction: row;
                        height: calc(#{$tile-height-increment} - #{$large-spacer * 4});
                        padding: #{$large-spacer * 2};
                    }

                    @supports not(container-type: inline-size) {
                        @include respond-to('largeipad') {
                            background-color: pink;
                            flex-direction: row;
                            height: calc(#{$tile-height-increment} - #{$large-spacer * 4});
                            padding: #{$large-spacer * 2};
                        }
                    }
                }
            }

            .tile-grid--one-column & {
                height: calc(#{$tile-height-increment * 2} - #{$large-spacer * 2});

                @include respond-to-container('xs') {
                    height: calc(#{$tile-height-increment} - #{$large-spacer});
                }

                @supports not(container-type: inline-size) {
                    @include respond-to('largeipad') {
                        height: calc(#{$tile-height-increment} - #{$large-spacer});
                    }
                }
            }
        }
    }

    &.tile-grid__item--r2 {

        .tile__padding {
            @include respond-to-container('xxs') {
                height: calc(100% - #{$large-spacer * 2});
                padding: $large-spacer;
            }

            @supports not( container-type: inline-size) {
                @include respond-to('xxs') {
                    height: calc(100% - #{$large-spacer * 2});
                    padding: $large-spacer;
                }
            }
        }

        &.tile-grid__item--c2,
        &.tile-grid__item--c3 {
            .tile__padding {
                @include respond-to-container('xxs') {
                    height: calc(100% - #{$large-spacer * 4});
                    padding: $large-spacer * 2;
                }

                @supports not( container-type: inline-size) {
                    @include respond-to('xxs') {
                        height: calc(100% - #{$large-spacer * 4});
                        padding: $large-spacer * 2;
                    }
                }
            }
        }
        // wide large
        &.tile-grid__item--c3 {
            .tile__header {
                @include font(24px, 28px);
            }

            .tile__abstract {
                @include font(16px, 20px);
            }
        }
    }

    &.tile-grid__item--r3 {

        &.tile-grid__item--c2 {
            .tile__padding {
                @include respond-to-container('xxs') {
                    height: calc(100% - #{$large-spacer * 4});
                    padding: $large-spacer * 2;
                }

                @supports not( container-type: inline-size) {
                    @include respond-to('xxs') {
                        height: calc(100% - #{$large-spacer * 4});
                        padding: $large-spacer * 2;
                    }
                }
            }
        }

        &.tile-grid__item--c3 {
            .tile__padding {
                @include respond-to-container('xxs') {
                    height: calc(100% - #{$large-spacer * 6});
                    padding: $large-spacer * 3;
                }

                @supports not( container-type: inline-size) {
                    @include respond-to('xxs') {
                        height: calc(100% - #{$large-spacer * 6});
                        padding: $large-spacer * 3;
                    }
                }
            }
        }
    }

    .tile {
        &__abstract {
            @include font-content();
            @include font(16px, 20px);
            color: $color-dark-grey;
        }

        &__bottom {
            height: 100%; //causes bottom to fill space if no top
            display: flex;
            justify-content: center;
            margin-left: 0;
            width: 100%;
        }

        &__button-container {
            align-items: center;
            display: flex;
            justify-content: center;
            margin: auto 0;
            width: 100%;
        }

        &__button {
            @include font-semibold();
            @include font(18px, 18px);
            background-color: $color-black;
            border: 1px solid $color-black;
            border-radius: 24px;
            color: $color-white;
            min-height: $button-min-height;
            transition: background-color ease-in-out 0.3s;
            width: max(100%, 300px);

            &--hoverable {
                cursor: pointer;
            }

            &--secondary {
                @include font-medium-italic();
                @include font(18px, 18px);
                background-color: $color-white-smoke;
                border: none;
                color: $color-black;
                font-style: italic;
            }

            &:focus-visible {
                @include focus-outline();
            }
        }

        &__header {
            @include font-medium();
            color: $color-purple;
            @include font(24px, 28px);
            padding-bottom: 8px;
            text-transform: none;
        }

        &__label {
            @include font-medium();
            @include font(14px);
            color: $color-white;
            margin-bottom: 8px;
            opacity: 0.75;
            text-transform: uppercase;
        }

        &__link {
            align-items: center;
            display: flex;
            height: inherit;
            justify-content: center;
        }

        &__padding {
            display: flex;
            flex-direction: column;
            height: calc(100% - #{$large-spacer * 2});
            padding: $large-spacer;

            &:not(&--clicks-disabled) {
                &:hover {
                    cursor: pointer;

                    .tile__button--hoverable {
                        background-color: $color-purple;
                        border-color: $color-purple;
                    }
                }
            }
        }
    }
}

*:not(.experience-editor) {
    .tile--hover {
        @include respond-to('xs') {
            &:hover,
            &:focus {
                .tile {
                    &__content {
                        opacity: 0;
                    }

                    &__content--hover {
                        opacity: 1;
                    }
                }
            }

            &:focus-visible {
                @include focus-outline();
            }
        }

        @include respond-to('xs', and, hover) {
            &:hover,
            &:focus {
                .tile {
                    &__image-overlay-container {
                        opacity: 0;
                    }
                }
                // Heading Sizes
                // Small 1x1
                // Medium Tall 1x2
                // Medium Wide 2x1
                &.tile-grid__item--c1.tile-grid__item--r1,
                &.tile-grid__item--c1.tile-grid__item--r2,
                &.tile-grid__item--c2.tile-grid__item--r1 {
                    .tile {
                        &__heading {
                            font-size: 28px;
                            line-height: 32px;

                            @include for-print() {
                                @include font(18px, 20px);
                            }
                        }
                    }
                }
                // Medium 2x2
                &.tile-grid__item--r2.tile-grid__item--c2 {
                    .tile {
                        &__heading {
                            font-size: 34px;
                            line-height: 38px;

                            @include for-print() {
                                @include font(18px, 20px);
                            }
                        }
                    }
                }
                // Extra Wide 3x1
                &.tile-grid__item--c3.tile-grid__item--r1 {
                    .tile {
                        &__heading {
                            font-size: 34px;
                            line-height: 38px;

                            @include for-print() {
                                @include font(18px, 20px);
                            }
                        }
                    }
                }
                // Extra Large 3x3
                // Large 2x3
                &.tile-grid__item--c3.tile-grid__item--r3,
                &.tile-grid__item--c2.tile-grid__item--r3 {
                    .tile {
                        &__heading {
                            font-size: 36px;
                            line-height: 40px;

                            @include for-print() {
                                @include font(18px, 20px);
                            }
                        }
                    }
                }
            }

            &:focus-visible {
                @include focus-outline();
            }
        }
    }
}

.tile--hover {
    // Heading Sizes
    // Small 1x1
    // Medium Tall 1x2
    // Medium Wide 2x1
    &.tile-grid__item--c1.tile-grid__item--r1,
    &.tile-grid__item--c1.tile-grid__item--r2,
    &.tile-grid__item--c2.tile-grid__item--r1 {
        .tile {
            &__heading {
                font-size: 24px;
                line-height: 28px;

                @include for-print() {
                    @include font(18px, 20px);
                }
            }
        }
    }
    // Medium 2x2
    &.tile-grid__item--r2.tile-grid__item--c2 {
        .tile {
            &__heading {
                font-size: 28px;
                line-height: 30px;

                @include for-print() {
                    @include font(18px, 20px);
                }
            }
        }
    }
    // Extra Wide 3x1
    &.tile-grid__item--c3.tile-grid__item--r1 {
        .tile {
            &__heading {
                font-size: 28px;
                line-height: 30px;

                @include for-print() {
                    @include font(18px, 20px);
                }
            }
        }
    }
    // Extra Large 3x3
    // Large 2x3
    // Wide Large 3x2
    &.tile-grid__item--c3.tile-grid__item--r3,
    &.tile-grid__item--c2.tile-grid__item--r3,
    &.tile-grid__item--c3.tile-grid__item--r2 {
        .tile {
            &__heading {
                font-size: 30px;
                line-height: 34px;

                @include for-print() {
                    @include font(18px, 20px);
                }
            }
        }
    }
    // Abstract Sizes
    // Small 1x1
    &.tile-grid__item--c1.tile-grid__item--r1 {
        .tile {
            &__body {
                @include font(16px, 18px);

                p {
                    @include font(16px, 18px);
                }

                @include for-print() {
                    @include font(14px, 16px);

                    p {
                        @include font(14px, 16px);
                    }
                }
            }
        }
    }
    // Medium Tall
    &.tile-grid__item--c1.tile-grid__item--r2 {
        .tile {
            &__body {
                @include font(18px, 20px);

                p {
                    @include font(18px, 20px);
                }

                @include for-print() {
                    @include font(14px, 16px);

                    p {
                        @include font(14px, 16px);
                    }
                }
            }
        }
    }
    // Medium Wide
    // Medium 2x2
    &.tile-grid__item--c2.tile-grid__item--r1,
    &.tile-grid__item--c2.tile-grid__item--r2 {
        .tile {
            &__body {
                @include font(18px, 20px);

                p {
                    @include font(18px, 20px);
                }

                @include respond-to('xs') {
                    @include font(18px, 22px);

                    p {
                        @include font(18px, 22px);
                    }
                }

                @include for-print() {
                    @include font(14px, 16px);

                    p {
                        @include font(14px, 16px);
                    }
                }
            }
        }
    }
    // Extra Wide 3x1
    &.tile-grid__item--c3.tile-grid__item--r1 {
        .tile {
            &__body {
                @include font(18px, 20px);

                p {
                    @include font(18px, 20px);
                }

                @include respond-to('xs') {
                    @include font(20px, 24px);

                    p {
                        @include font(20px, 24px);
                    }
                }

                @include for-print() {
                    @include font(14px, 16px);

                    p {
                        @include font(14px, 16px);
                    }
                }
            }
        }
    }
    // Large 2x3
    // Extra Large 3x3
    &.tile-grid__item--c3.tile-grid__item--r3 {
        .tile {
            &__body {
                @include font(18px, 20px);

                p {
                    @include font(18px, 20px);
                }

                @include respond-to('xs') {
                    @include font(24px, 30px);

                    p {
                        @include font(24px, 30px);
                    }
                }

                @include for-print() {
                    @include font(14px, 16px);

                    p {
                        @include font(14px, 16px);
                    }
                }
            }
        }
    }
    // Wide Large 3x2
    &.tile-grid__item--c3.tile-grid__item--r2 {
        .tile {
            &__body {
                @include font(18px, 22px);

                p {
                    @include font(18px, 22px);
                }

                @include respond-to('xs') {
                    @include font(22px, 28px);

                    p {
                        @include font(22px, 28px);
                    }
                }

                @include for-print() {
                    @include font(14px, 16px);

                    p {
                        @include font(14px, 16px);
                    }
                }
            }
        }
    }

    .tile {
        &__link {
            display: block;
            overflow: hidden;
            position: relative;
        }

        &__padding {
            height: calc(100% - #{$large-spacer * 2});
            padding: $large-spacer;
            width: calc(100% - #{$large-spacer * 2});
        }

        &__heading {
            @include font-medium();
            color: $color-white;
            padding-bottom: 16px;
            text-transform: none;
            transition: font-size $transition-timing-function $transition-duration, line-height $transition-timing-function $transition-duration;

            @include for-safari() {
                transition: none;
            }
        }

        &__media,
        &__content {
            height: 100%;
            width: 100%;
        }

        &__image-overlay-container {
            transition: opacity $transition-timing-function $transition-duration;
        }

        &__content {
            @include font-medium();
            display: block;
            left: 0;
            position: absolute;
            top: 0;
            transition: opacity $transition-timing-function $transition-duration;
            width: 100%;

            @include for-print() {
                opacity: 0;
            }
        }

        &__content--hover {
            opacity: 0;
            transition: opacity $transition-timing-function $transition-duration;

            @include respond-to('xs', and, hover) {
                background-color: rgba(100, 51, 128, 0.8);
                background-color: var(--background-color);
            }

            @include for-print() {
                background-color: rgba(0,0,0,0.45);
                opacity: 1;
            }
        }

        &__body,
        &__body p,
        &__body-cta {
            color: $color-white;
        }

        &__body {
            margin-bottom: 16px;
        }

        &__body-cta {
            .icon {
                font-size: 12px;
            }

            @include for-print() {
                display: none;
            }
        }

        &__body-cta-label {
            @include font-semibold();
            @include font(18px);
            margin-left: 8px;
        }
    }

    &.force-hover-on-touch-device {
        @include respond-to-max('xs', or, none) {
            .tile {
                &__content {
                    opacity: 0;
                }

                &__content--hover {
                    opacity: 1;
                }
            }
        }
    }
}

.experience-editor {
    .tile--hover {
        position: relative;

        .tile {
            &__content {
                opacity: 0;
            }

            &__content--hover {
                opacity: 1;
            }

            &__image {
                transform: none;
            }

            &__heading {
                transition: none;
            }
        }
    }
}
