.footnotes {
    @include for-print() {
        @include font(14px, 16px);
        margin-top: 24px;
    }

    p {
        @include for-print() {
            @include font(14px, 16px);
        }
    }

    h3 {
        @include font-heading();
        margin-top: 30px;
        margin-bottom: 12px;
    }

    ul {
        margin: 6px 0 10px;

        & > li {
            @include font-content();
            background: url("/contents/images/arrow-light-grey1.png") no-repeat scroll 0 5px;
            padding-left: 18px;
            margin-bottom: 8px;

            a {
                color: $color-black;
                text-decoration: underline;
            }
        }

        li:last-child {
            margin-bottom: 0;
        }
    }

    &__divider {
        border-bottom-width: 0px;
        margin: 38px auto 16px 0;
        max-width: 33%; 
    }

    &__large-divider {
        border-top: 2px solid $color-black;
        display: block;
        margin-top: 40px;
        padding-top: 40px;

        @include for-print() {
            display: none;
        }
    }
}
