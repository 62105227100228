//Fonts

//Base fonts
$font-header: 'FuturaW01-ExtraBoldCond';
$font-content: 'HCoWhitney';
$font-content-bold: 'HCoWhitney';
$font-whitney: 'HCoWhitney';
$font-sans-serif: arial;

//japanese fonts
$font-japanese-light: 'Yu Gothic Std-TT W50 L';
$font-japanese-content: 'Yu Gothic Pr6 R';
$font-japanese-medium: 'Yu Gothic Pr6N D Semi Bold';
$font-japanese-semibold: 'Yu Gothic Pr6 B';
$font-japanese-bold: 'Yu Gothic Std E Extra Bold';

//chinese fonts
//zh-cn 
$font-simplified-chinese-content:  'Noto Sans SC';
//zh-tw
$font-traditional-chinese-content:  'Noto Sans TC';

//russian fonts
$font-russian-bold: 'Frutiger Next Paneuropean Condensed Black';
//print
$font-japanese-print: 'MS Gothic';
$font-japanese-print-bold: 'MS Gothic ExtraBold';
$font-chinese-print: 'Hiragino Kaku Gothic Pro';

$font-whitney-old: 'whitneybook';
$font-whitney-semi-bold-old:'whitneysemibold';

//Font Sizes (In Flux, feel free to redefine as needed)
$font-size-base: 16px;
$font-size-xxsmall: 12px;
$font-size-xsmall: 14px;
$font-size-small: 16px;
$font-size-medium: 18px;
$font-size-large: 20px;
$font-size-xlarge: 22px;
$font-size-xxlarge: 24px;

//Column Size
$single-column-width: 940px;

//Line Heights
$line-height-base: 10px;

//page margin
$global-page-margin: 16px;


//z index
$z-index-neg3: -3;
$z-index-neg1: -1;
$z-index-0: 0;
$z-index-1: 1;
$z-index-10: 10;
$z-index-100: 100;
$z-index-1000: 1000;
$z-index-sidenav: 6000;
$z-index-nav: 7000;
$z-index-lightbox: 100000;

// TODO: add zindex for nav/toc

$focus-border: 5px solid #6ECBEC;


//transitions
$transition-timing: 0.3s;
$transition-function: ease-in-out;

//Accessibility
$button-min-height: 44px;