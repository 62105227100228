﻿.recent-resources {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 100%;
    width: 100vw;

    @include for-print() {
        display: none;
    }

    &--horizontal-full {
        @include respond-to('small') {
            background: $color-white-smoke;
        }
    }

    &__icon {
        color: $color-purple;
        font-size: 1.875em;
        margin-right: 1rem;

        &:before {
            background: $color-white;
            border-radius: 50%;
        }

        &--arrow {
            font-size: .75em;
            margin-right: .5em;
        }
    }

    &__title {
        @include font-heading();
        align-items: center;
        display: flex;
        font-size: 1em;
        text-transform: uppercase;
    }

    &__header {
        display: flex;
        margin-bottom: .75em;
        padding-left: 1.5em;
        padding-top: 2.25em;

        &--vertical {
            padding-top: 16px;
            padding-left: 1em;
        }
    }

    &__container {
        display: flex;
        flex-direction: column;
        max-width: 940px;
        width: 100%;

        @include respond-to('small') {
            flex-direction: row;
        }

        &--vertical {
            flex-direction: column;
            width: 100%;
        }

        &-list {
            background: #F0F0F0;
            flex: 1 auto;
            min-height: 100px;
            position: relative;

            &--horizontal,
            &--horizontal-full {
                & + & {
                    margin-top: 0.5rem;

                    @include respond-to('small') {
                        margin-top: initial;
                    }
                }

                @include respond-to('medium') {
                    max-width: 313px;
                    min-width: 275px;
                    width: 100%;
                }

                @include respond-to('small') {
                    width: 100%;
                }
            }

            &--vertical {
                margin-bottom: 8px;
                width: 100%;
            }
        }
    }

    &__list {
        margin-bottom: 4em;
        margin-left: 0;
        margin-right: 0;

        &_item {
            padding-left: 0;
            padding-right: 0;

            div {
                margin-left: .625em;
                margin-right: .625em;
                padding-left: 1em;
                padding-right: 1em;
            }
        }

        @include respond-to('small') {
            &_item {
                div {
                    margin-left: 0;
                    margin-right: 0;
                    padding-left: 0;
                    padding-right: 0;
                }
            }
        }

        a:last-child > &_item {
            border-bottom: none;
        }

        a {
            color: $color-black;
            width: 100%;
        }

        &--horizontal,
        &--horizontal-full {
            @include respond-to('small') {
                margin-bottom: 7em;
                margin-left: .625em;
                margin-right: .625em;
            }
        }

        &--vertical {
            margin-bottom: 16px;
        }
    }

    &__list_item {
        @include font-semibold();
        border-bottom: 2px $color-white solid;
        font-size: .875em;
        letter-spacing: 0.14px;
        padding: 1em;
        position: relative;

        &:hover {
            background-color: $color-very-light-grey;
        }
    }

    &__view-all {
        @include font-semibold();
        bottom: 1.5em;
        font-size: 1em;
        letter-spacing: 0.16px;
        margin-top: 25px;
        padding-left: 1.75em;
        position: absolute;

        @include respond-to('small') {
            margin-bottom: 2.5em;
            padding-left: 1em;
        }

        &--vertical {
            margin: 16px 0;
            position: static;
        }
    }

    &__no-message {
        display: flex;
        justify-content: center;
        align-self: center;
    }

    &__link {
        display: inline-block;
    }
}