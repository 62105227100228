﻿@use 'sass:color';

$side-nav-width: 440px;
$side-nav-width-mobile: 320px;

.side-navigation {
    background-color: $color-header;
    height: 100%;
    left: -$side-nav-width-mobile;
    overflow-x: hidden;
    overflow-y: auto;
    position: fixed;
    top: $mobile-nav-height;
    transition: 0.4s;
    width: $side-nav-width-mobile;
    z-index: $z-index-sidenav;

    @include respond-to-height('iphonese') {
        padding-bottom: 25px;
    }

    @include respond-to('small') {
        left: -$side-nav-width;
        top: 0px;
        width: $side-nav-width;
    }

    &--open {
        display: flex;
        left: 0;
    }

    &__close-btn {
        @include font(24px);
        color: color.adjust($color-white, $alpha: -0.25);
        cursor: pointer;
        display: none;
        position: absolute;
        right: 75px;
        top: 40px;
        z-index: #{$z-index-1000 * 2};

        @include hover-active {
            color: $color-white;
        }

        @include respond-to('small') {
            display: block;
        }

        @include for-print() {
            display: none;
        }
    }

    &__links-container {
        margin-top: 25px;
        position: relative;
        width: 100%;

        @include respond-to('small') {
            margin-top: 110px;
        }

        @include respond-to-height("iphonese") {
            height: calc(100vh + 250px);
            padding-bottom: 16px;
        }
    }

    &__links {
        @include font($font-default-line-height: initial);
        align-items: baseline;
        border-bottom: 2px solid rgba(255, 255, 255, 0.15);
        display: flex;
        flex-direction: column;
        padding: 0 8px 24px 16px;
        width: calc(100% - 24px);

        @include respond-to('small') {
            align-items: center;
            padding: 0 0 24px 0;
            width: 100%;
        }

        @include respond-to-height("iphonese") {
            padding-bottom: 16px;
        }

        &--secondary {
            padding: 20px 8px 20px 16px;

            @include respond-to('small') {
                padding: 20px 0;
            }

            @include respond-to-height("iphonese") {
                padding-bottom: 16px;
            }
        }
    }

    &__link {
        margin: 5.5px 0;
        text-align: left;

        @include respond-to('small') {
            margin: 16px 0;
            text-align: center;
        }

        a {
            @include font-content();
            @include font($font-default: 28px, $font-default-line-height: initial, $font-small: 24px);
            color: rgba(255, 255, 255, 0.75);
            transition: color 0.4s;

            @include hover-active {
                color: rgba(255, 255, 255, 1);
            }

            &:focus {
                @media (hover:none) and (pointer:coarse) {
                    outline: 2px dotted $color-black !important;
                }
            }
        }

        &--secondary {
            @include font($font-default-line-height: initial);
            align-items: baseline;
            text-align: left;
            width: auto;

            @include respond-to('small') {
                margin: 11px 0;
            }

            a {
                @include font-content();
                @include font($font-default: 24px, $font-small: 20px);
                color: rgba(255, 255, 255, 0.5);
                transition: color 0.4s;

                @include hover-active {
                    color: rgba(255, 255, 255, 1);
                }
            }
        }
    }

    &__social-links {
        align-items: center;
        display: flex;
        /*for ie*/
        justify-content: space-around;
        /*for moz & chrome*/
        -webkit-justify-content: space-evenly !important;
        margin-left: auto;
        margin-right: auto;
        margin-top: 30px;
        padding-bottom: 48px;
        max-width: $side-nav-width-mobile;
        transition: color 0.4s;

        @include respond-to('small') {
            margin-top: 48px;
        }

        @include respond-to-height("iphonese") {
            margin-top: 16px;
        }

        a {
            span {
                @include font($font-default: 38px, $font-small: 32px);
                color: hsla(0, 0%, 100%, 0.75);
                transition: color 0.4s;
            }

            @include hover-active {
                span {
                    color: $color-white;
                }
            }
        }
    }
}