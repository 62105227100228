@use 'sass:math';

$alternating-list-hover__gap: 16px;
$alternating-list-hover__breakpoint: 'xs';
$alternating-list-hover__width:  calc(50% - #{math.div($alternating-list-hover__gap, 2)});

.alternating-list-hover {
    @include print-background-images();
    padding: $alternating-list-hover__gap;

    @include for-print() {
        page-break-inside: avoid;
    }

    &:nth-child(odd) {
        background-color: $color-white-smoke;
    }

    &__heading {
        @include font(18px, 22px);
        @include font-semibold();

        @include for-print() {
            @include font(14px, 15px);
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        gap: $alternating-list-hover__gap;
        padding-top: $alternating-list-hover__gap;

        @include for-print() {
            @include font(14px, 16px);

            p {
                @include font(14px, 16px);
            }
        }

        @include respond-to($alternating-list-hover__breakpoint) {
            flex-direction: row;
        }

        @include for-print() {
            flex-direction: row;
        }

        &--reverse {
            flex-direction: column-reverse;

            @include respond-to($alternating-list-hover__breakpoint) {
                flex-direction: row-reverse;
            }

            @include for-print() {
                flex-direction: row-reverse;
            }
        }
    }

    &__half {
        @include richtext();
        width: 100%;

        @include respond-to($alternating-list-hover__breakpoint) {
            width: $alternating-list-hover__width;
        }

        @include for-print() {
            @include font(14px, 16px);
            width: $alternating-list-hover__width;

            P {
                @include font(14px, 16px);
            }
        }

        @supports not (gap: 1px) {
            margin-bottom: 0;

            &:nth-child(odd) {
                margin-bottom: $alternating-list-hover__gap;
            }

            @include respond-to($alternating-list-hover__breakpoint) {
                margin-bottom: 0;
                margin-right: 0;

                &:nth-child(odd) {
                    margin-bottom: 0;
                    margin-right: $alternating-list-hover__gap;
                }
            }

            @include for-print() {
                margin-bottom: 0;
                margin-right: 10px;
            }
        }

        &--min-height {
            height: 230px;

            @include respond-to($alternating-list-hover__breakpoint) {
                height: auto;
                min-height: 230px;
            }
        }
    }

    &__hover-tile {
        display: block;
        min-height: 230px;
        overflow: hidden;
        position: relative;
        position: relative;

        @include for-print() {
            overflow: visible;
        }

        &:focus,
        &:hover {
            .alternating-list-hover__image {
                transform: scale(1.2);

                @include for-print() {
                    transform: none;
                }
            }
        }

        @include respond-to($alternating-list-hover__breakpoint, and, hover) {
            &:focus,
            &:hover {
                .alternating-list-hover__image-overlay-container {
                    opacity: 0;

                    @include for-print() {
                        opacity: 1;
                    }
                }

                .alternating-list-hover__hover-tile-heading {
                    @include font(28px, 32px);

                    @include for-print() {
                        @include font(18px, 20px);
                    }
                }

                .alternating-list-hover__hover-tile-content {
                    opacity: 0;

                    @include for-print() {
                        opacity: 1;
                    }
                }

                .alternating-list-hover__hover-tile-content--hover {
                    opacity: 1;
                }
            }
        }

        &--forced-hover-on-touch-device {
            @include respond-to-max($alternating-list-hover__breakpoint, or, none) {
                .alternating-list-hover__hover-tile-content {
                    opacity: 0;

                    @include for-print() {
                        opacity: 1;
                    }
                }

                .alternating-list-hover__hover-tile-content--hover {
                    opacity: 1;
                }
            }
        }

        &--link {
            cursor: pointer;

            @include for-print() {
                cursor: unset;
            }
        }
    }

    &__media {
        height: 100%;
        opacity: 1;
        position: absolute;
        transition: opacity .3s ease-in-out;
        width: 100%;
    }

    &__image-container {
        display: inline-block;
        height: 100%;
        overflow: hidden;
        width: 100%;
    }

    &__image {
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: cover;
        height: 100%;
        transition: transform 0.3s ease-in;
        width: 100%;
    }

    &__image-overlay-container {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        transition: opacity 0.3s ease-in-out;
        width: 100%;
    }

    &__image-overlay {
        color: $color-white;
        transition: background-color 0.3s ease-in;
        padding: $large-spacer;
        height: calc(100% - #{$large-spacer * 2});

        &--gradient {
            background-image: linear-gradient(to bottom, rgba($color-black, 1), rgba($color-black, 0.75), rgba($color-black, 0));

            @include respond-to($alternating-list-hover__breakpoint, and, hover) {
                background-image: linear-gradient(to bottom, rgba($color-black, 1), rgba($color-black, 0));
            }
        }
    }

    &__hover-tile-content {
        @include font-medium();
        display: block;
        height: calc(100% - #{$alternating-list-hover__gap * 2});
        left: 0;
        padding: $alternating-list-hover__gap;
        position: absolute;
        top: 0;
        transition: opacity $transition-timing-function $transition-duration;
        width: calc(100% - #{$alternating-list-hover__gap * 2});

        &--hover {
            opacity: 0;
            transition: opacity $transition-timing-function $transition-duration;

            @include respond-to($alternating-list-hover__breakpoint, and, hover) {
                background-color: rgba(100, 51, 128, 0.8);
                background-color: var(--background-color);
            }

            @include for-print() {
                display: none;
            }
        }
    }

    &__hover-tile-heading {
        @include font-medium();
        color: $color-white;
        padding-bottom: 16px;
        text-transform: none;
        transition: font-size $transition-timing-function $transition-duration, line-height $transition-timing-function $transition-duration;

        @include for-print() {
            @include font(18px, 20px);
        }
    }

    &__hover-tile-cta {
        color: $color-white;

        @include for-print() {
            display: none;
        }

        .icon {
            font-size: 11px;
        }
    }

    &__hover-tile-cta-label {
        @include font(18px, 18px);
        @include font-semibold();
        margin-left: 12px;

        @include for-print() {
            @include font(14px, 18px);
        }
    }
}

.experience-editor {
    .alternating-list-hover__half img {
        height: 180px;
        width: 100%;
    }
}
