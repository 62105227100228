﻿.btn-halo {
    transition: all ease 0.2s;
    text-transform: uppercase;
    border: 2px solid #fff;
    background: transparent;
    min-width: 100px;
    border-radius: 0;
    color: #fff;
}

.btn-halo-dark {
    transition: all ease 0.2s;
    text-transform: uppercase;
    border: 2px solid #000;
    background: #000;
    min-width: 100px;
    border-radius: 0;
    color: #fff;
}

.btn-halo-dark.active,
.btn-halo-dark.active:focus,
.btn-halo-dark.active:hover,
.btn-halo-dark.active:active,
.btn-halo-dark.active:active:hover,
.btn-halo-dark:focus,
.btn-halo-dark:hover,
.btn-halo:focus,
.btn-halo:hover,
.btn-halo:active,
.btn-halo:active:hover,
.btn-halo.active,
.btn-halo.active:focus,
.btn-halo.active:hover,
.btn-halo.active:active,
.btn-halo.active:active:hover {
    color: #fff;
    border-color: #fff;
    background-color: $color-purple;
}

.btn-halo-dark.active,
.btn-halo-dark.active:focus,
.btn-halo-dark.active:hover,
.btn-halo-dark:focus,
.btn-halo-dark:hover {
    border-color: $color-purple;
}

.btn-halo-full {
    background: $color-purple;
    border-radius: 4px;
    color: #fff;
}

.input-halo {
    @include font-medium();
    text-transform: none;
    background: #fff;
    min-width: 100px;
    border-radius: 0;
    border: none;
    color: #000;
    font-size: 16px;
    height: 46px;
}

.input-halo:hover
.input-halo:focus,
.input-halo:active {
    background: #fff;
}

.input-halo-dark {
    @include font-heading();
    text-transform: uppercase;
    background: #fff;
    min-width: 100px;
    border-radius: 0;
    border: 2px solid #000;
    color: $color-black;
    font-size: 16px;
    height: 46px;
}

.dropdown.input-halo-dark,
.dropdown.input-halo {
    min-height: 46px;
}

.dropdown.input-halo-dark .dropdown-toggle,
.dropdown.input-halo-dark.open .dropdown-toggle,
.dropdown.input-halo .dropdown-toggle,
.dropdown.input-halo.open .dropdown-toggle {
    @include font-medium();
    width: 100%;
    border: none;
    color: #000;
    text-align: left;
    border-radius: 0;
    background: #fff;
    text-transform: none;
    position: relative;
    font-size: 16px;
    height: 100%;
    min-height: 46px;
}

.dropdown.input-halo-dark {
    border: none !important;
}

.dropdown.input-halo-dark .dropdown-toggle,
.dropdown.input-halo-dark.open .dropdown-toggle {
    border: 2px solid #000;
}

.dropdown.input-halo-dark .dropdown-toggle .selected-item,
.dropdown.input-halo .dropdown-toggle .selected-item {
    text-overflow: ellipsis;
    max-width: 90%;
    overflow: hidden;
    display: block;
}

.dropdown.input-halo-dark .dropdown-toggle .custom-dropdown-caret,
.dropdown.input-halo .dropdown-toggle .custom-dropdown-caret {
    position: absolute;
    top: 17px;
    right: 0;
}

.dropdown.input-halo-dark .dropdown-menu,
.dropdown.input-halo .dropdown-menu {
    border: 0;
    border-radius: 0;
    box-shadow: none;
    margin-top: 1px;
    width: 100%;
    padding: 0;
}

.dropdown.scrollable.input-halo-dark .dropdown-menu,
.dropdown.scrollable.input-halo .dropdown-menu {
    max-height: 150px;
    overflow: hidden;
    overflow-y: auto;
}

.dropdown.input-halo-dark .dropdown-menu {
    border: 2px solid #000;
    border-top: 1px;
    margin: 0;
}

.dropdown.input-halo-dark .dropdown-menu > li > a,
.dropdown.input-halo .dropdown-menu > li > a {
    @include font-medium();
    -moz-transition: none;
    -o-transition: none;
    -webkit-transition: none;
    transition: none;
    text-transform: none;
    background: #fff;
    line-height: 30px;
    padding: 0 10px;
    font-size: 14px;
    display: block;
    color: #666;
    overflow: hidden;
    -ms-text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
}

.dropdown.input-halo-dark .dropdown-menu > li > a.active,
.dropdown.input-halo .dropdown-menu > li > a.active,
.dropdown.input-halo-dark .dropdown-menu > li > a:focus,
.dropdown.input-halo .dropdown-menu > li > a:focus,
.dropdown.input-halo-dark .dropdown-menu > li > a:active,
.dropdown.input-halo .dropdown-menu > li > a:active,
.dropdown.input-halo-dark .dropdown-menu > li > a:hover,
.dropdown.input-halo .dropdown-menu > li > a:hover {
    color: #fff;
    background-color: $color-purple;
}
