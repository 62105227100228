﻿.event-materials-request {
    margin-bottom: 16px;

    &__label {
        @include font-medium();
        text-decoration: underline;

        &:focus-visible {
            @include focus-outline();
            color: $color-purple;
            text-decoration: none;
        }

        &:focus,
        &:hover {
            color: $color-purple;
            cursor: pointer;
            text-decoration: none;
        }
    }
}
