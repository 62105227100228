.mobile-location-landing-list {
    @include respond-to('xs') {
        display: none;
    }

    &__heading {
        @include font(16px, 20px);
        @include font-heading();
        color: $color-purple;
        margin-bottom: 16px;
        text-transform: uppercase;
    }

    &__region {
        padding-bottom: 16px;
    }

    &__region-heading {
        @include font(24px, 32px);
        @include font-heading();
        background: url(../images/icon-accordian-arrow.png) no-repeat 0 0;
        cursor: pointer;
        padding-bottom: 0;
        padding-left: 45px;
        text-transform: uppercase;

        &:hover,
        &:focus {
            background: url(../images/icon-accordian-arrow-hover.png) no-repeat 0 0;
            color: $color-purple;
        }

        &:focus-visible {
            @include focus-outline();
        }

        &--expand {

            &:hover,
            &:focus {
                background: url(../images/icon-accordian-arrow-active.png) no-repeat 0 0;
                color: $color-black;
            }
        }
    }

    &__offices {
        display: none;
        padding-top: 16px;
    }

    &__office {
        padding-bottom: 34px;
    }
}
