﻿.tile-grid {
    max-width: 100%;
    container-type: inline-size;

    &:after {
        content: '';
        display: block;
        clear: both;
    }

    &__heading {
        @include font-medium();
        @include font(24px, 28px);
        color: $color-purple;
        padding: 0 0 30px 0;

        @include respond-to('xs') {
            @include font(28px, 32px);
        }

        &--center {
            text-align: center;
        }

        &--right {
            text-align: right;
        }

        @include for-print() {
            @include font(18px, 22px);
        }
    }

    &__description {
        @include font-content();
        @include font(16px,20px);
        color: $color-dark-grey;
    }

    &__learnmore {
        @include font-content();
        color: $color-light-grey;
        font-size: 11px;
        margin-top: 40px;

        &-link {
            @include font-semibold();
            @include font(16px,20px);
            color: $color-black;
            padding-left: 5px;
        }

        &:hover {
            color: $color-purple;
        }

        @include for-print() {
            display: none;
        }
    }

    &__sizer,
    &__item {
        box-sizing: border-box;
        margin: 0 $medium-spacer;

        &--full {
            margin: $medium-spacer;
        }
    }

    &__item {
        height: $tile-height-increment;
        float: left;
        position: relative;

        &--r1 {
            height: $tile-height-increment;

            &.tile-grid__item--c2,
            &.tile-grid__item--c3 {
                height: #{$tile-height-increment * 2};

                @include respond-to('xxs') {
                    height: $tile-height-increment;
                }
            }
        }

        &--r2 {
            height: #{$tile-height-increment * 2};

            &.tile-grid__item--c1 {
                @include for-print() {
                    height: $tile-height-increment;
                }
            }
        }

        &--r3 {
            height: #{$tile-height-increment * 2};

            @include respond-to('xs') {
                height: #{$tile-height-increment * 3};
            }
        }
        // height rules for tiles that have background images/full height
        &--full {
            height: #{$tile-height-increment - ($medium-spacer * 2)};

            &.tile-grid__item--r1 {
                height: #{$tile-height-increment - ($medium-spacer * 2)};
            }

            &.tile-grid__item--r2 {
                height: #{($tile-height-increment * 2) - ($medium-spacer * 2)};
            }

            &.tile-grid__item--r3 {
                height: #{($tile-height-increment * 2) - ($medium-spacer * 2)};

                @include respond-to('xxs') {
                    height: #{($tile-height-increment * 3) - ($medium-spacer * 2)};
                }
            }
        }
    }
}

.tile-grid--one-column {
    .tile-grid {
        &__sizer,
        &__item {
            margin: 0;
        }

        &__sizer {
            width: 100%;
        }

        &__item {
            &--c1,
            &--c2,
            &--c3 {
                width: 100%;
            }
        }
    }
}

.tile-grid--two-column {
    .tile-grid {
        &__sizer {
            width: calc(100% - #{$large-spacer});
        }

        &__item {
            &--c1,
            &--c2,
            &--c3 {
                width: calc(100% - #{$large-spacer});
            }
        }

        @include respond-to('xxs') {
            &__sizer {
                width: calc(50% - #{$large-spacer});
            }

            &__item {
                &--c1 {
                    width: calc(50% - #{$large-spacer});
                }

                &--c2,
                &--c3 {
                    width: calc(100% - #{$large-spacer});
                }
            }
        }
    }
}

.tile-grid--three-column {
    .tile-grid {
        &__sizer {
            width: calc(100% - #{$large-spacer});

            @include for-print() {
                width: calc(50% - #{$large-spacer});
            }
        }

        &__item {
            &--c1,
            &--c2,
            &--c3 {
                width: calc(100% - #{$large-spacer});
            }
        }

        &__item--c1.tile-grid__item--r2 {
            &:not(.tile--hover) {
                height: #{$tile-height-increment * 2};
            }

            @include for-print() {
                width: calc(100% - #{$large-spacer});
            }
        }

        &__item--c3.tile-grid__item--r1 {
            width: calc(100% - #{$large-spacer});

            @include respond-to('xs') {
                width: calc(100% - #{$large-spacer * 1.5});
            }
        }

        @include respond-to('xxs') {
            &__sizer {
                width: calc(50% - #{$large-spacer});
            }

            &__item {
                &--c1 {
                    width: calc(50% - #{$large-spacer});
                }

                &--c2,
                &--c3 {
                    width: calc(100% - #{$large-spacer});
                }
            }
        }

        @include respond-to('xs') {
            &__sizer {
                width: calc(33% - #{$large-spacer});
            }

            &__item {
                &--c1 {
                    width: calc(33% - #{$large-spacer});

                    @include for-print() {
                        width: calc(50% - #{$large-spacer});
                    }
                }

                &--c2 {
                    width: calc(66% - #{$large-spacer});


                    @include for-print() {
                        width: calc(100% - #{$large-spacer});
                    }
                }

                &--c3 {
                    width: calc(100% - #{$large-spacer});

                    @include for-print() {
                        width: calc(100% - #{$large-spacer});
                    }
                }
            }
        }
    }
}

@media only screen and (max-width : 480px) {
    .container .layout-mobile .col-l .tile-grid {
        margin: 0;
    }
}
