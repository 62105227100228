.mini-tab {

    @include for-print() {
        display: block !important;
        margin-top: 22px;
    }

    &__panel {
        padding: 26px 16px 16px;
        width: calc(100% - 32px);

        // This updates the padding rule on the same default breakpoint as the responsive-component-margin mixin. because we don't have a great way to handle this with the breakpoint mixins.
        @media (max-width: #{940 + ($global-page-margin * 2) + 1}) {
            padding: 26px 0 16px;
        }
        @include for-print() {
            padding: 0;
        }
    }
}
