﻿/* Classes used in the Rich Text Editor's Apply Custom CSS dropdown 
   These classes need to be duplicated in \Project\MorganLewis\website\default.css to have any effect on the RTE */

.divider {
    background-color: #DCDCDC;
    border: 0 none;
    clear: both;
    display: block;
    height: 1px;
    float: none;
    margin: 32px auto;
    width: 100%;
}

