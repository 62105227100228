$spacer-spacing-amount-print-reduction-factor: 0.4;

.spacer {
    margin-top: var(--spacingAmount);

    @include for-print() {
        margin-top: calc(var(--spacingAmount) * #{$spacer-spacing-amount-print-reduction-factor});
    }

    &--divider {
        border-top: 2px solid $color-black;
        margin-bottom: calc(var(--spacingAmount) / 2);
        margin-top: calc(var(--spacingAmount) / 2);

        @include for-print() {
            border: none;
            margin-bottom: 0;
            margin-top: calc(var(--spacingAmount) * #{$spacer-spacing-amount-print-reduction-factor});
        }
    }
}
