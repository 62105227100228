$print-header-icon-size: 28px;

.print-header {
    background-color: $color-purple;
    border-bottom: 3px solid $color-black;
    display: flex;
    height: 43px;
    flex-direction: row;
    justify-content: center;
    width: 100%;

    &__container {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        width: 650px;
    }

    &__buttons {
        align-items: center;
        display: flex;
        gap: 16px;
        flex-direction: row;
    }

    &__button {
        all: unset;
        color: $color-white;
        cursor: pointer;
        height: $print-header-icon-size;
        width: $print-header-icon-size;

        svg {
            height: $print-header-icon-size;
            width: $print-header-icon-size;

            .bg {
                fill: transparent;
                stroke: $color-white;
                stroke-width: 3;
            }

            .st0 {
                fill: $color-white;
            }
        }
    }

    @media print {
        display: none;
    }
}
