.alternating-list {
    &__header {
        border: none;
        margin-bottom: 20px;
        padding-bottom: 9px;

        @include for-print() {
            padding-bottom: 0;
        }

        h2 {
            color: #000;
            font-size: 36px;
            text-align: left !important;
            text-transform: uppercase;
            margin-top: 0;

            @include for-print() {
                @include font(18px, 19px);
            }
        }
    }

    &__abstract {
        @include for-print() {
            @include font(14px, 16px);

            p {
                @include font(14px, 16px);
            }
        }
    }

    &__listings {
        overflow: hidden;
        transition: height 0.3s ease-out;
    }

    &__container {
        @include chevron-bullet();
        display: inline-block;
        margin-bottom: 8px;
        transition: all $transition-timing $transition-function;
        width: 100%;

        @include for-print() {
            @include font(14px, 16px);
        }

        &.expandable {
            margin-top: 24px;

            &:nth-child(odd) {
                background-color: $color-white-smoke;

                .alternating-list-expandable-item__link {
                    i, &-label {
                        color: $color-black;
                    }
                }

                &:not(:first-child) {
                    margin-top: 0;
                }
            }
        }

        &:nth-child(odd) {
            background-color: $color-white-smoke;

            .alternating-list-expandable-item__image {
                float: left;
                margin: 0 20px 20px 0;
            }

            .alternating-list-item__image-container {
                float: left;
                margin: 0 20px 20px 0;
            }
        }

        &:not(.expandable) {
            &:active, &:focus, &:hover {
                background-color: $color-gray85;
            }
        }
    }

    &__trigger {
        @include showmore-trigger--small();

        @include for-print() {
            display: none;
        }
    }

    &__trigger-label {
        @include showmore-trigger-label--small();
    }
}

.experience-editor {
    .accordion__panel {
        height: auto;
    }
}
