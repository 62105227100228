﻿/*/////////
These classes are here to add default styles to dropdown-menu elements that are created by PopperJS
PopperJS is used in SelectorDropdown.js to handle logic for opening and closing the tray without
it displaying out of bounds of the viewscreen.
https://www.npmjs.com/package/@popperjs/core
/////////*/
.vue__dropdown-menu {
    @include font-medium();
    border: 2px solid #000;
    overflow-x: hidden;
    padding: 0;
    z-index: 997;

    .vs__dropdown-option {
        @include font-medium();
        @include font(14px, 1.25em);
        height: auto;
        margin: 0;
        min-height: 20px;
        padding: 5px 10px;
        position: relative;
        white-space: normal;
        width: 100%;

        &:hover,
        &:focus,
        &:active,
        &--highlight {
            background-color: $color-purple;
        }
    }
}
