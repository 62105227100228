@mixin separator-after {
    content: '';
    border-right: 2px solid $color-light-grey;
    margin-left: 8px;
    margin-right: 8px;
    font-size: 12px;
}

@mixin hover-active{
    &:hover, &:active, &:focus {
        @content;
    } 
}