$case-study-carousel-pagination-item__icon-size: 16px;
$case-study-carousel-pagination-item__icon-border-size: 2px;
$case-study-carousel__slides-container__max-height: 570px;
$case-study-carousel__slides-container__top-padding: 30px;
$case-study-carousel__slides-container__bottom-padding: 6px;

.case-study-carousel {
    overflow: hidden;

    @include respond-to('small') {
        overflow: visible;
    }

    &__wrapper {
        overflow: hidden;

        @include respond-to('small') {
            overflow: visible;
        }

        .swiper-wrapper {
            @include for-print() {
                display: flex;
                flex-direction: column;
                flex-wrap: nowrap;
                transform: none !important;
                transition: none;
            }
        }
    }

    &__heading {
        @include font-heading();
        @include font(24px, 25px);
        color: $color-black;
        margin-bottom: 16px;
        text-transform: uppercase;

        @include for-print() {
            @include font(20px, 24px);
            font-weight: 400;
        }
    }

    &__abstract,
    &__abstract > p {
        @include richtext();
        @include font(16px, 19px);
        margin-bottom: 16px;
    }

    &__pagination {
        display: flex;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        margin-top: 24px;
        width: 100%;

        @include for-print() {
            display: none;
        }

        &-item {
            @include font(20px, $font-small: 18px);
            background-color: white;
            border: $color-black $case-study-carousel-pagination-item__icon-border-size solid;
            border-radius: $case-study-carousel-pagination-item__icon-size;
            color: rgba(255, 255, 255, 0.75);
            cursor: pointer;
            height: $case-study-carousel-pagination-item__icon-size;
            margin-left: 8px;
            margin-right: 8px;
            transition: 0.4s;
            width: $case-study-carousel-pagination-item__icon-size;

            &--active {
                color: rgba(255, 255, 255, 1);
                background-color: black;
            }

            @include hover-active {
                color: rgba(255, 255, 255, 1);
            }

            &:focus-visible {
                @include focus-outline();
            }
        }
    }

    &__slides {
        height: $case-study-carousel__slides-container__max-height;
        padding-bottom: $case-study-carousel__slides-container__bottom-padding;
        padding-top: $case-study-carousel__slides-container__top-padding;

        @include for-print() {
            padding: 8px 0;
            height: auto;
        }

        &-background {
            background: transparent linear-gradient(180deg, $color-gray97 0%, $color-white 100%) 0% 0% no-repeat padding-box;
            height: $case-study-carousel__slides-container__max-height;
            left: 0;
            margin-bottom: -$case-study-carousel__slides-container__bottom-padding;
            margin-top: -$case-study-carousel__slides-container__top-padding;
            position: absolute;
            right: 0;
            width: 100%;

            @include for-print() {
                display: none;
            }
        }

        .experience-editor & {
            height: 100%;
        }
    }
}

