﻿@use 'sass:color';

.header-close {
    align-content: center;
    align-items: center;
    display: flex;
    justify-content: center;
    margin-left: 31px;

    @include respond-to('small') {
        margin-left: 10px;
    }

    @include for-print() {
        display: none;
    }

    &--hide-in-mobile {
        display: none;

        @include respond-to('small') {
            display: flex;
        }
    }

    &__button {
        @include font(24px);
        background: transparent;
        border: none;
        color: color.adjust($color-white, $alpha: -0.25);
        cursor: pointer;
        padding: 0;
        transition: color $transition-timing $transition-function;

        @include hover-active {
            color: $color-white;
        }
    }
}
