.section-image-header {
    position: relative;

    @include for-print() {
        page-break-inside: avoid;
    }

    & + & {
        @include for-print() {
            padding-top: 20px;
        }
    }

    &__description {
        @include richtext(18px, 26px);

        @include for-print() {
            @include font(14px, 16px);

            div, span, li, p {
                @include font(14px, 16px);
            }
        }
    }

    &__description,
    &__heading,
    &__intro {
        margin: 0 auto;
        padding-left: 0;
        padding-right: 0;
        max-width: $structure-normal;
    }

    &__heading,
    &__intro {
        padding-bottom: 16px;
    }

    &__fullcontainer {
        padding-top: 100px;
        position: relative;

        @include respond-to('small') {
            padding-top: 180px;
        }

        .structure-one-column--wide & {
            @include respond-to('contentLayout') {
                padding-top: 226px;
            }
        }

        @include for-print() {
            margin: 0;
            padding: 0;
        }
    }

    &__heading {
        @include font-heading();
        @include font(24px, 25px);
        color: $color-black;
        margin: 0 auto;
        padding-top: 5%;
        text-transform: uppercase;

        @include for-print() {
            @include font(18px, 19px);
            margin: 0;
            padding: 0;
        }

        @include respond-to('xxs') {
            @include font(28px, 29px);
        }

        @include respond-to('small') {
            @include font(32px, 33px);
        }

        .structure-one-column--wide & {
            padding-top: 32px;

            @include respond-to('contentLayout') {
                padding-top: 56px;
            }
        }
    }

    &__image {
        background-position-x: center;
        background-position-y: top;
        background-repeat: no-repeat;
        background-size: cover;
        height: 100%;
        width: 100%;

        &-container {
            bottom: 0;
            left: 0;
            max-height: 140px;
            overflow: hidden;
            position: absolute;
            right: 0;
            top: 0;

            @include respond-to('small') {
                max-height: 260px;
            }

            .structure-one-column--wide & {
                @include respond-to('contentLayout') {
                    max-height: 500px;
                }
            }
        }

        @include for-print() {
            display: none;
        }
    }

    &__inset-container {
        background-color: $color-white;
        margin: auto;
        min-height: 175px;
        position: relative;
        text-align: left;
        width: 100%;
        max-width: #{$structure-narrow + 80};

        @include for-print() {
            margin: 0;
            min-height: 0;
            padding: 0;
            width: 100%;
        }

        .structure-one-column--wide & {
            @include respond-to('contentLayout') {
                max-width: $structure-normal;
            }
        }
    }

    &__inset-content {
        max-width: $structure-narrow;

        @include responsive-component-margin-narrow($global-page-margin);
    }

    &__intro {
        @include font-medium();
        @include font(20px, 28px);
        color: $color-dark-grey;
        letter-spacing: 0.1px;

        &--purple-para,
        &--purple-para > * {
            color: $color-purple;
            font-weight: 500;
        }

        p {
            @include font-medium();
            @include font(20px, 28px);
            letter-spacing: 0.1px;

            @include for-print() {
                @include font(14px, 16px);
                font-weight: 400;
            }
        }

        @include for-print() {
            @include font(14px, 16px);
            margin: 16px 0 0 0;
            padding: 0;
        }

        @include respond-to('small') {
            .structure-one-column--wide &,
            .structure-one-column--wide & p {
                @include font(22px, 30px);
            }
        }
    }
}
