/* Classes used in the Rich Text Editor's Apply Custom CSS dropdown 
   These classes need to be duplicated in \Project\MorganLewis\website\default.css to have any effect on the RTE */

.rte-link {
    margin: 20px 0 10px 0;

    a {
        @include font-semibold();
        background: url(../images/arrow-light-grey1.png) no-repeat 0 8px;
        color: #000;
        display: block;
        padding-left: 15px;
        text-decoration: none;

        @include for-print() {
            background: url(../images/arrow-light-grey1.png) no-repeat 0 3px;
        }
    }
}
