.card {
    @include print-background-images();
    background-color: transparent;
    cursor: pointer;
    filter: drop-shadow(0px 0px 6px $color-dropshadow);
    float: left;
    height: 125px;

    margin: {
        bottom: 8px;
        left: 4px;
        right: 4px;
    }

    min-width: 125px;
    perspective: 1000px; /* Remove this if you don't want the 3D effect */
    position: relative;
    width: 125px;

    @include for-print() {
        cursor: unset;
        filter: unset;
        perspective: unset;
    }

    &__icon {
        width: 30px;
        height: auto;
        position: absolute;
        right: 0;
        left: 0;
        margin: auto;
        font-size: 1.6em;
        bottom: 4px;

        @include for-print() {
            opacity: 0;
        }

        .bg {
            opacity: .2;
            transition: opacity .3s ease-in-out;
        }

        .fg {
            fill: white;
        }
    }

    &__abstract {
        @include font-medium();
        @include font(16px, 18px);
        color: $color-white;

        p {
            color: inherit;
        }
    }

    &__addition-content {
        display: flex;
        flex-direction: column;
        padding: 8px;
        height: calc(100% - 16px);
        width: calc(100% - 16px);

        p {
            color: inherit;
        }

        @include for-print() {
            display: none;
        }
    }

    &__content {
        background-color: $color-black;

        &--has-image {
            @include for-print() {
                background-color: transparent !important;
            }
        }

        .card__front-content {
            display: flex;
            flex-direction: column;
            height: calc(100% - 32px);
            padding: 16px;
        }
    }

    &__image {
        height: 140px;
        object-fit: cover;
        width: 140px;
        transition: opacity .3s ease-in-out;
    }

    &__inner {
        background-color: transparent;
        height: 100%;
        position: relative;
        text-align: center;
        transition: transform 0.8s;
        transform-style: preserve-3d;
        width: 100%;

        @include respond-to('xs') {
            background-color: $color-black;
        }
        /* iPad 1, 2, Mini and Air */
        @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
            background-color: transparent;
        }
        /* iPad 3, 4 and Pro 9.7" */
        @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
            background-color: transparent;
        }
    }

    &__title {
        @include font(18px, 20px);
        @include font-heading();
        color: $color-white;
        margin-bottom: 8px;
    }

    @at-root .card-grid--flip & {
        margin-bottom: 0;
        overflow: visible;

        &__content {
            color: $color-black;
            height: 100%;
            width: 100%;
        }

        &.card--active .card__inner {
            transform: rotateY(180deg);
        }

        @media (hover: hover) {
            &:hover .card__image {
                transition: opacity .3s ease-in-out;
                opacity: .82;
            }

            &:hover .card__icon .bg {
                transition: opacity .3s ease-in-out;
                opacity: 1;
            }

            &:hover .card__content {
                transition: opacity .3s ease-in-out;
                opacity: .82;

                @include for-print() {
                    transform: unset;
                }
            }
        }

        &__addition-content {
            background-color: $color-purple;
            color: $color-white;
            line-height: 20px;
            transform: rotateY(180deg);
        }

        &__addition-content,
        &__content {
            backface-visibility: hidden;
            position: absolute;
        }
    }

    @at-root .card-grid--slide-up & {
        overflow: hidden;

        &__content {
            height: 100%;
        }

        &__addition-content {
            bottom: 0;
            left: 0;
            position: absolute;
            top: auto;
            transform: translateY(100%);
            transition: 0.4s;
        }

        &.card--active .card__inner {
            .card__addition-content {
                transform: translateY(0px);
            }
        }

        @media (hover: hover) {
            &:hover .card__image {
                transition: opacity .3s ease-in-out;
                opacity: .82;
            }

            &:hover .card__icon .bg {
                transition: opacity .3s ease-in-out;
                opacity: 1;
            }

            &:hover .card__content {
                transition: opacity .3s ease-in-out;
                opacity: .82;

                @include for-print() {
                    transform: unset;
                }
            }
        }

        &__addition-content {
            background-color: $color-black;
            height: calc(100% - 5px);
            padding: 2.5px;
            top: 0;
            width: calc(100% - 5px);
        }
    }
}
