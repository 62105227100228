﻿.person-audio-clip {
    &__audio-container {
        height: 0;
        overflow: hidden;
        opacity: 0;
        position: relative;
        transition: all 0.3s ease-in-out;
        width: 100%;
        z-index: 2;

        @include respond-to('xs') {
            position: absolute;
        }

        &.open {
            height: 40px;
            opacity: 100;

            .person-audio-clip__name-label,
            .person-audio-clip__intro-label {
                height: 0;
                opacity: 0;
            }
        }

        .icon-cross {
            cursor: pointer;
            display: inline-block;
            height: 15px;
            margin-left: 8px;
            transform: translateY(calc(-50% - 5px));
            width: 15px;
        }

        audio {
            height: 100%;
            max-width: 300px;
            width: calc(100% - 30px);

            @include respond-to('xs') {
                max-width: 350px;
            }
        }
    }

    &__container {
        display: flex;
        flex-direction: column;
        height: auto;
        padding-bottom: 8px;

        .open + .person-audio-clip__name-label,
        .open + .person-audio-clip__intro-label {
            height: 0;
            opacity: 0;

            @include respond-to('xs') {
                transform: translateY(40px);
            }
        }

        &:not(:first-child) {
            margin-top: 16px;
        }

        @include respond-to('xs') {
            flex-direction: initial;
            height: 40px;

            &:not(:first-child) {
                margin-top: initial;
            }
        }
    }

    &__name-label {
        @include respond-to('xs') {
            margin-right: 30px;
        }
    }

    &__intro-label {
        margin-top: 8px;

        @include respond-to('xs') {
            margin-top: initial;
        }
    }

    &__intro-label,
    &__name-label {
        @include font-semibold();
        cursor: pointer;
        height: 40px;
        transition: all 0.3s ease-in-out;

        .icon-audio-black {
            font-size: 13px;
        }
    }
}
