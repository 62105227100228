﻿.career-body {
    .main-heading h1 {
        color: #000;
        font-size: 35px;
    }

    .top-level-cont {
        margin: 0;
        padding: 0;
    }

    p.heading-brief,
    .top-level-cont .top-level-two-col .col-l p {
        color: #000;
        line-height: 1.2;
        font-size: 16px;
        padding-bottom: 0px;
        margin-bottom: 0px;
    }

    .top-level-cont {
        border-bottom-style: none !important;
    }

    .collapse-box {
        padding: 0;
    }

    .print-wrapper .collapse-box {
        border-bottom-style: none !important;
        padding: 0;
    }

    .print-wrapper .stacked-media-module-main-video-holder,
    .print-wrapper .stacked-media-module-main-audio-holder {
        cursor: none;
        pointer-events: none;
    }

    .print-wrapper .ml-playlist.vertical {
        background: none;
        margin-bottom: 30px;
    }

    @media print {
        .ml-playlists .ml-playlist-row .ml-playlist-videos-container .ml-playlist-videos .ml-media-block {
            display: inline-block !important;
            width: 25% !important;
            padding: 0 4px 0 4px !important;
            white-space: normal !important;
            vertical-align: top !important;
        }
    }
}
