.animated-number {
    @include font-heading();
    @include font(56px, 57px);
    margin-top: 16px;

    @include respond-to('largeipad') {
        @include font(56px, 57px);
    }

    @include respond-to("xs") {
        margin-top: 0px;
    }

    @include for-print() {
        font-size: 36px;
        line-height: 37px;
    }
}
