ul li span.li-arrow {
    background: url(/contents/images/arrow-light-grey1.png) no-repeat scroll 0 4px;
    -webkit-print-color-adjust: exact !important;
    overflow: visible;
    height: 18px;
    width: 18px;
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;

    &-act {
        display: none;
        background: url(/Contents/images/arrow-light-grey1-active.png) no-repeat scroll 0 5px;
        -webkit-print-color-adjust: exact !important;
        overflow: visible;
        height: 18px;
        width: 18px;
        position: absolute;
        top: 0;
        left: 0;
    }

    & + ul {
        padding-left: 0;
    }

    & + a {
        display: inline-block;
        padding-left: 18px;
        max-width: calc(100% - 22px);
    }

    @include for-print() {
        background: url(/contents/images/arrow-light-grey1.png) no-repeat scroll 0 3px;
    }
}

.person-depart-info > ul > li {

    & > a:hover span.li-arrow-act {
        display: inline-block;
    }

    span.li-arrow {
        top: -1px;
    }
}

.print-wrapper {
    span.li-arrow-print {
        display: none;
    }

    .subsection-list ul li span.li-arrow,
    ul li span.li-arrow-print {
        top: 2px;
    }

    .person-depart-info > ul > li span.li-arrow,
    .side-section-listing > ul > li span.li-arrow,
    .side-section-listing > ul > li a:hover span.li-arrow-act,
    .collapse-expand-cont > ul > li span.li-arrow {
        display: none;
    }
}

.side-section-listing {
    ul {
        li {
            a {
                &:hover {
                    span.li-arrow-act {
                        display: block;
                    }
                }
            }
        }
    }
}