﻿.graphic-button-container {
    border-bottom: 2px solid #000000;
    padding-bottom: 20px;
    @include for-print() {
        display: none;
    }
}

.contact-list-container + .graphic-button-container {
    margin-top: -16px;
}

.graphic-button-container + .person-depart-info, .graphic-button-container + .side-section-listing  {
    margin-top: 36px;
}

.graphic-button {
    align-items: flex-start;
    cursor: pointer;
    display: flex;
    min-height: 76px;
    overflow: hidden;
    position: relative;

    &:hover,
    &:focus {
        .graphic-button__image {
            transform: scale(1.2);
        }
        .graphic-button__image-overlay {
            background-color: rgba(0,0,0,0.35);
        }
    }

    &__image-container {
        display: inline-block;
        height: 100%;
        overflow: hidden;
        position: absolute;
        width: 100%;
    }

    &__image {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 100%;
        transition: transform 0.3s ease-in;
        width: 100%;
    }

    &__image-overlay {
        background-color: rgba(0,0,0,0.25);
        height: 100%;
        position: absolute;
        transition: background-image 0.3s ease-in;
        width: 100%;
    }

    + .graphic-button {
        margin-top: 20px;
    }

    &__content-container {
        padding: 16px;
        z-index: 1;
    }

    &__content-line-one {
        @include font-heading();
        color: $color-white;
        font-size: 22px;
        letter-spacing: 0.55px;
        line-height: 25px;
        text-transform: uppercase;
    }

    &__content-line-two {
        @include font-medium();
        color: $color-white;
        font-size: 16px;
        line-height: 19px;
        text-transform: uppercase;
    }
}