$mini-tabs__labels-gap: 2px;
$mini-tabs__labels-spacing: 8px;

.mini-tabs {
    @include responsive-component-margin(0);

    @include respond-to('small') {
        @include responsive-component-margin($global-page-margin);
    }

    &__labels-container {
        background-color: $color-ml-light-grey;
    }

    &__labels {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        gap: $mini-tabs__labels-gap;
        padding: $mini-tabs__labels-spacing $mini-tabs__labels-spacing 0;

        @include for-print() {
            display: none;
        }
    }

    &__select-container {
        background-color: #eee;
        padding: 32px 16px 0 16px;

        @include for-print() {
                display: none;
        }
    }

    &__select, .dropdown-vue--mobile {
        background-color: $color-white;
        width: 100%;
    }

    &__label {
        @include font-semibold();
        @include font(14px, 16px);
        cursor: pointer;
        flex-grow: 1;
        min-height: 39px;
        margin: 0px;
        padding: 10px;
        text-align: center;

        &.is-active {
            background-color: $color-gray92;

            span {
                color: $color-purple;
            }
        }

        &:hover:not(.is-active) {
            background-color: rgba($color-gray92, 0.5);

            span {
                color: $color-purple;
            }
        }

        &:focus-visible {
            @include focus-outline();
        }

    }

    &__last {
        flex-grow: 1000;
    }

    &__panels-container {
        background-color: $color-gray92;

        @include for-print() {
            background-color: transparent;
        }
    }

    .dropdown-vue--mobiletabs__select {
        appearance: none;
        background: url(../images/selectArrow.png) no-repeat right;
        border: 2px solid $color-black;
        color: #666;
        font-size: 1em;
        height: 42px;
        line-height: 30px;
        padding-left: 10px;
        width: 100%;
    }
}
