﻿.onirow {
    display: flex;
    overflow: hidden;
    width: 100%;

    &--centered {
        justify-content: center;
    }
}

