@use "sass:selector";

@mixin respond-to($breakpoint, $operator: and, $hover: null) {
    @if map-has-key($breakpoints, $breakpoint) and map-has-key($any-hovers, $hover) {
        @if ($operator == and) {
            @media (min-width: #{map-get($breakpoints, $breakpoint) + 1}) and (any-hover: map-get($any-hovers, $hover)) {
                @content;
            }
        }
        @else {
            @media (min-width: #{map-get($breakpoints, $breakpoint) + 1}), (any-hover: map-get($any-hovers, $hover)) {
                @content;
            }
        }
    }
    @else if map-has-key($breakpoints, $breakpoint) {
        @media (min-width: #{map-get($breakpoints, $breakpoint) + 1}) {
            @content;
        }
    }
    @else {
        @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}` or no value could be retrieved from `#{any-hovers}`. " + "Available breakpoints are: #{map-keys($breakpoints)}. Available any-hover values are #{map-keys($any-hovers)}.";
    }
}

@mixin respond-to-max($breakpoint, $operator: and, $hover: null) {
    @if map-has-key($breakpoints, $breakpoint) and map-has-key($any-hovers, $hover) {
        @if ($operator == and) {
            @media (max-width: map-get($breakpoints, $breakpoint)) and (any-hover: map-get($any-hovers, $hover)) {
                @content;
            }
        }
        @else {
            @media (max-width: map-get($breakpoints, $breakpoint)), (any-hover: map-get($any-hovers, $hover)) {
                @content;
            }
        }
    }
    @else if map-has-key($breakpoints, $breakpoint) {
        @media (max-width: map-get($breakpoints, $breakpoint)) {
            @content;
        }
    }
    @else {
        @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}` or no value could be retrieved from `#{any-hovers}`. " + "Available breakpoints are: #{map-keys($breakpoints)}. Available any-hover values are #{map-keys($any-hovers)}.";
    }
}

@mixin respond-to-range($breakpoint-min, $breakpoint-max) {
    @if (map-has-key($breakpoints, $breakpoint-min) and map-has-key($breakpoints, $breakpoint-max)) {
        @media (min-width: map-get($breakpoints, $breakpoint-min)) and (max-width: map-get($breakpoints, $breakpoint-max)) {
            @content;
        }
    }
    @else {
        @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. " + "Available breakpoints are: #{map-keys($breakpoints)}.";
    }
}

@mixin respond-to-height($breakpoint) {
    @if map-has-key($height-breakpoints, $breakpoint) {
        @media (max-height: #{map-get($height-breakpoints, $breakpoint) + 1}) {
            @content;
        }
    }
    @else {
        @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. " + "Available breakpoints are: #{map-keys($height-breakpoints)}.";
    }
}

@mixin respond-to-container($breakpoint, $operator: and, $hover: null) {
    @if map-has-key($container-breakpoints, $breakpoint) and map-has-key($any-hovers, $hover) {
        @if ($operator == and) {
            @container (min-width: #{map-get($container-breakpoints, $breakpoint)}) and (any-hover: map-get($any-hovers, $hover)) {
                @content;
            }
        }
        @else {
            @container (min-width: #{map-get($container-breakpoints, $breakpoint)}), (any-hover: map-get($any-hovers, $hover)) {
                @content;
            }
        }
    }
    @else if map-has-key($container-breakpoints, $breakpoint) {
        @container (min-width: #{map-get($container-breakpoints, $breakpoint)}) {
            @content;
        }
    }
    @else {
        @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}` or no value could be retrieved from `#{any-hovers}`. " + "Available breakpoints are: #{map-keys($container-breakpoints)}. Available any-hover values are #{map-keys($any-hovers)}.";
    }
}

@function responsive-component-margin-breakpoint($minimum-page-margin: 16px, $offset: 0) {
    @return #{$structure-normal + ($minimum-page-margin * 2) + 1 + $offset};
}

@function responsive-component-margin-breakpoint--narrow($minimum-page-margin: 16px, $offset: 0) {
    @return #{$structure-narrow + ($minimum-page-margin * 2) + 1 + $offset};
}

@mixin responsive-component-margin($minimum-page-margin: 16px, $default-margins: auto) {
    $left: if($minimum-page-margin <= 0, 0, $default-margins);
    $right: if($minimum-page-margin <= 0, 0, $default-margins);

    margin-left: $left;
    margin-right: $right;
    width: 100%;

    @include for-narrow-structure() {
        @media (max-width: responsive-component-margin-breakpoint--narrow($minimum-page-margin)) {
            margin-left: $minimum-page-margin;
            margin-right: $minimum-page-margin;
            width: calc(100% - #{$minimum-page-margin * 2});
        }
    }

    @include for-not-narrow-structure() {
        @media (max-width: responsive-component-margin-breakpoint($minimum-page-margin)) {
            margin-left: $minimum-page-margin;
            margin-right: $minimum-page-margin;
            width: calc(100% - #{$minimum-page-margin * 2});
        }
    }
}

@mixin responsive-component-margin-unstructured($minimum-page-margin: 16px, $default-margins: auto) {
    $left: if($minimum-page-margin <= 0, 0, $default-margins);
    $right: if($minimum-page-margin <= 0, 0, $default-margins);

    margin-left: $left;
    margin-right: $right;
    width: 100%;

    @media (max-width: responsive-component-margin-breakpoint($minimum-page-margin)) {
        margin-left: $minimum-page-margin;
        margin-right: $minimum-page-margin;
        width: calc(100% - #{$minimum-page-margin * 2});
    }
}

@mixin responsive-component-margin-narrow($minimum-page-margin: 16px, $default-margins: auto) {
    $left: if($minimum-page-margin <= 0, 0, $default-margins);
    $right: if($minimum-page-margin <= 0, 0, $default-margins);

    margin-left: $left;
    margin-right: $right;
    width: 100%;

    @media (max-width: responsive-component-margin-breakpoint--narrow($minimum-page-margin)) {
        margin-left: $minimum-page-margin;
        margin-right: $minimum-page-margin;
        width: calc(100% - #{$minimum-page-margin * 2});
    }
}
