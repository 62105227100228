.ml-link{
    display: inline-block;
    min-height: 24px;

    &--opaque{
        color: rgba(255,255,255, .75);
        transition: color .4s;
        border: none;
        @include hover-active{
            color: rgba(255,255,255, 1);
        }
    }
    
    &.close-link{
        border: 1px solid $color-white;
        border-radius: 50%;
        color: $color-white;
        
    }
}