﻿.practice-group {
    margin: auto;
    max-width: $structure-normal;
    width: 100%;

    @include respond-to("xs") {
        width: initial;
    }

    &__links {
        column-count: 1;
        margin-top: 20px;
        width: 100%;

        @include respond-to("xs") {
            column-count: 2;
        }

        li {
            margin-bottom: 16px;
        }
    }

    &__link {
        @include font-semibold();
        @include font(16px, 20px);
        margin-bottom: 16px;

        @include for-print() {
            @include font-semibold();
            @include font(16px, 20px);
            display: block;
            padding-left: 15px;
        }

        &:hover {
            color: $color-purple;
            text-decoration: none;
        }

        &--large {
            font-size: 18px;

            @include for-print() {
                @include font(18px, 22px);
                @include font-semibold();
                padding-left: 0px;
            }
        }
    }
}
