.location-landing-list {
    columns: 3;
    display: none;

    @include respond-to('xs') {
        display: block;
    }

    &__heading {
        @include font(36px);
        @include font-heading();
        color: $color-black;
        padding-bottom: 36px;
        text-transform: uppercase;
    }

    &__office {
        break-inside: avoid-column;
        padding-bottom: 24px;
    }
}
