﻿.alternate-language-version-links {
  display: flex;
  font-family: $font-content-bold;
  font-size: 0px;
  line-height: 16px;
  color: $color-dark-grey;

  a.alternate-language-version-links__link {
    @include font-semibold();
    color: $color-dark-grey;
    font-size: 14px;
    text-transform: capitalize;
    word-break: keep-all;

    &:hover {
      color: $color-purple;
    }
  }

  &__separator:not(:last-of-type)::after {
    @include separator-after();
  }
  //person landing search results
  .c-content_team__card-info & {
    display: block;
    line-height: 20px;
    margin: 5px 0 4px 0;
  }
  //site search results
  .c-results__block & {
    display: block;
    margin-top: 6px;
  }
}
