.practice-accordion,
.practice-accordion--empty {
    background-color: $color-white-smoke;
    cursor: pointer;

    @include for-print() {
        background-color: transparent;
    }

    &:active,
    &:hover {
        background-color: $color-purple;

        .practice-accordion__title,
        .practice-accordion__title a {
            color: $color-white;
        }
    }

    & + & {
        margin-top: 10px;
    }

    &--empty {
        .practice-accordion__title {
            padding-left: 15px;
        }
    }

    &__title {
        @include font-semibold();
        @include font(16px, 44px);
        color: $color-black;
        display: block;
        max-width: 100%;
        vertical-align: middle;

        @include for-print() {
            @include font(18px, 22px);
            @include font-semibold();
            margin-bottom: 25px;
            padding-left: 0px;
        }

        @at-root div#{&} {
            @include for-print() {
                display: none;
            }
        }
        
        &:focus-visible {
            @include focus-outline();
        }
    }

    &__toggle {
        font-size: 15px;
        font-weight: 900;
        margin: 0 16px;

        @include for-print() {
            display: none;
        }
    }

    &__panel {
        background-color: $color-white;
        overflow: hidden;
        transition: 0.3s ease-out;

        @include for-print() {
            display: block !important;
            height: auto;
        }
    }

    &__content {
        padding: 25px 48px;

        @include for-print() {
            margin-bottom: 25px;
            padding: 0px;
        }
    }
}
