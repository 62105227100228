@use 'sass:math';

.table-of-contents {
    &__static {
        border-top: 2px solid $color-black;
        padding-top: 16px;
        padding-bottom: 16px;
    }

    &__content {
        &--fixed {
            max-width: $structure-normal;
            padding: 30px 0;

            @include responsive-component-margin-unstructured(); // needed to force margins at 940 when the component is placed in a narrow structure. fixed view content margin is always max at 940.
        }
    }

    &__heading {
        @include font-heading();
        @include font(16px, 21px);
        color: $color-black;
        text-transform: uppercase;

        @include for-print() {
            @include font(16px, 17px);
        }
    }

    &__list {
        columns: 1;
        padding-top: 4px;
        padding-bottom: 16px;

        @include respond-to('xs') {
            columns: 2;
        }

        @include for-print() {
            columns: 1;
        }
    }

    &__list-item {
        padding-top: 12px;
    }

    &__link {
        $table-of-contents__link-line-height: 22px;
        @include font(16px, $table-of-contents__link-line-height);
        color: $color-black;
        margin-left: 16px;
        position: relative;
        transition: color .3s ease-in-out;
        display: inline-block;

        @include for-print() {
            @include font(14px, 16px);
        }

        &:before {
            background: $color-purple;
            border-radius: 50%;
            content: "";
            height: 8px;
            left: -12px;
            opacity: 0;
            position: absolute;
            top: #{math.div($table-of-contents__link-line-height, 2)};
            transform: translate(-50%, -50%);
            transition: all 0.3s ease-in-out;
            width: 8px;
        }

        &:hover,
        &--active {
            color: $color-purple;

            @include for-print() {
                color: $color-black;
            }

            &:before {
                opacity: 1;

                @include for-print() {
                    opacity: 0;
                }
            }
        }
    }

    &__fixed { //fixed view rules
        background-color: $color-black;
        color: $color-white;
        left: 0;
        position: fixed;
        right: 0;
        top: 0;
        transform: translateY(-100%);
        transition: all 300ms ease-in-out;
        width: 100%;
        z-index: $z-index-sidenav;

        @include for-print() {
            display: none;
        }

        &--active {
            display: block;
            top: $navigation__fixed--mobile;
            transform: translateY(0);

            @include respond-to('small') {
                top: $navigation__fixed;
            }
        }

        .table-of-contents__heading {
            color: $color-white;
        }

        .table-of-contents__list {
            columns: 1;

            @include respond-to('xs') {
                columns: 3;
            }
        }

        .table-of-contents__link {
            color: $color-white;
            opacity: 0.75;

            &:hover {
                opacity: 1;

                &:before {
                    background: $color-white;
                }
            }

            &--active {
                opacity: 1;

                &:before {
                    background: $color-purple-semilight;
                }
            }
        }
    }

    &__mobile { //mobile/accordion view rules
        background-color: $color-black;
        color: $color-white;
        left: 0;
        position: fixed;
        right: 0;
        transform: translateY(-100%);
        transition: all 300ms ease-in-out;
        width: 100%;
        z-index: $z-index-sidenav;

        @include for-print() {
            display: none !important;
        }

        &--fixed {
            display: block;
            top: #{$navigation__fixed--mobile - 2}; //subtract for the 2px border
            transform: translateY(0);

            @include respond-to('small') {
                top: $navigation__fixed;
            }
        }
    }

    &__accordion {
        padding: 20px 0;
    }

    &__accordion-title {
        align-items: center;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        &:hover {
            .table-of-contents__heading,
            .table-of-contents__accordion-toggle {
                color: $color-extremely-light-grey;
            }
        }

        .table-of-contents__heading {
            @include font(16px, 21px);
            @include font-heading();
            color: $color-white;
            transition: color 0.3s ease-in-out;
            padding-right: 10px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    &__accordion-toggle {
        color: $color-white;
        cursor: pointer;
        display: inline-block;
        font-size: 30px;
        transition: color 0.3s ease-in-out;
        vertical-align: middle;

        &--active {
            height: 30px;
            transform: rotate(90deg);
        }
    }

    &__accordion-panel {
        height: 0px;
        overflow: hidden;
        transition: height 0.3s ease-in-out;
        max-height: 65vh;
        overflow-y: scroll;
        overflow-x: hidden;

        .table-of-contents__list {
            columns: 1;
        }

        .table-of-contents__list-item {
            margin-top: 12px;

            &:first-of-type {
                margin-top: 0;
            }
        }

        .table-of-contents__link {
            color: $color-white;
            opacity: 0.75;

            &:hover {
                opacity: 1;

                &:before {
                    background: $color-white;
                }
            }

            &--active {
                opacity: 1;

                &:before {
                    background: $color-purple-semilight;
                }
            }
        }
    }
}
