﻿.image-with-text {
    @include font(16px, 16px);
    @include font-content();
    @include responsive-component-margin();
    color: $color-dark-grey;
    display: flex;
    gap: 16px;
    flex-direction: column;
    justify-content: space-between;

    @include for-print() {
        @include font(14px, 16px);
        flex-direction: row;
        gap: 0;


        & + .image-with-text {
            margin-top: 24px;
        }
    }

    @include respond-to('small') {
        flex-direction: row;
    }

    &__content {

        &--left {
            order: 3;
        }

        &--right {
            order: 1;

            @include for-print() {
                order: 3;
            }
        }

        @include for-print(){
            display: inline-block;
        }
    }

    &__heading {
        @include font(24px, 24px);
        @include font-heading();
        color: $color-black;
        padding-bottom: 16px;

        @include for-print() {
            @include font(14px, 15px);
            padding-bottom: 8px;
        }
    }

    &__description {
        @include richtext();
        margin-bottom: 0px;

        @include for-print() {
            @include font(14px, 16px);

            p {
                @include font(14px, 16px);
            }
        }
    }

    &__image {
        height: 292px;
        max-width: 100%;
        order: 2;
        width: 100%;

        @include for-print() {
            display: inline-block;
            height: auto;
            margin-right: 16px;
            max-width: 100px;
            max-height: 100px;
            width: 100px;
        }

        @include respond-to('small') {
            max-width: 50%;
            width: 292px;
        }

        img {
            height: 100%;
            width: 100%;
        }
    }
}

.experience-editor {
    .image-with-text__content {
        @include respond-to('small') {
            width: 50%;
        }
    }
}