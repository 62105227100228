.publication-intro  {
    margin: 32px 0 32px 0;

    &__eyebrow {
        @include font(14px, 40px);
        @include font-semibold();
        color: $color-purple;
        text-transform: uppercase;
    }

    &__heading {
        @include font(24px, 25px);
        @include font-heading;
        color: $color-black;
        text-transform: uppercase;
        
        @include respond-to('xxs') {
            @include font(28px, 29px);
        }

        @include respond-to('small') {
            @include font(32px, 33px);
        }
    }

    &__subheading {
        @include font(20px, 24px);
        @include font-medium;
        color: $color-black;

        @include for-print() {
            @include font(20px, 24px);
        }

        @include respond-to('small') {
            @include font(24px, 30px);
        }
    }

    &__date {
        @include font-content();
        @include font(16px, 22px);
        color: $color-dark-grey;
        display: inline;

        @include for-print() {
            @include font(14px, 16px);
        }
    }

    &__duration {
        @include font-content();
        @include font(16px, 22px);
        color: $color-dark-grey;
        display: inline;

        @include for-print() {
            @include font(14px, 16px);
        }
    }

    &__date + &__duration:before {
        content: '|';
        @include font-content();
        @include font(16px, 22px);
        color: $color-dark-grey;
        display: inline;
        padding-left: 8px;
        padding-right: 8px;
        
        @include for-print() {
            @include font(14px, 16px);
        }
    }

    &__details-container {
        margin-top: 16px;
    }
}