﻿.careers-search {
    &__alumni-container,
    &__contacts-container,
    &__jobs-container {
        display: flex;
        flex-direction: column;
        border-bottom: 2px solid $color-black;
        padding-bottom: 20px;

        @include for-print() {
            display: none;
        }
    }

    &--banner,
    &__mobile-section {
        background: $color-black;
        bottom: 0;
        left: 0;
        margin: 0;
        max-height: 175px;
        max-width: min(940px, 100%);
        position: relative;

        @include respond-to('small') {
            margin: -76px auto 0 auto;
            max-height: 76px;
        }

        @include for-print() {
            display: none;
        }

        .careers-search__buttons-container {
            display: block;
            justify-content: space-evenly;
            margin: 10px 0 20px 0;
            width: 100%;


            @include respond-to('small') {
                display: flex;
                margin: 2px 5px;
                width: 45%;
            }

            @include respond-to('medium') {
                width: 35%;
            }

            button {
                color: $color-white;
                height: 42px;
            }
        }

        .careers-search__button--submit {
            @include font(16px);
            background-color: transparent;
            border: 2px solid $color-white;
            height: 46px;
            margin: 0 0 4px 10px;
            width: 100%;


            @include respond-to('small') {
                @include font(14px);
                width: 65px;
            }

            &:hover,
            &:active {
                background-color: $color-white;
                color: $color-black;
            }

            &:not(.visible) {
                display: none;
            }
        }

        .careers-search__container {
            display: flex;
            height: 175px;
            flex-direction: column;
            padding: 15px 15px;

            @include respond-to('small') {
                height: 46px;
                flex-direction: row;
            }
        }

        .careers-search__label {
            @include font(16px);
            color: $color-white;
            margin-top: 13px;
        }

        .careers-search__selector {
            height: 46px;
            width: 100%;

            &:not(.active) {
                display: none;
            }

            .autocomplete input {
                border: 0;
                height: 46px;
                padding: 0 15px;
                outline: none;
            }

            .autocomplete ul {
                padding-bottom: 0;
            }

            .dropdown-vue {
                background: $color-white;
                border: none;
                height: 46px;

                &--mobile--label {
                    height: 46px;
                    border: none;
                }

                &-container {
                    padding: 0;
                }

                .vs__dropdown-toggle {
                    height: 46px;
                }
            }
        }

        .careers-search__selector-container {
            display: flex;
            justify-content: space-between;
            width: 100%;

            @include respond-to('small') {
                width: 55%;
            }

            @include respond-to('medium') {
                width: 65%;
            }

            input,
            select {
                border: none;
                outline: none;
            }
        }
    }

    &__button {
        background-color: transparent;
        border: none;
        color: $color-black;
        transition: all 0.2s ease;
        height: 48px;
        width: 32%;

        &.active,
        &:hover,
        &:active {
            background-color: $color-purple-contrast;
            color: $color-white;
        }

        &:active,
        &:focus {
            outline: 5px auto -webkit-focus-ring-color;
            outline-offset: -2px;
        }

        &:focus-visible {
            @include focus-outline();
        }

        &:hover {
            cursor: pointer;
        }

        &--submit {
            background: $color-black;
            color: $color-white;
            margin: 0 0 15px auto;
            max-width: 100px;
            min-width: 54px;
            width: 100px;

            &:hover,
            &:active {
                background-color: $color-purple;
                border-color: $color-purple;
            }
        }
    }

    &__button,
    &__label {
        @include font(16px);
        @include font-heading();
        text-transform: uppercase;
    }

    &__heading {
        @include font-heading();
        color: $color-purple;
        padding: 20px 0 10px 0;
        text-transform: uppercase;

        @include respond-to('medium') {
            @include font(24px);
        }
    }

    &--horizontal {
        .careers-search {
            &__jobs-container,
            &__alumni-container,
            &__contacts-container {
                width: 30%;
                max-width: 100%;

                @include respond-to('xs') {
                    max-width: initial;
                }
            }
        }

        .careers-search__container {
            @include respond-to('small') {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
            }
        }

        .careers-search__selector {
            width: 100%;

            &-container {
                padding: 0;
            }
        }
    }

    &__main-section {
        display: none;

        @include respond-to('small') {
            display: block;
        }
    }

    &__mobile-section {
        display: block;
        margin: 0 auto;

        @include respond-to('small') {
            display: none;
        }
    }

    &__selector {
        height: 46px;
        margin: 0 0 15px 0;
        padding: 0;

        .autocomplete {
            &-list__item {
                white-space: pre-wrap;
            }

            input {
                background: $color-white;
                height: 46px;
                padding: 0 5px;
                border: solid 2px $color-black;

                &::placeholder {
                    color: $color-gray50
                }
            }
        }

        .dropdown-vue,
        .dropdown-vue--mobile {
            background: $color-white;
            border: 2px solid $color-black;
            height: 46px;

            &--label span {
                padding-left: 15px;
            }

            .vs__dropdown-menu {
                padding: 0;
                z-index: calc($z-index-nav + 1);
            }

            .vs__dropdown-toggle {
                height: 100%;
                padding: 0 15px;

                div, input {
                    padding: 0;
                    margin: 0;
                }

                .vs__actions {
                    top: 13px;
                }

                .vs__search {
                    color: $color-gray50;
                }
            }

            .vs__selected {
                height: 100%;
                margin: 0;
                padding: 0;
            }

            ul {
                .vs__dropdown-option__text {
                    margin: 0;

                    &:after {
                        display: none !important;
                    }
                }

                li {
                    @include font(14px);
                    height: auto;
                    margin: 0 !important;
                    min-height: 30px;
                    padding: 5px 10px !important;
                }
            }
        }

        .dropdown-vue {
            display: block;

            &--mobile {
                display: none;
                padding: 0 10px;

                &__label {
                    position: absolute;
                    z-index: 10;
                    height: 100%;
                    width: 100%;
                    background: white;
                    display: flex;
                }

                select {
                    @include font-medium;
                    @include font(16px, 19px);
                    backdrop-filter: $color-white;
                    border: none;
                    border-radius: 0;
                    height: 100%;
                    opacity: 1;
                    outline: none;
                    position: relative;

                    ::placeholder {
                        @include font-medium;
                        @include font(16px, 19px);
                    }

                    option:active,
                    option:focus,
                    option:hover {
                        backdrop-color: $color-purple;
                        color: $color-white;
                    }
                }
            }

            @include respond-to('small') {
                display: block;

                &--mobile {
                    display: none;
                }
            }
        }
    }

    &--vertical {
        position: relative;

        @include respond-to('small') {
            position: sticky;
            top: 102px;
        }
    }
}

.experience-editor {
    .dropdown__exp-editor{
        display: block !important;
    }
}