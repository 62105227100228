.page-heading {
  padding-bottom: 30px;
  h1 {
    @include font-heading();
    font-size: 36px;
    text-transform: uppercase;
    padding-bottom: 0px;
  }
  h2, &__eyebrow {
    @include font-heading();
    color: $color-purple !important;
    display: block;
    font-size: 16px !important;
    line-height: 1 !important;
    padding-bottom: 8px !important;
    text-transform: uppercase !important;
  }
  h3 {
    @include font-heading();
    font-size: 24px;
    text-transform: uppercase;
    padding-bottom: 15px;

    &.no-padding {
      padding: 0;
    }
  }
}
