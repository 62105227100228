div > a.rte-whitepill-double,
p > a.rte-whitepill-double {
    @include font-semibold();
    @include font(16px, 20px);
    align-items: center;
    background-color: $color-white;
    border: 2px solid $color-light-grey;
    border-radius: 12px;
    color: $color-black;
    display: block;
    padding: 8px;
    text-align: center;
    text-decoration: none;
    transition: color $transition-timing $transition-function, background-color $transition-timing $transition-function, border $transition-timing $transition-function;

    @include respond-to('xxs') {
        width: 220px;
    }
}

div > a.rte-whitepill-double:hover,
div > a.rte-whitepill-double:focus,
p > a.rte-whitepill-double:hover,
p > a.rte-whitepill-double:focus {
    background-color: $color-black;
    border: 2px solid $color-black;
    color: $color-white;
    cursor: pointer;
    transition: color $transition-timing $transition-function, background-color $transition-timing $transition-function, border $transition-timing $transition-function;
}
