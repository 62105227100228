.tabs {
    display: flex;
    flex-direction: row;
    margin: auto;
    padding: 0 0 20px 0;
    transition: all 0.15s linear;

    @include for-print() {
        padding: 0;
        transition: none;

        ul li:not(.is-active) {
            display: none;
        }
    }

    &__arrow {
        align-items: center;
        display: flex;
        font-size: 23px;
        height: 66px;
        z-index: $z-index-100;

        @include respond-to('small') {
            display: none;
            z-index: initial;
        }

        @include for-print() {
            display: none;
        }

        &--left {
            float: left;

            .icon-chevron {
                transform: scaleX(-1);
            }
        }

        &--right {
            float: right;
        }

        .icon-chevron.is-disabled {
            opacity: 0.5;
        }
    }

    &-title {
        background: transparent;
        border: 0;
        cursor: pointer;
        display: none;
        margin: 0;
        min-width: 100%;
        padding: 12px 0;
        pointer-events: none;
        text-align: center;
        text-transform: uppercase;
        transition: all 0.3s ease;
        width: 100%;

        & > a {
            pointer-events: none;
        }

        @include for-print() {
            margin: 0;
            padding: 0;
            text-align: left;

            &.is-active a {
                @include font(24px, 34px);
                color: $color-black;
            }
        }

        @include respond-to('small') {
            border: initial;
            display: initial;
            margin: 0 20px;
            min-width: auto;
            padding: 14px 15px 10px 15px;
            pointer-events: initial;
            transition: initial;
            width: auto;

            & > a {
                pointer-events: initial;
            }
        }

        &:focus-visible {
            @include focus-outline();
        }

        &:hover {
            background-color: $color-white;

            a {
                color: $color-purple;
            }
        }

        a {
            @include font(18px, 22px);
            @include font-heading();
            color: $color-black;
            display: block;
            padding: 10px 0;
        }

        &.is-active {
            display: block;

            a {
                border: 0;
                color: $color-black;

                @include respond-to('small') {
                    border: initial;
                    color: $color-purple;
                }
            }
        }

        &--block {
            border: 1px solid $color-ml-light-grey;
            display: block;
            margin: 0 0 16px 0;
            min-width: auto;
            padding: 0;
            pointer-events: initial;
            text-transform: none;
            width: auto;

            @include respond-to('small') {
                border: 0;
                margin: 0 20px 0 0;
                width: 100%
            }

            &:hover {
                background-color: $color-purple;

                a {
                    color: $color-white;
                }
            }

            a {
                @include font-semibold();
                @include font(18px);
            }

            &.is-active {
                background-color: $color-purple;

                a {
                    border: 0;
                    color: $color-white;
                }
            }

            &:last-of-type {
                @include respond-to('small') {
                    margin: 0;
                }
                margin: inherit;
            }
        }
    }

    .tabcontainer {
        align-items: center;
        border: none;
        display: flex;
        justify-content: center;
        margin: auto;
        padding: 0 15px;
        position: relative;
        transition: all 0.3s ease;
        width: 100%;

        @include for-print() {
            transition: none;
        }

        @include respond-to('small') {
            align-items: initial;
            border: initial;
            display: inline-block;
            justify-content: initial;
            position: initial;
            transition: initial;

            @include for-print() {
                padding: 0;
                transition: none;
            }
        }

        &--block {
            padding: 0;
        }

        &--inset {
            background-color: $color-white;
            max-width: #{map-get($breakpoints, 'contentLayout')};
            z-index: $z-index-10;
        }
    }

    .tabitems {
        align-content: center;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        max-width: #{map-get($breakpoints, 'contentLayout')};
        -ms-overflow-style: none; /* IE and Edge */
        overflow-x: hidden;
        padding-left: 0px;
        padding-right: 0px;
        position: relative;
        scrollbar-width: none; /* Firefox */
        width: 100%;

        @include for-print() {
            justify-content: left;
        }

        @include respond-to('small') {
            justify-content: center;
            max-width: initial;
            -ms-overflow-style: initial; /* IE and Edge */
            overflow-x: initial;
            padding-left: initial;
            padding-right: initial;
            position: initial;
            scrollbar-width: initial; /* Firefox */
            width: initial;

            &::-webkit-scrollbar {
                display: none;
            }
        }

        &--block {
            column-count: 2;
            display: block;
            justify-content: space-between;
            -moz-column-count: 2;
            -webkit-column-count: 2;

            @include respond-to('small') {
                column-count: initial;
                display: flex;
                -moz-column-count: initial;
                -webkit-column-count: initial;
            }
        }
    }

    .tabs-label {
        @include font-semibold();
        @include font(18px, 22px);
        align-items: center;
        display: block;
        color: $color-black;
        margin: 0 10px;
        margin-right: 40px;
        padding: 10px 0;
        text-align: left;

        @include respond-to("xs") {
            display: flex;
            text-align: center;
        }

        &--block {
            @include font-semibold();
            @include font(18px, 22px);
            align-items: center;
            color: $color-black;
            margin-right: 40px;
            padding: 10px 0;
            text-align: left;

            @include respond-to("small") {
                text-align: center;
            }

            @include for-print() {
                display: none;
            }
        }
    }

    &--block {
        flex-direction: column;
        margin: 0 16px;

        @include respond-to("medium") {
            margin: auto;
        }

        @include respond-to("small") {
            flex-direction: row;
        }
    }

    &--inset {
        background-color: $color-white;
        margin-top: 0px;

        @include respond-to("small") {
            margin-top: -66px;
        }

        @include for-print() {
            margin-top: 24px;
        }

        &.is-active {
            background: transparent;

            a {
                color: $color-purple;
                border: 0;

                &:hover {
                    background-color: $color-white;
                    color: $color-purple;
                }
            }
        }
    }
}
.structure-tabs {
    @include for-print() {
        margin: 0 auto;
        max-width: $print-width;
    }

    &__shared {
        .experience-editor & {
            min-width: 40vw;
        }
    }
}

.tab-transitioning[style*="display: none;"] {
    display: inherit !important;
    height: 0;
    pointer-events: none;
    user-select: none;
}

.experience-editor {
    .tabs {
        flex-direction: column;
    }

    .tabs,
    .tab {
        border: 1px solid green;
        padding: 20px 0;
        width: 100%;
    }
}

.tab {
    &-container {
        @include for-print() {
            opacity: 1 !important; // to ignore tabs transition effect
            transition: none;
        }
    }
}
