﻿.accolade-carousel {

    &__heading {
        @include font(30px, 32px);
        color: $color-purple;
        font-weight: 500;
        padding-bottom: 22px;

        @include for-print(){
            @include font(18px, 22px);
            font-weight: 500;
            padding-bottom: 16px;
        }
    }

    &__abstract {
        @include font(20px, 24px);
        padding-bottom: 22px;

        @include for-print() {
            display: none;
        }
    }

    &__pagination {
        display: flex;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        margin-top: 24px;
        width: 100%;

        @include for-print() {
            display: none;
        }

        &-item {
            @include font(20px, $font-small: 18px);
            background-color: $color-white;
            border: $color-black 2px solid;
            border-radius: 16px;
            color: rgba(255, 255, 255, 0.75);
            cursor: pointer;
            height: 16px;
            margin-left: 8px;
            margin-right: 8px;
            transition: 0.4s;
            width: 16px;

            &:focus-visible {
                @include focus-outline();
            }

            &--active {
                color: rgba(255, 255, 255, 1);
                background-color: black;
            }

            @include hover-active {
                color: rgba(255, 255, 255, 1);
            }

            &:only-child {
                display: none;
            }
        }
    }

    &__slides {
        align-items: center;
        display: flex;
        flex-direction: column;
        height: 507px;
        justify-content: center;
        position: static;
        overflow-x: hidden;

        @include respond-to('small') {
            position: relative;

            .structure-one-column & {
                overflow-x: visible;
                position: static;
            }
        }

        @include for-print() {
            height: auto;
            justify-content: left;
        }

        &-background {
            background: $color-white-smoke;
            height: 507px;
            left: 0;
            margin-bottom: 0;
            margin-top: 0;
            position: absolute;
            right: 0;
            width: 100%;

            @include for-print() {
                display: none;
            }
        }
    }

    &__wrapper {
        left: 0;
        width: 100%;
        overflow: visible;
        container-type: inline-size;

        @include respond-to-container('small') {
            width: 90%;
        }

        @include respond-to-container('medium') {
            width: 100%;
        }

        @supports not (container-type: inline-size) {
            @include respond-to-range('small', 'medium') {
                width: 90%;
            }
        }

        div.swiper-button {
            &-next,
            &-prev {
                &:after {
                    content: "";
                }

                .icon {
                    color: $color-black;
                    font-size: 32px;

                    &:hover {
                        color: $color-purple;
                    }

                    @include for-print() {
                        display: none;
                    }
                }
            }

            &-prev {
                .icon {
                    transform: rotate(180deg);
                }
            }

            &-disabled {
                display: none;
            }
        }

        .experience-editor & {
            display: flex;
            flex-direction: row;
            max-height: 287px;
        }

        .swiper-wrapper {
            max-height: 270px;
        }
    }

    @include for-print() {

        &__wrapper {
            margin-left: 0;

            .swiper-wrapper {
                display: inline;
            }
        }
    }
}
