@mixin print-background-images() {
  @media print {
    * {        
      /*used in print mode to print background images. 
      This will override the browser checkbox in print mode to print background 
      images that is unchecked by default.
      */
        color-adjust: exact !important;  /*Firefox*/
      }
  }
}