﻿.infographic {
    display: inline-block;

    @include for-print() {
        break-inside: avoid;
        pointer-events: none; /* prevent click events which open the modal */
    }

    &__heading {
        @include font(36px);
        @include font-heading();
        color: $color-black;
        margin-bottom: 9px;

        @include for-print() {
            @include font(18px, 19px);
        }
    }

    &__thumbnail-container {
        cursor: pointer;
        position: relative;
        width: fit-content;

        &:focus,
        &:hover {
            .infographic__thumbnail-icon {
                color: $color-light-blue;
                opacity: 1;
            }
        }
    }

    &__thumbnail {
        max-width: 100%;

        &:focus-visible {
            @include focus-outline();
        }
    }

    &__thumbnail-icon {
        bottom: 10px;
        color: #e0e0e0;
        font-size: 35px;
        opacity: 0.7;
        position: absolute;
        right: 10px;
        transition: color 0.3s ease-in-out;

        @include for-print() {
            display: none;
        }
    }

    &-grid {
        .infographic {
            display: inline-block;
            width: auto;

            & + .infographic {
                margin-left: 0;
            }
        }

        &__abstract {
            padding-top: 20px;
        }

        &__items {
            padding-top: 20px;
        }
    }
}
