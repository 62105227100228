.accolades-callout {
    &__image-container {
        height: 57px;
        width: 57px;

        @include for-print() {
            display: none;
        }
    }

    &__image {
        @include print-background-images();
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 100%;
        width: 100%;
    }

    &__list {
        columns: 1;
        margin-top: 24px;

        @include for-print() {
            columns: 2;
        }
    }

    &__list-item {
        break-inside: avoid;

        & + & {
            margin-top: 24px;

            @include for-print() {
                margin-top: 16px;
            }
        }
    }
}
