﻿.language-selector {
    a {
        @include font-semibold();
        font-size: $font-size-small;
        color: $color-dark-grey;

        &:hover {
            color: $color-purple;
        }
    }

    &__separator:not(:last-of-type)::after {
        content: "";
        border-right: 2px solid #7b7b7b;
        margin-left: 8px;
        margin-right: 8px;
        font-size: $font-size-xxsmall;
    }
}

.inpage-language-selector-container {
    @include for-print() {
        display: none;
    }
}
