.horizontal-scroll-list {
  border-bottom: 2px solid $color-ml-light-grey;
  padding: 10px 16px;
  position: relative;

  @include for-print() {
      display: none !important;
  }
  
  [show-arrows="both"] ~ .icon-chevron--right,
  [show-arrows="right"] ~ .icon-chevron--right {
    opacity: 1;
  }

  [show-arrows="both"] ~ .icon-chevron--left,
  [show-arrows="left"] ~ .icon-chevron--left {
    opacity: 1;
  }

  &__container {
    margin: 0 20px;
    overflow-x: auto;
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    -webkit-overflow-scrolling: touch;
    white-space: nowrap;
    
    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__items {
    transition: transform 0.2s ease-in-out;
    float: left;

    @include respond-to("xs") {
      float: initial;
    }
  }
  &-arrow {
    appearance: none;
    background: transparent;
    border: 0;
    display: inline;
    padding: 0;

    &:focus {
    outline: 0;
    }
    &:hover {
    cursor: pointer;
    }

    position: absolute;      
    opacity: 0;
    top: 15px;
    transition: opacity 0.3s;

    @include respond-to("xs") {
      display: none;
    }        
  }
  .icon {
    color: $color-footer-grey;
    font-size: 20px;
  }
  .icon-chevron--left {
    transform: rotate(180deg);
    left: 16px;
  }
  .icon-chevron--right {
    right: 16px;
  }
}
