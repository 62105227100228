$publication-hero__height: 246px;

.publication-hero {
    margin-bottom: 40px;
    min-height: $publication-hero__height;
    position: relative;
    width: 100%;

    @include for-print() {
        height: auto;
        min-height: 0;
    }

    &__image-container {
        height: 100%;
        overflow: hidden;
        position: absolute;
        width: 100%;
    }

    &__image {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 100%;
        width: 100%;

        @include for-print() {
            display: none;
        }
    }

    &__overlay {
        background-color: rgba(0,0,0,0.5);
        height: 100%;
        position: absolute;
        width: 100%;

        @include for-print() {
            background: none;
            position: relative;
        }
    }

    &__content {
        @include structure-normal();
        @include responsive-component-margin();
        margin-top: 23px;
        position: relative;

        @include respond-to('small') {
            margin-top: 46px;
        }

        @include for-print() {
            @include responsive-component-margin(0);
            margin-top: 0;
            padding: 0;
        }
    }

    &__eyebrow {
        @include font-medium();
        @include font(14px, 15px);
        color: $color-white;
        text-transform: uppercase;

        @include for-print() {
            @include font(14px, 14px);
            color: $color-purple;
        }
    }

    &__heading {
        @include font-heading();
        @include font(24px, 25px);
        color: $color-white;
        margin-top: 4px;
        text-transform: uppercase;

        @include respond-to('xxs') {
            @include font(28px, 29px);
        }

        @include respond-to('small') {
            @include font(32px, 33px);
        }

        @include for-print() {
            @include font(32px, 33px);
            color: $color-black;
            margin-top: 8px;
        }
    }

    &__subheading {
        @include font-content();
        @include font(20px, 24px);
        color: $color-white;
        margin-top: 4px;
        width: 100%;

        @include respond-to('xs') {
            @include font(22px, 26px);
        }

        @include respond-to('small') {
            @include font(24px, 30px);
        }

        @include for-print() {
            @include font(20px, 24px);
            color: $color-black;
            margin-top: 3px;
        }
    }

    &__supplemental {
        margin-top: 16px;

        @include for-print() {
            margin-top: 20px;
        }
    }

    &__supplemental-item {
        @include font-content();
        @include font(16px, 22px);
        color: $color-white;

        @include respond-to('xs') {
            @include font(18px, 22px);
        }

        @include for-print() {
            @include font(14px, 16px);
            color: $color-dark-grey;
        }

        & + & {
            &:before {
                content: '|';
                margin: 0 8px;
            }
        }
    }
}