.ourwork-grouping {

    &__heading {
        @include font(18px, 22px);
        @include font-semibold();
        color: $color-purple;

        @include for-print() {
            @include font(14px, 16px)
        }
    }

    &__list {
        background-color: $color-gray97;
        columns: 1;
        margin-bottom: 27px;
        margin-top: 9px;

        @include for-print() {
            background: none;
            margin-bottom: 17px;
            margin-top: 8px;
        }
    }

    &__list-item {
        @include richtext(18px, 22px);
        break-inside: avoid;
        color: $color-black;
        margin: 20px;

        @include for-print() {
            @include richtext(14px, 16px);
            color: $color-dark-grey;
            margin: 0;
        }
    }

    &__list-separator {
        border: solid 1px $color-gainsboro;
        margin: 20px;

        @include for-print() {
            margin: 8px 0;
        }

        &--nomargintop {
            margin-top: 0px;
        }
    }

    &__trigger {
        @include showmore-trigger();
        @include font(14px, 20px);
        @include font-semibold();

        @include for-print() {
            display: none;
        }

        margin-bottom: 20px;
        margin-left: 20px;
        margin-top: 0px;
        vertical-align: middle;
        width: fit-content;

        .icon {
            @include font(10px, 16px);
            vertical-align: middle;
        }

        &:focus-visible {
            @include focus-outline();
        }
    }

    &__trigger-label {
        @include showmore-trigger-label();
        vertical-align: middle;
    }
}
