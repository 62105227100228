$transition-timing-function: ease-in-out;
$transition-duration: 0.3s;

.related-reading {
    container-type: inline-size;

    @include for-print() {
        display: none;
    }

    &__items{
        display: flex;
        gap: 6px;
    }

    &__heading {
        @include font-medium();
        @include font(24px, 28px);
        color: $color-purple; 

        @include respond-to-container('xxs-withmargins') {
            @include font(30px, 34px);
        }
    }

    &__full-width-divider {
        border-top: 2px solid $color-black;
        display: block;
        margin-top: 40px;
        padding-top: 40px;

        @include respond-to('small'){
            display: none;
        }

        @include respond-to-container('small-withmargins') {
            border-top: 2px solid $color-black;
            display: block;
            margin-top: 40px;
            padding-top: 40px;
        }
    }

    &__image-container {
        height: 140px;
        justify-content: center;
        margin-bottom: 24px;
        position: relative;
        overflow: hidden;
        transition: opacity $transition-timing-function $transition-duration;

    }

    &__item + &__item {
        .related-reading__image-container {
            display: none;
        }
    }

    .related-reading__items {
        flex-direction: column;

        .related-reading__item {
            width: 100%;
        }
    }

    .related-reading__item{
        border-bottom: 1px solid #A0A0A0;

        &:last-of-type{
            border-bottom: 0;
            padding: 0;
        }
    }

    @include respond-to-container('small-withmargins') {
        &__item + &__item {
            .related-reading__image-container {
                display: block;
            }
        }
        
        .related-reading__items {
            flex-direction: row;
            
            .related-reading__item {
                border-bottom: none;
            }
        }
    }

    &__item{
        margin: 16px 10px 10px 0;
        padding-bottom: 24px;

        @include respond-to-container('small-withmargins') {
            padding-bottom: 0;
        }

        &:hover,
        &:focus {
            .related-reading__image {
                filter: brightness(70%);
                transform: scale(1.2);
            }
            
            a {
                color: $color-purple;
            }
        }

        .related-reading__image {                        
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            filter: brightness(100%);
            height: 100%;
            transition: transform $transition-duration $transition-timing-function;            
            width:100%;
        }
      

        &-pubtype{
            @include font(14px, 15px);
            @include font-semibold();
            color: $color-purple;
            margin-bottom: 4px;
            text-transform: uppercase;
        }

        &-link {
            @include font(16px, 20px);
            @include font-medium();
            color: $color-black;
        }
    }
}