﻿$media-module__max-width--print: 460px;

.media-module {

    @include for-print() {
        break-inside: avoid;
        pointer-events: none; /* some variants of the component have click handling at the top level */
    }

    &__description {
        margin: 10px 0;
    }

    &__link a {
        @include font-semibold();
        background: url('../images/arrow-light-grey1.png') no-repeat 0 5px;
        cursor: pointer;
        display: inline-block;
        padding-left: 15px;
        text-decoration: none;

        &:active,
        &:focus,
        &:hover {
            background: url('../images/arrow-light-grey1-active.png') no-repeat 0 5px;
        }
    }

    &__title {
        color: $color-black;
        margin: 20px 0 10px 0;

        h2 {
            color: $color-black;
            font-size: 36px;
            margin-top: 0;
            text-align: left !important;
            text-transform: uppercase;
        }
    }

    &.vertical-playlist-item {
        .media-module {
            &__description {
                @include font-content();
                @include font(12px, 16px);
                margin: 0;

                @include for-print() {
                    @include font(14px, 16px);
                }
            }

            &__details {
                margin-left: 0;
                width: 100%;

                @include respond-to('xs') {
                    margin-left: 20px;
                    width: 50%;
                }

                @include for-print() {
                    padding-left: 24px;
                    width: calc(50% - 24px);
                }
            }

            &__title {
                @include font-semibold();
                @include font(16px, 20px);
                margin: 4px 0;

                @include respond-to('xs') {
                    margin: 0;
                }
            }
        }

        .ml-media-description {
            @include for-print() {
                @include font(14px, 16px);
                padding-left: 24px;
                width: calc(50% - 24px);
            }
        }

        .audio-media-module,
        .video-media-module {
            display: flex;
            margin-bottom: 20px;

            &__media {
                @include respond-to('xs') {
                    width: 50%;
                }
            }

            &--mobile-view {
                display: block;

                &__media {
                    width: 100%;
                }
            }
        }

        .audio-media-module,
        .image-media-module__content,
        .video-media-module {
            @include for-print() {
                display: flex;
                flex-wrap: wrap;
            }
        }

        .image-media-module__content .media-module__title {
            @include for-print() {
                width: 100%;
            }
        }

        .audio-media-module__media,
        .image-media-module__image-container,
        .video-media-module__media {
            @include for-print() {
                width: calc(50% - 24px);
            }
        }
    }

    &.horizontal-playlist-item {
        .media-module {
            &__description {
                @include font-content();
                font-size: 12px;
                margin: 0;
            }

            &__details {
                display: block;
                padding-left: 0;
                position: relative;
            }

            &__title {
                @include font-semibold();
                @include font(16px, 20px);
                margin: 4px 0;

                @include respond-to('xs') {
                    margin: 0;
                }
            }
        }

        .audio-media-module,
        .video-media-module {
            &--mobile-view {
                margin-bottom: 20px;
            }
        }
    }

    video, video::-webkit-media-controls {
        @include for-print() {
            /* video elements should never be shown in print, else they appear as empty blocks */
            display: none;
        }
    }
}

.image-media-module {
    max-width: max-content;

    &:focus-visible {
        @include focus-outline();
    }

    &__image {
        cursor: pointer;
        display: inline-block;
        position: relative;
        width: 100%;

        &-container {
            background: transparent;
            border: none;
            border-radius: 0;
            display: inline-block;
            height: auto;
            max-width: 100%;
            padding: 0;
            transition: all .2s ease-in-out;
        }
    }
}

.audio-media-module,
.video-media-module {
    position: relative;

    @include for-print() {
        break-inside: avoid;
    }

    &__media {
        position: relative;
    }

    &__thumbnail {
        background-size: cover;
        cursor: pointer;
        width: 100%;
        z-index: 2;

        &-wrapper {
            max-width: $media-module__max-width--print;
            position: relative;
            width: 100%;
            z-index: 3;

            @include for-print() {
                display: block;
                pointer-events: none; /* prevent modal from triggering on click */
            }
        }
    }

    &__play-icon {
        cursor: pointer;
        display: block;
        height: 50%;
        left: 50%;
        max-height: 90px;
        min-height: 50px;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%); 

        @include for-print() {
            display: none;
        }
    }

    &--mobile-view &__play-icon {
        display: none;
    }
}

.audio-media-module {
    &__thumbnail-wrapper {
        max-width: 100%;

        @include for-print() {
            max-width: $media-module__max-width--print;
        }

        @include respond-to('xs') {
            max-width: $media-module__max-width--print;
        }

        &:focus-visible {
            @include focus-outline(-5px);
        }
    }

    .audio {
        bottom: 0;
        display: none;
        position: absolute;
        width: 100%;
        z-index: 4;

        @include for-print() {
            display: none;
        }

        &:focus-visible {
            @include focus-outline(-5px);
        }

        &-player:focus-visible {
            @include focus-outline();
        }
    }

    &--mobile-view {
        .audio {
            display: block;
        }

        .audio-media-module__thumbnail-wrapper {
            max-width: 100%;

            @include for-print() {
                max-width: $media-module__max-width--print;
            }
        }
    }
}

.video-media-module {
    &__thumbnail-wrapper {
        display: block;

        &:focus-visible {
            @include focus-outline(-5px);
        }

        @include for-print() {
            break-inside: avoid;
            display: block !important; /* required for the print preview, else it gets overridden by mobile styles */
            pointer-events: none; /* prevent modal from triggering on click */
        }
    }

    .video {
        display: none;

        &-player:focus-visible {
            @include focus-outline(-5px);
        }
    }

    &--mobile-view {
        .video {
            display: block;
        }

        .video-media-module__thumbnail-wrapper {
            display: none;
        }
    }
}