@use 'sass:math';

@mixin highlight__as-stack() {
    flex-direction: column;
    min-height: 200px;

    &__content-container {
        justify-content: flex-start;
    }

    &__image {
        filter: brightness(70%);
    }

    &__image-container {
        align-items: center;
        display: flex;
        justify-content: center;
        position: relative;
        width: 100%;

        .highlight__heading {
            color: $color-white;
            display: block;
            position: absolute;
        }
    }

    &__content-container {
        width: 100%;

        .highlight__heading {
            display: none;
        }
    }

    .highlight__image-container {
        order: 0;
    }

    .highlight__content-container {
        order: 1;
    }
}

$transition-timing-function: ease-in-out;
$transition-duration: 0.3s;

.highlight-container {
    .highlight:nth-of-type(even) {
        @include respond-to('xs') {
            .highlight__content {
                padding: 0 40px 0 40px;

                @media (max-width: responsive-component-margin-breakpoint($global-page-margin)) {
                    padding: 0 0 0 40px;
                }

                @include for-print() {
                    padding: 24px;
                }
            }
        }

        .highlight__image-container {
            order: 0;
        }

        .highlight__content-container {
            justify-content: flex-start;
            order: 1;
        }
    }
}

.highlight {
    @include print-background-images();
    break-inside: avoid;
    display: flex;
    flex-direction: row;

    &:hover,
    &:focus {
        .highlight__image {
            filter: brightness(70%);
            transform: scale(1.2);

            @include for-print() {
                transform: none;
            }
        }
    }

    &:focus-visible {
        @include focus-outline();
    }

    @include respond-to('xs') {
        height: 380px;
    }

    @include for-print() {
        height: unset;
        @include highlight__as-stack();
    }

    &__image-container {
        display: inline-block;
        height: 140px;
        opacity: 1;
        order: 1;
        overflow: hidden;
        transition: opacity $transition-timing-function $transition-duration;
        width: calc((50vw - #{math.div($structure-normal, 2)}) + 313px);

        @include respond-to('xs') {
            height: initial;
        }

        @include respond-to-range('xs', 'contentLayout') {
            width: 34%;
        }

        @include respond-to('xlarge') {
            width: calc(((#{$structure-wide} - #{$structure-normal})/2) + (#{$structure-normal} *.33));
        }

        @include for-print() {
            transition: none;
        }

        .highlight__heading {
            display: none;
        }
    }

    &__image {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        filter: brightness(100%);
        height: 100%;
        transition: transform $transition-duration $transition-timing-function;
        width: 100%;

        @include for-print() {
            min-height: 80px;
            transition: none;
        }
    }

    &__content-container {
        display: flex;
        justify-content: flex-end;
        order: 0;
        padding-bottom: 32px;
        padding-top: 32px;
        width: calc(100vw - ((50vw - #{math.div($structure-normal, 2)}) + 313px));

        @include respond-to('xs') {
            padding-bottom: 0;
            padding-top: 0;
        }

        @include respond-to-range('xs', 'contentLayout') {
            width: 66%;
        }

        @include respond-to('xlarge') {
            width: calc(((#{$structure-wide} - #{$structure-normal})/2) + (#{$structure-normal} *.67));
        }

        @include for-print() {
            background-color: transparent !important;
            min-height: 100px;
            padding-bottom: 0;
            padding-top: 0;
        }
    }

    &__content {
        align-self: center;
        padding: 0;

        @include responsive-component-margin($global-page-margin, 0);

        @media (max-width: responsive-component-margin-breakpoint($global-page-margin)) {
            padding: 0 40px 0 0;
        }

        .structure-one-column--wide & {
            padding: 0 40px 0 0;
        }

        @include respond-to('xs') {
            padding: 0 40px;
        }

        @include respond-to('contentLayout') {
            width: calc((#{$structure-normal} * .67) - 56px);
        }

        @include respond-to-range('xs', 'contentLayout') {
            width: 100%;
        }

        @include for-print() {
            padding: 24px;
        }
    }

    &__heading {
        @include font(24px, 28px);
        @include font-medium();
        letter-spacing: 0.15px;
        padding: 0;
        text-align: center;

        @include respond-to('xs') {
            @include font(28px, 32px);
            padding: 0 0 8px 0;
            text-align: initial;
        }

        @include for-print() {
            @include font(18px, 20px);
            padding: 24px;
            text-align: left;
            width: calc(100% - 48px);
        }
    }

    &__abstract {
        @include font-content();
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 16px;

        p {
            color: inherit;
        }

        @include for-print() {
            @include font(14px, 16px);
        }
    }

    &__link-container {
        display: table;
        font-size: 12px;
        margin-bottom: 8px;

        @include for-print() {
            display: none;
        }

        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            opacity: .75;
        }

        &:focus,
        &:hover {
            &,
            &.icon-chevron:before,
            a {
                color: inherit;
                color: var(--hovercolor);

                @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                    opacity: 1;
                }
            }
        }

        &::marker {
            color: red;
        }
    }

    &__link {
        @include font(16px, 24px);
        @include font-semibold();
        color: inherit;
        display: table-cell;
        padding-left: 5px;
    }

    &--link {
        cursor: pointer;
    }

    & + .highlight {
        @include respond-to('xs') {
            margin-top: 8px;
        }
    }

    @include respond-to-max('xs') {
        @include highlight__as-stack();
    }
}
