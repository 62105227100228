@mixin structure-normal {
    @include structure();
    max-width: $structure-normal;
}

@mixin structure-wide {
    @include structure();
    max-width: $structure-wide;
}

@mixin structure-narrow {
    margin-left: auto;
    margin-right: auto;
    max-width: $structure-narrow;

    @media (max-width: #{$structure-narrow + ($global-page-margin * 2) + 1}) {
        margin-left: 0;
        margin-right: 0;
        max-width: initial;
    }
}

@mixin structure {
    margin-left: auto;
    margin-right: auto;

    @media (max-width: #{$structure-normal + ($global-page-margin * 2) + 1}) {
        margin-left: 0;
        margin-right: 0;
        max-width: initial;
    }
}

@mixin for-narrow-structure() {
    @at-root #{selector-nest(".structure-one-column--narrow", &)} {
        @content;
    }

    @at-root #{selector-nest(".structure-two-column--narrow", &)} {
        @content;
    }
}

@mixin for-not-narrow-structure() {
    @at-root #{selector-nest(".structure-one-column:not(.structure-one-column--narrow)", &)} {
        @content;
    }

    @at-root #{selector-nest(".structure-two-column:not(.structure-two-column--narrow)", &)} {
        @content;
    }
}

@mixin for-unstructured() {
    @at-root #{selector-nest(":not(.structure-one-column)", &)}, #{selector-nest(":not(.structure-two-column)", &)} {
        @content;
    }
}