﻿.career-body {
    /* Large Devices, Wide Screens */
    @media only screen and (max-width: 1200px) {
        .toggle-global-options-controls {
            top: -90px;
        }

        .global-header-option-controls-container {
            position: relative;
        }

        .ml-wrapper.show-global-options-controls {
            top: 90px;
        }

        .global-header-option-controls-container .row {
            margin: 0;
        }
    }
    /* Medium Devices, Desktops */
    @media only screen and (max-width: 992px) {
        .global-header-option-controls-container {
            position: relative;
        }

        .global-header-option-controls-container form {
            padding: 0 10px;
        }

        .global-header-option-controls-container.toggle-global-options-controls {
            top: -105px;
        }

        .global-search-module {
            top: 10px;
            left: 0;
        }

        .cinemagraph-module-banner.jumbotron {
            padding: 0 20px !important;
        }

        .sticky-header-enabled .ml-wrapper.show-global-options-controls {
            top: 0px;
        }

        .ml-wrapper.show-global-options-controls {
            top: 90px;
        }

        .jump-link-module {
            right: 20px;
        }

        .two-column-container,
        .one-column-container {
            padding: 0 20px;
        }

        .two-column-container .column-container-content-bordered,
        .two-column-container .column-container-content {
            max-width: 100%;
        }

        .global-header-option-controls-container .row {
            margin: 0;
        }

        .site-selector-module {
            right: 0;
        }

        .stacked-media-modal-video-holder {
            width: 80%;
        }

        .global-search-module-control-container.ml-main-container form {
            padding: 0;
        }

        .global-search-module-control-container.ml-main-container form .form-group.col-xs-10.col-sm-10.col-md-10.col-lg-10 {
            width: 81%;
            padding-right: 7px;
            padding-left: 0;
        }

        .global-search-module-control-container.ml-main-container .form-group.col-xs-2.col-sm-2.col-md-2.col-lg-2 {
            text-align: right;
            padding-left: 3px;
            width: 19%;
        }

        .global-search-module-control-container.ml-main-container .form-group.col-xs-2.col-sm-2.col-md-2.col-lg-2 .btn {
            float: left;
        }

        .global-search-module-control-container.ml-main-container .form-group.col-xs-2.col-sm-2.col-md-2.col-lg-2 .global-search-module-close-btn {
            display: inline-block !important;
            margin-top: 14px;
            margin-right: -5px;
        }

        .global-search-module-control-container.ml-main-container .form-group.col-xs-1.col-sm-1.col-md-1.col-lg-1 {
            display: none;
        }

        .global-search-module-control-container input[type="text"] {
            padding: 15px;
            padding-top: 14px;
        }

        .site-selector-module-control-container form {
            padding: 0 10px 0 5px;
        }

        .top-navigation-module-responsive-menu {
            margin-top: 6px; /* adjust nav tree alignment */
        }
        /*.social-media-link-ls ul{
        margin:0;
        padding:0;
    }*/
    }

    @media only screen and (max-width: 956px) {
        .global-search-module {
            left: 10px;
        }

        .site-selector-module {
            right: 10px;
        }

        .top-navigation-module-header-menu > ul {
            margin-left: 0;
        }

        .top-navigation-module-header-menu > ul > li {
            margin: 0 10px;
            float: none;
        }

        .global-search-module-control-container {
            max-width: 100%;
        }

        .global-search-module-control-container.ml-main-container form {
            padding: 0 10px 0 5px;
        }

        .global-search-module-control-container.ml-main-container form .form-group.col-xs-10.col-sm-10.col-md-10.col-lg-10 {
            width: 79%;
            padding-right: 6px;
            padding-left: 5px;
        }

        .global-search-module-control-container.ml-main-container .form-group.col-xs-2.col-sm-2.col-md-2.col-lg-2 {
            text-align: right;
            padding-left: 4px;
            width: 21%;
        }

        .global-search-module-control-container.ml-main-container .form-group.col-xs-2.col-sm-2.col-md-2.col-lg-2 .btn {
            float: left;
        }

        .global-search-module-control-container.ml-main-container .form-group.col-xs-2.col-sm-2.col-md-2.col-lg-2 .global-search-module-close-btn {
            display: inline-block !important;
            margin-top: 14px;
            margin-right: -5px;
        }

        .global-search-module-control-container.ml-main-container .form-group.col-xs-1.col-sm-1.col-md-1.col-lg-1 {
            display: none;
        }

        .global-search-module-control-container input[type="text"] {
            padding: 15px;
        }

        .site-selector-module-control-container .row > .form-group.col-xs-7.col-sm-8.col-md-8.col-lg-8 {
            width: 69.25%;
        }

        .site-selector-module-control-container .row > .form-group.col-xs-4.col-sm-3.col-md-3.col-lg-3 {
            width: 24.75%;
            padding-left: 0;
        }

        .site-selector-module-control-container .row > .form-group.col-xs-1.col-sm-1.col-md-1.col-lg-1.text-center {
            width: 4.95%;
            text-align: right;
        }

        .site-selector-module-control-container {
            padding-left: 10px;
            padding-right: 10px;
        }

        .site-selector-module-control-container.active {
            max-height: 89px;
            padding-left: 10px;
            padding-right: 10px;
        }

        .site-selector-module-control-container form {
            padding: 0 10px 0 5px;
        }

        .site-selector-module-control-container .sile-selector-module-label {
            padding-top: 0;
            line-height: 1.25;
        }

        .site-selector-module-control-container select {
            padding: 15px 8px;
            float: right;
            max-width: 185.125px;
        }

        .site-selector-module-close-btn {
            margin-top: 15px;
            margin-right: -13px;
        }

        .subnavigation-module-header-menu ul li a:focus,
        .subnavigation-module-header-menu ul li a {
            font-size: 12px;
        }

        .footer-module .row .col-md-6.col-sm-6.col-xs-6:first-of-type {
            left: 10px;
        }

        .footer-module .row .col-md-6.col-sm-6.col-xs-6:last-of-type {
            right: 10px;
        }
    }

    @media only screen and (min-width: 768px) {
        /*.search-bar-module label {
        margin-top: 15px;
    }*/

        .search-bar-module .btn-group .btn {
            min-width: auto;
            font-size: 14px;
            margin-top: 2px;
            padding-left: 5px;
            padding-right: 5px;
            border-radius: 0 !important;
            border: 2px solid #000 !important;
        }

        .search-bar-module-holder .search-bar-submit {
            width: 100%;
            min-width: 100%;
            font-size: 14px;
            max-height: 46px;
            padding-left: 5px;
            padding-right: 5px;
        }

        .for-mobile-only {
            display: none;
        }
    }
    /* Small Devices, Tablets */
    @media only screen and (max-width: 768px) {
        .search-bar-module label {
            height: 44px;
            padding-top: 012px;
        }

        .global-header-option-controls-container {
            position: relative;
        }

        .global-header-option-controls-container form {
            padding: 0 10px;
        }

        .global-header-option-controls-container.toggle-global-options-controls {
            top: -105px;
        }

        .sticky-header-enabled .ml-wrapper.show-global-options-controls {
            top: 0;
        }

        .ml-wrapper.show-global-options-controls {
            top: 90px;
        }

        .sm-text-center {
            text-align: center;
        }

        .sm-margin-spacer {
            margin: 10px 0;
        }
        /*.ml-wrapper {
        top: 54px;
    }*/

        .cinemagraph-module.cinemagraph-module-overlay {
            height: 450px;
        }

        .cinemagraph-module-banner.jumbotron {
            height: 100%;
        }

        .jump-link-module {
            right: 0;
        }
        /*.footer-module-legal .footer-module-links,
    .footer-module-menu .footer-module-links.footer-module-links-site,
    .footer-module-menu .footer-module-links.footer-module-links-social {
        text-align: center;
    }*/

        .cinemagraph-module-banner.jumbotron {
            padding: 0 20px;
        }

        .top-navigation-module-menu-trigger {
            position: absolute;
            right: auto;
            top: 15px;
            left: 20px;
        }

        .global-search-module {
            top: 10px;
            left: 10px;
            right: auto;
        }

        .site-selector-module {
            right: 10px;
        }

        .two-column-container,
        .one-column-container {
            padding: 0 20px;
        }

        .two-column-container .column-container-content-bordered,
        .two-column-container .column-container-content {
            max-width: 100%;
        }

        .two-column-container > .row > .col-lg-4.col-md-4.col-sm-12.col-xs-12.sticky {
            max-width: 100%;
            position: relative;
            top: auto;
            right: auto;
            z-index: 554;
        }

        .search-bar-module {
            margin-top: -10px;
            z-index: 555;
        }

        .search-bar-module .dropdown {
            margin-top: 10px;
        }

        .global-header-option-controls-container .row {
            margin: 0;
        }

        .top-navigation-module-header-menu > ul {
            margin-left: 0;
        }

        .top-navigation-module-header-menu > ul > li {
            margin: 0 19px;
            float: none;
        }

        .stacked-media-modal-video-holder {
            width: 80%;
        }

        div[class^="search-module"] h3,
        div[class*="-search-module"] h3 {
            @include font-heading();
            color: $color-purple;
            text-transform: uppercase;
            font-size: 18px;
        }

        div[class^="search-module"] .input-halo-dark,
        div[class*="-search-module"] .input-halo-dark {
            font-size: 14px;
        }

        .footer-module-legal .row > .col-md-6.col-sm-6.col-xs-6:first-of-type {
            float: left;
            width: auto;
            left: 0;
            padding: 0 0 0 10px;
        }

        .footer-module-legal .row > .col-md-6.col-sm-6.col-xs-6:last-of-type {
            float: right;
            width: auto;
            right: 0;
            padding: 0 10px 0 0;
        }

        .global-search-module-control-container.ml-main-container form {
            padding: 0 10px 0 5px;
        }

        .global-search-module-control-container.ml-main-container form .form-group.col-xs-10.col-sm-10.col-md-10.col-lg-10 {
            width: 76%;
            padding-right: 4px;
        }

        .global-search-module-control-container.ml-main-container .form-group.col-xs-2.col-sm-2.col-md-2.col-lg-2 {
            text-align: right;
            padding-left: 4px;
            width: 24%;
        }

        .global-search-module-control-container.ml-main-container .form-group.col-xs-2.col-sm-2.col-md-2.col-lg-2 .btn {
            float: left;
        }

        .global-search-module-control-container.ml-main-container .form-group.col-xs-2.col-sm-2.col-md-2.col-lg-2 .global-search-module-close-btn {
            display: inline-block !important;
            margin-top: 14px;
            margin-right: -5px;
        }

        .global-search-module-control-container.ml-main-container .form-group.col-xs-1.col-sm-1.col-md-1.col-lg-1 {
            display: none;
        }

        .global-search-module-control-container input[type="text"] {
            padding: 15px;
        }

        .site-selector-module-control-container .row > .form-group.col-xs-7.col-sm-8.col-md-8.col-lg-8 {
            width: 69.25%;
        }

        .site-selector-module-control-container .row > .form-group.col-xs-4.col-sm-3.col-md-3.col-lg-3 {
            width: 24.75%;
            padding-left: 0;
        }

        .site-selector-module-control-container .row > .form-group.col-xs-1.col-sm-1.col-md-1.col-lg-1.text-center {
            width: 4.95%;
            text-align: right;
        }

        .site-selector-module-control-container {
            padding-left: 10px;
            padding-right: 10px;
        }

        .site-selector-module-control-container.active {
            max-height: 89px;
            padding-left: 10px;
            padding-right: 10px;
        }

        .site-selector-module-control-container form {
            padding: 0 10px 0 5px;
            margin: 0;
        }

        .site-selector-module-control-container .sile-selector-module-label {
            padding-top: 0;
            line-height: 1.25;
        }

        .site-selector-module-control-container select {
            padding: 15px 8px;
            float: right;
            max-width: 185.125px;
        }

        .site-selector-module-close-btn {
            margin-top: 15px;
            margin-right: -13px;
        }

        .subnavigation-module-header-menu ul li a:focus,
        .subnavigation-module-header-menu ul li a {
            font-size: 10px;
        }

        .tabs-module[data-hide-tabs="true"] {
            display: none;
        }

        .for-mobile-only {
            display: none;
        }
        /*.social-media-link-ls ul{
        margin:0;
        padding:0;
    }*/

        .btn-halo:focus,
        .btn-halo:hover,
        .btn-halo:active,
        .btn-halo:active:hover {
            color: #fff;
            border-color: #000;
            background-color: transparent;

            &.search-bar-submit {
                border-color: #fff;
            }
        }
    }

    @media only screen and (max-width: 726px) and (min-width: 641px) {
        .search-bar-module label {
            height: 0;
            padding-top: 0;
        }

        .top-navigation-module-header-menu > ul > li {
            margin: 0 13px;
            float: none;
        }

        .global-search-module-control-container.ml-main-container form {
            padding: 0 10px 0 5px;
        }

        .global-search-module-control-container.ml-main-container form .form-group.col-xs-10.col-sm-10.col-md-10.col-lg-10 {
            width: 72%;
            padding-right: 3px;
            padding-left: 5px;
        }

        .global-search-module-control-container.ml-main-container .form-group.col-xs-2.col-sm-2.col-md-2.col-lg-2 {
            text-align: right;
            padding-left: 3px;
            width: 28%;
        }

        .global-search-module-control-container.ml-main-container .form-group.col-xs-2.col-sm-2.col-md-2.col-lg-2 .btn {
            float: left;
        }

        .global-search-module-control-container.ml-main-container .form-group.col-xs-2.col-sm-2.col-md-2.col-lg-2 .global-search-module-close-btn {
            display: inline-block !important;
            margin-top: 14px;
            margin-right: -5px;
        }

        .global-search-module-control-container.ml-main-container .form-group.col-xs-1.col-sm-1.col-md-1.col-lg-1 {
            display: none;
        }

        .global-search-module-control-container input[type="text"] {
            padding: 15px;
        }

        .site-selector-module-control-container .row > .form-group.col-xs-7.col-sm-8.col-md-8.col-lg-8 {
            width: 69.25%;
        }

        .site-selector-module-control-container .row > .form-group.col-xs-4.col-sm-3.col-md-3.col-lg-3 {
            width: 24.75%;
            padding-left: 0;
        }

        .site-selector-module-control-container .row > .form-group.col-xs-1.col-sm-1.col-md-1.col-lg-1.text-center {
            width: 4.95%;
            text-align: right;
        }

        .site-selector-module-control-container.active {
            max-height: 89px;
        }

        .site-selector-module-control-container form {
            padding: 0 10px 0 5px;
        }

        .site-selector-module-control-container .sile-selector-module-label {
            padding-top: 7px;
            line-height: 1.25;
        }

        .site-selector-module-control-container select {
            padding: 15px 8px;
            float: right;
            max-width: 185.125px;
        }

        .site-selector-module-close-btn {
            margin-top: 14px;
            margin-right: -13px;
        }

        .subnavigation-module-header-menu ul li a:focus,
        .subnavigation-module-header-menu ul li a {
            font-size: 10px;
        }

        .search-bar-module .dropdown {
            margin-top: 10px;
        }

        .tabs-module[data-hide-tabs="true"] {
            display: none;
        }

        .for-mobile-only {
            display: none;
        }
    }
    /* Extra Small Devices, Phones */
    @media only screen and (max-width: 640px) {
        .search-bar-module label {
            height: 0;
            padding-top: 0;
        }

        .toggle-global-options-controls {
            top: 0;
        }

        .global-header-option-controls-container {
            position: fixed;
            z-index: 5555;
        }

        .global-header-option-controls-container.toggle-global-options-controls {
            top: 0;
        }

        .global-header-option-controls-container.show-global-options-controls {
            top: 0 !important;
        }

        .global-header-option-controls-container form {
            padding: 0 10px;
        }

        .ml-wrapper.show-global-options-controls,
        .ml-wrapper {
            top: 0;
        }

        .site-selector-module-control-container.ml-main-container {
            background-color: $color-purple;
            position: absolute;
            z-index: 5555;
            padding-top: 0;
        }

        .global-search-module-control-container.ml-main-container {
            background-color: $color-purple;
            position: absolute;
            z-index: 5555;
        }

        .site-selector-module-control-container.ml-main-container form .form-group,
        .global-search-module-control-container.ml-main-container form .form-group {
            margin-bottom: 0;
        }

        .site-selector-module-control-container.ml-main-container form .form-group.col-xs-7, .site-selector-module-control-container.ml-main-container form .form-group.col-xs-4 {
            width: 100%;
        }

        .site-selector-module-control-container.ml-main-container form .form-group.col-xs-1 {
            display: none;
        }

        .top-navigation-module-menu-trigger.hidden-sm.hidden-md.hidden-lg i,
        .site-selector-module.hidden-md.hidden-lg i,
        .global-search-module.hidden-md.hidden-lg i {
            font-size: 24px !important;
        }

        .site-selector-module-control-container.ml-main-container,
        .global-search-module-control-container.ml-main-container {
            transition: all ease-in-out 0.6s;
            top: 0;
        }

        .site-selector-module-control-container.active,
        .global-search-module-control-container.active {
            top: 52px;
        }
        /*.global-search-module-control-container + .site-selector-module-control-container.active {
        top: 146px !important;
    }*/

        .top-navigation-module-header-menu {
            display: none;
        }

        .cinemagraph-module.cinemagraph-module-overlay {
            height: 187px;
            top: -5px;
        }

        .cinemagraph-module-banner {
            height: 100%;
        }

        .cinemagraph-module-banner.jumbotron {
            padding: 0 20px;
        }

        .jumbotron h1 {
            font-size: 42px;
        }

        .top-navigation-module-header-logo {
            padding-left: 10px;
            text-align: left;
        }

        .two-column-container,
        .one-column-container {
            padding: 0 20px;
        }

        .two-column-container .column-container-content-bordered,
        .two-column-container .column-container-content {
            max-width: 100%;
        }

        .two-column-container > .row > .col-lg-4.col-md-4.col-sm-12.col-xs-12.sticky {
            max-width: 100%;
            position: relative;
            top: auto !important;
            right: auto;
            z-index: 554;
        }

        .responsive-header-options {
            display: block !important;
        }

        .responsive-header-options-toggle {
            display: none;
        }
        /*.footer-module .footer-module-menu .footer-module-links.footer-module-links-site li:first-of-type {
        margin-right: 15px;
        margin-left: 10px;
    }

    .footer-module .footer-module-menu .footer-module-links.footer-module-links-site li:last-of-type {
        margin-right: 0;
        margin-left: 10px;
    }

    .footer-module .row>.col-xs-6:first-of-type {
        padding-right: 0;
    }*/

        .career-trajectory-module {
            padding: 20px;
            margin: 0 -20px;
        }

        .career-trajectory-module-wrapper .page-header.identity-title h1 {
            font-size: 26px;
        }

        .career-trajectory-module-wrapper .page-header.identity-title h2 {
            font-size: 22px;
        }

        .career-trajectory-module-wrapper .page-header.identity-title h4 {
            font-size: 18px;
        }

        .career-trajectory-module-timeline-item .page-header h1 {
            font-size: 36px;
        }

        .career-trajectory-module-timeline-item .page-header h2 {
            font-size: 36px;
        }

        .career-trajectory-module-timeline-item {
            height: auto;
            max-height: 345px;
        }

        .career-trajectory-module-timeline-events > ul > li > a > h3 {
            font-size: 14px;
            margin-top: 10px;
        }

        .stacked-media-modal-video-holder {
            width: 80%;
        }

        div[class^="search-module"] h3,
        div[class*="-search-module"] h3 {
            @include font-heading();
            color: $color-purple;
            font-size: 14px;
            text-transform: uppercase;
        }

        .global-search-module-control-container.ml-main-container {
            padding: 0;
            margin: 0;
        }

        .global-search-module-control-container.ml-main-container form {
            padding: 0px 10px 0 5px;
        }

        .global-search-module-control-container.ml-main-container form .form-group.col-xs-10.col-sm-10.col-md-10.col-lg-10 {
            width: 72%;
            padding-right: 3px;
            padding-left: 5px;
        }

        .global-search-module-control-container.ml-main-container .form-group.col-xs-2.col-sm-2.col-md-2.col-lg-2 {
            text-align: right;
            padding-left: 3px;
            width: 28%;
        }

        .global-search-module-control-container.ml-main-container .form-group.col-xs-2.col-sm-2.col-md-2.col-lg-2 .btn {
            float: left;
        }

        .global-search-module-control-container.ml-main-container .form-group.col-xs-2.col-sm-2.col-md-2.col-lg-2 .global-search-module-close-btn {
            display: inline-block !important;
            margin-top: 13px;
            margin-right: -5px;
        }

        .global-search-module-control-container.ml-main-container .form-group.col-xs-1.col-sm-1.col-md-1.col-lg-1 {
            display: none;
        }

        .global-search-module-control-container input[type="text"] {
            padding: 15px;
            padding-top: 15px;
        }

        .site-selector-module-control-container.active {
            padding-bottom: 0;
            max-height: 121px;
            padding-left: 5px;
            padding-right: 10px;
        }

        .site-selector-module-control-container .dropdown.input-halo {
            margin-top: -2px;
        }

        .site-selector-module-control-container form {
            margin: 8px 0 0 0;
            padding: 0 10px 0 5px;
        }

        .site-selector-module-control-container .sile-selector-module-label {
            padding-top: 0;
            margin-top: 20px;
            line-height: 1.25;
        }

        .site-selector-module-control-container .form-group.col-xs-4.col-sm-3.col-md-3.col-lg-3 {
            padding-right: 0;
        }

        .site-selector-module-control-container select {
            padding: 15px 8px;
            width: 100%;
            max-width: 100%;
        }

        .site-selector-module-control-container .row > .form-group.col-xs-7.col-sm-8.col-md-8.col-lg-8 {
            width: 100%;
            padding-left: 5px;
        }

        .site-selector-module-control-container .row > .form-group.col-xs-4.col-sm-3.col-md-3.col-lg-3 {
            width: 100%;
            padding-left: 5px;
        }

        .site-selector-module-control-container .row > .form-group.col-xs-1.col-sm-1.col-md-1.col-lg-1.text-center {
            display: none;
        }

        .breadcrumb > li + li:before {
            content: "\003c";
            color: #fff;
        }

        .breadcrumb > li:only-child :before {
            content: "\003c\0020";
            color: #fff;
        }

        .tabs-module {
            display: block;
            margin-top: 0px;
        }

        .tabs-module .nav-tabs-wrapper {
            width: calc(100% - 62px);
            position: relative;
            overflow: hidden;
            margin-top: -4px;
            max-height: 65px;
            margin: 0 16px;
            float: left;
        }

        .tabs-module .nav-tabs-wrapper ul {
            transition: all ease-in-out 0.4s;
            position: relative;
            display: block;
            width: 1024px;
            left: 0;
        }

        .tabs-module .nav-tabs-wrapper ul > li {
            display: inline-block;
        }

        .tabs-module-holder .nav-tabs.nav-justified > li {
            float: left;
        }

        .tabs-module .prev-holder,
        .tabs-module .next-holder {
            display: inline-block;
        }

        .tabs-module-adjustment {
            padding-top: 0 !important;
        }

        .for-mobile-only {
            display: block;
        }

        .hide-in-mobile {
            display: none;
        }

        .top-navigation-module-responsive-menu {
            margin-top: 3px;
        }

        .subnavigation-module {
            display: none;
        }

        .search-bar-module .dropdown {
            margin-top: 10px;
        }

        .stacked-media-module-selection-holder {
            overflow-x: auto;
        }

        .stacked-media-module-selection-holder .stacked-media-module-selection {
            left: 0 !important;
        }

        .tabs-module[data-hide-tabs="true"] {
            display: none;
        }

        .search-bar-module + .one-column-container .social-share {
            margin-top: 10px;
            padding-top: 0 !important;
            padding-bottom: 0 !important;
            z-index: 999;
            width: 0;
        }

        .tabs-module[data-hide-tabs="true"]
      + .tab-content
      .two-column-container
      .social-share {
      margin-top: 20px;
    }
    }

    @media only screen and (max-width: 632px) {
        .search-bar-module label {
            height: 0;
            padding-top: 0;
        }

        .footer-module-legal p.text-right {
            margin: 0 0 5px 0;
        }

        .site-selector-module-control-container.active {
            padding-bottom: 0;
            max-height: 121px;
        }

        .site-selector-module-control-container.active {
            padding-bottom: 0;
            max-height: 121px;
        }

        .breadcrumb > li + li:before {
            content: "\003c";
            color: #fff;
        }

        .breadcrumb > li:only-child :before {
            content: "\003c\0020";
            color: #fff;
        }

        .tabs-module {
            display: block;
        }

        .tabs-module .nav-tabs-wrapper {
            width: calc(100% - 62px);
            position: relative;
            overflow: hidden;
            margin-top: -4px;
            max-height: 65px;
            margin: 0 16px;
            float: left;
        }

        .tabs-module .nav-tabs-wrapper ul {
            transition: all ease-in-out 0.4s;
            position: relative;
            display: block;
            width: 1024px;
            left: 0;
        }

        .tabs-module .nav-tabs-wrapper ul > li {
            display: inline-block;
        }

        .tabs-module .prev-holder,
        .tabs-module .next-holder {
            display: inline-block;
        }

        .tabs-module-adjustment {
            padding-top: 0 !important;
        }

        .for-mobile-only {
            display: block;
        }

        .hide-in-mobile {
            display: none;
        }

        .subHeading.for-mobile-only {
            font-style: italic;
        }

        .top-navigation-module-responsive-menu {
            margin-top: 3px;
        }

        .subnavigation-module {
            display: none;
        }

        .search-bar-module .dropdown {
            margin-top: 10px;
        }

        .stacked-media-module-selection-holder {
            overflow-x: auto;
        }

        .stacked-media-module-selection-holder .stacked-media-module-selection {
            left: 0 !important;
        }

        .tabs-module[data-hide-tabs="true"] {
            display: none;
        }

        .detail-page .col-l .social-media-link-ls {
            display: block;
            left: auto;
            right: 0px;
            width: auto;
        }
    }

    @media only screen and (max-width: 600px) and (min-width: 501px) {
        .search-bar-module label {
            height: 0;
            padding-top: 0;
        }

        .global-search-module-control-container.ml-main-container form {
            padding: 2px 10px 0 5px;
        }

        .global-search-module-control-container.ml-main-container form .form-group.col-xs-10.col-sm-10.col-md-10.col-lg-10 {
            width: 68%;
            padding-right: 3px;
        }

        .global-search-module-control-container.ml-main-container .form-group.col-xs-2.col-sm-2.col-md-2.col-lg-2 {
            text-align: right;
            padding-left: 3px;
            width: 32%;
        }

        .global-search-module-control-container.ml-main-container .form-group.col-xs-2.col-sm-2.col-md-2.col-lg-2 .btn {
            float: left;
        }

        .global-search-module-control-container.ml-main-container .form-group.col-xs-2.col-sm-2.col-md-2.col-lg-2 .global-search-module-close-btn {
            display: inline-block !important;
            margin-top: 14px;
            margin-right: -6px;
        }

        .global-search-module-control-container.ml-main-container .form-group.col-xs-1.col-sm-1.col-md-1.col-lg-1 {
            display: none;
        }

        .global-search-module-control-container input[type="text"] {
            padding: 15px;
            padding-top: 14px;
        }

        .site-selector-module-control-container.active {
            max-height: 121px;
        }

        .site-selector-module-control-container form {
            padding: 0 10px 0 5px;
        }

        .site-selector-module-control-container .sile-selector-module-label {
            padding-top: 0;
            margin-top: 12px;
            line-height: 1.25;
        }

        .site-selector-module-control-container .form-group.col-xs-4.col-sm-3.col-md-3.col-lg-3 {
            padding-right: 0;
        }

        .site-selector-module-control-container select {
            padding: 15px 8px;
            width: 100%;
            max-width: 100%;
        }

        .site-selector-module-control-container .row > .form-group.col-xs-7.col-sm-8.col-md-8.col-lg-8 {
            width: 100%;
            padding-left: 5px;
        }

        .site-selector-module-control-container .row > .form-group.col-xs-4.col-sm-3.col-md-3.col-lg-3 {
            width: 100%;
            padding-left: 5px;
        }

        .site-selector-module-control-container .row > .form-group.col-xs-1.col-sm-1.col-md-1.col-lg-1.text-center {
            display: none;
        }

        .breadcrumb > li + li:before {
            content: "\003c";
            color: #fff;
        }

        .breadcrumb > li:only-child :before {
            content: "\003c\0020";
            color: #fff;
        }

        .tabs-module {
            display: block;
        }

        .tabs-module .nav-tabs-wrapper {
            width: calc(100% - 62px);
            position: relative;
            overflow: hidden;
            margin-top: -4px;
            max-height: 65px;
            margin: 0 16px;
            float: left;
        }

        .tabs-module .nav-tabs-wrapper ul {
            transition: all ease-in-out 0.4s;
            position: relative;
            display: block;
            width: 1024px;
            left: 0;
        }

        .tabs-module .nav-tabs-wrapper ul > li {
            display: inline-block;
        }

        .tabs-module .prev-holder,
        .tabs-module .next-holder {
            display: inline-block;
        }

        .tabs-module-adjustment {
            padding-top: 0 !important;
        }

        .for-mobile-only {
            display: block;
        }

        .hide-in-mobile {
            display: none;
        }

        .top-navigation-module-responsive-menu {
            margin-top: 3px;
        }

        .subnavigation-module {
            display: none;
        }

        .search-bar-module .dropdown {
            margin-top: 10px;
        }

        .stacked-media-module-selection-holder {
            overflow-x: auto;
        }

        .stacked-media-module-selection-holder .stacked-media-module-selection {
            left: 0 !important;
        }

        .tabs-module[data-hide-tabs="true"] {
            display: none;
        }

        .detail-page .col-l .social-media-link-ls {
            display: block;
            left: auto;
            right: 0px;
            width: auto;
        }
    }

    @media only screen and (max-width: 500px) {
        .search-bar-module label {
            height: 0;
            padding-top: 0;
        }

        .global-header {
            z-index: 5555;
        }

        .footer-module-menu {
            min-height: 104px;
            margin-top: 0;
        }

        .footer-module .col-xs-6 {
            width: 100%;
        }

        .footer-module-legal p,
        .footer-module-legal .footer-module-links,
        .footer-module-menu .footer-module-links.footer-module-links-site,
        .footer-module-menu .footer-module-links.footer-module-links-social {
            text-align: center;
        }

        .footer-module-menu .footer-module-links.footer-module-links-site {
            margin-top: 4px;
        }

        .footer-module-menu .footer-module-links.footer-module-links-site > li:last-of-type {
            margin-left: 24px;
        }

        .footer-module-legal .row > .col-md-6.col-sm-6.col-xs-6:first-of-type,
        .footer-module-legal .row > .col-md-6.col-sm-6.col-xs-6:last-of-type {
            float: none;
            width: 100%;
        }

        .footer-module-legal .footer-module-links {
            margin: 5px 0 8px -10px;
        }

        .footer-module-legal p.text-right {
            margin: 5px 0 0 10px;
        }

        .footer-module-menu .footer-module-links.footer-module-links-social {
            float: none;
            margin-top: 15px;
        }

        .footer-module-menu .footer-module-links.footer-module-links-social li {
            margin: 0 8px;
            float: none;
        }

        .footer-module .row .col-md-6.col-sm-6.col-xs-6:first-of-type {
            left: 0;
        }

        .footer-module .row .col-md-6.col-sm-6.col-xs-6:last-of-type {
            right: 0;
        }

        .stacked-media-modal-video-holder {
            width: 100%;
        }

        .two-column-container > .row > .col-lg-7.col-md-7.col-sm-7.col-xs-7,
        .two-column-container > .row > .col-lg-4.col-md-4.col-sm-4.col-xs-4 {
            width: 100%;
            max-width: 100% !important;
            position: relative;
            top: auto !important;
            right: auto !important;
            margin: 0;
        }

        .global-search-module-control-container.ml-main-container form {
            padding: 0px 10px 0 5px;
        }

        .global-search-module-control-container.ml-main-container form .form-group.col-xs-10.col-sm-10.col-md-10.col-lg-10 {
            padding-right: 4px;
            width: 100%;
        }

        .global-search-module-control-container.ml-main-container .form-group.col-xs-2.col-sm-2.col-md-2.col-lg-2, .global-search-module-control-container.ml-main-container .form-group.col-xs-1.col-sm-1.col-md-1.col-lg-1 {
            display: none;
        }

        .global-search-module-control-container input[type="text"] {
            padding: 15px;
            padding-top: 15px;
        }

        .global-search-module-control-container.ml-main-container .responsive-submit {
            background: url("/~/media/MorganLewisCareers/GlobalSearch/btn-search-mobile.ashx") no-repeat 0 0;
            display: block !important;
            width: 40px;
            height: 40px;
            padding: 0;
            margin: 0;
            right: 10px;
            top: 5px;
        }

        .site-selector-module-control-container.active {
            padding-bottom: 0;
            padding-left: 5px;
            padding-right: 10px;
            max-height: none;
        }

        .site-selector-module-control-container .dropdown.input-halo {
            margin-top: -2px;
        }

        .site-selector-module-control-container form {
            padding: 0 10px 0 5px;
        }

        .site-selector-module-control-container .sile-selector-module-label {
            padding-top: 0;
            margin-top: 20px;
            line-height: 1.25;
        }

        .site-selector-module-control-container .form-group.col-xs-4.col-sm-3.col-md-3.col-lg-3 {
            padding-right: 0;
        }

        .site-selector-module-control-container select {
            padding: 15px 8px;
            width: 100%;
            max-width: 100%;
        }

        .site-selector-module-control-container .row > .form-group.col-xs-7.col-sm-8.col-md-8.col-lg-8 {
            width: 100%;
            padding-left: 5px;
        }

        .site-selector-module-control-container .row > .form-group.col-xs-4.col-sm-3.col-md-3.col-lg-3 {
            width: 100%;
            padding-left: 5px;
        }

        .site-selector-module-control-container .row > .form-group.col-xs-1.col-sm-1.col-md-1.col-lg-1.text-center {
            display: none;
        }

        .breadcrumb > li + li:before {
            content: "\003c";
            color: #fff;
        }

        .breadcrumb > li:only-child :before {
            content: "\003c\0020";
            color: #fff;
        }

        .tabs-module {
            display: block;
        }

        .tabs-module .nav-tabs-wrapper {
            width: calc(100% - 62px);
            position: relative;
            overflow: hidden;
            margin-top: -4px;
            max-height: 65px;
            margin: 0 16px;
            float: left;
        }

        .tabs-module .nav-tabs-wrapper ul {
            transition: all ease-in-out 0.4s;
            position: relative;
            display: block;
            width: 1024px;
            left: 0;
        }

        .tabs-module .nav-tabs-wrapper ul > li {
            display: inline-block;
        }

        .tabs-module .prev-holder,
        .tabs-module .next-holder {
            display: inline-block;
        }

        .tabs-module-adjustment {
            padding-top: 0 !important;
        }

        .for-mobile-only {
            display: block;
        }

        .hide-in-mobile {
            display: none;
        }

        .top-navigation-module-responsive-menu {
            margin-top: 3px;
        }

        .subnavigation-module {
            display: none;
        }

        .search-module {
            display: none;
        }

        .search-bar-module.alt {
            display: block;
            margin-left: -20px;
            margin-right: -20px;
        }

        .search-bar-module .dropdown {
            margin-top: 10px;
        }

        .stacked-media-module-selection-holder {
            overflow-x: auto;
        }

        .stacked-media-module-selection-holder .stacked-media-module-selection {
            left: 0 !important;
        }

        .tabs-module[data-hide-tabs="true"] {
            display: none;
        }

        .detail-page .col-l .social-media-link-ls {
            display: block;
            left: auto;
            right: 0px;
            width: auto;
        }
    }
    /* AUTO-COMPLETE (from current ML.com) - START */

    @media only screen and (max-width: 956px) {
        ul.autocomplete-width {
            width: 98% !important;
        }
    }

    @media only screen and (max-width: 760px) {
        ul.autocomplete-width {
            width: 97% !important;
        }
    }

    @media only screen and (max-width: 640px) {
        ul.autocomplete-width {
            width: 97% !important;
        }
    }
    /*400-500*/
    @media only screen and (max-width: 500px) {
        ul.autocomplete-width {
            width: 95% !important;
        }
    }

    @media only screen and (max-width: 475px) {
        ul.autocomplete-width {
            width: 94.75% !important;
        }
    }

    @media only screen and (max-width: 450px) {
        ul.autocomplete-width {
            width: 94.5% !important;
        }
    }

    @media only screen and (max-width: 425px) {
        ul.autocomplete-width {
            width: 94.25% !important;
        }
    }

    @media only screen and (max-width: 400px) {
        ul.autocomplete-width {
            width: 94% !important;
        }
    }
    /*300-375*/
    @media only screen and (max-width: 375px) {
        ul.autocomplete-width {
            width: 93.75% !important;
        }
    }

    @media only screen and (max-width: 350px) {
        ul.autocomplete-width {
            width: 93.5% !important;
        }
    }

    @media only screen and (max-width: 325px) {
        ul.autocomplete-width {
            width: 92.8% !important;
        }
    }

    @media only screen and (max-width: 300px) {
        ul.autocomplete-width {
            width: 92.5% !important;
        }
    }
    /* AUTO-COMPLETE - END */
    /*Social Share SideBar Responsive*/
    @media only screen and (max-width: 992px) and (min-width: 940px) {
        .social-share {
            left: -8.89999%;
        }
    }

    @media only screen and (max-width: 865px) and (min-width: 780px) {
        .social-share + div {
            margin-left: 20px;
        }
    }

    @media only screen and (max-width: 780px) and (min-width: 641px) {
        .social-share {
            left: 10px;
        }

        .social-share + div {
            margin-left: 40px;
        }

        .page-header.career-trajectory-module-timeline-title > h4 {
            font-size: 14px;
            word-wrap: break-word;
        }

        .career-trajectory-module-timeline-events > ul > li > a > h3 {
            font-size: 12px;
        }

        .page-header.career-trajectory-module-timeline-title > h2 {
            font-size: 42px;
        }
    }

    @media only screen and (max-width: 640px) {
        .social-share {
            left: auto;
            width: auto;
            z-index: 567;
            height: 30px;
            overflow: hidden;
            margin-top: -24px;
            position: relative;
        }

        .social-share + div {
            margin-top: -26px;
            margin-left: -15px;
        }

        .printing {
            display: none !important;
        }
    }
}
