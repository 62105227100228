@use 'sass:math';

$accordion__margin: 16px;
$accordion__toggle-size: 15px;

@mixin accordion-title() {
    align-items: center;
    border: none;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    gap: $accordion__margin;
    justify-content: flex-start;
    min-height: 45px;
    padding: 0;
    transition: background-color $transition-timing $transition-function;
    width: 100%;

    @include for-print() {
        cursor: initial;
        gap: 0;
        min-height: 0px;
        transition: none;
    }
}

@mixin accordion-toggle() {
    color: $color-black;
    font-size: $accordion__toggle-size;
    margin-left: $accordion__margin;
    transition: color $transition-timing $transition-function;

    @include for-print() {
        display: none;
    }
}

@mixin accordion-heading() {
    @include font(18px, 22px);
    @include font-semibold();
    color: $color-black;
    max-width: calc(100% + #{0 - $accordion__toggle-size - ($accordion__margin * 3)});
    padding: 12px 0;
    transition: color $transition-timing $transition-function;
    text-align: left;

    @include for-print() {
        @include font(16px, 15px);
        padding-bottom: 0px;
    }
}

@mixin accordion-panel() {
    height: 0px;
    overflow: hidden;
    transition: height $transition-timing $transition-function;

    @include for-print() {
        height: auto;
        transition: none;
    }
}