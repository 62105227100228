﻿.horizontal-playlist {
    &__accordion-toggle {
        display: inline-block;
        font-size: 22px;
        transition: transform 0.3s ease-in;

        @include respond-to('xs') {
            display: unset;
        }

        &.open {
            transform: rotate(90deg);
        }

        @include for-print() {
            display: none;
        }
    }

    &__button {
        @include font-heading();
        background: $color-white;
        border: 2px solid $color-black;
        color: $color-black;
        cursor: pointer;
        display: block;
        font-size: 16px;
        margin: auto;
        max-width: 350px;
        padding: 15px;
        text-align: center;
        text-transform: uppercase;
        width: 100%;

        @include respond-to('xs') {
            display: none;
        }

        &-container {
            margin: 10px 0 15px 0;
            position: relative;
            text-align: center;
            width: 100%;
        }

        &:focus,
        &:hover {
            background: $color-purple;
            color: $color-white;
        }

        @include for-print() {
            display: none;
        }
    }

    &__description {
        margin: 12px 0;

        @include for-print() {
            margin: 12px 0;
        }
    }

    &__item-container {
        flex: 1 0 calc(25% - 8px);
        margin: 0 4px;
        width: 100%;

        @include respond-to('xs') {
            width: unset;
        }

        @include for-print() {
            // abcpdf doesn't like flexbox
            display: inline-block;
            width: 23%;
        }
    }

    &__items {
        transition: transform 0.3s ease-in-out;

        @include respond-to('xs') {
            display: flex;
            flex-direction: row;
        }

        &-container {
            overflow-x: hidden;
        }
    }

    &__nav-next,
    &__nav-prev {
        display: none;
        min-height: 100%;

        &.disabled {
            visibility: hidden;
        }

        &:focus-visible {
            @include focus-outline(-5px);
        }

        @include respond-to('xs') {
            display: table;
        }

        .icon {
            display: table-cell;
            color: #000;
            cursor: pointer;
            height: 25px;
            font-size: 3rem;
            vertical-align: top;
        }

        @include for-print() {
            display: none;
        }
    }

    &__nav-prev .icon {
        transform: rotate(180deg);
        vertical-align: bottom;
    }

    &__panel {
        overflow-y: hidden;
        transition: height 0.3s ease-in-out;

        @include respond-to('xs') {
            display: flex;
            flex-direction: row;
        }

        &.closed {
            height: 0 !important;

            @include respond-to('xs') {
                height: unset;
            }
        }

        @include for-print() {
            overflow: hidden;
        }
    }

    &__title {
        cursor: pointer;
        font-size: 18px;
        margin-bottom: 12px;
        text-transform: uppercase;

        h3 {
            display: inline-block
        }
    }
}
