@mixin liststyles() {
    list-style-type: disc;
    margin-left: 27px;

    li::marker {
        color: $color-purple-bullet;
        font-size: 24px;

        @include for-print() {
            font-size: 18px;
        }
    }
}
