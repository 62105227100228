@mixin image-grid-card-width($col, $offset: 0px) {
    width: calc(#{100% / #{$col}} - ( ( (#{$col} - 1) * var(--gap) ) / #{$col}) - #{$offset});
}

.image-grid-card {
    align-items: center;
    aspect-ratio: 1/1;
    background-color: var(--image-grid__grid-bg-color);
    display: flex;
    justify-content: center;
    width: 100%;

    @supports not (gap: 1px) {
        margin-bottom: var(--gap);
    }

    @include for-print() {
        page-break-inside: avoid;
        @include image-grid-card-width(3);
    }

    @include respond-to('xxs') {
        @supports not (gap: 1px) {
            &:nth-child(2n) {
                margin-right: 0;
            }

            &:not(:nth-child(2n)) {
                margin-right: var(--gap);
            }
        }

        @include for-print() {
            @include image-grid-card-width(3);

            @supports not (gap: 1px) {
                &:nth-child(2n),
                &:not(:nth-child(2n)) {
                    margin-right: 0;
                }

                &:nth-child(3n) {
                    margin-right: 0;
                }

                &:not(:nth-child(3n)) {
                    margin-right: var(--gap);
                }
            }
        }
    }

    @include respond-to('small') {
        @supports not (gap: 1px) {
            &:nth-child(3n) {
                margin-right: 0;
            }

            &:not(:nth-child(3n)) {
                margin-right: var(--gap);
            }
        }
    }

    @at-root .image-grid-card__layout--two-col {
        .image-grid-card {
            @include image-grid-card-width(1);

            @include respond-to('xxs') {
                @include image-grid-card-width(2);
            }
        }
    }

    @at-root .image-grid-card__layout--three-col {
        .image-grid-card {
            @include image-grid-card-width(1);

            @include respond-to('xxs') {
                @include image-grid-card-width(2);
            }

            @include respond-to('largeipad') {
                @include image-grid-card-width(3);
            }
        }
    }

    @at-root .image-grid-card__layout--four-col {
        .image-grid-card {
            @include image-grid-card-width(1);

            @include respond-to('xxs') {
                @include image-grid-card-width(2);
            }

            @include respond-to('largeipad') {
                @include image-grid-card-width(4);
            }
        }
    }

    &__image,
    & > img {
        height: 80%;
        transition: all 0.3s ease-in-out;
        width: 80%;

        @include for-print() {
            margin: 10%; // for abcpdf
        }
    }

    &--hover {
        &:hover {
            .image-grid-card__image {
                transform: scale(1.2);

                @include for-print() {
                    transform: initial;
                }
            }
        }
    }
}

.experience-editor {
    .image-grid-card {
        // subtract experience editor borders
        @include respond-to('xxs') {
            @include image-grid-card-width(2, 2px);

            @include for-print() {
                @include image-grid-card-width(3, 2px);
            }
        }

        @include respond-to('small') {
            @include image-grid-card-width(3, 2px);
        }
    }
}
