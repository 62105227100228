$square-image-size: 114px;

.animated-infographic-card {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 30px;
    margin-bottom: 30px;
    padding-left: 32px;
    width: 100%;

    @include respond-to('xs') {
        padding-left: 0;
    }

    @include for-print() {
        page-break-inside: avoid;
    }

    &__details {
        padding-left: 10px;
        padding-right: 20px;
        width: calc(100% - #{$square-image-size} - 30px);
    }

    &__title {
        @include font-heading();
        @include font(20px, 21px);
        margin-bottom: 8px;
        margin-top: 16px;
        text-transform: uppercase;
    }

    &__description {
        @include font-content();
        @include font(16px, 20px);
    }

    &__image-container {
        background-repeat: no-repeat;
        height: $square-image-size;
        min-height: $square-image-size;
        width: 100%;

        @include respond-to('xs') {
            height: auto;
            width: $square-image-size;
        }

        @include for-print() {
            display: none;
        }
    }

    .animated-infographic-container__container--one .animated-infographic-container__container--item & {
        flex-direction: column;
    }
}
