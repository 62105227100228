.key-takeaways {

    &__heading {
        @include font-heading();
        @include font(20px, 21px);
        margin-bottom: 15px;
        text-transform: uppercase;

        @include for-print() {
            @include font(14px, 16px);
        }
    }

    &__content {
        ul {
            @include liststyles();
        }

        ul li {
            @include font-medium();
            @include font(18px, 26px);
            color: $color-black;
            display: list-item;
            font-size: 18px;
            margin-bottom: 8px;

            @include for-print() {
                @include font(14px, 16px);
            }
        }
    }
}
