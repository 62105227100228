﻿.pull-quote {
	background-color: var(--backgroundcolor);
	display: flex;
	justify-content: center;
	line-height: 2em;
	margin: auto;
	min-height: 240px;
	overflow: hidden;
	position: relative;
	width: 100%;

	@include for-print() {
		display: none;
	}

	@include respond-to('small') {
		min-height: 300px;
	}

	.structure-two-column &,
	.structure-two-column--halves &,
	.structure-two-column--narrow & {
		min-height: 240px;
	}

	&__content-container {
		display: flex;
		height: 100%;
		margin: auto;
		max-width: $structure-normal;
		overflow: hidden;
		padding-top: 44px;
		padding-bottom: 44px;
		z-index: $z-index-1;

		.structure-two-column &,
		.structure-two-column--halves &,
		.structure-two-column--narrow & {
			margin: auto 16px;
		}
	}

	&__content {
		display: flex;
		flex-direction: column;
		margin: 10px 8px;

		@include respond-to('medium') {
			margin: 10px 15px;
		}

		.structure-two-column--narrow & {
			margin: 10px 8px;
		}
	}

	&__image {
		background-position-x: center;
		background-position-y: top;
		background-repeat: no-repeat;
		background-size: cover;
		height: 100%;
		position: absolute;
		width: 100%;
	}

	&__overlay {
		background-color: rgba(0,0,0,.45);
		height: 100%;
		position: absolute;
		width: 100%;
		z-index: $z-index-0;

		@include for-print() {
			background: none;
			position: relative;
		}
	}

	&__text {

		@include font-content();
		@include font(22px, 25px);
		color: var(--textcolor);

		&:hover {
			color: $color-purple;
			opacity: 1;
		}

		&--lighten {
			&:hover {
				color: var(--textcolor);
				opacity: .8;
			}
		}

		@include respond-to('small') {
			@include font(36px, 41px);
		}

		.structure-two-column &,
		.structure-two-column--halves & {
			@include font(22px, 25px);
			padding-bottom: 24px;
			padding-top: 24px;
		}

		.structure-two-column--narrow & {
			@include font(22px, 25px);

			@include respond-to('small') {
				@include font(18px, 25px);
			}
		}
	}

	&__mark {

		&--hide {
			display: none;
		}

		&::before {
			@include font-heading-alt();
			@include font(90px, 95px);
			color: var(--quotecolor);
			content: '“';
			letter-spacing: 0px;
			margin-left: 15px;
			transform: rotateX(180deg);

			@include respond-to('small') {
				@include font(120px, 95px);
			}

			.structure-two-column--skewed-left &,
			.structure-two-column--skewed-right &,
			.structure-two-column--narrow & {
				@include font(90px, 95px);

				@include respond-to('small') {
					@include font(70px, 95px);
				}

				margin-left: 0px;
			}

			.structure-two-column--halves & {
				@include font(95px, 95px);
			}
		}

		&--reverse {
			margin-right: 0px;
			margin-bottom: .5em;
			transform: scale(-1);

			@include respond-to('largeipad') {
				margin-right: 15px;
			}

			.structure-two-column--skewed-left &,
			.structure-two-column--skewed-right &,
			.structure-two-column--narrow & {
				margin-bottom: 0px;
				margin-right: 0px;
			}

			&::after {
				@include font-heading-alt();
				@include font(90px, 95px);
				color: var(--quotecolor);
				content: '“';
				letter-spacing: 0px;

				.structure-two-column--skewed-left &,
				.structure-two-column--skewed-right &,
				.structure-two-column--narrow & {
					@include font(90px, 95px);

					@include respond-to('small') {
						@include font(70px, 95px);
					}

					margin-bottom: 0px;
				}

				.structure-two-column--halves & {
					@include font(95px, 95px);
				}

				@include respond-to('small') {
					@include font(120px, 95px);
				}
			}
		}

		&--end {
			bottom: 0px;
			right: 0;
		}
	}

	&__accreditation {
		&-primary {
			@include font(16px, 19px);
			@include font-heading();
			color: var(--secondarycolor);
			letter-spacing: 0px;
			margin-top: 1em;
			text-align: end;
			text-transform: uppercase;

			@include respond-to('xs') {
				@include font(18px, 19px);
			}

			.structure-two-column--narrow & {
				@include font(14px, 19px);
			}
		}

		&-secondary {
			@include font(16px, 22px);
			color: var(--textcolor);
			text-align: end;
		}
	}
}
