$case-study-carousel-max-height: 481px;
$case-study-carousel-max-width: 569px;

.case-study-carousel-slide {
    box-shadow: 0px 3px 6px $color-gray50;
    height: 100%;
    overflow: hidden;
    width: calc(100% - 64px);

    @include respond-to('xs') {
        height: $case-study-carousel-max-height;
        max-height: $case-study-carousel-max-height;
        max-width: $case-study-carousel-max-width;
        width: $case-study-carousel-max-width;
    }

    @include for-print() {
        box-shadow: none !important;
        display: flex;
        height: auto;
        max-height: unset;
        max-width: unset;
        padding-bottom: 24px;
        page-break-after: auto;
        page-break-inside: avoid;
        position: relative;
        transform: none !important;
        width: 100%;

        .swiper-slide-shadow-left,
        .swiper-slide-shadow-right {
            display: none;
        }
    }

    &:focus-within {
        @include focus-outline();
    }

    &__background {
        height: $case-study-carousel-max-height;
        width: 100%;

        .experience-editor__image {
            left: auto;
            right: 0;
        }

        @include for-print() {
            height: 100px;
            width: auto;
        }
    }

    &__background-image {
        background-position: center;
        background-size: cover;
        height: 100%;
        width: 100%;

        @include for-print() {
            height: 100px;
            width: 100px;
        }
    }

    &__content {
        background-color: rgba(255, 255, 255, 0.8);
        bottom: 0;
        height: calc(256px - 64px);
        padding: 32px;
        position: absolute;
        width: calc(100% - 64px);
        @include richtext();

        @include for-print() {
            background: none;
            display: block;
            height: auto;
            opacity: 1 !important;
            padding: 0 13px;
            position: relative;
            transition: none !important;
            visibility: visible !important;
        }

        &--hoverable {
            cursor: pointer;
        }

        .experience-editor & {
            opacity: 1 !important;
            visibility: visible !important;
        }

        &:focus-visible {
            outline: none;
        }
    }

    &__heading {
        @include font-heading();
        @include font(24px, 25px);
        color: $color-black;
        margin-bottom: 16px;
        text-transform: uppercase;
        transform: translate3d(0,0,0);

        @include for-print() {
            @include font(14px, 15px);
            margin-bottom: 8px;
        }
    }

    &__abstract,
    &__abstract > p {
        @include font(16px, 19px);
        color: $color-black;
        margin-bottom: 16px;
        position: relative;
        transform: translate3d(0,0,0);

        @include for-print() {
            @include font(14px, 16px);
            margin-bottom: 0;
        }
    }

    &__abstract {
        display: inline-block;
        max-height: 98px;
        overflow: hidden;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 5;

        @include respond-to('xs') {
            max-height: 118px;
            -webkit-line-clamp: 6;
        }

        @include for-print() {
            max-height: unset;
            -webkit-line-clamp: unset;
        }
    }

    &__call-to-action,
    &__call-to-action > a {
        @include font(16px, 19px);
        color: $color-black;
        font-weight: 600;

        @include for-print() {
            display: none;
        }

        .icon {
            @include font(14px, 19px);
            text-align: center;
        }

        &:hover {
            color: $color-purple;
        }
    }

    &.swiper-slide-active .swiper-slide-shadow-left,
    &.swiper-slide-active .swiper-slide-shadow-right {
        // seems verbose - but necessary to prevent a bug in SwiperJS where active slide gets shaded
        display: none;
    }

    &.swiper-slide-active &__content {
        opacity: 1;
        visibility: visible;
        transition-delay: 0s;

        .case-study-carousel-slide__abstract {
            display: -webkit-box;
            text-overflow: ellipsis;
        }
    }

    &.swiper-slide-prev:hover, 
    &.swiper-slide-next:hover {
        cursor: pointer;
    }

    &:not(.swiper-slide-active) &__content {
        opacity: 0;
        visibility: hidden;
        transition: visibility 0.3s linear 0.3s, opacity 0.3s linear;
    }
}
