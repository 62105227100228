﻿$footer__image-width: 30px;

.footer {
    @include for-print() {
        margin: 0 auto;
        width: $print-width;
    }

    &__bottom {
        background-color: $color-white;        
        border-top: 1px solid $color-footer-grey;
        bottom: 0;
        left: 0;
        margin: auto;
        opacity: 1;
        padding: 10px 0;
        position: fixed;
        width: 100%;
        z-index: calc($z-index-nav); 

        .footer__right {
            margin-top: 0;
        }

        @include for-print() {
            position: relative;
            border: none;
        }
        
    }

    &__row {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        max-width: 940px;

        @include responsive-component-margin-unstructured($global-page-margin);

        @include respond-to('medium') {
            align-items: initial;
            flex-direction: row;
            justify-content: space-between;
        }

        @include for-print() {
            margin-left: 0;
            marging-right: 0;
        }
    }

    &__top {
        background-color: $color-footer-grey;
        padding: 24px 0 58px;

        @include for-print() {
            display: none;
        }
    }    

    &__left {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        @include respond-to('medium') {
            display: initial;
            flex-wrap: initial;
            justify-content: initial;
        }

        @include for-print() {
            display: none;
        }
    }

    &__left--two-thirds {
        width: 100%;

        @include respond-to('medium') {
            width: 72%;
        }
    }

    &__right {
        margin-top: 10px;

        @include respond-to('medium') {
            margin-top: initial;
        }

        @include for-print() {
            @include font(14px, 16px);
            width: 100%;
        }
    }

    &__right--secondary {
        @include font-content();
        @include font(12px, 15px);
        color: $color-black;
        margin-top: 8px;
        text-align: center;
        width: 100%;

        @include for-print() {
            text-align: left;
        }
    }

    &__link {
        float: left;
        margin-right: 16px;

        &-image {
            aspect-ratio: auto 1/1;
            width: $footer__image-width;
        }

        &:focus,
        &:hover {
            color: $color-purple;
            text-decoration: underline;
        }
    }

    &__link--primary {
        @include font-semibold();
        color: $color-black;
        font-size: $font-size-xsmall;
        line-height: 33px;
    }

    &__link--social {
        height: 30px;
        margin-left: 12px;
        margin-right: 0;
        overflow: hidden;
        position: relative;
        width: 30px;

        &:first-of-type {
            margin-left: 0;
        }

        &:focus,
        &:hover {
            .footer__link-image {
                aspect-ratio: auto 1/1;
                left: 0;
                opacity: 1;
                position: absolute;
                top: 0;
                z-index: 1;
                width: $footer__image-width;
            }
        }

        @include respond-to('medium') {
            &:hover {
                .footer__link-image--hover {
                    opacity: 1;
                }
            }
        }
    }

    &__link--secondary {
        @include font-content();
        color: $color-black;
        font-size: $font-size-xxsmall;
    }

    &__link-image--hover {
        aspect-ratio: auto 1/1;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 0;
        z-index: 1;
        width: $footer__image-width;
    }
}
