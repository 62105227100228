.print-wrapper {
    &.career-body {
        @include font-content();

        div {
            p {
                @include font-content();
                font-size: 14px;

                a {
                    text-decoration: none;
                }
            }
        }

        .intro {
            @include font-content();
            font-size: 14px;
            line-height: 1.42857143;
        }

        .module-header > h2 {
            background: none;
            color: $color-black;
            font-size: 18px;
            line-height: 18px;
            padding-left: 0;
        }

        .print-footer {
            margin: auto;
            max-width: 650px;
        }

        .tabs-module {
            margin-top: 0;
            padding-top: 30px;

            .tabs-module-holder {
                padding-left: 0;

                .nav-tabs.nav-justified > .active > a {
                    color: $color-black;
                    float: left;
                    font-size: 24px;
                    line-height: 24px;
                    margin-bottom: 20px;
                    padding: 0;
                }
            }
        }
        .print-hide {
            display: none !important;
        }

        #content-wrapper,
        .tabs-module,
        .tab-content,
        #footer,
        .ml-wrapper .one-column-container {
            margin: 0 auto;
            max-width: 650px;
        }

        #content-wrapper ~ .one-column-container {
            padding-top: 30px;
        }
        /*Header Print*/
        .print-head {
            background: $color-purple;
            border-bottom: 3px solid #000000;

            .container {
                height: 43px;

                .right {
                    float: right;
                    padding-top: 8px;
                }

                ul {
                    li {
                        float: left;
                        margin-left: 17px;

                        a {
                            cursor: pointer;
                            pointer-events: all;
                        }
                    }
                }
            }
        }

        .print-wrapper .container {
            width: 650px;
        }

        .print-wrapper ul {
            margin-bottom: 10px;
            list-style-type: none;
        }

        .print-wrapper ul li {
            position: relative;
        }

        .print-wrapper ul li, .print-wrapper ol li {
            font-size: 14px;
        }

        .print-wrapper .logo-wrapper {
            padding-bottom: 40px;
            text-align: center;
        }

        .logo-wrapper {
            text-align: center;
            margin-top: 5px;
        }
        /*Footer Print*/
        .print-footer {
            padding-top: 15px;
        }

        .print-footer p {
            font-size: 14px;
        }

        .print-footer p {
            font-size: 14px;
        }
        /* Hidden Modules*/
        .social-share,
        .search-module,
        .breadcrumbs-module,
        .jump-link-module {
            display: none !important;
        }

        body {
            font-size: 14px;
            color: #414042;
        }

        .intro {
            color: #000 !important;
            font-size: 14px !important;
            line-height: 1.42857143;
        }

        a {
            pointer-events: none;
            cursor: default;
        }

        .link-thru {
            display: none !important;
        }

        .jumbotron h1 {
            line-height: 1;
            padding-bottom: 0px !important;
            margin-top: -3px !important;
            margin-bottom: 0 !important;
            font-size: 36px !important;
            line-height: 36px !important;
            color: #000;
            margin-left: auto;
            margin-right: auto;
            max-width: 780px;
            width: 100%;
            position: relative;
        }

        .jumbotron h1 br {
            display: none;
        }

        .module-header > h2 {
            background: none;
            color: black;
            cursor: default;
            font-size: 18px;
            padding-left: 0;
            line-height: 18px;
        }

        .col-md-6,
        .col-md-5,
        .col-lg-pull-6,
        .col-lg-7 {
            width: 100% !important;
        }

        .col-lg-pull-6 {
            right: 0px;
        }

        .col-lg-push-6 {
            left: 0px;
        }


        .collapse {
            display: block !important;
        }
        /*Infographic Module*/

        .info-graphic-module .info-graphic-module-wrapper {
            pointer-events: none;
            cursor: default;
        }

        .info-graphic-overlay {
            display: none;
        }
        /*Copy Module*/
        div.copy-module-text a {
            text-decoration: none;
        }

        .page-header {
            padding-bottom: 0px !important;
            margin: 0px !important;
        }

        .copy-module-text .table-container {
            width: 50%;
        }

        .copy-module-text .table-container .table {
            pointer-events: none;
        }
        /*Containers*/
        .two-column-container {
            .column-container-content {
                padding-top: 20px !important;
                max-width: none;
            }

            .column-container-content-bordered {
                max-width: none;
            }
        }

        .one-column-container .column-container-content {
            padding-top: 5px !important;
            padding-bottom: 25px !important;
        }

        .column-container-content-bordered {
            padding-top: 0 !important;
            padding-bottom: 25px !important;
            border-top: none !important;
            border-bottom: none !important;
        }

        .col-md-5.pull-right {
            float: none;
        }
        /*Stacked Media*/
        .stacked-media-module-controls-holder {
            display: none;
        }

        .stacked-media-module-overlay-wrapper {
            display: none;
        }

        .stacked-media-module-overlay {
            display: none;
        }
        /*Tabs*/
        li.nav {
            display: none !important;
        }

        li.nav.active {
            display: block !important;
            width: 100% !important;
        }

        .tabs-module-holder {
            padding: 0;
        }

        .tabs-module {
            margin-top: 0;
            padding-top: 30px;

            .nav-tabs-wrapper {
                min-height: 0;
            }
        }

        .tabs-module[data-hide-tabs=true] {
            margin: 0 !important;
            padding: 0 !important;
        }

        .tabs-module-holder .nav-tabs.nav-justified > .active > a {
            float: left;
            padding: 0;
            font-size: 24px;
            line-height: 24px;
            color: black;
            margin-bottom: 20px;
        }

        .tab-content .tab-pane.fade.active {
            display: block !important;
        }

        .tab-content .tab-pane.fade {
            display: none !important;
        }

        .for-mobile-only {
            display: none !important;
        }
        /*Accordion*/
        .read-more-module-content-trigger {
            display: none;
        }

        img.accordion-icon {
            display: none;
        }

        .accordion-module {
            margin: 0px !important;
        }

        .accordion-module-content {
            padding: 0px !important;
        }

        .accordion-icon-title-container .stack {
            width: 0px;
        }

        .accordion-icon-title-container .title-collapsed {
            font-size: 18px;
        }
        /*Cinemagraph*/
        .career-body {
            .cinemagraph-module-overlay {
                background-color: #fff;
            }
        }


        .cinemagraph-module-overlay {
            height: auto !important;
        }

        .cinemagraph-module-banner {
            height: auto !important;

            h1 {
                div[style] {
                    text-shadow: none !important;
                }
            }
        }

        .community_main[style] {
            background: none !important;
        }

        .community_main {
            margin: 0 !important;
            padding: 10px 0 !important;

            .community_holder {
                margin: 0;
                padding: 0;
                width: 100%;

                .community_header {
                    font-size: 18px;
                    padding: 0;
                }

                .community_body {
                    font-size: 14px;
                    padding: 9px 0 0 0;
                }
            }
        }

        #the-video {
            display: none !important;
        }

        .cinema-item.img-thumbnail {
            display: none;
        }

        .youtube-container {
            display: none;
        }

        .cinemagraph-module img.cinema-item {
            display: none !important;
        }
        /*Career Trajectory*/
        .career-trajectory-module {
            width: 443px;
        }

        .career-trajectory-module .col-lg-6 {
            width: 50% !important;
        }

        .career-trajectory-module-timeline-events > ul {
            top: 5px;
        }
        /*Awards Module*/
        .awards-module .col-md-6 {
            width: 50% !important;
        }
        /*Social Share SideBar Responsive*/
        @media only screen and (max-width: 640px) {
            .ml-wrapper {
                padding-top: 0px;
            }

            .cinemagraph-module-banner.jumbotron {
                padding: 0 !important;
            }

            .tabs-module {
                padding-left: 0;

                .nav-tabs-wrapper {
                    margin: 0;
                    width: 100%;
                }
            }
        }

        @media only screen and (max-width: 865px) and (min-width:641px) {
            .print-footer {
                margin: 0;
                margin-left: 35px;
            }

            .jumbotron h1 {
                padding-left: 15px;
            }

            .tabs-module-holder {
                padding: 0 0 0 15px;
            }
        }

        @media only screen and (max-width: 992px) {
            .cinemagraph-module-banner.jumbotron {
                padding: 0 !important;
            }

            .one-column-container,
            .two-column-container {
                padding: 0 !important;
            }
        }

        @media only screen and (max-width: 780px) and (min-width: 641px) {
            .social-share + div {
                margin-left: 0 !important;
            }
        }

        @media only screen and (max-width: 865px) and (min-width: 780px) {
            .social-share + div {
                margin: 0;
            }
        }
        /*Print Styles*/
        @media print {
            a[href]:after {
                content: none !important;
            }

            .jumbotron h1 {
                margin-left: 0;
            }

            .jumbotron h1 br {
                display: none;
            }

            body {
                margin: 50px 50px 50px 50px;
            }

            .print-head {
                border-bottom: none !important;
            }

            a[href]:after {
                content: none !important;
            }

            .print-head .right ul li a img {
                display: none;
            }

            @page :left {
                margin-left: 50px;
                margin-right: 50px;
            }

            @page :right {
                margin-left: 50px;
                margin-right: 50px;
            }

            @page {
                margin: 1cm;
            }

            .copy-module-text ul li {
                background: url('/contents/images/arrow-light-grey1.png') no-repeat 0 5px !important;
                -webkit-print-color-adjust: exact !important;
            }

            .learn-more-icon {
                background: url('/contents/images/arrow-light-grey1.png') no-repeat 0 5px !important;
                -webkit-print-color-adjust: exact !important;
            }

            .career-trajectory-module {
                background-color: #000 !important;
                padding: 40px 40px;
                margin-top: 20px;
                -webkit-print-color-adjust: exact !important;
            }

            .career-trajectory-module-wrapper .page-header.identity-title h1,
            .career-trajectory-module-wrapper .page-header.identity-title h2,
            .career-trajectory-module-wrapper .page-header.identity-title h4 {
                color: #fff !important;
                margin-top: 0 !important;
                margin-bottom: 0 !important;
                -webkit-print-color-adjust: exact !important;
            }

            .career-trajectory-module-timeline-events > ul > li > a > h3 {
                color: #fff !important;
            }

            .career-trajectory-module-timeline-item p {
                color: #fff !important;
            }

            .career-trajectory-module-timeline-item .page-header h2 {
                color: $color-purple !important;
            }

            .footer-inner-wrapper {
                margin-left: 0;
            }
        }
    }
}
