﻿@font-face {
  font-family: "FuturaW01-ExtraBoldCond";
  src: url("../../contents/fonts/FuturaW01-ExtraBoldCond/bec66ca3-753a-469a-b153-fdd9e3ea7ce4.eot?#iefix");
  src: url("../../contents/fonts/FuturaW01-ExtraBoldCond/bec66ca3-753a-469a-b153-fdd9e3ea7ce4.eot?#iefix")
      format("embedded-opentype"),
    url("../../contents/fonts/FuturaW01-ExtraBoldCond/04733668-9f3f-4f2f-a534-14fb44683f83.woff")
      format("woff"),
    url("../../contents/fonts/FuturaW01-ExtraBoldCond/1f9bced0-1224-4785-9553-68fcbac00914.ttf")
      format("truetype"),
    url("../../contents/fonts/FuturaW01-ExtraBoldCond/23d05127-305c-4753-8f3a-16d8109db5ca.svg#23d05127-305c-4753-8f3a-16d8109db5ca")
      format("svg");
  font-display: swap;
}

/*
	Copyright (C) Hoefler & Co.
	This software is the property of Hoefler & Co. (H&Co).
	Your right to access and use this software is subject to the
	applicable License Agreement, or Terms of Service, that exists
	between you and H&Co. If no such agreement exists, you may not
	access or use this software for any purpose.
	This software may only be hosted at the locations specified in
	the applicable License Agreement or Terms of Service, and only
	for the purposes expressly set forth therein. You may not copy,
	modify, convert, create derivative works from or distribute this
	software in any way, or make it accessible to any third party,
	without first obtaining the written permission of H&Co.
	For more information, please visit us at http://typography.com.
*/
@font-face {
  font-family: "HCoWhitney";
  src: url("../../contents/fonts/Whitney/woff2/Whitney-Light_Web.woff2")
      format("woff2"),
    url("../../contents/fonts/Whitney/woff/Whitney-Light_Web.woff")
      format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "HCoWhitney";
  src: url("../../contents/fonts/Whitney/woff2/Whitney-LightItal_Web.woff2")
      format("woff2"),
    url("../../contents/fonts/Whitney/woff/Whitney-LightItal_Web.woff")
      format("woff");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "HCoWhitney";
  src: url("../../contents/fonts/Whitney/woff2/Whitney-Book_Web.woff2")
      format("woff2"),
    url("../../contents/fonts/Whitney/woff/Whitney-Book_Web.woff")
      format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "HCoWhitney";
  src: url("../../contents/fonts/Whitney/woff2/Whitney-BookItal_Web.woff2")
      format("woff2"),
    url("../../contents/fonts/Whitney/woff/Whitney-BookItal_Web.woff")
      format("woff");
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "HCoWhitney";
  src: url("../../contents/fonts/Whitney/woff2/Whitney-Medium_Web.woff2")
      format("woff2"),
    url("../../contents/fonts/Whitney/woff/Whitney-Medium_Web.woff")
      format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "HCoWhitney";
  src: url("../../contents/fonts/Whitney/woff2/Whitney-MediumItal_Web.woff2")
      format("woff2"),
    url("../../contents/fonts/Whitney/woff/Whitney-MediumItal_Web.woff")
      format("woff");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "HCoWhitney";
  src: url("../../contents/fonts/Whitney/woff2/Whitney-Semibld_Web.woff2")
      format("woff2"),
    url("../../contents/fonts/Whitney/woff/Whitney-Semibld_Web.woff")
      format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "HCoWhitney";
  src: url("../../contents/fonts/Whitney/woff2/Whitney-SemibldItal_Web.woff2")
      format("woff2"),
    url("../../contents/fonts/Whitney/woff/Whitney-SemibldItal_Web.woff")
      format("woff");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "HCoWhitney";
  src: url("../../contents/fonts/Whitney/woff2/Whitney-Bold_Web.woff2")
      format("woff2"),
    url("../../contents/fonts/Whitney/woff/Whitney-Bold_Web.woff")
      format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "HCoWhitney";
  src: url("../../contents/fonts/Whitney/woff2/Whitney-BoldItal_Web.woff2")
      format("woff2"),
    url("../../contents/fonts/Whitney/woff/Whitney-BoldItal_Web.woff")
      format("woff");
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "HCoWhitney";
  src: url("../../contents/fonts/Whitney/woff2/Whitney-Black_Web.woff2")
      format("woff2"),
    url("../../contents/fonts/Whitney/woff/Whitney-Black_Web.woff")
      format("woff");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "HCoWhitney";
  src: url("../../contents/fonts/Whitney/woff2/Whitney-BlackItal_Web.woff2")
      format("woff2"),
    url("../../contents/fonts/Whitney/woff/Whitney-BlackItal_Web.woff")
      format("woff");
  font-weight: 800;
  font-style: italic;
  font-display: swap;
}

/*FrutigerNextPanEuropeanCondensedBlack*/
@font-face {
    font-family: "Frutiger Next Paneuropean Condensed Black";
    src: url("../../contents/fonts/FrutigerNextPaneuropean-CondensedBlack/FrutigerNextPaneuropeanCondensedBlack_normal_condensed.woff2") 
        format("woff2"),
        url("../../contents/fonts/FrutigerNextPaneuropean-CondensedBlack/FrutigerNextPaneuropeanCondensedBlack_normal_condensed.woff") 
        format("woff");
    font-weight: normal;
    font-style: normal;
}

/* Yu Gothic */
@font-face {
  font-family: "Yu Gothic Pr6 R";
  src: url("../fonts/YuGothic/d1fb43b4-f925-4bde-ab1d-0b6297f8dec8.eot?#iefix");
  src: url("../fonts/YuGothic/d1fb43b4-f925-4bde-ab1d-0b6297f8dec8.eot?#iefix")
      format("eot"),
    url("../fonts/YuGothic/3e301271-d8ba-44d2-bfa6-2980a4bddc75.woff2")
      format("woff2"),
    url("../fonts/YuGothic/018a4e77-ebea-4fae-b4e8-f7f23e56d53c.woff")
      format("woff"),
    url("../fonts/YuGothic/ffa02c6f-a976-4843-8914-3f7501558a91.ttf")
      format("truetype"),
    url("../fonts/YuGothic/d5cfb1ac-1947-4def-a524-3901ebf0e2e0.svg#d5cfb1ac-1947-4def-a524-3901ebf0e2e0")
      format("svg");
  font-display: swap;
}

@font-face {
  font-family: "Yu Gothic Pr6 M";
  src: url("../fonts/YuGothic/21b78508-d1c9-488a-aec1-435d16b38454.eot?#iefix");
  src: url("../fonts/YuGothic/21b78508-d1c9-488a-aec1-435d16b38454.eot?#iefix")
      format("eot"),
    url("../fonts/YuGothic/244d369f-d2a9-4d69-9e92-3a99dba43a58.woff2")
      format("woff2"),
    url("../fonts/YuGothic/eb9056c8-eba6-43e7-9c33-4a1eee89c981.woff")
      format("woff"),
    url("../fonts/YuGothic/01953334-84b1-4c25-869b-950c7418ea69.ttf")
      format("truetype"),
    url("../fonts/YuGothic/88807cf8-adc6-4abc-84b1-94800ca94703.svg#88807cf8-adc6-4abc-84b1-94800ca94703")
      format("svg");
  font-display: swap;
}

@font-face {
  font-family: "Yu Gothic Pr6 B";
  src: url("../fonts/YuGothic/e6deb3ac-e499-41e0-a775-64cad2058316.eot?#iefix");
  src: url("../fonts/YuGothic/e6deb3ac-e499-41e0-a775-64cad2058316.eot?#iefix")
      format("eot"),
    url("../fonts/YuGothic/709cb4d5-3d9a-41c6-8697-53d3e3a67bb6.woff2")
      format("woff2"),
    url("../fonts/YuGothic/4b897fd5-9e42-4e66-aab4-760abb41b50e.woff")
      format("woff"),
    url("../fonts/YuGothic/093632f1-675d-4e6a-a3ee-e6ab3c85a94b.ttf")
      format("truetype"),
    url("../fonts/YuGothic/9059d525-82a7-4518-afee-ae2037986eaa.svg#9059d525-82a7-4518-afee-ae2037986eaa")
      format("svg");
  font-display: swap;
}

@font-face {
  font-family: "Yu Gothic Pr6N D Semi Bold";
  src: url("../fonts/YuGothic/59762577-8801-4573-9831-0e83546149cd.eot?#iefix");
  src: url("../fonts/YuGothic/59762577-8801-4573-9831-0e83546149cd.eot?#iefix")
      format("eot"),
    url("../fonts/YuGothic/ac2ebfd3-c3d8-49e5-bb06-daa240436dfc.woff2")
      format("woff2"),
    url("../fonts/YuGothic/dc96e8d8-045c-46f0-9f68-b1e0c2f377d2.woff")
      format("woff"),
    url("../fonts/YuGothic/f867848e-c3d9-4ed7-9431-8f5ade762c13.ttf")
      format("truetype"),
    url("../fonts/YuGothic/59b3b18e-14d5-4205-baac-ac837fb48e80.svg#59b3b18e-14d5-4205-baac-ac837fb48e80")
      format("svg");
  font-display: swap;
}

@font-face {
  font-family: "Yu Gothic Pr6N D Semi Bold";
  src: url("../fonts/YuGothic/59762577-8801-4573-9831-0e83546149cd.eot?#iefix");
  src: url("../fonts/YuGothic/59762577-8801-4573-9831-0e83546149cd.eot?#iefix")
      format("eot"),
    url("../fonts/YuGothic/ac2ebfd3-c3d8-49e5-bb06-daa240436dfc.woff2")
      format("woff2"),
    url("../fonts/YuGothic/dc96e8d8-045c-46f0-9f68-b1e0c2f377d2.woff")
      format("woff"),
    url("../fonts/YuGothic/f867848e-c3d9-4ed7-9431-8f5ade762c13.ttf")
      format("truetype"),
    url("../fonts/YuGothic/59b3b18e-14d5-4205-baac-ac837fb48e80.svg#59b3b18e-14d5-4205-baac-ac837fb48e80")
      format("svg");
  font-display: swap;
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Yu Gothic Std E Extra Bold";
  src: url("../fonts/YuGothic/385102af-845c-4bdd-a21c-03c57d95898a.eot?#iefix");
  src: url("../fonts/YuGothic/385102af-845c-4bdd-a21c-03c57d95898a.eot?#iefix")
      format("eot"),
    url("../fonts/YuGothic/8f1ff443-e920-4d96-a92e-1e4ac7eed1ab.woff2")
      format("woff2"),
    url("../fonts/YuGothic/11227b8c-f25c-4008-a337-9fd04bc4dfbd.woff")
      format("woff"),
    url("../fonts/YuGothic/b9888672-c3bb-4aa1-abff-1c0f8d02450f.ttf")
      format("truetype"),
    url("../fonts/YuGothic/d9d67844-cd85-4f0d-b16f-f0a486f2ac23.svg#d9d67844-cd85-4f0d-b16f-f0a486f2ac23")
      format("svg");
  font-display: swap;
}

@font-face {
  font-family: "Yu Gothic Std H";
  src: url("../fonts/YuGothic/5f32e900-3c81-4bde-9a2b-fb9f847671ca.eot?#iefix");
  src: url("../fonts/YuGothic/5f32e900-3c81-4bde-9a2b-fb9f847671ca.eot?#iefix")
      format("eot"),
    url("../fonts/YuGothic/8323b4a5-19e0-49b3-87d9-cf5176a64cca.woff2")
      format("woff2"),
    url("../fonts/YuGothic/12a5c03a-2448-453d-a24b-c1f52180dbe9.woff")
      format("woff"),
    url("../fonts/YuGothic/15b56d78-dd3f-4a4e-a6cf-3e1fcbd789d7.ttf")
      format("truetype"),
    url("../fonts/YuGothic/79327cdf-db8e-4428-b950-786d64ea4565.svg#79327cdf-db8e-4428-b950-786d64ea4565")
      format("svg");
  font-display: swap;
}

@font-face {
  font-family: "Yu Gothic Pr6 L";
  src: url("../fonts/YuGothic/1fbbde57-e03c-4534-bf93-cf7b5f94ac64.eot?#iefix");
  src: url("../fonts/YuGothic/1fbbde57-e03c-4534-bf93-cf7b5f94ac64.eot?#iefix")
      format("eot"),
    url("../fonts/YuGothic/8e80a12b-55b2-46e8-8487-54790016bc67.woff2")
      format("woff2"),
    url("../fonts/YuGothic/59b6b536-d7a7-4c2b-9d95-2cda494f5134.woff")
      format("woff"),
    url("../fonts/YuGothic/65a3f1ce-8c19-4eb4-99a6-9a12236bff0e.ttf")
      format("truetype"),
    url("../fonts/YuGothic/8d621914-912d-4ac2-9b2d-9175e16dcd4b.svg#8d621914-912d-4ac2-9b2d-9175e16dcd4b")
      format("svg");
  font-display: swap;
}
/* web font start */
/*@import url("http://fast.fonts.net/t/1.css?apiType=css&projectid=20d74cc5-7f7c-49a7-80ae-fa2f389c550d");*/
@font-face {
  font-family: "FuturaW01-ExtraBoldCond";
  src: url("../fonts/bec66ca3-753a-469a-b153-fdd9e3ea7ce4.eot?#iefix");
  src: url("../fonts/bec66ca3-753a-469a-b153-fdd9e3ea7ce4.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/04733668-9f3f-4f2f-a534-14fb44683f83.woff") format("woff"),
    url("../fonts/1f9bced0-1224-4785-9553-68fcbac00914.ttf") format("truetype"),
    url("../fonts/23d05127-305c-4753-8f3a-16d8109db5ca.svg#23d05127-305c-4753-8f3a-16d8109db5ca")
      format("svg");
}

// not sure if the whitney ones after this are used
@font-face {
  font-family: "whitneybook";
  src: url("../fonts/whitney-book-bas-webfont.eot");
  src: url("../fonts/whitney-book-bas-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/whitney-book-bas-webfont.woff2") format("woff2"),
    url("../fonts/whitney-book-bas-webfont.woff") format("woff"),
    url("../fonts/whitney-book-bas-webfont.ttf") format("truetype"),
    url("../fonts/whitney-book-bas-webfont.svg#whitneybook") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "whitneymedium";
  src: url("../fonts/whitney-medium-bas-webfont.eot");
  src: url("../fonts/whitney-medium-bas-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/whitney-medium-bas-webfont.woff2") format("woff2"),
    url("../fonts/whitney-medium-bas-webfont.woff") format("woff"),
    url("../fonts/whitney-medium-bas-webfont.ttf") format("truetype"),
    url("../fonts/whitney-medium-bas-webfont.svg#whitneymedium") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "whitneysemibold";
  src: url("../fonts/whitney-semibold-bas-webfont.eot");
  src: url("../fonts/whitney-semibold-bas-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/whitney-semibold-bas-webfont.woff2") format("woff2"),
    url("../fonts/whitney-semibold-bas-webfont.woff") format("woff"),
    url("../fonts/whitney-semibold-bas-webfont.ttf") format("truetype"),
    url("../fonts/whitney-semibold-bas-webfont.svg#whitneysemibold")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "whitney_condensedbold";
  src: url("../fonts/whitneycond-bold-webfont.eot");
  src: url("../fonts/whitneycond-bold-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/whitneycond-bold-webfont.woff2") format("woff2"),
    url("../fonts/whitneycond-bold-webfont.woff") format("woff"),
    url("../fonts/whitneycond-bold-webfont.ttf") format("truetype"),
    url("../fonts/whitneycond-bold-webfont.svg#whitney_condensedbold")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

//Use for fallback fonts for Japanese
@font-face {
  font-family: "Yu Gothic Pr6 R";
  src: url("../fonts/YuGothic/d1fb43b4-f925-4bde-ab1d-0b6297f8dec8.eot?#iefix");
  src: url("../fonts/YuGothic/d1fb43b4-f925-4bde-ab1d-0b6297f8dec8.eot?#iefix")
      format("eot"),
    url("../fonts/YuGothic/3e301271-d8ba-44d2-bfa6-2980a4bddc75.woff2")
      format("woff2"),
    url("../fonts/YuGothic/018a4e77-ebea-4fae-b4e8-f7f23e56d53c.woff")
      format("woff"),
    url("../fonts/YuGothic/ffa02c6f-a976-4843-8914-3f7501558a91.ttf")
      format("truetype"),
    url("../fonts/YuGothic/d5cfb1ac-1947-4def-a524-3901ebf0e2e0.svg#d5cfb1ac-1947-4def-a524-3901ebf0e2e0")
      format("svg");
  font-display: swap;
  font-weight: 400;
}
@font-face {
  font-family: "Yu Gothic Pr6N D Semi Bold";
  src: url("../fonts/YuGothic/59762577-8801-4573-9831-0e83546149cd.eot?#iefix");
  src: url("../fonts/YuGothic/59762577-8801-4573-9831-0e83546149cd.eot?#iefix")
      format("eot"),
    url("../fonts/YuGothic/ac2ebfd3-c3d8-49e5-bb06-daa240436dfc.woff2")
      format("woff2"),
    url("../fonts/YuGothic/dc96e8d8-045c-46f0-9f68-b1e0c2f377d2.woff")
      format("woff"),
    url("../fonts/YuGothic/f867848e-c3d9-4ed7-9431-8f5ade762c13.ttf")
      format("truetype"),
    url("../fonts/YuGothic/59b3b18e-14d5-4205-baac-ac837fb48e80.svg#59b3b18e-14d5-4205-baac-ac837fb48e80")
      format("svg");
  font-display: swap;
  font-weight: 500;
}
@font-face {
  font-family: "Yu Gothic Pr6 B";
  src: url("../fonts/YuGothic/e6deb3ac-e499-41e0-a775-64cad2058316.eot?#iefix");
  src: url("../fonts/YuGothic/e6deb3ac-e499-41e0-a775-64cad2058316.eot?#iefix")
      format("eot"),
    url("../fonts/YuGothic/709cb4d5-3d9a-41c6-8697-53d3e3a67bb6.woff2")
      format("woff2"),
    url("../fonts/YuGothic/4b897fd5-9e42-4e66-aab4-760abb41b50e.woff")
      format("woff"),
    url("../fonts/YuGothic/093632f1-675d-4e6a-a3ee-e6ab3c85a94b.ttf")
      format("truetype"),
    url("../fonts/YuGothic/9059d525-82a7-4518-afee-ae2037986eaa.svg#9059d525-82a7-4518-afee-ae2037986eaa")
      format("svg");
  font-display: swap;
  font-weight: 600;
}

@font-face {
  font-family: "Yu Gothic Std E Extra Bold";
  src: url("../fonts/YuGothic/385102af-845c-4bdd-a21c-03c57d95898a.eot?#iefix");
  src: url("../fonts/YuGothic/385102af-845c-4bdd-a21c-03c57d95898a.eot?#iefix")
      format("eot"),
    url("../fonts/YuGothic/8f1ff443-e920-4d96-a92e-1e4ac7eed1ab.woff2")
      format("woff2"),
    url("../fonts/YuGothic/11227b8c-f25c-4008-a337-9fd04bc4dfbd.woff")
      format("woff"),
    url("../fonts/YuGothic/b9888672-c3bb-4aa1-abff-1c0f8d02450f.ttf")
      format("truetype"),
    url("../fonts/YuGothic/d9d67844-cd85-4f0d-b16f-f0a486f2ac23.svg#d9d67844-cd85-4f0d-b16f-f0a486f2ac23")
      format("svg");
  font-display: swap;
  font-weight: 700;
}

@font-face {
  font-family: "Noto Sans SC";
  src: url("../../contents/fonts/NotoSansSC/NotoSansSC-Light.otf")
    format("opentype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Noto Sans SC";
  src: url("../../contents/fonts/NotoSansSC/NotoSansSC-Regular.otf")
    format("opentype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Noto Sans SC";
  src: url("../../contents/fonts/NotoSansSC/NotoSansSC-Medium.otf")
    format("opentype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Noto Sans SC";
  src: url("../../contents/fonts/NotoSansSC/NotoSansSC-Bold.otf")
    format("opentype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Noto Sans SC";
  src: url("../../contents/fonts/NotoSansSC/NotoSansSC-Bold.otf")
    format("opentype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Noto Sans SC";
  src: url("../../contents/fonts/NotoSansSC/NotoSansSC-Black.otf")
    format("opentype");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Noto Sans TC";
  src: url("../../contents/fonts/NotoSansTC/NotoSansTC-Light.otf")
    format("opentype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Noto Sans TC";
  src: url("../../contents/fonts/NotoSansTC/NotoSansTC-Regular.otf")
    format("opentype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Noto Sans TC";
  src: url("../../contents/fonts/NotoSansTC/NotoSansTC-Medium.otf")
    format("opentype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Noto Sans TC";
  src: url("../../contents/fonts/NotoSansTC/NotoSansTC-Bold.otf")
    format("opentype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Noto Sans TC";
  src: url("../../contents/fonts/NotoSansTC/NotoSansTC-Bold.otf")
    format("opentype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Noto Sans TC";
  src: url("../../contents/fonts/NotoSansTC/NotoSansTC-Black.otf")
    format("opentype");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

h1,
h2,
h3,
h4,
h5 {
  line-height: 1em;
}

b,
strong {
  @include font-semibold();

  a {
    @include font-medium();
  }
}
a {
  b,
  strong {
    @include font-medium();
  }
}

p {
  line-height: 20px;
}
