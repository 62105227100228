﻿/*Team List - Body*/
.special-topic.teamlist.container .thumbnail {
  float: left;
  margin-right: 4%;
  margin-bottom: 4%;
  width: 30%;
}

.special-topic.teamlist.container .thumbnail-detail {
  @include font-heading();
  width: 63%;
  padding-top: 0;
  font-size: 14px;
  display: inline-block;
  /*padding-bottom:20px;*/
}

.special-topic.teamlist.container .thumbnail-detail .practices {
  font-weight: normal;
}

.special-topic.teamlist.container h2,
.special-topic.teamlist.container h3 {
  @include font-heading();
  color: $color-black;
  font-size: 18px;
  text-transform: uppercase;
}

.special-topic.teamlist.container h3 {
  font-size: 16px;
  padding-bottom: 0;

  a {
    display: inline-block;
    line-height: 24px;
  }
}
.special-topic.teamlist.container span a {
  display: inline-block;
  line-height: 24px;
}

.special-topic.teamlist.container {
  width: 100%;
  position: relative;
  float: left;
  border-bottom: none;
}

.special-topic.teamlist.container .teamlist-intro {
  display: block;
  width: 100%;
}

.special-topic.teamlist.container .col1 {
  /*height: auto;*/
  width: 50%;
  /*display: inline-block;*/
}

.special-topic.teamlist.container .col1.hide-image .thumbnail-detail,
.special-topic.teamlist.container .col2.hide-image .thumbnail-detail {
  width: 100%;
  vertical-align: top;
}
.special-topic.teamlist.container .col2 {
  /*height: auto;*/
  width: 50%;
  /*position: absolute;
    right: 0;
    display: inline-block;*/
}
.team-memberblock {
  clear: both;
}
.special-topic.teamlist.container .col3 {
  width: 29.5%;
  height: 250px;
  /*display: inline-block;*/
  float: left;
  vertical-align: top;
  margin-right: 15px;
  border-bottom: 0 solid #f2f2f2;
  /*padding: 10px 0 0 0;*/
  padding-bottom: 20px;
}

.special-topic.teamlist.container .col3:last-child {
  padding-bottom: 25px;
}
.special-topic.teamlist.container .col1,
.special-topic.teamlist.container .col2 {
  border-bottom: 0 solid #f2f2f2;
  /*padding: 10px 0 0 0;*/
  /*height: 70px;*/
  float: left;
  padding-bottom: 20px;
}

.special-topic.teamlist.container .col3 .thumbnail-detail,
.special-topic.teamlist.container .col3 .thumbnail,
.special-topic.teamlist.container
  .team-memberblock.col-stack
  .member-block
  .col1
  .thumbnail,
.special-topic.teamlist.container
  .team-memberblock.col-stack
  .member-block
  .col1
  .thumbnail-detail {
  width: 100%;
  text-align: center;
}
.special-topic.teamlist.container .col3.hide-image .thumbnail-detail,
.special-topic.teamlist.container
  .team-memberblock.col-stack
  .member-block
  .col1.hide-image
  .thumbnail-detail {
  text-align: left;
}

.special-topic.teamlist.container .col3.hide-image .thumbnail,
.special-topic.teamlist.container
  .team-memberblock.col-stack
  .member-block
  .col1.hide-image
  .thumbnail {
  display: none;
}

.special-topic.teamlist.container .thumbnail.hide-image {
  display: none;
}

.special-topic.teamlist.container .thumbnail-detail h3 {
  @include font-heading();
  font-size: 14px;
  color: $color-black;
  text-transform: uppercase;
  margin: 0;
}

.special-topic.teamlist.container .thumbnail-detail h4 {
    @include font-medium();
    color: $color-black;
    margin-bottom: 0;
    margin-top: 2px;
    font-size: 16px;
    line-height: 17px;
}
.col-r .right-box .special-topic.teamlist.container .thumbnail,
.col-r .special-topic.teamlist.container .thumbnail {
  width: 34%;
}
.special-topic.teamlist.container .thumbnail-detail span {
    @include font-content();
    font-size: 14px;
  color: #000;
}

.special-topic.teamlist.container .thumbnail img {
  max-width: 100%;
}

.special-topic.teamlist.container .member-block {
  margin-bottom: 10px;
  display: block;
}

/* team list practice - 3 grid layout */
.special-topic.teamlist.container .team-memberblock.col-stack {
  width: 29.5%;
  height: auto;
  display: inline-block;
  vertical-align: top;
  margin-right: 15px;
  padding-bottom: 20px;
}

.special-topic.teamlist.container
  .team-memberblock.col-stack
  .member-block
  .col1 {
  width: 100%;
  padding-top: 3px;
  height: auto;
  min-height: 10px;
  padding-bottom: 2px;
}
div.clear {
  clear: both;
}

.col-l .special-topic.teamlist.container .show-more-link {
  display: none;
  clear: both;
}

.special-topic.teamlist.container .block-header {
  display: block;
}

.special-topic.teamlist.container .block-header h3 {
  @include font-content();
  font-size: 16px;
  text-transform: uppercase;
  padding-bottom: 15px;
  margin: 0;
}

.special-topic.teamlist.container .teamlist-intro.hide {
  display: none;
}
.special-topic.teamlist.container .manual.block-header {
  display: block;
}

.special-topic.teamlist.container
  .member-block
  ~ .member-block
  > .manual.block-header
  h3 {
  @include font-content();
  font-size: 16px;
  text-transform: uppercase;
}

.col-l .special-topic.teamlist.container .col1.show-image.hide,
.col-l .special-topic.teamlist.container .col2.show-image.hide,
.col-l .special-topic.teamlist.container .col3.show-image.hide,
.col-l .special-topic.teamlist.container .col1.hide-image.hide,
.col-l .special-topic.teamlist.container .col2.hide-image.hide,
.col-l .special-topic.teamlist.container .col3.hide-image.hide {
  display: inline-block;
}

.col-l .special-topic.teamlist.container .block-header.hide {
  display: block;
}

.special-topic.title.people-intro {
  padding-bottom: 0;
}

/*Team List Right Panel*/
.col-r .special-topic.teamlist.container {
  border-bottom: 2px solid #000;
  padding-top: 27px;
}

.col-r .special-topic.teamlist.container .col1,
.col-r .special-topic.teamlist.container .col2,
.col-r .special-topic.teamlist.container .col3 {
  width: 100%;
  height: auto;
}

.col-r .special-topic.teamlist.container .col2 {
  position: relative;
}

.col-r .special-topic.teamlist.container .show-more-link {
  display: block;
  padding: 0 0 10px;
  clear: both;
}

.col-r .special-topic.teamlist.container .col1.expand,
.col-r .special-topic.teamlist.container .col2.expand,
.col-r .special-topic.teamlist.container .col3.expand {
  display: none;
}

.toggle-items {
  display: inline-block !important;
}

.col-r .special-topic.teamlist.container .member-block.hide {
  display: none !important;
}

.col-r .special-topic.teamlist.container .member-block.expand {
  display: none;
}

.col-r .special-topic.teamlist.container .team-memberblock.col-stack {
  width: 100%;
  display: block;
  position: initial;
  padding-bottom: 0;
}

.col-r
  .special-topic.teamlist.container
  .team-memberblock.col-stack
  .member-block
  .col1 {
  padding-bottom: 10px;
}

.col-r
  div.right-box
  .special-topic.teamlist.container
  .team-memberblock.col-stack
  .member-block
  .col1 {
  padding-bottom: 0;
  padding-top: 0;
}

.col-r .special-topic.teamlist.container .col3 {
  margin: 0 0 5px 0;
}

.col-r .special-topic.teamlist.container .col3 .thumbnail {
  width: 34%;
}

.col-r
  .special-topic.teamlist.container
  .col1
  .thumbnail.hide-image
  + .thumbnail-detail,
.col-r
  .special-topic.teamlist.container
  .col2
  .thumbnail.hide-image
  + .thumbnail-detail,
.col-r
  .special-topic.teamlist.container
  .col3
  .thumbnail.hide-image
  + .thumbnail-detail {
  padding-top: 0;
}

.col-r .special-topic.teamlist.container .col1 .thumbnail-detail,
.col-r .special-topic.teamlist.container .col2 .thumbnail-detail,
.col-r .special-topic.teamlist.container .col3 .thumbnail-detail {
  width: 62%;
  text-align: left;
  padding-top: 22px;
}

.col-r .special-topic.teamlist.container .teamlist-intro h2 {
  @include font-heading();
  color: $color-purple;
  font-size: 16px;
  text-transform: uppercase;
}

.col-r .special-topic.teamlist.container .teamlist-intro h2 + p:empty {
  margin-bottom: 5px;
}

.col-r .special-topic.teamlist.container .manual.block-header h3 {
  @include font-content();
  font-size: 16px;
  text-transform: uppercase;
}

.col-r .special-topic.teamlist.container .teamlist-intro .manual {
  display: block;
}

.col-r
  .special-topic.teamlist.container
  .teamlist-intro
  ~ .teamlist-intro
  > .manual,
.col-r
  .special-topic.teamlist.container
  .teamlist-intro
  ~ .teamlist-intro
  > .rightpanel-mobile-title {
  display: none;
}

.col-r .special-topic.teamlist.container .hide {
  display: none;
}

.col-r .special-topic.teamlist.container.show-all .hide {
  display: inline-block;
}

/*[JPM]*/

.col-r .special-topic.teamlist.container .block-header.hide {
  display: none;
}

.col-r .special-topic.teamlist.container h2.rightpanel-mobile-title {
  display: block;
}

.col-r .special-topic.teamlist.container .thumbnail-detail h3 {
  font-size: 18px;
}

.col-r .special-topic.teamlist.container .thumbnail-detail p {
  font-size: 16px;
}

/* Team List - Accordion */
.team-list-accordion .special-topic.teamlist.container .teamlist-intro {
  display: none;
}

.team-list-accordion .special-topic.teamlist.container .thumbnail-detail h3 {
  padding-bottom: 0;
}

.team-list-accordion .special-topic.teamlist.container .col3,
.team-list-accordion
  .special-topic.teamlist.container
  .team-memberblock.col-stack {
  width: 28.5%;
}

.team-list-accordion .special-topic.teamlist.container .col1 .thumbnail,
.team-list-accordion .special-topic.teamlist.container .col2 .thumbnail {
  width: 33%;
}

.team-list-accordion .special-topic.teamlist.container .thumbnail-detail {
  width: 60%;
}

.team-list-accordion .special-topic.teamlist.container .col3 .thumbnail-detail {
  width: 100% !important;
}

/*Team List Mobile*/
@media only screen and (max-width: 768px) {
  .layout-mobile .special-topic.teamlist.container {
    background-color: #eeeeee;
    border-bottom: 0 solid #000000;
    margin-bottom: 20px;
    padding: 10px 0 5px 10px;
    width: 97%;
  }

  .layout-mobile .col-r .special-topic.teamlist.container {
    margin-top: 10px;
  }

  .layout-mobile .special-topic.teamlist.container .col1,
  .layout-mobile .special-topic.teamlist.container .col2,
  .layout-mobile .special-topic.teamlist.container .col3 {
    width: 100%;
    display: block;
    position: initial;
    border-bottom: 0 solid #f2f2f2;
    padding: 8px 0 10px 0;
  }

  .layout-mobile .special-topic.teamlist.container .col1 .thumbnail,
  .layout-mobile .special-topic.teamlist.container .col2 .thumbnail,
  .layout-mobile .special-topic.teamlist.container .col3 .thumbnail {
    display: none;
  }

  .layout-mobile .special-topic.teamlist.container .block-header h3 {
    @include font-content();
  }

  .layout-mobile .special-topic.teamlist.container .block-header {
    margin-top: 10px;
  }

  .layout-mobile .special-topic.teamlist.container .member-block {
    margin-bottom: 5px;
  }

  .layout-mobile .special-topic.teamlist.container .show-more-link {
    display: block;
    padding: 0 0 10px;
  }

  .layout-mobile .special-topic.teamlist.container .col1.expand,
  .layout-mobile .special-topic.teamlist.container .col2.expand,
  .layout-mobile .special-topic.teamlist.container .col3.expand {
    display: none;
  }

  .layout-mobile .special-topic.teamlist.container .member-block.expand {
    display: none;
  }

  .layout-mobile .special-topic.teamlist.container .col3 .thumbnail-detail,
  .special-topic.teamlist.container .col3 .thumbnail {
    width: 100%;
    text-align: left;
  }

  .layout-mobile .special-topic.teamlist.container .teamlist-intro .manual {
    display: block;
  }

  .layout-mobile .col-l .special-topic.teamlist.container .col1.show-image.hide,
  .layout-mobile .col-l .special-topic.teamlist.container .col2.show-image.hide,
  .layout-mobile .col-l .special-topic.teamlist.container .col3.show-image.hide,
  .layout-mobile .col-l .special-topic.teamlist.container .col1.hide-image.hide,
  .layout-mobile .col-l .special-topic.teamlist.container .col2.hide-image.hide,
  .layout-mobile
    .col-l
    .special-topic.teamlist.container
    .col3.hide-image.hide {
    display: none;
  }

  .layout-mobile .col-l .special-topic.teamlist.container .block-header.hide {
    display: none;
  }

  .layout-mobile
    .col-l
    .special-topic.teamlist.container
    h2.rightpanel-mobile-title {
    display: block;
  }

  .layout-mobile
    .col-l
    .special-topic.teamlist.container
    .teamlist-intro
    .manual.rightpanel-mobile-title {
    display: none;
  }

  .layout-mobile .col-r .special-topic.teamlist.container .teamlist-intro h2,
  .layout-mobile .col-l .special-topic.teamlist.container .teamlist-intro h2 {
    color: $color-purple;
    font-size: 16px;
    padding-bottom: 6px;
  }

  .layout-mobile
    .col-r
    .special-topic.teamlist.container
    .teamlist-intro
    ~ .teamlist-intro
    > .manual,
  .layout-mobile
    .col-l
    .special-topic.teamlist.container
    .teamlist-intro
    ~ .teamlist-intro
    > .manual,
  .layout-mobile
    .col-r
    .special-topic.teamlist.container
    .teamlist-intro
    ~ .teamlist-intro
    > .rightpanel-mobile-title,
  .layout-mobile
    .col-l
    .special-topic.teamlist.container
    .teamlist-intro
    ~ .teamlist-intro
    > .rightpanel-mobile-title {
    display: none;
  }

  .layout-mobile
    .col-r
    .special-topic.teamlist.container
    .col1
    .thumbnail
    + .thumbnail-detail,
  .layout-mobile
    .col-r
    .special-topic.teamlist.container
    .col2
    .thumbnail
    + .thumbnail-detail,
  .layout-mobile
    .col-r
    .special-topic.teamlist.container
    .col3
    .thumbnail
    + .thumbnail-detail,
  .layout-mobile
    .col-l
    .special-topic.teamlist.container
    .col1
    .thumbnail
    + .thumbnail-detail,
  .layout-mobile
    .col-l
    .special-topic.teamlist.container
    .col2
    .thumbnail
    + .thumbnail-detail,
  .layout-mobile
    .col-l
    .special-topic.teamlist.container
    .col3
    .thumbnail
    + .thumbnail-detail {
    padding-top: 0;
    width: auto;
  }

  .layout-mobile .special-topic.teamlist.container .thumbnail-detail h3 {
    font-size: 18px;
  }

  .layout-mobile
    .team-list-accordion
    .special-topic.teamlist.container
    .thumbnail-detail
    h3,
  .team-list-accordion .special-topic.teamlist.container .thumbnail-detail h3 {
    padding-bottom: 0;
  }

  .layout-mobile .team-list-accordion .special-topic.teamlist.container .col3,
  .team-list-accordion .special-topic.teamlist.container .col3 {
    width: 100%;
  }

  .layout-mobile .special-topic.teamlist.container .thumbnail-detail p {
    font-size: 16px;
  }

  .layout-mobile .team-list-accordion .special-topic.teamlist.container,
  .team-list-accordion .special-topic.teamlist.container {
    padding: 0 0 5px 0;
    background: none;
  }

  .team-list-accordion .special-topic.teamlist.container .block-header h3,
  .layout-mobile
    .team-list-accordion
    .special-topic.teamlist.container
    .block-header
    h3 {
    padding-bottom: 0;
  }

  .team-list-accordion,
  .layout-mobile .team-list-accordion {
    margin-top: 0;
  }

  .layout-mobile .col-r .person-depart-info:first-of-type, .layout-mobile .col-r .side-section-listing:first-of-type {
    padding-top: 15px;
  }

  .special-topic.teamlist.container {
    background-color: #eeeeee;
    border-bottom: 0 solid #000000;
    margin-bottom: 20px;
    padding: 20px 0px 5px 10px;
    width: 97%;
  }

  .col-r .special-topic.teamlist.container {
    margin-top: 10px;
    border-bottom: 0;
  }

  .special-topic.teamlist.container .col1,
  .special-topic.teamlist.container .col2,
  .special-topic.teamlist.container .col3,
  .special-topic.teamlist.container
    .team-memberblock.col-stack
    .member-block
    .col1 {
    width: 100%;
    display: block;
    position: initial;
    border-bottom: 0 solid #f2f2f2;
    height: auto !important;
    padding: 8px 0 10px 0;
  }

  .special-topic.teamlist.container .team-memberblock.col-stack {
    width: 100%;
    display: block;
    position: initial;
    padding-bottom: 0;
  }

  .team-list-accordion
    .special-topic.teamlist.container
    .member-block.col-stack {
    width: 100%;
    display: block;
  }

  .special-topic.teamlist.container .col1 .thumbnail,
  .special-topic.teamlist.container .col2 .thumbnail,
  .special-topic.teamlist.container .col3 .thumbnail {
    display: none;
  }

  .special-topic.teamlist.container .block-header h3 {
    @include font-content();
  }

  .special-topic.teamlist.container .block-header {
    margin-top: 10px;
  }

  .special-topic.teamlist.container .member-block {
    margin-bottom: 5px;
  }

  .special-topic.teamlist.container .show-more-link {
    display: block;
    padding: 0 0 10px;
  }

  .special-topic.teamlist.container .col1.expand,
  .special-topic.teamlist.container .col2.expand,
  .special-topic.teamlist.container .col3.expand {
    display: none;
  }

  .special-topic.teamlist.container .member-block.expand {
    display: none;
  }

  .special-topic.teamlist.container .col3 .thumbnail-detail,
  .special-topic.teamlist.container .col3 .thumbnail {
    width: 100%;
    text-align: left;
  }

  .special-topic.teamlist.container .teamlist-intro .manual {
    display: block;
  }

  .col-l .special-topic.teamlist.container .col1.show-image.hide,
  .col-l .special-topic.teamlist.container .col2.show-image.hide,
  .col-l .special-topic.teamlist.container .col3.show-image.hide,
  .col-l .special-topic.teamlist.container .col1.hide-image.hide,
  .col-l .special-topic.teamlist.container .col2.hide-image.hide,
  .col-l .special-topic.teamlist.container .col3.hide-image.hide {
    display: none;
  }

  .col-l .special-topic.teamlist.container .show-more-link {
    display: block;
  }
  .col-l .special-topic.teamlist.container .block-header.hide {
    display: none;
  }

  .col-l .special-topic.teamlist.container h2.rightpanel-mobile-title {
    display: block;
  }

  .col-l
    .special-topic.teamlist.container
    .teamlist-intro
    .manual.rightpanel-mobile-title {
    display: none;
  }

  .col-r .special-topic.teamlist.container .teamlist-intro h2,
  .col-l .special-topic.teamlist.container .teamlist-intro h2 {
    color: $color-purple;
    font-size: 16px;
    padding-bottom: 6px;
  }

  .col-r
    .special-topic.teamlist.container
    .teamlist-intro
    ~ .teamlist-intro
    > .manual,
  .col-l
    .special-topic.teamlist.container
    .teamlist-intro
    ~ .teamlist-intro
    > .manual,
  .col-r
    .special-topic.teamlist.container
    .teamlist-intro
    ~ .teamlist-intro
    > .rightpanel-mobile-title,
  .col-l
    .special-topic.teamlist.container
    .teamlist-intro
    ~ .teamlist-intro
    > .rightpanel-mobile-title {
    display: none;
  }

  .col-r .special-topic.teamlist.container .col1 .thumbnail + .thumbnail-detail,
  .col-r .special-topic.teamlist.container .col2 .thumbnail + .thumbnail-detail,
  .col-r .special-topic.teamlist.container .col3 .thumbnail + .thumbnail-detail,
  .col-l .special-topic.teamlist.container .col1 .thumbnail + .thumbnail-detail,
  .col-l .special-topic.teamlist.container .col2 .thumbnail + .thumbnail-detail,
  .col-l
    .special-topic.teamlist.container
    .col3
    .thumbnail
    + .thumbnail-detail {
    padding-top: 0;
    width: auto;
  }

  .special-topic.teamlist.container .manual.block-header h3 {
    @include font-content();
    font-size: 16px;
  }

  .special-topic.teamlist.container .thumbnail-detail h3 {
    font-size: 18px;
  }

  .special-topic.teamlist.container .thumbnail-detail p {
    font-size: 16px;
  }

  .col-r .person-depart-info:first-of-type, .col-r .side-section-listing:first-of-type {
    padding-top: 15px;
  }

  .col-r .special-topic.teamlist.container {
    padding-top: 20px;
  }

  .special-topic.teamlist.container .thumbnail-detail {
    padding-bottom: 0;
  }

  .right-box .special-topic.teamlist.container .thumbnail-detail {
    padding-bottom: 20px;
  }

  .right-box .special-topic.teamlist.container .col1,
  .right-box .special-topic.teamlist.container .col2,
  .right-box .special-topic.teamlist.container .col3,
  .right-box
    .special-topic.teamlist.container
    .team-memberblock.col-stack
    .member-block
    .col1 {
    padding: 0;
  }

  .right-box
    .special-topic.teamlist.container
    .team-memberblock
    .member-block
    .show-image:last-child {
    padding-bottom: 5px;
  }
}

/*Print*/
.print-wrapper .teamlist-print {
  @include font-semibold();
  padding-bottom: 15px;
}
.print-wrapper .teamlist-print a {
  text-decoration: underline;
}
.print-wrapper .page-heading {
  padding-bottom: 0px;
}

.teamlist-print u {
  color: #000000;
}
.teamlist-print span {
  @include font-heading();
  margin-right: 5px;
  color: $color-purple;
  font-size: 16px;
  text-transform: uppercase;
}

.teamlist.keycontact-print {
  display: none;
}

.print-wrapper .col-l .teamlist.keycontact-print {
  display: block;
}
.print-wrapper .special-topic.teamlist.container {
  display: none;
}

.print-wrapper .teamlist-print.secondarylistclass-print .teamlist-title {
  text-transform: uppercase;
}

.print-wrapper .teamlist-print.secondarylistclass-print {
  padding-top: 10px;
}

.thumbnail.show-image {
  margin: 0 !important;
  width: 64px !important;
  margin-right: 4% !important;
}

.thumbnail-detail {
  width: 140px;
}

.list-block .thumbnail-detail {
  width: 62%;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .list-block .thumbnail-detail {
    width: 100%;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .list-block .thumbnail-detail {
    width: 62%;

    &--no-image {
        width: 100%;
    }
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .list-block .thumbnail-detail {
    width: 62%;

    &--no-image {
        width: 100%;
    }
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .list-block .thumbnail-detail {
    width: 62%;

    &--no-image {
        width: 100%;
    }
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .list-block .thumbnail-detail {
    width: 62%;

    &--no-image {
        width: 100%;
    }
  }
}

.col1 .thumbnail-detail {
  margin-right: 20px;
}

.col3 .thumbnail.show-image {
  width: 100% !important;
}

.special-topic.teamlist.container .member-block .col1:last-child,
.special-topic.teamlist.container .member-block .col2:last-child {
  padding-bottom: 25px;
}

.col-r
  .right-box
  .special-topic
  .team-memberblock
  .member-block
  .col1
  .thumbnail-detail,
.right-box
  .special-topic
  .team-memberblock
  .member-block
  .col1
  .thumbnail-detail {
  width: 100% !important;
}

.col-r
  .right-box
  .special-topic.teamlist.container
  .team-memberblock
  .member-block
  .col1,
.col-r
  .right-box
  .special-topic.teamlist.container
  .team-memberblock
  .member-block
  .col2,
.col-r .special-topic.teamlist.container .team-memberblock .member-block .col1,
.col-r .special-topic.teamlist.container .team-memberblock .member-block .col2 {
  width: 100% !important;
}

.col-r
  .right-box
  .special-topic.teamlist.container
  .team-memberblock
  .member-block
  .col1.show-image
  .thumbnail-detail,
.col-r
  .right-box
  .special-topic.teamlist.container
  .team-memberblock
  .member-block
  .col2.show-image
  .thumbnail-detail,
.col-r
  .special-topic.teamlist.container
  .team-memberblock
  .member-block
  .col1.show-image
  .thumbnail-detail,
.col-r
  .special-topic.teamlist.container
  .team-memberblock
  .member-block
  .col2.show-image
  .thumbnail-detail {
  width: 62% !important;
}
.col-r .team-memberblock .member-block .col1.show-image .thumbnail-detail {
  margin-right: 0;
}

.col-r
  .right-box
  .special-topic.teamlist.container
  .team-memberblock
  .member-block
  .col1.show-image
  .thumbnail.show-image,
.col-r
  .right-box
  .special-topic.teamlist.container
  .team-memberblock
  .member-block
  .col2.show-image
  .thumbnail.show-image,
.col-r
  .special-topic.teamlist.container
  .team-memberblock
  .member-block
  .col1.show-image
  .thumbnail.show-image,
.col-r
  .special-topic.teamlist.container
  .team-memberblock
  .member-block
  .col2.show-image
  .thumbnail.show-image {
  width: 34% !important;
}

/*SINGLE COLUMN TEAM LISTS VIEW*/

.single-col {
  width: 100% !important;
  padding: 0;
  margin: 0;
  height: 0 !important;
}

.c-special-topic__block-header--sc {
  padding-bottom: 10px !important;
}

/*Names*/
.c-special-topic__name {
  @include font-content();
  width: 100% !important;
  padding: 0 !important;
  margin: 0 !important;
  text-transform: none !important;
  font-size: 16px !important;
}

.c-special-topic__position--sc {
  @include font-content();
  font-size: 14px;
  color: #000;
  text-transform: none !important;
  font-weight: bold;
}
.c-block-header--tl-title {
  @include font-content();
  text-transform: none !important;
  line-height: 20px;
  font-size: 16px !important;
  color: #414042 !important;
  font-weight: bold;
  padding: 10px 0 0 0 !important;
  margin: 0 !important;
}

.c-special-topic__container--sc {
  background-color: transparent !important;
  background-color: none !important;
  border-bottom: none !important;
  margin-bottom: 0 !important;
  padding: 0 !important;
}

.c-special-topic__title--sc {
  color: #000 !important;
}
.thumbnail-detail--single-col h3 a {
  text-decoration: underline !important;
}

.thumbnail-detail--single-col {
  width: 100% !important;
  padding: 0 !important;
}

.thumbnail-detail--single-col:last-child {
  padding-bottom: 5px !important;
}

.single-col h3 {
  margin: 0;
}

/*SINGLE COLUMN TEAM LISTS VIEW*/
@media screen and (min-width: 1025px) {
  .special-topic.teamlist.container .col3 .thumbnail-detail,
  .special-topic.teamlist.container .col3 .thumbnail,
  .special-topic.teamlist.container
    .team-memberblock.col-stack
    .member-block
    .col1
    .thumbnail,
  .special-topic.teamlist.container
    .team-memberblock.col-stack
    .member-block
    .col1
    .thumbnail-detail {
    width: 63%;
  }

  .special-topic.teamlist.container .col3.show-image .thumbnail-detail,
  .special-topic.teamlist.container .col3.show-image .thumbnail.show-image {
    width: 100%;
  }

  .col1 .thumbnail-detail {
    margin-right: 0;
  }
}
@media screen and (min-width: 769px) and (max-width: 1024px) {
  .thumbnail.show-image {
    width: 40px !important;
  }

  .thumbnail-detail:not(.thumbnail-detail--no-image) {
    width: 136px !important;
  }

  .col1 {
    width: 168px !important; /*64 + 12 + 140 + 20*/
  }

  .col2 {
    width: 155px !important; /*64 + 12 + 140*/
  }

  .col3 .thumbnail-detail {
    width: 100% !important;
  }

  .thumbnail-detail--single-col {
    width: 100% !important;
  }
}
@media only screen and (max-width: 951px) {
  .list-block .thumbnail-detail {
    padding-top: 0;
  }
}

@media screen and (max-width: 768px) {
  .col1 .thumbnail-detail,
  .col2 .thumbnail-detail,
  .col3 .thumbnail-detail {
    width: auto !important;
  }

  .special-topic.teamlist.container .col1,
  .special-topic.teamlist.container .col2 {
    height: auto;
  }
  /*[LDM] Fix show more/less and right box width*/
  .col-r
    .right-box
    .special-topic
    .team-memberblock
    .member-block
    .col1
    .thumbnail-detail,
  .col-r
    .special-topic
    .team-memberblock
    .member-block
    .col1
    .thumbnail-detail {
    width: 60% !important;
  }

  .col-r
    .right-box
    .special-topic.teamlist.container
    .team-memberblock
    .member-block
    .col1.show-image
    .thumbnail-detail,
  .col-r
    .special-topic.teamlist.container
    .team-memberblock
    .member-block
    .col1.show-image
    .thumbnail-detail {
    width: 100% !important;
  }

  .col-r
    .right-box
    .special-topic.teamlist.container
    .team-memberblock
    .member-block
    .col1.show-image
    .thumbnail-detail,
  .col-r
    .right-box
    .special-topic.teamlist.container
    .team-memberblock
    .member-block
    .col2.show-image
    .thumbnail-detail,
  .col-r
    .special-topic.teamlist.container
    .team-memberblock
    .member-block
    .col1.show-image
    .thumbnail-detail,
  .col-r.special-topic.teamlist.container
    .team-memberblock
    .member-block
    .col2.show-image
    .thumbnail-detail {
    width: 100% !important;
  }

  .special-topic .team-memberblock.en .member-block .col1.show-image,
  .special-topic .team-memberblock.en .member-block .col2.show-image,
  .special-topic .team-memberblock.en .member-block .col3.show-image {
    padding: 0 0 20px 0;
  }

  .special-topic .team-memberblock.en .member-block .col1.show-image:last-child,
  .special-topic .team-memberblock.en .member-block .col2.show-image:last-child,
  .special-topic
    .team-memberblock.en
    .member-block
    .col3.show-image:last-child {
    padding-bottom: 20px;
  }

  .special-topic
    .team-memberblock.en
    .member-block
    .col1.show-image
    .thumbnail-detail.en,
  .special-topic
    .team-memberblock.en
    .member-block
    .col2.show-image
    .thumbnail-detail.en,
  .special-topic
    .team-memberblock.en
    .member-block
    .col3.show-image
    .thumbnail-detail.en {
    padding-bottom: 0;
  }
}

@media screen and (max-width: 500px) {
  .col-r
    .right-box
    .special-topic
    .team-memberblock
    .member-block
    .col1
    .thumbnail-detail,
  .col-r
    .special-topic
    .team-memberblock
    .member-block
    .col1
    .thumbnail-detail {
    width: 63% !important;
  }
}
