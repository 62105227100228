﻿.breadcrumbs {
    display: none;
    float: left;
    padding: 13px 0 17px;
    width: calc(100% - 32px);

    @include for-print() {
        display: none;
    }

    &__wrapper {
        display: flex;
        flex-direction: row;
        list-style: none;
        flex-wrap: wrap;
    }

    span,
    a {
        @include font-semibold();
        color: $color-white;
        font-size: 14px;
        text-decoration: none;
    }

    span {
        line-height: 1.35em;
        margin-right: 8px;
    }

    a {
        &:focus,
        &:hover {
            color: $color-white;
            text-decoration: underline;
        }
    }

    &--mobile {
        display: block;

        @include for-print() {
            display: none;
        }
    }

    .overlap-ban & {
        margin-left: 16px;
    }

    @include respond-to('small') {
        .overlap-ban  & {
            margin-left: 1%;
        }
    }

    @include respond-to('contentLayoutPlusMargin')
    {
        .overlap-ban  & {
            margin-left: 0;
        }
    }

    @include respond-to('small') {
        .no-ban + .overlap-ban  & {
            margin-left: 0;
            margin-right: 0;
        }
    }
    
    


    @include respond-to('mobile-default') {
        display: block;
        padding: 13px 0 46px;
        width: 84%;      

        &--mobile {
            display: none;
        }
    }
}