.image-grid {
    --gap: 16px;

    @include for-print() {
        --gap: 20px;
    }

    &__heading {
        @include font(28px, 32px);
        @include font-heading();
        padding: 0 0 30px 0;

        &--center {
            text-align: center;
        }

        &--left {
            text-align: left;
        }

        &--right {
            text-align: right;
        }

        @include for-print() {
            @include font(18px, 19px);
            text-align: left;
        }

        h2 {
            @include font(28px, 32px);
            @include font-heading();

            @include for-print() {
                @include font(18px, 19px);
            }
        }
    }

    &__grid {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: var(--gap);
    }

    &__call-to-action-container {
        display: table;
        font-size: 11px;

        @supports (gap: 1px) {
            padding-top: var(--gap);
        }

        @include for-print() {
            display: none;
        }
    }

    &__call-to-action {
        @include font(16px, 20px);
        @include font-semibold();
        display: table-cell;
        padding-left: 5px;
    }
}
