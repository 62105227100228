@mixin showmore-trigger--base() {
    cursor: pointer;
    margin-top: 20px;
    text-transform: uppercase;
    transition: color 0.3s ease-in-out;
    width: fit-content;

    &:hover {
        color: $color-purple;
    }

    &:focus-visible {
        @include focus-outline();
    }
}

@mixin showmore-trigger() {
    @include showmore-trigger--base();
    @include font(18px, 20px);
    @include font-heading();
    color: $color-black;

    .icon {
        @include font(16px);
    }
}

@mixin showmore-trigger-label() {
    margin-left: 8px;
}

@mixin showmore-trigger--small() {
    @include showmore-trigger--base();
    @include font(14px, 16px);
    @include font-semibold();
    color: $color-very-dark-grey;
    vertical-align: middle;

    .icon {
        @include font(10px, 16px);
        vertical-align: middle;
    }
}

@mixin showmore-trigger-label--small() {
    margin-left: 8px;
    vertical-align: middle;
}