$color-black: #000;
$color-white: #fff;

$color-header: #2e2d2e;

$color-dark-grey: #414042;
$color-very-dark-grey: #707070;
$color-medium-grey: #231f20;
$color-light-grey: #7b7b7b;
$color-very-light-grey: #CCCCCC;
$color-ml-light-grey: #c8c8c8;
$color-extremely-light-grey: #eae7e7;
$color-white-smoke: #f0f0f0;
$color-grey: #757575;
$color-grey10: #eeeeee;
$color-gray50: #7f7f7f;
$color-gray60: #999999;
$color-gray85: #e4e4e4; 
$color-gray92: #ebebeb;
$color-gray97: #f7f7f7;
$color-footer-grey: #d7d7d7;
$color-silver: #bcbcbc;
$color-dropshadow: #00000033;
$color-gainsboro: #dcdcdc;

$color-purple: #4f3996;
$color-purple-bullet: #6244B3;
$color-purple-contrast: #6244b3;
$color-purple-dark: #622d85;
$color-purple-label: #4F3996;
$color-purple-light: #dcd7e9;
$color-purple-semilight: #b8afd5;
$color-purple-spotlight: #e6e0ea;

$color-red: #ff0000;

$color-light-blue: #93d8f7;