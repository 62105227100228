.titles {
    &__title {
        @include font-heading();
        @include font(48px, 49px);

        text-transform: uppercase;
        
        @include for-print() {
            @include font(32px, 33px);
        }
    }

    &__subtitle {
        @include font-heading();
        @include font(24px, 25px);
        @include for-print() {
            @include font(16px, 17px);
        }
    }
}