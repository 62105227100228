.force-font-en {
    font-family: $font-content !important;
}
.force-font-heading-en {
    font-family: $font-header !important;
}
.force-font-ja {
    font-family: $font-japanese-semibold, $font-content !important;
}
.force-font-zh {
    font-family: $font-simplified-chinese-content,$font-content !important;
}
.force-font-zh-cn {
    font-family: $font-simplified-chinese-content,$font-content !important;
}
.force-font-zh-tw {
    font-family: $font-traditional-chinese-content,$font-content !important;
}