.sub-navigation-links {
    background-color: $color-black;
    display: none;

    @include for-print() {
        display: none;
    }

    @include respond-to("small") {
        display: block;
    }

    & a {
        color: $color-white;
        text-decoration: none;

        &:active,
        &:hover,
        &:focus {
            color: $color-white;
        }
    }

    & ul {
        display: flex;
        justify-content: center;
        list-style-type: none;
        list-style: none;
        margin: 0;
        padding: 0;
        text-align: center;
    }

    & li {
        @include font-heading();
        display: inline-block;
        font-size: 12px;
        list-style: none;
        padding: 16px 18px 16px 18px;
        text-transform: uppercase;

        &:active {
            background: $color-purple;
            font-weight: normal;
        }

        &:hover {
            background: $color-purple-contrast;
        }

        @include respond-to("medium") {
            font-size: 14px;
        }
    }
}
