.card-grid {
    display: flex;
    flex-wrap: wrap;
    margin: 25px auto;
    max-width: 100%;
    position: relative;
    width: 100%;

    @include for-print() {
        page-break-inside: avoid;
        flex-direction: row;
        justify-content: center;
        margin: 0;
    }

    .card {
        @include for-print() {
            page-break-inside: avoid;
        }

        &:focus-visible {
            @include focus-outline();
        }
    }

    &--small {
        justify-content: center;
        max-width: 640px;

        @include respond-to('contentLayout') {
            justify-content: initial;
            max-width: initial;
        }

        .card {
            height: 125px;
            margin-top: 8px;
            padding-bottom: 0;
            padding-top: 0;
            width: 125px;

            &__abstract {
                font-size: 15px;
                line-height: 16px;
            }

            &__image {
                height: 125px;
                width: 125px;
            }

            &__title {
                @include font(16px, 20px);
                margin-bottom: 4px;
                text-transform: uppercase;
            }

            &__icon {
                bottom: 0px;
            }
        }
    }

    &--medium {
        align-items: center;
        flex-direction: column;
        justify-content: center;
        max-width: 640px;

        @include for-print() {
            flex-direction: row;
        }

        @include respond-to('xs') {
            align-items: initial;
            flex-direction: row;
        }

        @include respond-to('contentLayout') {
            justify-content: initial;
            max-width: initial;
        }

        .card {
            height: 248px;

            margin: {
                bottom: 20px;
                left: 10px;
                right: 10px;
            }

            width: calc(100% - 20px);

            @include respond-to('xs') {
                width: 290px;
            }

            &__addition-content {
                height: calc(100% - 32px);
                padding: 16px;
                width: calc(100% - 32px);
            }

            &__image {
                height: 248px;
                min-height: 248px;
                object-fit: cover;
                width: 100%;

                @include respond-to('xs') {
                    height: 248px;
                    min-height: auto;
                    object-fit: initial;
                    width: 290px;
                }
            }

            @include for-print() {
                height: 166.33px;
                width: calc(33% - 20px);

                &__image {
                    height: 166.33px;
                    min-height: auto;
                    object-fit: cover;
                    width: 100%;
                }
            }
        }
    }

    &--large {
        align-items: center;
        flex-direction: column;
        justify-content: center;

        @include for-print() {
            flex-direction: row;
        }

        @include respond-to('contentLayout') {
            align-items: initial;
            flex-direction: row;
            justify-content: initial;
        }

        .card {
            height: 225px;

            margin: {
                bottom: 20px;
                left: 10px;
                right: 10px;
            }

            width: calc(100% - 20px);

            @include respond-to('xs') {
                width: 450px;
            }

            &__addition-content {
                height: calc(100% - 32px);
                padding: 16px;
                width: calc(100% - 32px);
            }

            &__image {
                height: 225px;
                width: 100%;

                @include respond-to('xs') {
                    width: 450px;
                }
            }

            @include for-print() {
                height: 181px;
                width: calc(50% - 20px);

                &__image {
                    height: 100%;
                    width: 100%;
                }
            }
        }
    }
}
