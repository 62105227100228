﻿@mixin flex-table() {
    table {
        border-collapse: collapse;
        display: block;
        max-width: 100%;
        overflow-x: scroll;
        width: auto;

        @include respond-to('contentLayout') {
            display: block;
            overflow-x: auto;
        }

        &.table-alternating-row-color {
            tbody tr:nth-child(even) {
                background: $color-white-smoke;
            }
        }

        thead {

            td, th {
                @include font-semibold();
                @include font(16px, 20px);
                color: $color-white;
                border: solid 1px $color-purple-label;

                p, span {
                    @include font-semibold();
                    @include font(16px, 20px);
                    color: $color-white;
                }
            }

            tr {
                background: $color-purple-label;
            }
        }

        tbody {
            tr td {
                border: solid 1px $color-very-dark-grey;
                color: $color-dark-grey;

                li, p, span {
                    color: $color-dark-grey;
                }
            }
        }



        tr td {
            padding: 8px;
            min-width: 100px;

            &:first-child {
                border-left: 0;
            }

            &:last-child {
                border-right: 0;
            }

            &:only-of-type {
                margin: 0 50%;
            }

            li, p, span {
                @include font-content();
                @include font(16px, 20px);
                margin: 0;
            }
        }
    }
}
