$team-list__listing--gap: 16px;

@mixin text-only-in-print-seperator() {
    //this mixin is used to add the comma after each name when putting team list persons into a comma seperated list.
    //the list could be divided into __shown and __hidden parent divs which we ignore to show all items in the list
    @include font-semibold();
    content: ", ";
}

.team-list {
    &__heading {
        @include font(18px, 19px);
        @include font-content();
        color: $color-black;
        text-transform: uppercase;

        @include for-print() {
            @include font(16px, 17px);
        }
    }

    &__listing-container {
        margin-top: 8px;

        @include for-print() {
            .showmore__hidden,
            .showmore__shown {
                display: inline !important;
            }
        }
    }

    &__listing {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: $team-list__listing--gap;
        margin-top: $team-list__listing--gap;

        @include respond-to-container('xs') {
            grid-template-columns: repeat(2, 1fr);
        }

        @include respond-to-container('medium') {
            grid-template-columns: repeat(var(--team-list__listing--columns), 1fr);
        }

        @include for-print() {
            grid-template-columns: repeat(2, 1fr);
        }

        &--large-row-gap {
            @include respond-to-container('medium') {
                row-gap: 40px;
            }
        }
    }

    &__trigger {
        @include showmore-trigger--small();

        @include for-print() {
            display: none;
        }
    }

    &__trigger-label {
        @include showmore-trigger-label--small();
    }
    // aside
    &--aside &__heading {
        @include font(16px, 16px);
    }

    &--aside {
        .team-list__listing {
            grid-template-columns: repeat(1, 1fr);
        }
    }

    @include for-print() {
        &__abstract {
            @include font(14px, 16px);
            margin-top: 8px;
        }

        &__listing-container--text-only-in-print {
            all: unset;
            margin-top: 16px;

            .showmore__hidden,
            .showmore__shown {
                all: unset;

                .team-list__listing {
                    all: unset;
                }
            }

            .team-list-person {
                display: inline-block;
                margin-right: 2px !important;
                white-space: nowrap;

                &:not(:last-of-type):after {
                    @include text-only-in-print-seperator();
                }

                &__image-container,
                &__title,
                &__practices,
                &__offices,
                &__block {
                    display: none;
                }

                &__details-container {
                    display: inline-block;
                    margin-top: 0;
                }
            }

            .showmore__shown .team-list__listing {
                .team-list-person:not(:last-of-type):after {
                    @include text-only-in-print-seperator();
                }
            }
            //showmore hidden will only be present if there are persons hidden in non print mode
            .showmore__hidden .team-list__listing {
                .team-list-person:first-of-type {
                    &:before {
                        @include text-only-in-print-seperator();
                    }

                    .team-list-person__name {
                        margin-left: 2px;
                    }
                }
            }
        }
    }
}
