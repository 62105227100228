.modal {
    height: 100vh;
    left: 0;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: $z-index-lightbox;

    &__overlay {
        background: url('../images/overlay.png') repeat 0 0;
        height: 100vh;
        left: 0;
        opacity: 0.9;
        position: fixed;
        top: 0;
        width: 100vw;
        z-index: #{$z-index-lightbox - 2};
    }

    &__container {
        align-items: center;
        display: flex;
        height: 100vh;
        justify-content: center;
        opacity: 1;
        overflow: auto;
        position: relative;
        max-width: 100vw;
        z-index: #{$z-index-lightbox - 1};
    }

    &__content {
        background-color: $color-white;
        border: 10px solid $color-black;
        border-radius: 15px;
        display: inline-block;
        max-height: 80vh;
        max-width: $modal-width;
        padding: 8px;
        position: relative;
        width: inherit;

        @include respond-to('xs') {
            max-width: $modal-width--xs;
        }

        @include respond-to('largeipad') {
            max-width: $modal-width--largeipad;
        }
    }

    &__close-container {
        display: block;
        height: 21px;
        position: absolute;
        right: 0px;
        top: -50px;

        @media (orientation: landscape) and (max-height: 600px) {
            right: -45px;
            top: -5px;
        }
    }

    &__close-btn {
        background: none;
        border: none;
        color: $color-white;
        font-size: 30px;
        position: absolute;
        cursor: pointer;
        display: block;
        right: 0;
        text-align: center;

        &:focus-visible{
            @include focus-outline(5px);
        }
    }

    .mejs__overlay-button {
        cursor: pointer;
    }
}
