.ml-button {
    @include font-heading();
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: center;

    &:focus-visible {
        outline: $focus-border;
    }

    &:hover {
        color: $color-white;
    }

    &--hollow-white {
        background-color: transparent;
        border: 1px solid $color-white;
        color: $color-white;
        transition: background-color 0.4s;

        &.hover-white {
            &:hover {
                background-color: $color-white;
                color: $color-black;
            }
        }

        &:hover {
            background-color: $color-black;
        }
    }

    &--large {
        @include font(18px);
        height: 50px;
        line-height: 23px;
        width: 124px;
    }

    &--extra-large {
        @include font(18px, $font-iphonev: 16px);
        height: 40px;
        width: 250px;

        @include respond-to("iphonev") {
            height: 47px;
            width: 290px;
        }
    }

    &--rounded {
        border-radius: 24px;
    }
}
