﻿@use 'sass:color';

.header-search-form {
    @include responsive-component-margin-unstructured($global-page-margin);
    display: flex;
    max-width: $single-column-width;
    position: relative;
    width: 100%;

    input.autocomplete--keep-open {
        @include font-medium();
        @include font(16px);
        border: none;
        color: $color-black;
        padding: 15px;
        width: 100%;

        @include respond-to('small') {
            width: calc(100% - 20px);
        }
    }

    ul.autocomplete-list--show.autocomplete-list {
        margin-left: 0;
        margin-top: 49px;
        width: 100%;

        @include respond-to('small') {
            margin-top: 50px;
            width: calc(100% - 20px);
        }
    }

    &__submit-btn {
        @include font(18px, 23px);
        @include font-heading();
        align-items: center;
        background-color: $color-header;
        border: none;
        color: $color-white;
        cursor: pointer;
        display: flex;
        height: 40px;
        justify-content: center;
        position: absolute;
        right: 5px;
        transition: background-color $transition-timing $transition-function;
        top: calc(50% - 20px);
        width: 40px;

        &:focus-visible {
            outline: $focus-border;
        }

        @include hover-active {
            background-color: $color-black;
            color: $color-white;
        }

        @include respond-to('small') {
            background-color: transparent;
            border: 1px solid $color-white;
            height: 50px;
            position: initial;
            right: initial;
            top: initial;
            width: 124px;
        }
    }

    &__submit-label {
        display: none;

        @include respond-to('small') {
            display: block;
        }

        &--mobile {
            display: block;

            @include respond-to('small') {
                display: none;
            }
        }
    }
}
