﻿.alternating-list-expandable-item {
	padding: 24px 16px;

	@include for-print() {
		& + & {
			margin-top: 16px;
		}
	}

	&__content {
		display: inline-block;

		@include for-print() {
			@include font(14px, 16px);

			p {
				@include font(14px, 16px);
			}
		}
	}

	&__image {
		background-size: cover;
		background-repeat: no-repeat;
		float: right;
		margin: 0 0 20px 20px;
		min-height: 242px;
		width: 100%;

		@include respond-to("xxs") {
			width: 46%;
		}

		@include respond-to("small") {
			width: 35%;
		}
		
		img {
			max-width: 100%;
		}
	}

	h3 {
		@include font-semibold();
		color: $color-black !important;

		&.large {
			@include font(28px, 32px);

			@include respond-to("xxs") {
				@include font(32px, 36px);
			}

			@include for-print() {
				@include font(16px, 17px);
			}
		}

		&.medium {
			@include font(24px, 28px);

			@include for-print() {
				@include font(16px, 17px);
			}
		}

		&.small {
			@include font(18px, 22px);

			@include for-print() {
				@include font(14px, 15px);
			}
		}
	}

	&__abstract {
		margin-top: 16px;

		@include for-print() {
			@include font(14px, 16px);
		}

		p:first-child {
			margin-top: 16px;
		}

		a {
			color: $color-black;
			cursor: pointer;
			text-decoration: underline;

			&:focus,
			&:hover {
				color: $color-purple;
				text-decoration: none;
			}
		}
	}

	&__show-more-abstract-content {
		overflow: hidden;
		transition: height 0.3s ease-in-out;

		@include for-print() {
			overflow: visible;
		}
	}

	&__link {
		@include showmore-trigger--small();

		&-label {
			@include showmore-trigger-label--small;
		}

		&__wrapper {
			background: none;
			border: none;
			cursor: pointer;
			display: inline-block;
			margin-top: 20px;

			@include for-print() {
				display: none;
			}

			&:focus,
			&:hover {
				i, p, span {
					color: $color-purple;
				}
			}

			&:focus-visible {
				@include focus-outline();
			}
		}
	}
}

.experience-editor {
	.alternating-list-expandable-item__image{ 
		max-height: 300px;
		overflow: hidden;

		img {
			width: 100%;
			object-fit: cover;
		}
	}
	.alternating-list-expandable-item__abstract.rich-text__content{
		span{
			display: block;
		}
	}
}