﻿.breadcrumb {

    @include for-print() {
        display: none;
    }

    &--jumbotron.hide-in-mobile {
        background: transparent;
        padding: 8px 0;

        li, a {
            @include font-semibold();
            color: $color-white;
            font-size: $font-size-xsmall;
            text-decoration: none;
        }

        a {
            &:hover {
                color: $color-white;
                text-decoration: underline;
            }
        }
    }

    &--jumbotron.for-mobile-only {
        li, a {
            @include font-semibold();
        }
    }
}
