.ourwork {


    &__heading {
        @include font(36px, 46px);
        @include font-heading();
        color: $color-black;
    }

    &__abstract {
        @include richtext(30px, 36px, 'medium');
        color: $color-purple;
        margin-top: 20px;
        transition: all 0.3s ease-in-out;

        p {
            @include font-medium();
            color: $color-purple;
        }

        @include for-print() {
            @include richtext(18px, 22px, 'medium');
            margin-top: 0;
        }
    }

    &__list {
        margin-top: 24px;
    }
}
