.scroll-to-top {
    bottom: 60px;
    position: fixed;
    right: 0px;
    z-index: 1000;

    @include respond-to('small') {
        bottom: 30px;
        right: 1px;
    }

    &__button {
        background: none;
        border: none;
        cursor: pointer;
        margin: 0;
        padding: 0;

        img {
            height: 50px;
            width: 50px;
        }

        &:focus-visible {
            @include focus-outline();
        }
    }

    @include for-print() {
        display: none;
    }
    
}